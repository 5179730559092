'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AveragePledgeAmount',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M32.7046 68.096C33.3766 68.096 33.9366 68.656 33.9366 69.328V97.104C33.9366 97.7759 33.3766 98.336 32.7046 98.336H14.4486C13.7766 98.336 13.2166 97.7759 13.2166 97.104V69.44C13.2166 68.768 13.7766 68.208 14.4486 68.208H32.7046V68.096ZM32.7046 65.8559H14.4486C12.5446 65.8559 10.9766 67.424 10.9766 69.328V97.104C10.9766 99.0079 12.5446 100.576 14.4486 100.576H32.7046C34.6086 100.576 36.1766 99.0079 36.1766 97.104V69.44C36.1766 67.424 34.6086 65.8559 32.7046 65.8559Z"
        fill="#1D1D1B"
      />
      <path
        d="M64.7366 48.72C64.0646 48.72 63.6166 48.2719 63.6166 47.6V39.648C63.6166 38.864 63.1686 38.304 62.4966 38.304H45.6966C45.0246 38.304 44.5766 38.976 44.5766 39.648V47.488C44.5766 48.16 44.1286 48.608 43.4566 48.608C42.7846 48.608 42.3366 48.16 42.3366 47.488V39.648C42.3366 37.632 43.7926 36.064 45.6966 36.064H62.4966C64.4006 36.064 65.8566 37.632 65.8566 39.648V47.6C65.8566 48.2719 65.2966 48.72 64.7366 48.72Z"
        fill="#1D1D1B"
      />
      <path
        d="M62.4966 100.576H45.6966C43.7926 100.576 42.3366 99.0079 42.3366 96.992V66.976C42.3366 66.304 42.7846 65.8559 43.4566 65.8559C44.1286 65.8559 44.5766 66.304 44.5766 66.976V96.88C44.5766 97.6639 45.1366 98.2239 45.6966 98.2239H62.4966C63.1686 98.2239 63.6166 97.5519 63.6166 96.88V66.976C63.6166 66.304 64.0646 65.8559 64.7366 65.8559C65.4086 65.8559 65.8566 66.304 65.8566 66.976V96.88C65.8566 98.896 64.2886 100.576 62.4966 100.576Z"
        fill="#1D1D1B"
      />
      <path
        d="M99.2326 48.72C98.5606 48.72 98.1126 48.2719 98.1126 47.6V14.896C98.1126 14.112 97.5526 13.44 96.9926 13.44H79.9686C79.2966 13.44 78.8486 14.112 78.8486 14.896V47.7119C78.8486 48.3839 78.4006 48.832 77.7286 48.832C77.0566 48.832 76.6086 48.3839 76.6086 47.7119V14.896C76.6086 12.88 78.1766 11.2 79.9686 11.2H96.8806C98.7846 11.2 100.241 12.88 100.241 14.896V47.7119C100.353 48.2719 99.7926 48.72 99.2326 48.72Z"
        fill="#1D1D1B"
      />
      <path
        d="M96.9926 100.576H79.9686C78.0646 100.576 76.6086 98.896 76.6086 96.88V66.976C76.6086 66.304 77.0566 65.8559 77.7286 65.8559C78.4006 65.8559 78.8486 66.304 78.8486 66.976V96.768C78.8486 97.552 79.4086 98.2239 79.9686 98.2239H96.8806C97.5526 98.2239 98.0006 97.552 98.0006 96.768V66.976C98.0006 66.304 98.4486 65.8559 99.1206 65.8559C99.7926 65.8559 100.241 66.304 100.241 66.976V96.768C100.353 98.896 98.7846 100.576 96.9926 100.576Z"
        fill="#1D1D1B"
      />
      <path
        d="M99.3433 58.24H90.3833C89.7113 58.24 89.2633 57.792 89.2633 57.12C89.2633 56.448 89.7113 56 90.3833 56H99.3433C100.015 56 100.463 56.448 100.463 57.12C100.463 57.792 99.9033 58.24 99.3433 58.24ZM83.6633 58.24H74.7033C74.0313 58.24 73.5833 57.792 73.5833 57.12C73.5833 56.448 74.0313 56 74.7033 56H83.6633C84.3353 56 84.7833 56.448 84.7833 57.12C84.7833 57.792 84.2233 58.24 83.6633 58.24ZM67.9833 58.24H59.0233C58.3513 58.24 57.9033 57.792 57.9033 57.12C57.9033 56.448 58.3513 56 59.0233 56H67.9833C68.6553 56 69.1033 56.448 69.1033 57.12C69.1033 57.792 68.5433 58.24 67.9833 58.24ZM52.3033 58.24H43.3433C42.6713 58.24 42.2233 57.792 42.2233 57.12C42.2233 56.448 42.6713 56 43.3433 56H52.3033C52.9753 56 53.4233 56.448 53.4233 57.12C53.4233 57.792 52.8633 58.24 52.3033 58.24ZM36.6233 58.24H27.6633C26.9913 58.24 26.5433 57.792 26.5433 57.12C26.5433 56.448 26.9913 56 27.6633 56H36.6233C37.2953 56 37.7433 56.448 37.7433 57.12C37.7433 57.792 37.1833 58.24 36.6233 58.24ZM20.9433 58.24H11.9833C11.3113 58.24 10.8633 57.792 10.8633 57.12C10.8633 56.448 11.3113 56 11.9833 56H20.9433C21.6153 56 22.0633 56.448 22.0633 57.12C22.0633 57.792 21.5033 58.24 20.9433 58.24Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
