'use client'

import { useBreakpointValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

function useMountedBreakpoints<T>(
  values: Record<string, T>,
  def: T,
  opts?: {
    ssr?: boolean
    fallback?: string
  }
): T {
  const [val, setVal] = useState(def)
  const breakpointValue = useBreakpointValue(values, opts)

  useEffect(() => {
    setVal(breakpointValue ?? def)
  }, [breakpointValue, def])
  return val
}

export default useMountedBreakpoints
