'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AverageInterest',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M42.4472 98.5601C41.9992 98.5601 41.6632 98.3361 41.4392 98.0001L34.7192 85.2321L20.4952 82.7681C19.9352 82.6561 19.4872 82.0961 19.5992 81.5361L21.6152 67.2001L11.5352 56.7841C11.0872 56.3361 11.0872 55.6641 11.5352 55.2161L21.6152 44.8001L19.5992 30.4641C19.4872 29.9041 19.9352 29.3441 20.4952 29.2321L34.7192 26.6561L41.4392 13.8881C41.7752 13.3281 42.3352 13.1041 42.8952 13.4401L55.9992 19.7121L68.9912 13.3281C69.5512 13.1041 70.2232 13.3281 70.4472 13.7761L77.2792 26.6561L91.5032 29.1201C92.0632 29.2321 92.5112 29.7921 92.3992 30.3521L90.3832 44.6881L100.463 55.1041C100.911 55.5521 100.911 56.2241 100.463 56.6721L90.3832 67.0881L92.3992 81.4241C92.5112 81.9841 92.0632 82.5441 91.5032 82.6561L77.2792 85.2321L70.4472 98.0001C70.1112 98.5601 69.5512 98.7841 68.9912 98.4481L55.9992 92.0641L43.0072 98.4481C42.7832 98.5601 42.6712 98.5601 42.4472 98.5601ZM55.9992 89.7121C56.2232 89.7121 56.3352 89.7121 56.4472 89.8241L68.9912 95.9841L75.4872 83.6641C75.5992 83.3281 75.9352 83.1041 76.2712 83.1041L90.0472 80.6401L88.1432 66.8641C88.1432 66.5281 88.2552 66.1921 88.4792 65.9681L98.2232 56.0001L88.3672 45.9201C88.1432 45.6961 88.0312 45.3601 88.0312 45.0241L89.9352 31.2481L76.1592 28.8961C75.8232 28.7841 75.4872 28.6721 75.3752 28.3361L68.8792 16.0161L56.3352 22.1761C55.9992 22.2881 55.6632 22.2881 55.3272 22.1761L42.7832 16.0161L36.2872 28.3361C36.1752 28.6721 35.8392 28.8961 35.5032 28.8961L21.7272 31.2481L23.6312 45.0241C23.6312 45.3601 23.5192 45.6961 23.2952 45.9201L13.5512 55.8881L23.2952 65.8561C23.5192 66.0801 23.6312 66.4161 23.6312 66.7521L21.9512 80.6401L35.7272 82.9921C36.0632 83.1041 36.3992 83.2161 36.5112 83.5521L43.0072 95.8721L55.5512 89.7121C55.6632 89.8241 55.7752 89.7121 55.9992 89.7121Z"
        fill="#2A2A2A"
      />
      <path
        d="M64.9615 40.4319C64.4015 40.0959 63.7295 40.3199 63.3935 40.8799L46.5935 70C46.2575 70.56 46.4815 71.232 47.0415 71.568C47.2655 71.68 47.3775 71.68 47.6015 71.68C47.9375 71.68 48.3855 71.456 48.6095 71.12L65.4095 41.9999C65.7455 41.4399 65.5215 40.7679 64.9615 40.4319Z"
        fill="#FF6C4A"
      />
      <path
        d="M53.2015 46.1439C53.2015 42.7839 50.5135 40.0959 47.1535 40.0959C43.7935 40.0959 41.1055 42.7839 41.1055 46.1439C41.1055 49.504 43.7935 52.1919 47.1535 52.1919C50.5135 52.1919 53.2015 49.504 53.2015 46.1439ZM43.4575 46.1439C43.4575 44.0159 45.1375 42.3359 47.2655 42.3359C49.3935 42.3359 51.0735 44.0159 51.0735 46.1439C51.0735 48.2719 49.3935 49.9519 47.2655 49.9519C45.1375 49.9519 43.4575 48.2719 43.4575 46.1439Z"
        fill="#FF6C4A"
      />
      <path
        d="M64.8495 59.584C61.4895 59.584 58.8015 62.2719 58.8015 65.6319C58.8015 68.9919 61.4895 71.68 64.8495 71.68C68.2095 71.68 70.8975 68.9919 70.8975 65.6319C70.8975 62.2719 68.2095 59.584 64.8495 59.584ZM64.8495 69.328C62.7215 69.328 61.0415 67.648 61.0415 65.52C61.0415 63.392 62.7215 61.7119 64.8495 61.7119C66.9775 61.7119 68.6575 63.392 68.6575 65.52C68.6575 67.648 66.9775 69.328 64.8495 69.328Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
