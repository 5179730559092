'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoInnocent',
  viewBox: '0 0 38 50',
  path: (
    <>
      <path
        d="M0.156904 49.8723V45.5179H1.38075V49.8723H0.156904ZM0.784519 43.7379C1.00418 43.7379 1.19247 43.8015 1.34937 43.9604C1.50628 44.0876 1.56904 44.2783 1.56904 44.469C1.56904 44.6597 1.50628 44.8186 1.34937 44.9775C1.19247 45.1047 1.00418 45.1682 0.784519 45.1682C0.564854 45.1682 0.376571 45.1047 0.219667 44.9457C0.062763 44.8186 0 44.6279 0 44.4372C0 44.2465 0.062763 44.0876 0.219667 43.9604C0.376571 43.8015 0.564854 43.7379 0.784519 43.7379Z"
        fill="currentColor"
      />
      <path
        d="M2.63598 45.5173H3.85983V45.9623C4.14226 45.7398 4.3933 45.6127 4.61297 45.5173C4.83263 45.422 5.08368 45.3902 5.30334 45.3902C5.80544 45.3902 6.21339 45.5491 6.55857 45.8352C6.841 46.0894 6.99791 46.4709 6.99791 46.9794V49.84H5.77406V47.933C5.77406 47.4244 5.74268 47.0748 5.67991 46.9158C5.61715 46.7569 5.52301 46.6298 5.39749 46.5344C5.27196 46.4391 5.11506 46.4073 4.8954 46.4073C4.64435 46.4073 4.42468 46.4709 4.2364 46.6298C4.04811 46.7887 3.92259 46.9794 3.85983 47.2337C3.82845 47.3608 3.79707 47.6787 3.79707 48.1237V49.8718H2.57322V45.5173"
        fill="currentColor"
      />
      <path
        d="M8.15899 45.5173H9.38284V45.9623C9.66527 45.7398 9.91632 45.6127 10.136 45.5173C10.3556 45.422 10.6067 45.3902 10.8264 45.3902C11.3284 45.3902 11.7364 45.5491 12.0816 45.8352C12.364 46.0894 12.5209 46.4709 12.5209 46.9794V49.84H11.2971V47.933C11.2971 47.4244 11.2657 47.0748 11.2029 46.9158C11.1402 46.7569 11.046 46.6298 10.9205 46.5344C10.795 46.4391 10.6381 46.4073 10.4184 46.4073C10.1674 46.4073 9.9477 46.4709 9.75941 46.6298C9.57113 46.7887 9.44561 46.9794 9.38284 47.2337C9.35146 47.3608 9.32008 47.6787 9.32008 48.1237V49.8718H8.09623V45.5173"
        fill="currentColor"
      />
      <path
        d="M15.8787 46.4401C15.5021 46.4401 15.1569 46.5673 14.9059 46.7898C14.6548 47.0122 14.5293 47.3301 14.5293 47.6797C14.5293 48.0611 14.6548 48.3472 14.9059 48.6015C15.1569 48.824 15.4707 48.9511 15.8787 48.9511C16.2552 48.9511 16.6004 48.824 16.8515 48.6015C17.1025 48.379 17.228 48.0611 17.228 47.6797C17.228 47.2983 17.1025 47.0122 16.8515 46.7898C16.6004 46.5673 16.2866 46.4401 15.8787 46.4401ZM15.8473 45.423C16.318 45.423 16.7573 45.5184 17.1653 45.7409C17.5732 45.9316 17.887 46.2176 18.1381 46.5673C18.3577 46.9169 18.4833 47.2983 18.4833 47.7115C18.4833 48.1247 18.3577 48.5061 18.1381 48.8558C17.9184 49.2054 17.6046 49.4915 17.1967 49.6822C16.7887 49.8729 16.3494 49.9682 15.8787 49.9682C15.1569 49.9682 14.5607 49.7457 14.0586 49.3007C13.5565 48.8558 13.3054 48.3154 13.3054 47.6797C13.3054 47.0122 13.5879 46.4401 14.1527 45.9951C14.6548 45.6137 15.2197 45.423 15.8473 45.423Z"
        fill="currentColor"
      />
      <path
        d="M23.7866 46.4073L22.8452 46.9158C22.6569 46.7251 22.5 46.6298 22.3117 46.5344C22.1234 46.4709 21.9351 46.4391 21.7155 46.4391C21.3075 46.4391 20.9623 46.5662 20.6799 46.7887C20.4289 47.043 20.272 47.329 20.272 47.7105C20.272 48.0919 20.3975 48.3779 20.6485 48.6004C20.8996 48.8229 21.2448 48.9501 21.6527 48.9501C22.1548 48.9501 22.5628 48.7911 22.8452 48.4415L23.7239 49.0454C23.2531 49.6493 22.5628 49.9672 21.6841 49.9672C20.8996 49.9672 20.272 49.7447 19.8326 49.2997C19.3933 48.8547 19.1423 48.3144 19.1423 47.7105C19.1423 47.2973 19.2364 46.9158 19.4561 46.5344C19.6757 46.1848 19.9895 45.8987 20.3661 45.708C20.7427 45.5173 21.182 45.3902 21.6841 45.3902C22.1234 45.3902 22.5314 45.4855 22.8766 45.6445C23.2845 45.8352 23.5669 46.0894 23.7866 46.4073Z"
        fill="currentColor"
      />
      <path
        d="M27.8035 47.1712C27.7407 46.9487 27.5838 46.7262 27.3641 46.599C27.1445 46.4401 26.8934 46.3765 26.611 46.3765C26.2972 46.3765 26.0148 46.4719 25.7951 46.6308C25.6382 46.7262 25.5127 46.9169 25.3872 47.1712H27.8035ZM28.9332 47.9976H25.293C25.3558 48.3154 25.4813 48.5379 25.701 48.7286C25.9206 48.9193 26.2031 49.0147 26.5796 49.0147C26.9876 49.0147 27.3641 48.8875 27.6779 48.6015L28.6194 49.0465C28.3683 49.3643 28.0859 49.6186 27.7721 49.7775C27.4269 49.9364 27.0503 50 26.5796 50C25.8892 50 25.293 49.7775 24.8537 49.3643C24.4143 48.9193 24.1947 48.4108 24.1947 47.7433C24.1947 47.0758 24.4143 46.5355 24.8537 46.0905C25.293 45.6455 25.8579 45.423 26.5169 45.423C27.2386 45.423 27.8035 45.6455 28.2428 46.0905C28.6821 46.5355 28.9018 47.1076 28.9018 47.8069L28.9332 47.9976Z"
        fill="currentColor"
      />
      <path
        d="M29.8429 45.5173H31.0668V45.9623C31.3492 45.7398 31.6002 45.6127 31.8199 45.5173C32.0396 45.422 32.2906 45.3902 32.5103 45.3902C33.0124 45.3902 33.4203 45.5491 33.7655 45.8352C34.0479 46.0894 34.2048 46.4709 34.2048 46.9794V49.84H32.981V47.933C32.981 47.4244 32.9496 47.0748 32.8869 46.9158C32.8241 46.7569 32.73 46.6298 32.6044 46.5344C32.4789 46.4391 32.322 46.4073 32.1023 46.4073C31.8513 46.4073 31.6316 46.4709 31.4433 46.6298C31.2551 46.7887 31.1295 46.9794 31.0668 47.2337C31.0354 47.3608 31.004 47.6787 31.004 48.1237V49.8718H29.7802V45.5173"
        fill="currentColor"
      />
      <path
        d="M35.523 43.8966H36.7469V45.5176H37.5V46.4711H36.7469V49.8721H35.523V46.4711H34.864V45.5176H35.523V43.8966Z"
        fill="currentColor"
      />
      <path
        d="M18.2313 7.34496C15.564 7.82173 7.78154 9.18847 8.47191 7.44031C9.82129 4.10293 17.792 3.34009 17.792 3.34009C17.792 3.34009 17.9803 3.43545 18.2941 3.91222C18.6079 4.38899 21.3066 4.00757 21.8715 3.81686C22.405 3.62616 23.064 2.76797 26.7355 3.43545C30.3443 4.13471 20.5849 6.93176 18.2313 7.34496ZM31.2543 2.64083C29.2774 0.129848 24.8213 0.415913 23.3778 0.701975C23.3778 0.701975 20.7418 -0.664767 16.5054 0.41591C12.2376 1.4648 5.61627 4.80219 4.76899 8.87062C3.92171 12.9073 17.7292 9.60166 18.8903 9.3156C20.02 8.99776 33.2313 5.15182 31.2543 2.64083Z"
        fill="currentColor"
      />
      <path
        d="M19.2979 25.1127C19.2979 25.1127 19.7372 21.6164 17.9485 22.411C16.1284 23.2056 16.3481 27.4012 17.4778 28.2912C18.6075 29.2129 20.2079 28.2594 19.8941 26.7973C19.5803 25.3352 19.2979 25.1127 19.2979 25.1127Z"
        fill="currentColor"
      />
      <path
        d="M24.2876 24.2862C24.2876 24.2862 25.1977 23.0466 23.9111 22.1248C22.6245 21.1713 21.2751 23.5233 21.4634 25.0172C21.5889 26.0661 23.0324 27.56 24.0993 26.0343C24.4759 25.4304 23.9111 25.2079 24.2876 24.2862Z"
        fill="currentColor"
      />
      <path
        d="M26.4215 34.0119C20.6161 37.2857 14.6537 37.54 14.6537 37.54C6.77718 37.7625 7.68722 31.4056 7.68722 31.4056C8.03241 26.3836 11.1077 22.6012 11.1077 22.6012C14.2458 18.2467 15.5638 18.4057 15.5638 18.4057C16.0031 18.3421 16.6621 18.6599 16.6621 18.6599C17.2584 19.0731 17.2897 18.5964 17.2897 18.5964C17.6035 18.0878 17.8232 18.3739 17.8232 18.3739C18.9215 19.7406 20.0826 18.4374 20.0826 18.4374C20.773 17.7064 22.75 16.5304 22.75 16.5304C24.5701 15.4179 26.5157 17.4203 26.5157 17.4203C29.8734 20.1856 29.9676 26.6379 29.9676 26.6379C30.1872 32.2002 26.4215 34.0119 26.4215 34.0119ZM25.7939 13.5108C23.8797 12.8433 17.9801 17.8971 17.9801 17.8971C17.3211 15.3225 15.7521 15.4815 15.7521 15.4815C13.3985 15.4179 10.7625 18.7235 10.7625 18.7235C6.55751 23.2369 5.45918 27.1782 5.45918 27.1782C3.45082 32.4545 4.92571 35.9826 4.92571 35.9826C7.27927 40.6867 13.4927 40.7185 13.4927 40.7185C18.1998 41.0681 25.3546 37.3493 25.3546 37.3493C33.8588 33.5987 32.9487 25.4936 32.9487 25.4936C32.5408 15.3543 25.7939 13.5108 25.7939 13.5108Z"
        fill="currentColor"
      />
    </>
  ),
})
