'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Biodiversity',
  viewBox: '0 0 20 18',
  path: (
    <>
      <path
        d="M19.1 9.8C18.6 8.9 17.9 8 16.9 7.1L17.1 6.7C17.6 5.6 17.4 4.3 16.7 3.4L17.5 2.8C17.7 2.6 17.8 2.3 17.6 2.1C17.3 2 17 1.9 16.8 2.1L15.9 2.8C15.8 2.7 15.6 2.6 15.5 2.6L13.5 1.7C13.4 1.7 13.3 1.6 13.2 1.6L13.1 0.499999C13.1 0.199999 12.8 -9.83477e-07 12.5 0.099999C12.2 0.099999 12 0.399999 12.1 0.699999L12.2 1.6C11 1.6 9.8 2.3 9.3 3.5H9.2C7.8 3.3 6.6 3.4 5.5 3.7C2.9 4.4 1.1 6.6 0.1 8.3C0 8.4 0 8.6 0 8.7C0.1 8.8 0.2 9 0.3 9C2.4 9.7 4.2 10.1 5.7 10.1C5.9 10.1 6 10.1 6.2 10.1L5.8 11C5.1 12.6 5.8 14.5 7.4 15.2L8 15.4L7.7 16.1C7.6 16.4 7.7 16.6 8 16.8C8.1 16.8 8.1 16.8 8.2 16.8C8.4 16.8 8.6 16.7 8.7 16.5L9 15.8L9.5 16C9.9 16.2 10.4 16.3 10.8 16.3C12 16.3 13.2 15.6 13.7 14.4L14 13.7C15.1 15 16.7 16.2 18.9 17.2C19 17.2 19 17.2 19.1 17.2C19.2 17.2 19.3 17.2 19.3 17.1C19.4 17 19.5 16.9 19.6 16.8C20.2 14 20.1 11.7 19.1 9.8ZM10.2 3.7C10.5 2.9 11.3 2.4 12.2 2.4C12.5 2.4 12.8 2.5 13.1 2.6L15.1 3.5C15.6 3.7 16 4.2 16.3 4.7C16.5 5.2 16.5 5.8 16.3 6.4L16.2 6.5C15.4 5.9 14.5 5.4 13.5 4.8H13.4C13.4 4.8 13.4 4.8 13.3 4.7C12.1 4.2 11.1 3.9 10.2 3.7ZM12.2 9.8L10 8.8C10.8 8.3 11.5 7.7 12.2 6.8C12.1 7.8 12.1 8.8 12.2 9.8ZM1.3 8.3C2.3 6.8 3.8 5.2 5.8 4.7C6.7 4.5 7.7 4.4 8.8 4.6C9.1 4.6 9.5 4.7 9.8 4.8C10.6 5 11.4 5.2 12.3 5.5C10.9 7.2 9.5 8.3 8 8.8C7.9 8.8 7.8 8.9 7.7 8.9C7.4 8.9 7.1 9 6.7 9C5.3 9.2 3.4 9 1.3 8.3ZM10.8 15.3C10.5 15.3 10.2 15.2 9.9 15.1L7.9 14.2C7.4 14 7 13.6 6.8 13C6.6 12.5 6.6 12 6.8 11.5L12.7 14.1C12.3 14.8 11.6 15.3 10.8 15.3ZM13.1 13.2L7.1 10.6L7.4 9.9C7.6 9.9 7.7 9.8 7.9 9.8C8.2 9.7 8.6 9.6 8.9 9.4L12.4 11C12.5 11.4 12.7 11.8 12.9 12.2C13 12.4 13.1 12.6 13.2 12.8L13.1 13.2ZM18.7 16C16.8 15 15.3 13.9 14.4 12.7C14.2 12.4 14 12.1 13.8 11.8V11.7C12.9 9.8 13.1 7.7 13.5 6C14.3 6.5 15.1 7 15.7 7.5C16 7.7 16.3 7.9 16.5 8.2C17.3 8.9 17.9 9.7 18.3 10.4C19 11.8 19.2 13.7 18.7 16Z"
        fill="currentColor"
      />
    </>
  ),
})
