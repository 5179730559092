'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ChevronUp',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M7.99815 5.00077C8.04142 4.99897 8.08459 5.00635 8.12481 5.02244C8.16503 5.03853 8.20138 5.06295 8.23148 5.0941L14.2315 11.0941C14.2627 11.1251 14.2875 11.162 14.3044 11.2026C14.3214 11.2432 14.3301 11.2868 14.3301 11.3308C14.3301 11.3748 14.3214 11.4183 14.3044 11.459C14.2875 11.4996 14.2627 11.5365 14.2315 11.5674C14.168 11.6289 14.0832 11.6633 13.9948 11.6633C13.9065 11.6633 13.8216 11.6289 13.7581 11.5674L7.99815 5.80077L2.23148 11.5674C2.16917 11.6285 2.0854 11.6627 1.99815 11.6627C1.9109 11.6627 1.82712 11.6285 1.76481 11.5674C1.73357 11.5365 1.70877 11.4996 1.69185 11.459C1.67493 11.4183 1.66621 11.3748 1.66621 11.3308C1.66621 11.2868 1.67493 11.2432 1.69185 11.2026C1.70877 11.162 1.73357 11.1251 1.76481 11.0941L7.76481 5.0941C7.79491 5.06295 7.83126 5.03853 7.87148 5.02244C7.9117 5.00635 7.95487 4.99897 7.99815 5.00077Z"
      fill="#003F2C"
    />
  ),
})
