'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RenewableEnergyBig',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M63.3001 33.4C63.1 33 62.8001 32.7 62.3001 32.7C62.0001 32.7 55.8001 33.2 51.7001 36.8C51.7001 36.7 51.6 36.6 51.6 36.6C48.5 31.7 42.1001 30.9 39.0001 30.5C38.7001 30.5 38.4001 30.4 38.2001 30.4C37.7001 30.3 37.3001 30.6 37.1 31.1C35.1 37.2 35.3001 42.1 37.9001 45.7C41.3001 50.4 47.6001 51.2 49.9001 51.4C48.0001 56.9 49.1001 63.9 49.2001 64.2C49.3001 64.7 49.7001 65 50.2001 65C50.3001 65 50.3001 65 50.4001 65C50.9001 64.9 51.3001 64.4 51.2001 63.8C51.2001 63.7 50.1001 57 51.9001 52C56.9001 51.9 60.5001 50.4 62.5001 47.5C66.4001 42 63.4001 33.7 63.3001 33.4ZM49.8001 39.1C48.9001 40.6 48.4001 42.4 48.5001 44.3C46.3001 41.3 44.2001 39.3 44.1 39.2C43.7 38.8 43.1001 38.8 42.7001 39.2C42.3001 39.6 42.3001 40.2 42.7001 40.6C42.7001 40.6 46.6001 44.3 49.2001 49.2C46.7001 48.9 42.1 47.9 39.6 44.4C37.5001 41.5 37.3001 37.5 38.8001 32.4C41.6001 32.7 47.3001 33.5 49.9001 37.6C50.0001 37.8 50.2001 38.1 50.3001 38.3C50.1001 38.6 49.9001 38.8 49.8001 39.1ZM60.9001 46.3C59.4001 48.4 56.7001 49.7 53.1 49.9C55 46.9 57.3001 42.4 57.4001 42.2C57.7001 41.7 57.5001 41.1 57.0001 40.9C56.5001 40.7 55.9001 40.8 55.7001 41.3C55.7001 41.3 54.5 43.7 53.1 46.1C52.6 47.1 52.0001 48.1 51.5001 48.9C51.3001 48.2 51.1001 47.6 50.9001 47C50.4001 44.7 50.5001 42.6 51.2001 40.9C51.3001 40.6 51.4001 40.4 51.6 40.2C51.9 39.7 52.3001 39.2 52.7001 38.8C55.4001 36 59.9001 35.1 61.7001 34.9C62.2001 36.8 63.5001 42.5 60.9001 46.3Z"
        fill="currentColor"
      />
      <path
        d="M49.9999 11.9C52.6999 11.9 55.2999 12.4 57.6999 13.2C65.7999 16.1 71.5999 23.4 72.4999 31.8C73.2999 39.2 70.4999 46.6 64.8999 51.5C62.2999 53.7 60.7999 57 60.7999 60.6V66.6C58.6999 67.3 54.6999 68.3 49.7999 68.3C46.1999 68.3 42.7999 67.8 39.5999 66.7V60.7C39.5999 57.2 38.1999 54 35.5999 51.8C29.9999 47 27.0999 40.1 27.6999 32.8C28.4999 22.2 37.0999 13.3 47.4999 12.1C48.2999 11.9 49.1999 11.9 49.9999 11.9ZM49.9999 9.89999C49.0999 9.89999 48.1999 9.89999 47.2999 9.99999C35.8999 11.3 26.5999 21 25.6999 32.5C24.9999 40.4 28.1999 48 34.2999 53.2C36.3999 55 37.5999 57.6 37.5999 60.6V67.5C37.5999 67.8 37.6999 68 37.9999 68.2C42.1999 69.8 46.1999 70.3 49.7999 70.3C56.7999 70.3 61.9999 68.3 62.3999 68.2C62.6999 68.1 62.7999 67.8 62.7999 67.5V60.6C62.7999 57.6 63.9999 54.9 66.1999 53.1C72.2999 47.8 75.3999 39.8 74.4999 31.6C73.4999 22.4 67.0999 14.4 58.3999 11.3C55.6999 10.4 52.7999 9.89999 49.9999 9.89999Z"
        fill="black"
      />
      <path
        d="M49.7999 76.2C45.7999 76.2 41.9999 75.5 38.4999 74H38.3999C37.8999 73.7 37.4999 73.1 37.4999 72.5V71.6C37.4999 71 37.8999 70.6 38.4999 70.6C39.0999 70.6 39.4999 71 39.4999 71.6V72.4C42.6999 73.7 46.0999 74.3 49.6999 74.3C54.8999 74.3 58.9999 73 60.6999 72.4V71.9C60.6999 71.3 61.0999 70.9 61.6999 70.9C62.2999 70.9 62.6999 71.3 62.6999 71.9V72.6C62.6999 73.2 62.3999 73.8 61.7999 74.1C61.7999 74.1 61.6999 74.1 61.6999 74.2C59.9999 74.7 55.4999 76.2 49.7999 76.2Z"
        fill="black"
      />
      <path
        d="M49.7999 81.7C46.2999 81.7 42.9999 81 39.9999 79.5C39.9999 79.5 39.8999 79.5 39.8999 79.4C39.3999 79.1 39.0999 78.5 39.0999 77.9V77C39.0999 76.4 39.4999 76 40.0999 76C40.6999 76 41.0999 76.4 41.0999 77V77.8C43.7999 79 46.7999 79.7 49.7999 79.7C54.1999 79.7 57.6999 78.5 59.1999 77.8V77.2C59.1999 76.6 59.5999 76.2 60.1999 76.2C60.7999 76.2 61.1999 76.6 61.1999 77.2V77.9C61.1999 78.5 60.8999 79.1 60.3999 79.4L60.2999 79.5C58.7999 80.2 54.7999 81.7 49.7999 81.7Z"
        fill="black"
      />
      <path
        d="M49.8999 87.2C46.8999 87.2 43.9999 86.5 41.2999 85C41.2999 85 41.1999 85 41.1999 84.9C40.7999 84.6 40.4999 84 40.4999 83.4V82.5C40.4999 81.9 40.8999 81.5 41.4999 81.5C42.0999 81.5 42.4999 81.9 42.4999 82.5V83.3C44.7999 84.5 47.2999 85.1 49.8999 85.1C53.5999 85.1 56.5999 83.9 57.8999 83.3V82.7C57.8999 82.1 58.2999 81.7 58.8999 81.7C59.4999 81.7 59.8999 82.1 59.8999 82.7V83.4C59.8999 84 59.5999 84.5 59.1999 84.9C59.1999 84.9 59.0999 85 58.9999 85C57.6999 85.6 54.1999 87.2 49.8999 87.2Z"
        fill="black"
      />
    </>
  ),
})
