'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RightArrow',
  viewBox: '0 0 13 12',
  path: (
    <>
      <path
        d="M11.8033 5.64643C12.0795 5.64643 12.3033 5.87029 12.3033 6.14643C12.3033 6.42258 12.0795 6.64643 11.8033 6.64643L0.803329 6.64643C0.527187 6.64643 0.303329 6.42258 0.303329 6.14643C0.303329 5.87029 0.527187 5.64643 0.803329 5.64643L11.8033 5.64643Z"
        fill="currentColor"
      />
      <path
        d="M11.7498 5.8C11.945 5.60474 12.2616 5.60474 12.4569 5.8C12.6521 5.99527 12.6521 6.31185 12.4569 6.50711L7.50714 11.4569C7.31188 11.6521 6.99529 11.6521 6.80003 11.4569C6.60477 11.2616 6.60477 10.945 6.80003 10.7498L11.7498 5.8Z"
        fill="currentColor"
      />
      <path
        d="M12.4498 5.79288C12.645 5.98814 12.645 6.30472 12.4498 6.49999C12.2545 6.69525 11.9379 6.69525 11.7427 6.49999L6.79292 1.55024C6.59766 1.35498 6.59766 1.03839 6.79292 0.843132C6.98818 0.64787 7.30477 0.64787 7.50003 0.843132L12.4498 5.79288Z"
        fill="currentColor"
      />
    </>
  ),
})
