import { Flex, type IconProps, Button, type BoxProps } from '@chakra-ui/react'
import { type RefObject, type FC, useState, useEffect } from 'react'
import Slider from 'react-slick'

export interface Props extends BoxProps {
  sliderRef: RefObject<Slider>
  centeredControls?: boolean
  ArrowLeft: FC<IconProps>
  ArrowRight: FC<IconProps>
  isDark?: boolean
  navColor?: string
  activeSlide: number
  totalSlide?: number
  withArrow: boolean
}

const Nav: FC<Props> = ({
  sliderRef,
  centeredControls,
  ArrowLeft,
  ArrowRight,
  isDark,
  navColor,
  activeSlide,
  totalSlide = 0,
  withArrow,
  ...props
}) => {
  const setNext = () => {
    sliderRef.current?.slickNext()
  }

  const setPrevious = () => {
    sliderRef.current?.slickPrev()
  }

  const color = navColor || (isDark && 'dark_green') || 'white'
  const size = { base: '32px', md: '29px' }

  const marginToMove = 100 / totalSlide

  const [margin, setMargin] = useState(`0%`)

  useEffect(() => {
    setMargin(`${marginToMove * activeSlide}%`)
  }, [activeSlide, marginToMove])

  return (
    <Flex
      zIndex="1"
      justifyContent={{ base: 'center', md: centeredControls ? 'center' : 'flex-end' }}
      color={color}
      {...props}
      w="full"
    >
      {withArrow ? (
        <>
          <Button variant="ghost" onClick={setPrevious} aria-label="previous" name="previous">
            <ArrowLeft cursor="pointer" h={size} w={size} />
          </Button>
          <Button variant="ghost" onClick={setNext} ml="6px" aria-label="next" name="next">
            <ArrowRight onClick={setNext} h={size} w={size} />
          </Button>
        </>
      ) : (
        <Flex direction="row" h="6px" w="full" bg="light_gray" borderLeftRadius="8px" borderRightRadius="8px">
          <Flex
            height="full"
            w={`${marginToMove}%`}
            bg={'dark_green'}
            border="1px solid light_gray"
            borderLeftRadius={'8px'}
            borderRightRadius={'8px'}
            ml={margin}
            transition={'0.4s ease'}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default Nav
