'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'WasteReductionBig',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M81.8 22.4H18.2C17.6 22.4 17.2 22.8 17.2 23.4C17.2 24 17.6 24.4 18.2 24.4H22.1L27 81C27.1 82.9 28.7 84.4 30.5 84.4H68.1999C69.9999 84.4 71.5999 82.9 71.6999 81L77.9 24.4H81.8C82.4 24.4 82.8 24 82.8 23.4C82.8 22.8 82.3 22.4 81.8 22.4ZM69.6999 80.9C69.5999 81.8 68.9999 82.5 68.1999 82.5H30.6C29.8 82.5 29.2 81.8 29.1 80.9L24.2 24.4H75.9L69.6999 80.9Z"
        fill="black"
      />
      <path
        d="M44.5 20.6H55.5999C56.1999 20.6 56.5999 20.2 56.5999 19.6C56.5999 19 56.1999 18.6 55.5999 18.6H44.5C43.9 18.6 43.5 19 43.5 19.6C43.5 20.2 43.9 20.6 44.5 20.6Z"
        fill="black"
      />
      <path
        d="M50.3 67.4C50.3 67.3 50.3 67.3 50.3 67.2C50.3 67.1 50.3 67.1 50.2 67V66.9L47.4 62.7C47.1 62.2 46.5 62.1 46 62.4C45.5 62.7 45.4 63.3 45.7 63.8L47.3 66.1C42 65.1 37.8 61 36.5 55.7C35.1 49.8 37.8 43.5 43.1 40.5C43.6 40.2 43.7 39.6 43.5 39.1C43.2 38.6 42.6 38.5 42.1 38.7C36.1 42.2 33 49.4 34.6 56.1C36 62.1 40.8 66.7 46.7 68L44.4 69.6C44 69.9 43.9 70.5 44.2 71C44.4 71.3 44.7 71.4 45 71.4C45.2 71.4 45.4 71.3 45.6 71.2L49.9 68.1C50 68.1 50 68 50 67.9L50.1 67.8C50.1 67.7 50.1 67.7 50.2 67.6C50.2 67.5 50.2 67.5 50.2 67.4C50.2 67.4 50.2 67.4 50.2 67.3C50.3 67.4 50.3 67.4 50.3 67.4Z"
        fill="currentColor"
      />
      <path
        d="M65.5 48.9C64.1 42.9 59.3 38.3 53.4 37L55.7 35.4C56.1 35.1 56.2 34.5 55.9 34C55.6 33.6 55 33.5 54.5 33.8L50.2 36.9L50.1 37C50.1 37.1 50 37.1 50 37.2C50 37.2 50 37.3 49.9 37.3C49.9 37.4 49.9 37.4 49.8 37.5C49.8 37.5 49.8 37.5 49.8 37.6V37.7C49.8 37.8 49.8 37.8 49.8 37.9C49.8 38 49.8 38 49.9 38.1V38.2L52.7 42.4C52.9 42.7 53.2 42.8 53.5 42.8C53.7 42.8 53.9 42.7 54.1 42.6C54.6 42.3 54.7 41.7 54.4 41.2L52.8 38.9C58 40 62.3 44 63.5 49.3C64.9 55.2 62.4 61.2 57.2 64.4C56.7 64.7 56.6 65.3 56.9 65.8C57.1 66.1 57.4 66.3 57.8 66.3C58 66.3 58.2 66.3 58.3 66.2C64.2 62.5 67.1 55.6 65.5 48.9Z"
        fill="currentColor"
      />
    </>
  ),
})
