'use client'

import { createIcon } from '@chakra-ui/icons'

const Search = createIcon({
  displayName: 'Search',
  viewBox: '0 0 24 24',
  d: 'M21.35 20.71L16.07 15.39C17.4631 13.8149 18.1882 11.7594 18.0917 9.65885C17.9952 7.55834 17.0846 5.57794 15.553 4.13729C14.0213 2.69664 11.9889 1.90895 9.88641 1.94113C7.78393 1.97332 5.77659 2.82284 4.28973 4.30969C2.80288 5.79655 1.95336 7.80389 1.92118 9.90637C1.88899 12.0089 2.67668 14.0413 4.11733 15.5729C5.55798 17.1046 7.53839 18.0151 9.6389 18.1117C11.7394 18.2082 13.795 17.4831 15.37 16.09L20.64 21.42C20.7386 21.5094 20.8668 21.5593 21 21.56C21.0647 21.5609 21.1289 21.549 21.189 21.525C21.2491 21.5009 21.3038 21.4653 21.35 21.42C21.3969 21.3735 21.4341 21.3182 21.4595 21.2573C21.4848 21.1963 21.4979 21.131 21.4979 21.065C21.4979 20.999 21.4848 20.9336 21.4595 20.8727C21.4341 20.8117 21.3969 20.7564 21.35 20.71ZM3 10.06C3.00006 8.66108 3.41568 7.29375 4.19412 6.13147C4.97257 4.9692 6.07876 4.06438 7.3723 3.53183C8.66585 2.99928 10.0884 2.86302 11.4596 3.14033C12.8307 3.41764 14.0885 4.09602 15.0734 5.08939C16.0584 6.08276 16.726 7.34634 16.9916 8.71977C17.2572 10.0932 17.1088 11.5146 16.5652 12.8035C16.0217 14.0925 15.1074 15.1909 13.9385 15.9594C12.7697 16.7279 11.3988 17.1319 10 17.12C8.13881 17.1015 6.35996 16.3499 5.04947 15.0282C3.73897 13.7064 3.00255 11.9212 3 10.06Z',
  defaultProps: {
    w: '100%',
    h: '100%',
  },
})

export default Search
