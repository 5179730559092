'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'SingleCompany',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M77.3 84.2H77.2C76.8 84.1 76.5 83.9 76.4 83.5L75.6 80.6C75.5 81.1 75.4 81.6 75.4 82.2C75.4 82.7 74.9 83.1 74.4 83.1H74C73.5 83.1 73 82.7 73 82.1C73 81.7 73.2 81.4 73.5 81.2C73.7 80 74 78.8 74.4 77.6C74.7 76.9 75.1 76.7 75.4 76.7C76 76.6 76.6 76.9 76.9 77.8L77.7 80.6C77.9 80.1 78.1 79.6 78.3 79.1C78.4 78.7 78.8 78.5 79.2 78.4C79.6 78.4 80 78.6 80.1 79L80.5 80C81.2 79.7 81.7 79.4 82.3 79C82.7 78.7 83.4 78.8 83.7 79.2C84 79.6 83.9 80.3 83.5 80.6C82.7 81.2 81.8 81.7 80.8 82C80.2 82.2 79.7 82.1 79.3 81.9L79.2 81.8C78.9 82.5 78.5 83.1 78.1 83.7C77.9 84 77.6 84.2 77.3 84.2Z"
        fill="#FF6C4A"
      />
      <path
        d="M64.8 59H80.5C81.1 59 81.5 58.6 81.5 58C81.5 57.4 81.1 57 80.5 57H64.8C64.2 57 63.8 57.4 63.8 58C63.8 58.6 64.2 59 64.8 59Z"
        fill="#2A2A2A"
      />
      <path
        d="M64.8 69.1H80.5C81.1 69.1 81.5 68.7 81.5 68.1C81.5 67.5 81.1 67.1 80.5 67.1H64.8C64.2 67.1 63.8 67.5 63.8 68.1C63.8 68.7 64.2 69.1 64.8 69.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M89.3 46.1H56.1C55.8 46.1 55.5 46.4 55.5 46.7V51.3C52.3 46.6 47.4 42.5 40 41.3C46.7 39.4 51.7 33.3 51.7 25.9C51.7 17.1 44.6 9.89996 35.7 9.89996C26.8 9.89996 19.7 17 19.7 25.9C19.7 33 24.4 39 30.8 41.1C14.2 43.3 10.7 59.6 10 67C10 67.5 10.4 68 10.9 68H11C11.5 68 11.9 67.6 12 67.1C14 46.2 26.9 42.9 34.7 42.9H35.1C41 42.9 50.1 44.8 55.5 55V89.4C55.5 89.7 55.8 90 56.1 90H89.2C89.5 90 89.8 89.7 89.8 89.4V46.8C89.9 46.4 89.6 46.1 89.3 46.1ZM21.8 26C21.8 18.3 28.1 12 35.8 12C43.5 12 49.8 18.3 49.8 26C49.8 33.7 43.5 40 35.8 40C28.1 40 21.8 33.6 21.8 26ZM87.9 88H57.5V48.1H87.8V88H87.9Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
