'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RewardContrib',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M58.4 67.2H55C54.7 67.2 54.4 67.4 54.2 67.6C54.1 67.8 54 68 54 68.2C54 68.8 54.4 69.2 55 69.2H58.3C58.9 69.2 59.3 68.8 59.3 68.2C59.3 67.6 58.9 67.2 58.4 67.2Z"
        fill="#2A2A2A"
      />
      <path
        d="M58.4 78.3H55C54.4 78.3 54 78.7 54 79.3C54 79.9 54.4 80.3 55 80.3H58.3C58.9 80.3 59.3 79.9 59.3 79.3C59.3 78.7 58.9 78.3 58.4 78.3Z"
        fill="#2A2A2A"
      />
      <path
        d="M76.4 79.3C76.4 79.9 76.8 80.3 77.4 80.3H80.7C81.3 80.3 81.7 79.9 81.7 79.3C81.7 78.7 81.3 78.3 80.7 78.3H77.4C76.9 78.3 76.4 78.8 76.4 79.3Z"
        fill="#2A2A2A"
      />
      <path
        d="M77.5 67.2C77.3 67.2 77.1 67.3 76.9 67.4C76.7 67.6 76.5 67.9 76.5 68.2C76.5 68.5 76.7 68.8 76.9 69C77.1 69.1 77.2 69.2 77.5 69.2H80.9C81.5 69.2 81.9 68.8 81.9 68.2C81.9 67.9 81.7 67.6 81.5 67.4C81.3 67.3 81.1 67.2 80.9 67.2H77.5Z"
        fill="#2A2A2A"
      />
      <path
        d="M89 61.6H70.9C71.4 60.5 71.2 59.2 70.4 58.2C69.6 57.3 69.6 56 70.4 55.2C71 54.5 71.3 53.6 71.2 52.6C70.9 50.9 69.4 49.7 67.8 49.8C67 49.8 66.3 49.5 65.8 48.9C65.4 48.4 65.2 47.7 65.3 47C65.4 46.5 65.4 46 65.3 45.5C64.9 43.7 63.3 42.4 61.5 42.4C60.5 42.4 59.5 42.8 58.7 43.6C58 44.3 57.6 45.3 57.6 46.4C57.6 46.6 57.6 46.7 57.6 46.9C57.7 47.7 57.5 48.4 57 49C56.6 49.5 55.9 49.8 55.3 49.8C53.6 49.7 52.1 50.9 51.8 52.7C51.7 53.6 52 54.5 52.6 55.2C53 55.7 53.2 56.3 53.2 56.9C50.5 51.5 45.8 46.3 37.7 44.8C43.6 43.1 47.9 37.6 47.9 31.1C47.9 23.2 41.5 16.8 33.6 16.8C25.7 16.8 19.3 23.2 19.3 31.1C19.3 37.4 23.4 42.7 29.1 44.6C14.2 46.6 11.1 61.2 10.4 67.8C10.3 68.3 10.8 68.8 11.3 68.9H11.4C11.9 68.9 12.3 68.5 12.4 68C13.2 59.9 16.8 46.4 32.6 46.4H33C43.7 46.4 49.2 52.6 52.1 59.1C51.8 59.9 51.8 60.8 52.1 61.6H48.6C48 61.6 47.6 62 47.6 62.6V85C47.6 85.6 48 86 48.6 86H89C89.6 86 90 85.6 90 85V62.6C90 62 89.5 61.6 89 61.6ZM21.1 31.1C21.1 24.3 26.6 18.8 33.4 18.8C40.2 18.8 45.7 24.3 45.7 31.1C45.7 37.9 40.2 43.4 33.4 43.4C26.6 43.4 21.1 37.8 21.1 31.1ZM55 51.8C56.3 51.8 57.5 51.3 58.3 50.3C59.2 49.3 59.6 48 59.4 46.6C59.4 46.5 59.4 46.4 59.4 46.4C59.4 45.9 59.6 45.4 60 45C60.4 44.6 60.8 44.4 61.4 44.4C62.3 44.4 63.1 45 63.3 45.9C63.4 46.1 63.4 46.4 63.3 46.6C63.1 47.9 63.4 49.1 64.3 50.1C65.2 51.1 66.5 51.7 67.8 51.7C68.5 51.7 69.1 52.2 69.2 52.8C69.2 53.1 69.1 53.5 68.9 53.8C67.5 55.4 67.5 57.9 68.9 59.5C69.3 60 69.3 60.6 68.9 61.1C68.8 61.3 68.7 61.4 68.5 61.6H62.3L64.8 57.8C65.1 57.3 65 56.7 64.5 56.4C64 56 63.3 56.2 63 56.6L62.3 57.7V53.2C62.3 52.6 61.9 52.2 61.3 52.2C60.7 52.2 60.3 52.6 60.3 53.2V57.8L59.5 56.6C59.2 56.1 58.6 56 58.1 56.3C57.6 56.6 57.5 57.2 57.8 57.7L60.3 61.5H54C53.9 61.3 53.8 61.2 53.6 61C53.2 60.5 53.2 59.9 53.6 59.4C53.7 59.3 53.7 59.2 53.8 59.1C55 57.5 55 55.2 53.6 53.6C53.4 53.3 53.3 53 53.3 52.7C53.6 52.2 54.2 51.7 55 51.8ZM88 83.9H49.3V74.7H88V83.9ZM88 72.7H49.3V63.5H88V72.7Z"
        fill="#2A2A2A"
      />
      <path
        d="M88.3 42.9C87.9 42.5 87.3 42.6 86.9 43L83.4 47C83.3 47 83.2 46.9 83.1 46.9L85.6 36.9C85.7 36.4 85.4 35.8 84.9 35.7C84.4 35.6 83.8 35.9 83.7 36.4L81.2 46.5C81.1 46.5 81 46.5 80.9 46.5L79.4 41.3C79.2 40.8 78.7 40.5 78.2 40.6C77.7 40.8 77.4 41.3 77.5 41.8L79 46.7C76.8 47.4 74.9 49.2 74.5 51.7L74.8 61.6H76.8L76.5 51.9C77 49.9 78.8 48.4 80.9 48.4C81.2 48.4 81.5 48.4 81.8 48.5C83 48.7 84 49.4 84.7 50.4C85.3 51.3 85.6 52.5 85.4 53.6L84.9 54.6L79.4 53.6C78.9 53.5 78.3 53.9 78.2 54.4C78.1 54.9 78.5 55.5 79 55.6L84 56.5L82.9 58.9L80 58.4C79.5 58.3 78.9 58.7 78.8 59.2C78.7 59.7 79.1 60.3 79.6 60.4L82.1 60.8L81.8 61.6H84L87.3 54.2C87.8 51.8 86.9 49.5 85.2 48L88.5 44.2C88.7 43.8 88.7 43.2 88.3 42.9Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
