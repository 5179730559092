'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Company',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M52.6 84.9H52.5C52.1 84.8 51.8 84.6 51.7 84.2L51.2 82.2C51.2 82.5 51.1 82.7 51.1 83C51.1 83.5 50.6 83.9 50.1 83.9H49.7C49.2 83.9 48.7 83.5 48.7 82.9C48.7 82.5 48.9 82.2 49.2 82.1C49.4 81.1 49.6 80 50 79C50.3 78.3 50.7 78.1 51 78.1C51.5 78 52.2 78.3 52.4 79.2L53 81.3C53.1 81 53.2 80.7 53.4 80.4C53.5 80 53.9 79.8 54.3 79.7C54.7 79.7 55.1 79.9 55.2 80.3L55.5 81C56 80.8 56.5 80.5 56.9 80.2C57.3 79.9 58 80 58.3 80.4C58.6 80.8 58.5 81.5 58.1 81.8C57.4 82.3 56.6 82.7 55.8 83C55.3 83.2 54.8 83.1 54.5 82.9C54.2 83.4 53.9 83.9 53.6 84.4C53.2 84.7 52.9 84.9 52.6 84.9Z"
        fill="#FF6C4A"
      />
      <path
        d="M62.8 52.9H35.8C34.9 52.9 34.2 53.6 34.2 54.5V89.3C34.2 90.2 34.9 90.9 35.8 90.9H62.8C63.7 90.9 64.4 90.2 64.4 89.3V54.5C64.4 53.7 63.7 52.9 62.8 52.9ZM62.4 89H36.2V54.9H62.4V89Z"
        fill="#2A2A2A"
      />
      <path
        d="M42.8 64.7H55.7C56.3 64.7 56.7 64.3 56.7 63.7C56.7 63.1 56.3 62.7 55.7 62.7H42.8C42.2 62.7 41.8 63.1 41.8 63.7C41.8 64.3 42.3 64.7 42.8 64.7Z"
        fill="#2A2A2A"
      />
      <path
        d="M42.8 73H55.7C56.3 73 56.7 72.6 56.7 72C56.7 71.4 56.3 71 55.7 71H42.8C42.2 71 41.8 71.4 41.8 72C41.8 72.6 42.3 73 42.8 73Z"
        fill="#2A2A2A"
      />
      <path
        d="M78.1 37.1C81.6 35.8 84.2 32.3 84.2 28.3C84.2 23.1 80 18.9 74.8 18.9C69.6 18.9 65.4 23.1 65.4 28.3C65.4 32.2 67.8 35.5 71.1 36.9C68.1 37.4 65.7 38.7 63.7 40.8C61.3 38.5 58.1 36.6 53.9 35.8C59.3 34.1 63.2 29.1 63.2 23.1C63.3 16 57.4 10 50 10C42.6 10 36.7 16 36.7 23.3C36.7 29.1 40.4 34 45.6 35.8C41.3 36.4 38.1 38.1 35.7 40.4C33.8 38.6 31.6 37.5 28.9 37C32.3 35.6 34.6 32.2 34.6 28.4C34.6 23.2 30.4 19 25.2 19C20 19 15.8 23.2 15.8 28.4C15.8 32.4 18.3 35.8 21.9 37.2C13.7 38.9 11 46.5 10 51.3C9.9 51.8 10.2 52.4 10.8 52.5C10.9 52.5 10.9 52.5 11 52.5C11.5 52.5 11.9 52.2 12 51.7C14.1 41 20.5 38.8 25.6 38.8H25.8C25.8 38.8 25.8 38.8 25.9 38.8C29.4 38.8 32.2 39.9 34.3 41.9C30 46.9 28.7 53.7 28.3 57.5C28.2 58 28.7 58.5 29.2 58.6H29.3C29.8 58.6 30.2 58.2 30.3 57.7C31 50.2 34.4 37.7 49 37.6H49.4C64.1 37.6 68.2 50.2 69.4 57.7C69.5 58.2 70 58.6 70.5 58.5C71 58.4 71.4 57.9 71.3 57.4C70.7 53.7 69.2 47.4 65 42.4C67.2 40 70.2 38.8 74 38.7C74 38.7 74 38.7 74.1 38.7H74.3C79.4 38.7 85.9 41 88 51.6C88.1 52.1 88.5 52.4 89 52.4C89.1 52.4 89.1 52.4 89.2 52.4C89.7 52.3 90.1 51.8 90 51.2C89.1 46.5 86.3 38.9 78.1 37.1ZM38.7 23.3C38.7 17.1 43.8 12 50 12C56.2 12 61.3 17.1 61.3 23.3C61.3 29.5 56.3 34.6 50 34.6C43.7 34.6 38.7 29.5 38.7 23.3ZM17.9 28.4C17.9 24.3 21.2 21 25.3 21C29.4 21 32.7 24.3 32.7 28.4C32.7 32.5 29.4 35.8 25.3 35.8C21.2 35.8 17.9 32.4 17.9 28.4ZM67.4 28.4C67.4 24.3 70.7 21 74.8 21C78.9 21 82.2 24.3 82.2 28.4C82.2 32.5 78.9 35.8 74.8 35.8C70.7 35.8 67.4 32.4 67.4 28.4Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
