'use client'

import { Flex, FlexProps } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

import type { FrontCollectTypeType, Coordinates } from '@miimosa/common/types'

import useMountedBreakpoints from '../../lib/hooks/use_mounted_breakpoints'

import MapPoint from './MapPoint'
import mapStyles from './mapStyles'
import OverlayContainer from './OverlayContainer'

interface MapProps extends FlexProps {
  coordinates: Coordinates
  projectsMarkers: {
    latitude: number
    longitude: number
    projectId: number
    collectType: FrontCollectTypeType
  }[]
  zoom: number
  fromHome: boolean
  onClickProject?: (coordinates: Coordinates & { id: number }) => void
  visibleCoordinates?: { id: number } & Coordinates
  clearSelectedProject?: () => void
  propsPinMapUrl?: string
}

function GoogleMap({
  visibleCoordinates,
  onClickProject,
  projectsMarkers,
  coordinates,
  zoom,
  clearSelectedProject,
  fromHome,
  propsPinMapUrl,
  ...props
}: MapProps) {
  const ref = useRef(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)

  useEffect(() => {
    // we need to save google-map object for adding markers and routes in future
    if (ref.current && !map) {
      // here will connect map frame to div element in DOM by using ref hook
      const createdMap = new window.google.maps.Map(ref.current, {
        center: coordinates,
        zoom: zoom,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        mapTypeId: 'miimoStyle',
      })
      createdMap.mapTypes.set('miimoStyle', new google.maps.StyledMapType(mapStyles as any, { name: 'miimoStyle' }))
      setMap(createdMap)
    }
  }, [coordinates, zoom, map])

  const onSelectPoint = (coordinates: Coordinates, id: number) => {
    if (map && onClickProject) {
      // scroll to its id in list
      onClickProject({ ...coordinates, id })
      // when selecting point we center the map to the point
      map.panTo(coordinates)
      // then if mobile we offset it to show the card
      if (isMobile) {
        map.panBy(0, 100)
      }
    }
  }

  useEffect(() => {
    if (visibleCoordinates && map) {
      // when selecting point we center the map to the point
      map.panTo({ lat: visibleCoordinates.lat, lng: visibleCoordinates.lng })

      // then if mobile we offset it to show the card
      if (isMobile) {
        map.panBy(0, 100)
      }
    }
  }, [isMobile, map, visibleCoordinates])

  if (map && isMobile && visibleCoordinates && clearSelectedProject) {
    // onMobile we close the opened project card if user moves the map
    google.maps.event.addListener(map, 'dragend', function () {
      clearSelectedProject()
    })
  }

  return (
    <Flex ref={ref} id="map" {...props}>
      {projectsMarkers?.map(({ latitude, longitude, projectId, collectType }, index) => (
        <OverlayContainer
          map={map}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          key={index}
        >
          <MapPoint
            map={map!}
            visibleCoordinates={visibleCoordinates}
            onSelectPoint={onSelectPoint}
            fromHome={fromHome}
            projectId={projectId}
            collectType={collectType}
            longitude={longitude}
            latitude={latitude}
            propsPinMapUrl={propsPinMapUrl}
          />
        </OverlayContainer>
      ))}
    </Flex>
  )
}

export default GoogleMap
