'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Up',
  viewBox: '0 0 20 11',
  path: (
    <path
      d="M9.9971 0.50118C10.062 0.498473 10.1268 0.50955 10.1871 0.533681C10.2474 0.557812 10.302 0.594448 10.3471 0.641179L19.3471 9.64118C19.394 9.68766 19.4312 9.74296 19.4565 9.80389C19.4819 9.86482 19.495 9.93017 19.495 9.99618C19.495 10.0622 19.4819 10.1275 19.4565 10.1885C19.4312 10.2494 19.394 10.3047 19.3471 10.3512C19.2519 10.4434 19.1246 10.495 18.9921 10.495C18.8596 10.495 18.7322 10.4434 18.6371 10.3512L9.9971 1.70118L1.3471 10.3512C1.25363 10.4428 1.12797 10.4941 0.997097 10.4941C0.86622 10.4941 0.740559 10.4428 0.647095 10.3512C0.600231 10.3047 0.563036 10.2494 0.537651 10.1885C0.512266 10.1275 0.499195 10.0622 0.499195 9.99618C0.499195 9.93017 0.512266 9.86482 0.537651 9.80389C0.563036 9.74296 0.600231 9.68766 0.647095 9.64118L9.6471 0.641179C9.69224 0.594448 9.74677 0.557812 9.8071 0.533681C9.86742 0.50955 9.93218 0.498473 9.9971 0.50118Z"
      fill="currentColor"
    />
  ),
})
