'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Discover',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        d="M2.34982 14.6568C2.78168 16.2066 3.58037 17.6299 4.67856 18.8069C5.77675 19.9838 7.14224 20.8798 8.65988 21.4193V2.58667C6.28078 3.43755 4.31011 5.15236 3.14065 7.38936C1.97119 9.62635 1.68882 12.2212 2.34982 14.6568Z"
        fill="#2A2A2A"
      />
      <path
        d="M9.33288 2.36585C9.10177 2.42605 8.88072 2.50631 8.65967 2.58658V21.4193C10.5985 22.1136 12.7057 22.1872 14.6884 21.63C14.9295 21.5698 15.1506 21.4795 15.3817 21.3992V2.58658C13.4371 1.88868 11.3233 1.81155 9.33288 2.36585Z"
        fill="#F8DE61"
      />
      <path
        d="M15.3516 2.58667V21.3993C17.2967 20.7087 18.98 19.4337 20.1704 17.7495C21.3607 16.0653 21.9998 14.0544 21.9998 11.993C21.9998 9.93155 21.3607 7.92067 20.1704 6.23645C18.98 4.55224 17.2967 3.27729 15.3516 2.58667Z"
        fill="#DD3E41"
      />
    </g>
  ),
  defaultProps: {
    w: '24px',
    h: '24px',
  },
})
