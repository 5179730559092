'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Web',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM21 12.6C20 13.8 17.7 14.6 15 15C15.1 14 15.1 13 15.1 12C15.1 11.2 15.1 10.5 15 9.7C17 9.4 18.8 8.9 20.1 8.2C20.7 9.4 21 10.6 21 12C21 12.2 21 12.4 21 12.6ZM12 21C11.5 21 10.6 19.3 10.1 16.1C10.7 16.1 11.3 16.2 12 16.2C12.6 16.2 13.3 16.2 13.9 16.1C13.4 19.3 12.5 21 12 21ZM12 15.2C11.3 15.2 10.7 15.2 10 15.1C9.9 14.2 9.9 13.2 9.9 12C9.9 11.2 9.9 10.5 10 9.8C10.6 9.9 11.3 10 12 10C12.7 10 13.4 10 14.1 9.9C14.1 10.6 14.2 11.3 14.2 12.1C14.2 13.2 14.1 14.3 14.1 15.2C13.3 15.2 12.7 15.2 12 15.2ZM12 3.1C12.6 3.1 13.6 5.1 14 8.9C13.3 8.9 12.7 9 12 9C11.3 9 10.7 9 10 8.9C10.4 5.1 11.4 3.1 12 3.1ZM19.7 7.3C18.5 8 16.8 8.5 15 8.8C14.8 6.4 14.3 4.4 13.5 3.2C16.1 3.6 18.3 5.1 19.7 7.3ZM10.5 3.1C9.8 4.3 9.3 6.4 9 8.8C7.2 8.5 5.5 8 4.3 7.3C5.7 5.1 7.9 3.6 10.5 3.1ZM3.8 8.2C5.1 8.9 6.9 9.5 8.9 9.7C8.9 10.5 8.9 11.3 8.9 12C8.9 13 8.9 14 9 15C6.3 14.6 4.1 13.8 3.1 12.7C3.1 12.7 3.1 12.7 3 12.7C3 12.5 3 12.3 3 12.1C3 10.6 3.3 9.4 3.8 8.2ZM3.3 14.1C4.6 15 6.7 15.7 9.1 16C9.4 18 9.8 19.8 10.5 20.8C6.9 20.3 4.1 17.6 3.3 14.1ZM13.5 20.9C14.2 19.8 14.6 18.1 14.9 16.1C17.3 15.8 19.4 15.1 20.8 14.2C19.9 17.6 17.1 20.3 13.5 20.9Z"
      fill="currentColor"
    />
  ),
})
