'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Phone',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M16.6 21.51C13.17 21.51 9.59997 19.34 7.05997 15.72C4.51997 12.1 3.64997 7.79 4.81997 4.54C4.85217 4.4398 4.91516 4.35231 4.99997 4.29L7.63997 2.6L7.75997 2.55C8.47997 2.31 9.28997 2.85 9.99997 4C10.71 5.15 11.43 7.44 10.42 8.36L8.84997 9.73C8.54997 10 8.94997 11.55 10.3 13.45C11.65 15.35 12.81 16.11 13.15 15.92L14.92 14.92C16.09 14.29 17.92 15.7 18.78 16.73C19.64 17.76 19.94 18.78 19.47 19.4L19.38 19.49L16.91 21.39C16.8263 21.4613 16.7199 21.5004 16.61 21.5L16.6 21.51ZM5.70997 5C4.70997 7.94 5.54997 11.79 7.87997 15.11C10.21 18.43 13.43 20.4 16.44 20.48L18.69 18.73C18.76 18.55 18.6 18.04 18.02 17.36C17.08 16.25 15.8 15.56 15.4 15.78L13.63 16.78C12.15 17.57 10.08 14.86 9.47997 14.01C8.99997 13.4 6.86997 10.17 8.18997 9L9.71997 7.63C10.07 7.32 9.84997 5.82 9.09997 4.53C8.64997 3.74 8.25997 3.53 8.09997 3.53L5.70997 5Z"
        fill="currentColor"
      />
    </>
  ),
})
