'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Particulier',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M55.3 53.6C65.1 51.3 72.4 42.6 72.4 32.1C72.4 19.9 62.5 10 50.3 10C38.1 10 28.2 19.9 28.2 32.1C28.2 42.3 35.1 50.9 44.5 53.4C20.8 55.9 16 78.7 15 88.8C14.9 89.3 15.4 89.8 15.9 89.9H16C16.5 89.9 16.9 89.5 17 89C19.8 59.8 37.9 55.2 48.8 55.1H48.9H49.4C60.3 55.1 78.7 59.7 83.3 89C83.4 89.5 83.9 89.9 84.4 89.8C84.9 89.7 85.3 89.2 85.2 88.7C83.7 78.8 77.8 56.9 55.3 53.6ZM30.2 32.1C30.2 21 39.2 12 50.3 12C61.4 12 70.4 21 70.4 32.1C70.4 43.2 61.4 52.2 50.3 52.2C39.2 52.1 30.2 43.1 30.2 32.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M50.1 47.6C45.8 47.6 42.4 44.5 42.4 40.6C42.4 40 42.8 39.6 43.4 39.6C44 39.6 44.4 40 44.4 40.6C44.4 43.3 47 45.6 50.1 45.6C53.2 45.6 55.8 43.4 55.8 40.6C55.8 40 56.2 39.6 56.8 39.6C57.4 39.6 57.8 40 57.8 40.6C57.8 44.5 54.4 47.6 50.1 47.6Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
