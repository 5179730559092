'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AveragePledgeAmount',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M60.3662 15.6799C60.0302 15.6799 59.5822 15.6799 59.2462 15.6799C58.5742 15.6799 58.1262 16.2399 58.1262 16.7999C58.1262 17.3599 58.6862 17.9199 59.2462 17.9199C59.5822 17.9199 59.9182 17.9199 60.3662 17.9199C81.4222 17.9199 98.5582 35.0559 98.5582 56.1119C98.5582 65.1839 95.3102 74.0319 89.3742 80.8639C82.0942 89.3759 71.5662 94.1919 60.3662 94.1919C39.3102 94.1919 22.1742 77.0559 22.1742 55.9999C22.1742 51.1839 23.0702 46.5919 24.7502 42.1119C24.7502 41.9999 24.8622 41.7759 24.8622 41.6639L31.0222 53.1999C31.2462 53.5359 31.5822 53.7599 32.0302 53.7599C32.2542 53.7599 32.3662 53.7599 32.5902 53.6479C33.1502 53.3119 33.3742 52.6399 33.0382 52.0799L26.0942 39.1999C25.9822 38.9759 25.7582 38.7519 25.4222 38.6399C25.0862 38.5279 24.8622 38.6399 24.5262 38.7519L11.7582 45.6959C11.1982 46.0319 10.9742 46.7039 11.3102 47.2639C11.6462 47.8239 12.3182 48.0479 12.8782 47.7119L22.3982 42.5599C20.8302 46.9279 20.0462 51.4079 20.0462 56.1119C20.0462 78.3999 38.1902 96.5439 60.4782 96.5439C72.3502 96.5439 83.5502 91.3919 91.1662 82.4319C97.3262 75.0399 100.798 65.7439 100.798 56.1119C100.798 33.8239 82.6542 15.6799 60.3662 15.6799Z"
        fill="#2A2A2A"
      />
      <path
        d="M60.9296 56.1121L70.6736 46.3681C71.1216 45.9201 71.1216 45.2481 70.6736 44.8001C70.2256 44.3521 69.5536 44.3521 69.1056 44.8001L59.3616 54.5441L49.5056 44.8001C49.0576 44.3521 48.3856 44.3521 47.9376 44.8001C47.4896 45.2481 47.4896 45.9201 47.9376 46.3681L57.6816 56.1121L47.9376 65.8561C47.4896 66.3041 47.4896 66.9761 47.9376 67.4241C48.1616 67.6481 48.4976 67.7601 48.7216 67.7601C48.9456 67.7601 49.2816 67.6481 49.5056 67.4241L59.2496 57.6801L68.9936 67.4241C69.2176 67.6481 69.5536 67.7601 69.7776 67.7601C70.0016 67.7601 70.3376 67.6481 70.5616 67.4241C71.0096 66.9761 71.0096 66.3041 70.5616 65.8561L60.9296 56.1121Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
