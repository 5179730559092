'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoDanielNina',
  viewBox: '0 0 93 47',
  path: (
    <>
      <path d="M31.4028 14.0406H37.8689V16.8095H34.7202V23.0187H31.4028V14.0406Z" fill="#009EE3" />
      <path
        d="M42.1984 25.424C41.6361 26.2351 40.8208 26.7106 39.8087 26.7106C37.4472 26.7106 35.7604 24.6688 35.7604 22.0397C35.7604 19.4106 37.4472 17.3689 39.8087 17.3689C40.8208 17.3689 41.6361 17.8444 42.1984 18.6275L42.5358 17.5647H45.5158V26.5148H42.5358L42.1984 25.424ZM42.2827 22.0397C42.2827 21.0608 41.5799 20.3336 40.6521 20.3336C39.7244 20.3336 39.0215 21.0608 39.0215 22.0397C39.0215 23.0187 39.7244 23.7459 40.6521 23.7459C41.5799 23.7459 42.2827 23.0187 42.2827 22.0397Z"
        fill="#009EE3"
      />
      <path
        d="M15.2093 27.7734C12.3136 27.7734 10.0645 25.3401 10.0645 22.2355C10.0645 19.047 12.2855 16.6697 15.2093 16.6697C16.7555 16.6697 18.0206 17.2011 19.2576 18.3758L22.0128 15.3551C20.1011 13.4812 17.7957 12.5302 15.2093 12.5302C9.55843 12.5302 5.28516 16.6976 5.28516 22.2635C5.28516 27.8014 9.55843 31.9688 15.2093 31.9688C17.9363 31.9688 20.1854 30.9899 22.2939 28.8922L19.4826 25.8715C18.0206 27.214 16.7836 27.7734 15.2093 27.7734Z"
        fill="#009EE3"
      />
      <path
        d="M60.9784 18.2639C59.3478 17.7045 58.8698 17.4248 58.8698 16.9214C58.8698 16.4459 59.2916 16.1382 59.9944 16.1382C60.7816 16.1382 61.4844 16.4459 62.2435 17.1451L63.9303 15.1593C62.862 14.1524 61.4282 13.5651 59.9663 13.5651C57.661 13.5651 55.9179 15.0754 55.9179 17.0612C55.9179 18.8233 56.8176 19.7742 59.2916 20.5853C60.9784 21.1447 61.4001 21.3964 61.4001 21.8439C61.4001 22.4033 60.9221 22.7389 60.1068 22.7389C59.1791 22.7389 58.3357 22.4593 57.5766 21.8999L56.0585 23.9416C57.183 24.8087 58.6168 25.3121 60.1068 25.3121C62.5246 25.3121 64.3801 23.7458 64.3801 21.7041C64.3239 20.0259 63.4524 19.103 60.9784 18.2639Z"
        fill="#009EE3"
      />
      <path
        d="M64.9705 22.3474C64.9705 19.6624 67.0509 17.6765 69.8622 17.6765C72.6736 17.6765 74.754 19.6624 74.754 22.3474C74.754 25.0324 72.6736 27.0182 69.8622 27.0182C67.0509 27.0182 64.9705 25.0324 64.9705 22.3474ZM71.4928 22.3474C71.4928 21.3685 70.79 20.6413 69.8622 20.6413C68.9345 20.6413 68.2316 21.3685 68.2316 22.3474C68.2316 23.3263 68.9345 24.0535 69.8622 24.0535C70.8181 24.0535 71.4928 23.3263 71.4928 22.3474Z"
        fill="#009EE3"
      />
      <path
        d="M11.948 8.05516C11.948 6.6567 13.185 5.37012 14.6469 5.37012C16.1088 5.37012 17.3458 6.62873 17.3458 8.05516C17.3458 9.42564 16.1369 10.7402 14.6469 10.7402C13.185 10.7402 11.948 9.42564 11.948 8.05516Z"
        fill="#009EE3"
      />
      <path
        d="M27.0452 25.3401C26.4829 26.1512 25.6676 26.6267 24.6555 26.6267C22.294 26.6267 20.6072 24.5849 20.6072 21.9558C20.6072 19.3267 22.294 17.285 24.6555 17.285C25.6676 17.285 26.4829 17.7604 27.0452 18.5436L27.3825 17.4808H30.3626V26.4309H27.3825L27.0452 25.3401ZM27.1295 21.9558C27.1295 20.9769 26.4267 20.2497 25.4989 20.2497C24.5712 20.2497 23.8683 20.9769 23.8683 21.9558C23.8683 22.9347 24.5712 23.6619 25.4989 23.6619C26.4267 23.6619 27.1295 22.9347 27.1295 21.9558Z"
        fill="#009EE3"
      />
      <path
        d="M52.0663 21.5922C54.7652 22.4872 55.7211 23.4941 55.7211 25.3401C55.7211 27.5497 53.6969 29.2558 51.0823 29.2558C49.2549 29.2558 47.4838 28.5565 46.2187 27.3818L47.9336 25.2282C48.8332 26.0673 49.8734 26.4868 51.0542 26.4868C51.9538 26.4868 52.4599 26.1232 52.4599 25.5079C52.4599 25.0324 52.0101 24.7527 50.1827 24.1374C47.4838 23.2424 46.5279 22.2075 46.5279 20.3056C46.5279 18.152 48.4396 16.5298 50.9136 16.5298C52.488 16.5298 54.0624 17.1731 55.215 18.2639L53.3876 20.4175C52.5723 19.6624 51.8133 19.3267 50.9417 19.3267C50.1827 19.3267 49.7329 19.6624 49.7329 20.1658C49.761 20.6692 50.2951 20.9769 52.0663 21.5922Z"
        fill="#009EE3"
      />
      <path
        d="M37.5034 29.5635H39.2465C41.4393 29.5635 42.7607 30.7382 42.7607 32.6401C42.7607 34.5699 41.4393 35.7167 39.2465 35.7167H37.5034V29.5635ZM38.7404 30.6822V34.5979H39.3027C40.7084 34.5979 41.4956 33.8987 41.4956 32.6401C41.4956 31.3815 40.7084 30.6822 39.3027 30.6822H38.7404Z"
        fill="#181716"
      />
      <path
        d="M46.4716 35.1573C46.1905 35.5489 45.7406 35.8006 45.2346 35.8006C44.11 35.8006 43.2947 34.8776 43.2947 33.6469C43.2947 32.4163 44.11 31.4933 45.2346 31.4933C45.7687 31.4933 46.1905 31.745 46.4716 32.1366L46.6122 31.5772H47.6805V35.7167H46.6122L46.4716 35.1573ZM46.5278 33.6749C46.5278 33.0316 46.1061 32.5841 45.4876 32.5841C44.8972 32.5841 44.4474 33.0596 44.4474 33.6749C44.4474 34.2902 44.8691 34.7657 45.4876 34.7657C46.078 34.7377 46.5278 34.2902 46.5278 33.6749Z"
        fill="#181716"
      />
      <path
        d="M50.0703 33.1715V35.7167H48.8614V31.5772H49.9297L50.0703 32.1646C50.4077 31.745 50.8575 31.4933 51.3354 31.4933C52.3194 31.4933 52.9379 32.1925 52.9379 33.3113V35.7167H51.729V33.4791C51.729 32.9197 51.4479 32.5841 50.9699 32.5841C50.6326 32.5841 50.3233 32.7799 50.0703 33.1715Z"
        fill="#181716"
      />
      <path
        d="M54.4841 31.0179C54.0905 31.0179 53.7812 30.6822 53.7812 30.2907C53.7812 29.8991 54.1186 29.5635 54.4841 29.5635C54.8777 29.5635 55.215 29.8991 55.215 30.2907C55.1869 30.6822 54.8777 31.0179 54.4841 31.0179ZM55.0745 35.7167H53.8656V31.5772H55.0745V35.7167Z"
        fill="#181716"
      />
      <path
        d="M58.0264 35.8006C56.7613 35.8006 55.8055 34.8776 55.8055 33.6469C55.8055 32.4722 56.677 31.4933 57.914 31.4933C59.151 31.4933 60.0225 32.4722 60.0225 33.6469C60.0225 33.7868 60.0225 33.9546 59.9944 34.0385H56.9019C57.0425 34.514 57.4642 34.8496 57.9983 34.8496C58.3919 34.8496 58.7293 34.7098 59.0948 34.4021L59.6852 35.1573C59.2634 35.6048 58.6449 35.8006 58.0264 35.8006ZM56.9581 33.1994H58.9261C58.7855 32.7519 58.4482 32.4443 57.9421 32.4443C57.4361 32.4443 57.0706 32.7799 56.9581 33.1994Z"
        fill="#181716"
      />
      <path d="M61.9342 35.7167H60.7253V29.3677H61.9342V35.7167Z" fill="#181716" />
      <path
        d="M68.2316 33.3673H67.5287V33.9546C67.5287 35.1014 66.7134 35.8006 65.5327 35.8006C64.4081 35.8006 63.5647 35.1014 63.5647 34.1504C63.5647 33.591 63.9021 33.1156 64.4924 32.8638C64.0145 32.6121 63.7334 32.1926 63.7334 31.6891C63.7334 30.7941 64.4924 30.0949 65.5045 30.0949C66.1793 30.0949 66.8259 30.4305 67.2757 31.0179L66.6291 31.6612C66.2636 31.2696 65.8981 31.0458 65.5327 31.0458C65.0828 31.0458 64.7736 31.3255 64.7736 31.7171C64.7736 32.1087 65.0828 32.4163 65.5608 32.4163H68.2316V33.3673ZM66.4323 33.8987V33.3673H65.6451C65.0547 33.3673 64.6611 33.647 64.6611 34.0945C64.6611 34.514 65.0266 34.8217 65.5327 34.8217C66.0668 34.8217 66.4323 34.514 66.4323 33.8987Z"
        fill="#181716"
      />
      <path
        d="M70.7056 31.5772V35.7167H69.5248V29.5355H70.7337L73.4888 33.7029V29.5355H74.6696V35.7167H73.4607L70.7056 31.5772Z"
        fill="#181716"
      />
      <path
        d="M76.4128 31.0179C76.0192 31.0179 75.71 30.6822 75.71 30.2907C75.71 29.8991 76.0473 29.5635 76.4128 29.5635C76.8064 29.5635 77.1437 29.8991 77.1437 30.2907C77.1156 30.6822 76.8064 31.0179 76.4128 31.0179ZM77.0032 35.7167H75.7943V31.5772H77.0032V35.7167Z"
        fill="#181716"
      />
      <path
        d="M79.421 33.1715V35.7167H78.2121V31.5772H79.2804L79.421 32.1646C79.7583 31.745 80.2082 31.4933 80.6861 31.4933C81.6701 31.4933 82.2886 32.1925 82.2886 33.3113V35.7167H81.0797V33.4791C81.0797 32.9197 80.7985 32.5841 80.3206 32.5841C79.9833 32.5841 79.6459 32.7799 79.421 33.1715Z"
        fill="#181716"
      />
      <path
        d="M86.3087 35.1573C86.0276 35.5489 85.5778 35.8006 85.0717 35.8006C83.9472 35.8006 83.1319 34.8776 83.1319 33.647C83.1319 32.4163 83.9472 31.4933 85.0717 31.4933C85.6059 31.4933 86.0276 31.7451 86.3087 32.1366L86.4493 31.5772H87.5176V35.7167H86.4493L86.3087 35.1573ZM86.365 33.6749C86.365 33.0316 85.9433 32.5841 85.3248 32.5841C84.7344 32.5841 84.2846 33.0596 84.2846 33.6749C84.2846 34.2902 84.7063 34.7657 85.3248 34.7657C85.9152 34.7378 86.365 34.2902 86.365 33.6749Z"
        fill="#181716"
      />
      <path
        d="M7.39355 39.017H8.93981V39.3247H7.75903V40.1917H8.85546V40.4994H7.75903V41.5902H7.39355V39.017Z"
        fill="#181716"
      />
      <path
        d="M10.1205 41.6461C9.61447 41.6461 9.22088 41.2546 9.22088 40.7511C9.22088 40.2477 9.58635 39.8561 10.1205 39.8561C10.6547 39.8561 11.0202 40.2477 11.0202 40.7511C11.0202 41.2825 10.6266 41.6461 10.1205 41.6461ZM10.1205 41.3385C10.4298 41.3385 10.6547 41.0867 10.6547 40.7511C10.6547 40.4155 10.4298 40.1638 10.1205 40.1638C9.81126 40.1638 9.58635 40.4155 9.58635 40.7511C9.58635 41.0867 9.81126 41.3385 10.1205 41.3385Z"
        fill="#181716"
      />
      <path
        d="M11.8074 40.4994V41.6182H11.47V39.912H11.7792L11.8355 40.1638C11.976 39.968 12.1728 39.8561 12.3977 39.8561C12.8194 39.8561 13.0444 40.1358 13.0444 40.6113V41.6182H12.707V40.6672C12.707 40.3875 12.5664 40.1917 12.3134 40.1917C12.0885 40.1917 11.9198 40.3036 11.8074 40.4994Z"
        fill="#181716"
      />
      <path
        d="M14.8156 41.3664C14.675 41.5342 14.4782 41.6461 14.2252 41.6461C13.7472 41.6461 13.3818 41.2545 13.3818 40.7511C13.3818 40.2477 13.7472 39.8561 14.2252 39.8561C14.4501 39.8561 14.6469 39.968 14.7874 40.1358V38.9331H15.181V41.5902H14.8718L14.8156 41.3664ZM14.8437 40.7511C14.8437 40.4155 14.6188 40.1638 14.3095 40.1638C14.0003 40.1638 13.7754 40.4155 13.7754 40.7511C13.7754 41.0867 14.0003 41.3385 14.3095 41.3385C14.6188 41.3385 14.8437 41.0867 14.8437 40.7511Z"
        fill="#181716"
      />
      <path
        d="M17.0084 41.3664C16.8679 41.5342 16.6711 41.6461 16.418 41.6461C15.9401 41.6461 15.5746 41.2545 15.5746 40.7511C15.5746 40.2477 15.9401 39.8561 16.418 39.8561C16.643 39.8561 16.8679 39.968 17.0084 40.1358L17.0365 39.8841H17.3458V41.5902H17.0365L17.0084 41.3664ZM17.0365 40.7511C17.0365 40.4155 16.8116 40.1638 16.5024 40.1638C16.1931 40.1638 15.9682 40.4155 15.9682 40.7511C15.9682 41.0867 16.1931 41.3385 16.5024 41.3385C16.7835 41.3385 17.0365 41.0867 17.0365 40.7511Z"
        fill="#181716"
      />
      <path
        d="M17.6831 39.94L18.0486 39.912H18.0767L18.1329 39.4366H18.4141V39.912H19.0044V40.2197H18.4141V41.0308C18.4141 41.2266 18.5265 41.3385 18.6671 41.3385C18.7514 41.3385 18.8639 41.2825 18.9482 41.1986L19.1169 41.4224C18.9763 41.5622 18.8076 41.6182 18.639 41.6182C18.2735 41.6182 18.0767 41.3664 18.0767 41.0028V40.1917H17.7112V39.94H17.6831Z"
        fill="#181716"
      />
      <path
        d="M19.6229 39.6044C19.5104 39.6044 19.398 39.4925 19.398 39.3806C19.398 39.2687 19.5104 39.1569 19.6229 39.1569C19.7354 39.1569 19.8478 39.2687 19.8478 39.3806C19.8478 39.4925 19.7635 39.6044 19.6229 39.6044ZM19.7916 41.6181H19.4542V39.912H19.7916V41.6181Z"
        fill="#181716"
      />
      <path
        d="M21.113 41.6461C20.607 41.6461 20.2134 41.2545 20.2134 40.7511C20.2134 40.2477 20.5789 39.8561 21.113 39.8561C21.6472 39.8561 22.0127 40.2477 22.0127 40.7511C22.0127 41.2825 21.6191 41.6461 21.113 41.6461ZM21.113 41.3385C21.4223 41.3385 21.6472 41.0867 21.6472 40.7511C21.6472 40.4155 21.4223 40.1638 21.113 40.1638C20.8038 40.1638 20.5789 40.4155 20.5789 40.7511C20.5789 41.0867 20.8038 41.3385 21.113 41.3385Z"
        fill="#181716"
      />
      <path
        d="M22.7998 40.4994V41.6181H22.4625V39.912H22.7717L22.828 40.1638C22.9685 39.968 23.1653 39.8561 23.3902 39.8561C23.8119 39.8561 24.0368 40.1358 24.0368 40.6113V41.6181H23.6995V40.6672C23.6995 40.3875 23.5589 40.1917 23.3059 40.1917C23.081 40.1917 22.9123 40.3036 22.7998 40.4994Z"
        fill="#181716"
      />
      <path
        d="M25.8643 39.8841C26.0892 39.8841 26.286 39.968 26.4547 40.0799L26.286 40.3316C26.1736 40.2197 26.0049 40.1638 25.8643 40.1638C25.6956 40.1638 25.6113 40.2477 25.6113 40.3596C25.6113 40.4714 25.6956 40.5274 26.0049 40.6392C26.3704 40.7511 26.539 40.891 26.539 41.1707C26.539 41.4783 26.286 41.6741 25.8924 41.6741C25.6394 41.6741 25.4145 41.5902 25.2177 41.4224L25.3864 41.1707C25.5551 41.3105 25.7237 41.3944 25.8924 41.3944C26.0892 41.3944 26.2017 41.3105 26.2017 41.1707C26.2017 41.0588 26.1173 41.0028 25.8362 40.9189C25.4426 40.8071 25.2739 40.6392 25.2739 40.3875C25.2458 40.0799 25.4988 39.8841 25.8643 39.8841Z"
        fill="#181716"
      />
      <path
        d="M27.6635 41.6461C27.1575 41.6461 26.7639 41.2545 26.7639 40.7511C26.7639 40.2477 27.1293 39.8561 27.6635 39.8561C28.1977 39.8561 28.5631 40.2477 28.5631 40.7511C28.5631 41.2825 28.1695 41.6461 27.6635 41.6461ZM27.6635 41.3385C27.9728 41.3385 28.1977 41.0867 28.1977 40.7511C28.1977 40.4155 27.9728 40.1638 27.6635 40.1638C27.3543 40.1638 27.1293 40.4155 27.1293 40.7511C27.1293 41.0867 27.3543 41.3385 27.6635 41.3385Z"
        fill="#181716"
      />
      <path
        d="M30.1375 41.3664C29.9969 41.5342 29.8001 41.6461 29.5752 41.6461C29.1535 41.6461 28.9286 41.3664 28.9286 40.9469V39.912H29.266V40.8909C29.266 41.1706 29.4066 41.3385 29.6315 41.3385C29.8001 41.3385 29.9688 41.2266 30.0813 41.0308V39.912H30.4186V41.6181H30.1094L30.1375 41.3664Z"
        fill="#181716"
      />
      <path
        d="M31.5714 39.8841C31.7963 39.8841 31.9931 39.968 32.1618 40.0799L31.9931 40.3316C31.8806 40.2197 31.7119 40.1638 31.5714 40.1638C31.4027 40.1638 31.3183 40.2477 31.3183 40.3596C31.3183 40.4714 31.4027 40.5274 31.7119 40.6392C32.0774 40.7511 32.2461 40.891 32.2461 41.1707C32.2461 41.4783 31.9931 41.6741 31.5995 41.6741C31.3465 41.6741 31.1215 41.5902 30.9247 41.4224L31.0934 41.1707C31.2621 41.3105 31.4308 41.3944 31.5995 41.3944C31.7963 41.3944 31.9087 41.3105 31.9087 41.1707C31.9087 41.0588 31.8244 41.0028 31.5432 40.9189C31.1497 40.8071 30.981 40.6392 30.981 40.3875C30.981 40.0799 31.2059 39.8841 31.5714 39.8841Z"
        fill="#181716"
      />
      <path d="M33.7643 41.6182H33.4269V38.9611H33.7643V41.6182Z" fill="#181716" />
      <path d="M34.1578 39.8841L34.3265 39.017H34.7201L34.4108 39.8841H34.1578Z" fill="#181716" />
      <path
        d="M35.6196 41.6461C35.1136 41.6461 34.72 41.2546 34.72 40.7511C34.72 40.2477 35.0855 39.8561 35.5634 39.8561C36.0413 39.8561 36.4068 40.2477 36.4068 40.7511C36.4068 40.8071 36.4068 40.835 36.4068 40.891H35.0574C35.1136 41.1427 35.3385 41.3385 35.5915 41.3385C35.7883 41.3385 35.9289 41.2825 36.0695 41.1427L36.2662 41.3664C36.1257 41.5622 35.9008 41.6461 35.6196 41.6461ZM35.0855 40.6113H36.0695C36.0132 40.3595 35.8164 40.1638 35.5634 40.1638C35.3666 40.1638 35.1417 40.3595 35.0855 40.6113ZM35.8164 38.9891L35.9851 39.2688L35.2823 39.6883L35.1417 39.4925L35.8164 38.9891Z"
        fill="#181716"
      />
      <path
        d="M37.6439 42.4013C37.3065 42.4013 36.9972 42.2894 36.7161 42.0657L36.8848 41.8139C37.1097 42.0097 37.3627 42.0936 37.6157 42.0936C38.0093 42.0936 38.2624 41.8699 38.2624 41.5622C38.2624 41.4503 38.2342 41.3664 38.2061 41.2545C38.0656 41.4783 37.8407 41.6181 37.5595 41.6181C37.0816 41.6181 36.7161 41.2266 36.7161 40.7231C36.7161 40.2197 37.0816 39.8281 37.5595 39.8281C37.7844 39.8281 38.0093 39.94 38.1499 40.1078L38.178 39.8561H38.4873V41.0028L38.5154 41.0588C38.5716 41.1986 38.6278 41.3664 38.6278 41.5063C38.6278 42.0657 38.2061 42.4013 37.6439 42.4013ZM38.178 40.7511C38.178 40.4155 37.9531 40.1638 37.6439 40.1638C37.3346 40.1638 37.1097 40.4155 37.1097 40.7511C37.1097 41.0867 37.3346 41.3385 37.6439 41.3385C37.9531 41.3385 38.178 41.0867 38.178 40.7511Z"
        fill="#181716"
      />
      <path
        d="M39.2182 39.6044C39.1058 39.6044 38.9933 39.4925 38.9933 39.3806C38.9933 39.2687 39.1058 39.1569 39.2182 39.1569C39.3307 39.1569 39.4431 39.2687 39.4431 39.3806C39.4431 39.4925 39.3307 39.6044 39.2182 39.6044ZM39.3869 41.6181H39.0495V39.912H39.3869V41.6181Z"
        fill="#181716"
      />
      <path
        d="M41.2423 41.3664C41.1018 41.5342 40.905 41.6461 40.652 41.6461C40.174 41.6461 39.8085 41.2545 39.8085 40.7511C39.8085 40.2477 40.174 39.8561 40.652 39.8561C40.8769 39.8561 41.0737 39.968 41.2142 40.1358V38.9331H41.5516V41.5902H41.2423V41.3664ZM41.2423 40.7511C41.2423 40.4155 41.0174 40.1638 40.7082 40.1638C40.3989 40.1638 40.174 40.4155 40.174 40.7511C40.174 41.0867 40.3989 41.3385 40.7082 41.3385C41.0174 41.3385 41.2423 41.0867 41.2423 40.7511Z"
        fill="#181716"
      />
      <path
        d="M42.9012 41.6461C42.3951 41.6461 42.0015 41.2546 42.0015 40.7511C42.0015 40.2477 42.367 39.8561 42.845 39.8561C43.3229 39.8561 43.6884 40.2477 43.6884 40.7511C43.6884 40.8071 43.6884 40.835 43.6884 40.891H42.3389C42.3951 41.1427 42.6201 41.3385 42.8731 41.3385C43.0699 41.3385 43.2104 41.2825 43.351 41.1427L43.5478 41.3664C43.3791 41.5622 43.1542 41.6461 42.9012 41.6461ZM42.367 40.6113H43.351C43.2948 40.3595 43.098 40.1638 42.845 40.1638C42.6201 40.1638 42.4233 40.3595 42.367 40.6113Z"
        fill="#181716"
      />
      <path
        d="M46.1903 41.3664C46.0497 41.5342 45.8529 41.6461 45.5999 41.6461C45.122 41.6461 44.7565 41.2545 44.7565 40.7511C44.7565 40.2477 45.122 39.8561 45.5999 39.8561C45.8248 39.8561 46.0216 39.968 46.1622 40.1358V38.9331H46.4996V41.5902H46.1903V41.3664ZM46.2184 40.7511C46.2184 40.4155 45.9935 40.1638 45.6843 40.1638C45.375 40.1638 45.1501 40.4155 45.1501 40.7511C45.1501 41.0867 45.375 41.3385 45.6843 41.3385C45.9935 41.3385 46.2184 41.0867 46.2184 40.7511Z"
        fill="#181716"
      />
      <path
        d="M47.8491 41.6461C47.3431 41.6461 46.9495 41.2545 46.9495 40.7511C46.9495 40.2477 47.315 39.8561 47.7929 39.8561C48.2708 39.8561 48.6363 40.2477 48.6363 40.7511C48.6363 40.807 48.6363 40.835 48.6363 40.8909H47.2868C47.3431 41.1427 47.568 41.3385 47.821 41.3385C48.0178 41.3385 48.1584 41.2825 48.2989 41.1427L48.4957 41.3664C48.3552 41.5622 48.1302 41.6461 47.8491 41.6461ZM47.315 40.6113H48.2989C48.2427 40.3595 48.0459 40.1638 47.7929 40.1638C47.568 40.1638 47.3712 40.3595 47.315 40.6113Z"
        fill="#181716"
      />
      <path d="M50.2106 41.6182H49.8732V38.9611H50.2106V41.6182Z" fill="#181716" />
      <path
        d="M52.0661 41.3664C51.9255 41.5343 51.7288 41.6461 51.4757 41.6461C50.9978 41.6461 50.6323 41.2546 50.6323 40.7511C50.6323 40.2477 50.9978 39.8561 51.4757 39.8561C51.7006 39.8561 51.9255 39.968 52.0661 40.1358L52.0942 39.8841H52.4035V41.5902H52.0942L52.0661 41.3664ZM52.0942 40.7511C52.0942 40.4155 51.8693 40.1638 51.5601 40.1638C51.2508 40.1638 51.0259 40.4155 51.0259 40.7511C51.0259 41.0867 51.2508 41.3385 51.5601 41.3385C51.8412 41.3385 52.0942 41.0867 52.0942 40.7511Z"
        fill="#181716"
      />
      <path
        d="M53.8372 39.017H55.3835V39.3247H54.2027V40.1917H55.2991V40.4994H54.2027V41.5902H53.8372V39.017Z"
        fill="#181716"
      />
      <path
        d="M56.5644 41.6461C56.0584 41.6461 55.6648 41.2546 55.6648 40.7511C55.6648 40.2477 56.0302 39.8561 56.5644 39.8561C57.0986 39.8561 57.464 40.2477 57.464 40.7511C57.4359 41.2825 57.0704 41.6461 56.5644 41.6461ZM56.5644 41.3385C56.8736 41.3385 57.0986 41.0867 57.0986 40.7511C57.0986 40.4155 56.8736 40.1638 56.5644 40.1638C56.2551 40.1638 56.0302 40.4155 56.0302 40.7511C56.0302 41.0867 56.2551 41.3385 56.5644 41.3385Z"
        fill="#181716"
      />
      <path
        d="M58.2231 40.4994V41.6181H57.8857V39.912H58.1949L58.2512 40.1638C58.3917 39.968 58.5885 39.8561 58.8134 39.8561C59.2351 39.8561 59.4601 40.1358 59.4601 40.6113V41.6181H59.1227V40.6672C59.1227 40.3875 58.9821 40.1917 58.7291 40.1917C58.5323 40.1917 58.3636 40.3036 58.2231 40.4994Z"
        fill="#181716"
      />
      <path
        d="M61.2593 41.3664C61.1187 41.5342 60.9219 41.6461 60.6689 41.6461C60.191 41.6461 59.8255 41.2545 59.8255 40.7511C59.8255 40.2477 60.191 39.8561 60.6689 39.8561C60.8938 39.8561 61.0906 39.968 61.2312 40.1358V38.9331H61.5685V41.5902H61.2593V41.3664ZM61.2874 40.7511C61.2874 40.4155 61.0625 40.1638 60.7532 40.1638C60.444 40.1638 60.2191 40.4155 60.2191 40.7511C60.2191 41.0867 60.444 41.3385 60.7532 41.3385C61.0344 41.3385 61.2874 41.0867 61.2874 40.7511Z"
        fill="#181716"
      />
      <path
        d="M63.4523 41.3664C63.3117 41.5343 63.1149 41.6461 62.8619 41.6461C62.3839 41.6461 62.0185 41.2546 62.0185 40.7511C62.0185 40.2477 62.3839 39.8561 62.8619 39.8561C63.0868 39.8561 63.3117 39.968 63.4523 40.1358L63.4804 39.8841H63.7896V41.5902H63.4804L63.4523 41.3664ZM63.4804 40.7511C63.4804 40.4155 63.2555 40.1638 62.9462 40.1638C62.637 40.1638 62.4121 40.4155 62.4121 40.7511C62.4121 41.0867 62.637 41.3385 62.9462 41.3385C63.2274 41.3385 63.4804 41.0867 63.4804 40.7511Z"
        fill="#181716"
      />
      <path
        d="M64.127 39.94L64.4925 39.912H64.5206L64.5768 39.4366H64.8579V39.912H65.4483V40.2197H64.8579V41.0308C64.8579 41.2266 64.9704 41.3385 65.111 41.3385C65.1953 41.3385 65.3078 41.2825 65.3921 41.1986L65.5608 41.4224C65.4202 41.5622 65.2515 41.6182 65.0829 41.6182C64.7174 41.6182 64.5206 41.3664 64.5206 41.0028V40.1917H64.1551V39.94H64.127Z"
        fill="#181716"
      />
      <path
        d="M66.0668 39.6044C65.9543 39.6044 65.8419 39.4925 65.8419 39.3806C65.8419 39.2687 65.9543 39.1569 66.0668 39.1569C66.1792 39.1569 66.2917 39.2687 66.2917 39.3806C66.2917 39.4925 66.1792 39.6044 66.0668 39.6044ZM66.2354 41.6181H65.8981V39.912H66.2354V41.6181Z"
        fill="#181716"
      />
      <path
        d="M67.5567 41.6461C67.0507 41.6461 66.6571 41.2546 66.6571 40.7511C66.6571 40.2477 67.0226 39.8561 67.5567 39.8561C68.0909 39.8561 68.4564 40.2477 68.4564 40.7511C68.4564 41.2825 68.0628 41.6461 67.5567 41.6461ZM67.5567 41.3385C67.866 41.3385 68.0909 41.0867 68.0909 40.7511C68.0909 40.4155 67.866 40.1638 67.5567 40.1638C67.2475 40.1638 67.0226 40.4155 67.0226 40.7511C67.0226 41.0867 67.2475 41.3385 67.5567 41.3385Z"
        fill="#181716"
      />
      <path
        d="M69.2156 40.4994V41.6182H68.8782V39.912H69.1875L69.2437 40.1638C69.3843 39.968 69.5811 39.8561 69.806 39.8561C70.2277 39.8561 70.4526 40.1358 70.4526 40.6113V41.6182H70.1152V40.6672C70.1152 40.3875 69.9747 40.1917 69.7217 40.1917C69.5249 40.1917 69.3562 40.3036 69.2156 40.4994Z"
        fill="#181716"
      />
      <path
        d="M73.067 41.3664C72.9264 41.5342 72.7296 41.6461 72.4766 41.6461C71.9987 41.6461 71.6332 41.2545 71.6332 40.7511C71.6332 40.2477 71.9987 39.8561 72.4766 39.8561C72.7015 39.8561 72.8983 39.968 73.0389 40.1358V38.9331H73.3763V41.5902H73.0951L73.067 41.3664ZM73.067 40.7511C73.067 40.4155 72.8421 40.1638 72.5328 40.1638C72.2236 40.1638 71.9987 40.4155 71.9987 40.7511C71.9987 41.0867 72.2236 41.3385 72.5328 41.3385C72.8421 41.3385 73.067 41.0867 73.067 40.7511Z"
        fill="#181716"
      />
      <path
        d="M74.7258 41.6461C74.2198 41.6461 73.8262 41.2546 73.8262 40.7511C73.8262 40.2477 74.1917 39.8561 74.6696 39.8561C75.1475 39.8561 75.513 40.2477 75.513 40.7511C75.513 40.8071 75.513 40.835 75.513 40.891H74.1636C74.2198 41.1427 74.4447 41.3385 74.6977 41.3385C74.8945 41.3385 75.0351 41.2825 75.1757 41.1427L75.3724 41.3664C75.2038 41.5622 74.9789 41.6461 74.7258 41.6461ZM74.1917 40.6113H75.1757C75.1194 40.3595 74.9226 40.1638 74.6696 40.1638C74.4447 40.1638 74.2479 40.3595 74.1917 40.6113Z"
        fill="#181716"
      />
      <path
        d="M76.8063 39.017H78.3525V39.3247H77.1717V40.1917H78.2682V40.4994H77.1717V41.5902H76.8063V39.017Z"
        fill="#181716"
      />
      <path
        d="M79.1115 40.4994V41.6181H78.7741V39.912H79.0834L79.1396 40.1638C79.2802 39.9959 79.477 39.8561 79.6738 39.8561C79.73 39.8561 79.7862 39.8561 79.8144 39.8841L79.73 40.2477C79.7019 40.2477 79.6457 40.2197 79.6176 40.2197C79.4208 40.2477 79.2521 40.3036 79.1115 40.4994Z"
        fill="#181716"
      />
      <path
        d="M81.3607 41.3664C81.2201 41.5343 81.0233 41.6461 80.7703 41.6461C80.2924 41.6461 79.9269 41.2546 79.9269 40.7511C79.9269 40.2477 80.2924 39.8561 80.7703 39.8561C80.9952 39.8561 81.2201 39.968 81.3607 40.1358L81.3888 39.8841H81.6981V41.5902H81.3888L81.3607 41.3664ZM81.3888 40.7511C81.3888 40.4155 81.1639 40.1638 80.8547 40.1638C80.5454 40.1638 80.3205 40.4155 80.3205 40.7511C80.3205 41.0867 80.5454 41.3385 80.8547 41.3385C81.1358 41.3385 81.3888 41.0867 81.3888 40.7511Z"
        fill="#181716"
      />
      <path
        d="M82.5976 40.4994V41.6181H82.2603V39.912H82.5695L82.6257 40.1638C82.7663 39.968 82.9631 39.8561 83.188 39.8561C83.6097 39.8561 83.8346 40.1358 83.8346 40.6113V41.6181H83.4973V40.6672C83.4973 40.3875 83.3567 40.1917 83.1037 40.1917C82.9069 40.1917 82.7382 40.3036 82.5976 40.4994Z"
        fill="#181716"
      />
      <path
        d="M85.1278 41.3385C85.2965 41.3385 85.4371 41.2825 85.5777 41.1427L85.8026 41.3664C85.6339 41.5622 85.409 41.6461 85.1278 41.6461C84.6218 41.6461 84.2282 41.2545 84.2282 40.7511C84.2282 40.2477 84.5937 39.8561 85.1278 39.8561C85.3809 39.8561 85.5777 39.94 85.7745 40.1078L85.5495 40.3316C85.409 40.2197 85.2965 40.1638 85.1278 40.1638C84.8186 40.1638 84.5937 40.4155 84.5937 40.7511C84.5656 41.0867 84.8186 41.3385 85.1278 41.3385Z"
        fill="#181716"
      />
      <path
        d="M86.899 41.6461C86.393 41.6461 85.9994 41.2545 85.9994 40.7511C85.9994 40.2477 86.3648 39.8561 86.8428 39.8561C87.3207 39.8561 87.6862 40.2477 87.6862 40.7511C87.6862 40.807 87.6862 40.835 87.6862 40.8909H86.3367C86.393 41.1427 86.6179 41.3385 86.8709 41.3385C87.0677 41.3385 87.2082 41.2825 87.3488 41.1427L87.5456 41.3664C87.405 41.5622 87.1801 41.6461 86.899 41.6461ZM86.3648 40.6113H87.3488C87.2926 40.3595 87.0958 40.1638 86.8428 40.1638C86.6179 40.1638 86.4211 40.3595 86.3648 40.6113Z"
        fill="#181716"
      />
    </>
  ),
})
