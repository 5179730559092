'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AverageAmount',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M41.4411 31.3601H30.2411C29.5691 31.3601 29.1211 30.9121 29.1211 30.2401C29.1211 29.5681 29.5691 29.1201 30.2411 29.1201H41.4411C42.1131 29.1201 42.5611 29.5681 42.5611 30.2401C42.5611 30.9121 42.1131 31.3601 41.4411 31.3601Z"
        fill="#FF6C4A"
      />
      <path
        d="M41.4411 44.8001H30.2411C29.5691 44.8001 29.1211 44.3521 29.1211 43.6801C29.1211 43.0081 29.5691 42.5601 30.2411 42.5601H41.4411C42.1131 42.5601 42.5611 43.0081 42.5611 43.6801C42.5611 44.3521 42.1131 44.8001 41.4411 44.8001Z"
        fill="#FF6C4A"
      />
      <path
        d="M41.4411 58.2401H30.2411C29.5691 58.2401 29.1211 57.7921 29.1211 57.1201C29.1211 56.4481 29.5691 56.0001 30.2411 56.0001H41.4411C42.1131 56.0001 42.5611 56.4481 42.5611 57.1201C42.5611 57.7921 42.1131 58.2401 41.4411 58.2401Z"
        fill="#FF6C4A"
      />
      <path
        d="M81.7589 31.36H48.1589C47.4869 31.36 47.0389 30.912 47.0389 30.24C47.0389 29.568 47.4869 29.12 48.1589 29.12H81.7589C82.4309 29.12 82.8789 29.568 82.8789 30.24C82.8789 30.912 82.4309 31.36 81.7589 31.36Z"
        fill="#2A2A2A"
      />
      <path
        d="M81.7589 44.7999H48.1589C47.4869 44.7999 47.0389 44.352 47.0389 43.68C47.0389 43.008 47.4869 42.56 48.1589 42.56H81.7589C82.4309 42.56 82.8789 43.008 82.8789 43.68C82.8789 44.352 82.4309 44.7999 81.7589 44.7999Z"
        fill="#2A2A2A"
      />
      <path
        d="M81.7589 58.24H48.1589C47.4869 58.24 47.0389 57.792 47.0389 57.12C47.0389 56.448 47.4869 56 48.1589 56H81.7589C82.4309 56 82.8789 56.448 82.8789 57.12C82.8789 57.792 82.4309 58.24 81.7589 58.24Z"
        fill="#2A2A2A"
      />
      <path
        d="M85.6789 11.2H26.3189C22.8469 11.2 20.0469 14 20.0469 17.472V96.208C20.0469 97.888 21.3909 99.1199 22.9589 99.1199C23.5189 99.1199 24.0789 99.0079 24.5269 98.672L32.8149 93.072C33.0389 92.96 33.2629 92.848 33.4869 92.848C33.7109 92.848 34.0469 92.96 34.2709 93.072L43.0069 100.128C43.5669 100.576 44.1269 100.8 44.7989 100.8C45.4709 100.8 46.0309 100.576 46.5909 100.128L55.3269 93.072C55.5509 92.8479 55.7749 92.848 56.1109 92.848C56.3349 92.848 56.6709 92.96 56.8949 93.072L65.6309 100.128C66.1909 100.576 66.7509 100.8 67.4229 100.8C68.0949 100.8 68.6549 100.576 69.2149 100.128L77.9509 93.072C78.1749 92.8479 78.3989 92.848 78.7349 92.848C78.9589 92.848 79.1829 92.96 79.4069 93.072L87.6949 98.672C88.1429 99.0079 88.7029 99.1199 89.2629 99.1199C90.7189 99.1199 92.1749 97.888 92.1749 96.208V17.472C91.8389 14 89.1509 11.2 85.6789 11.2ZM26.3189 13.44H85.6789C87.9189 13.44 89.7109 15.232 89.7109 17.472V75.04H22.3989C22.3989 75.04 22.3989 75.04 22.2869 75.04V17.472C22.3989 15.232 24.1909 13.44 26.3189 13.44ZM89.0389 96.88C88.9269 96.88 88.8149 96.88 88.7029 96.768L80.4149 91.168C79.8549 90.832 79.1829 90.608 78.5109 90.608C77.7269 90.608 76.9429 90.8319 76.3829 91.392L67.6469 98.448C67.4229 98.56 67.3109 98.56 67.1989 98.56C67.0869 98.56 66.9749 98.56 66.8629 98.448L58.1269 91.392C57.5669 90.9439 56.7829 90.608 55.9989 90.608C55.2149 90.608 54.4309 90.8319 53.8709 91.392L45.1349 98.448C45.0229 98.56 44.9109 98.56 44.7989 98.56C44.6869 98.56 44.5749 98.56 44.4629 98.448L35.7269 91.392C35.1669 90.9439 34.3829 90.608 33.5989 90.608C32.9269 90.608 32.2549 90.832 31.6949 91.168L23.4069 96.768C23.2949 96.88 23.1829 96.88 23.0709 96.88C22.8469 96.88 22.3989 96.656 22.3989 96.208V77.28C22.3989 77.28 22.3989 77.28 22.5109 77.28H89.7109V96.208C89.5989 96.656 89.2629 96.88 89.0389 96.88Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
