'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Tractor',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M700,4801c-115-35-189-142-177-255c9-90,61-161,144-198l43-19l-2-611l-3-611l-85-36c-279-118-401-238-416-413
		c-7-72,13-174,51-255l23-51l-46-64C26,2005-48,1609,40,1259c107-421,417-754,832-893c245-81,566-77,810,10c238,86,463,257,610,465
		l63,89h522h521l12-38c51-156,190-336,345-442c220-152,547-186,805-85c170,66,352,224,439,378c155,276,156,614,3,874l-31,52l-3,473
		c-4,453-5,476-26,543c-38,123-80,194-156,270c-129,127-230,162-503,172l-173,6v242v241l31,30c57,53,79,116,79,224
		c0,52-5,112-10,132c-18,62-69,120-135,153c-59,30-62,30-225,30c-133,0-172-4-202-17c-62-28-103-65-134-120c-27-50-29-59-29-168
		c1-132,11-164,74-233l41-44v-237v-236h-107h-108l-174,593c-96,325-175,597-177,602c-2,6,20,24,49,40c127,74,156,249,60,356
		c-69,76-94,83-296,87c-122,3-188,0-207-8c-54-22-56-105-4-129c15-7,86-11,185-11c199,0,228-12,229-93c0-27-7-45-25-62l-24-25H1862
		H723l-27,26c-37,37-35,86,3,125l29,29h832h831l24,25c32,31,32,69,0,100l-24,25l-833-1C1100,4809,714,4805,700,4801z M1910,3730
		v-600h-78c-49,0-113,9-175,25c-233,59-474,62-759,9l-38-7v587v586h525h525V3730z M2889,4298c6-18,83-280,171-583
		c89-302,163-558,166-567c5-17-27-18-585-18h-591v593c0,327,3,597,7,600c3,4,190,7,414,7h407L2889,4298z M4004,4026
		c55-23,66-49,66-149c0-88,0-89-34-123l-34-34h-151h-151l-32,29c-32,28-33,33-36,114c-7,151,24,176,216,177
		C3931,4040,3981,4035,4004,4026z M3960,3350v-220h-110h-110v220v220h110h110V3350z M1400,3049c548-51,1028-405,1241-915
		c39-93,69-200,69-245c0-97-77-204-167-233c-20-6-22-3-28,41c-21,161-62,300-126,426c-183,362-519,613-916,682
		c-171,30-456,13-503-30c-42-38-11-117,46-118c16,0,77,5,137,12c315,35,635-75,871-299c100-95,162-177,221-291
		c90-175,125-318,125-516c0-708-641-1227-1340-1087c-108,22-275,93-377,159c-48,32-129,99-178,149c-131,132-208,256-265,424
		c-140,417-28,867,289,1165c64,60,108,92,249,181c53,33,68,66,47,106c-20,39-56,47-108,24c-58-26-150-87-229-150c-37-30-69-54-72-54
		c-11,0-36,105-36,150c0,63,40,141,93,181c72,53,296,156,407,187c90,25,181,42,305,56C1219,3061,1289,3060,1400,3049z M4494,2952
		c84-31,149-76,206-140c28-31,50-60,50-64s-84-8-187-8c-176,0-191-2-238-24c-96-45-145-115-153-217c-8-120,46-214,151-259
		c64-28,210-39,256-21c37,16,56,57,42,93c-14,38-26,42-133,48c-111,6-145,23-165,81c-15,47-6,84,29,120l30,29l216-2l217-3l3-367
		c1-203,0-368-3-368c-2,0-28,18-57,40c-69,54-193,113-293,142c-68,19-103,23-230,22c-134,0-159-3-240-28c-377-115-627-444-629-828
		l-1-118h-467c-258,0-468,1-468,3s11,36,24,75c26,76,51,191,62,284c6,54,8,57,38,64c109,24,235,133,277,241c34,87,32,202-5,313
		c-125,369-345,660-653,865c-40,26-73,50-73,52s525,2,1168,1l1167-3L4494,2952z M4477,1873c114-41,185-86,274-173
		c87-86,146-180,185-295c24-70,27-96,28-215c1-115-2-146-22-210c-77-248-274-439-522-506c-99-27-272-25-375,4
		c-203,58-368,197-460,387c-59,123-79,219-72,354c9,193,73,335,212,477c84,85,167,139,272,176c94,32,131,37,268,34
		C4379,1903,4405,1899,4477,1873z"
        />
        <path
          fill="currentColor"
          d="M1101,2160c-208-56-382-232-436-440c-22-85-22-225,0-310c44-169,171-324,325-398c101-48,163-62,273-62
		c194,0,372,86,484,234c259,343,120,814-285,963C1367,2183,1205,2188,1101,2160z M1432,2000c225-85,349-342,278-571
		c-95-309-481-435-729-239c-259,205-247,584,24,764C1132,2039,1287,2056,1432,2000z"
        />
        <path
          fill="currentColor"
          d="M4160,1580c-74-16-163-66-214-120c-232-248-71-659,266-678c197-11,355,99,418,291c17,53,15,164-4,228
		C4568,1493,4356,1620,4160,1580z M4345,1416c166-77,203-287,73-416c-106-107-295-93-383,29c-57,78-68,155-35,244
		C4052,1411,4213,1478,4345,1416z"
        />
      </g>
    </>
  ),
})
