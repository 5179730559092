'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AverageRepay',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M55.9989 82.9921C55.3269 82.9921 54.8789 82.5441 54.8789 81.8721V51.4081C54.8789 50.7361 55.3269 50.2881 55.9989 50.2881C56.6709 50.2881 57.1189 50.7361 57.1189 51.4081V81.8721C57.1189 82.5441 56.6709 82.9921 55.9989 82.9921Z"
        fill="#FF6C4A"
      />
      <path
        d="M87.5836 98.4481H85.5676C84.7836 80.6401 79.0716 60.3681 65.5196 55.6641C64.6236 55.3281 63.9516 54.6561 63.7276 53.7601C63.5036 52.8641 63.6156 51.9681 64.1756 51.1841C64.5116 50.7361 65.0716 50.2881 65.6316 50.1761C73.2476 47.6001 78.7356 42.3361 81.9836 34.3841C84.8956 27.2161 85.5676 19.1521 85.6796 13.6641H87.6956C88.3676 13.6641 88.8156 13.2161 88.8156 12.5441C88.8156 11.8721 88.3676 11.4241 87.6956 11.4241H24.4156C23.7436 11.4241 23.2956 11.8721 23.2956 12.5441C23.2956 13.2161 23.7436 13.6641 24.4156 13.6641H26.4316C26.6556 19.1521 27.2156 27.2161 30.1276 34.3841C33.3756 42.3361 38.8636 47.6001 46.4796 50.1761C47.3756 50.5121 48.0476 51.1841 48.2716 52.0801C48.4956 52.9761 48.3836 53.8721 47.8236 54.6561C47.4876 55.1041 47.0396 55.5521 46.4796 55.6641C32.8156 60.3681 27.2156 80.6401 26.3196 98.5601H24.3036C23.6316 98.5601 23.1836 99.0081 23.1836 99.6801C23.1836 100.352 23.6316 100.8 24.3036 100.8H87.3596C88.0316 100.8 88.4796 100.352 88.4796 99.6801C88.4796 99.0081 88.1436 98.4481 87.5836 98.4481ZM47.3756 57.6801C48.3836 57.3441 49.2796 56.6721 49.8396 55.7761C50.7356 54.4321 51.0716 52.7521 50.5116 51.1841C50.0636 49.6161 48.8316 48.3841 47.2636 47.9361C40.3196 45.5841 35.2796 40.6561 32.2556 33.3761C29.4556 26.5441 28.8956 18.8161 28.6716 13.5521H83.2156C82.9916 18.8161 82.4316 26.5441 79.6316 33.3761C76.6076 40.6561 71.5676 45.5841 64.6236 47.9361C63.6156 48.2721 62.7196 48.9441 62.0476 49.8401C61.1516 51.1841 60.8156 52.8641 61.2636 54.4321C61.7116 56.0001 62.9436 57.2321 64.5116 57.6801C75.0396 61.3761 82.0956 76.9441 83.1036 98.4481H28.7836C29.6796 76.9441 36.7356 61.3761 47.3756 57.6801Z"
        fill="#1D1D1B"
      />
    </>
  ),
})
