'use client'

import { createIcon } from '@chakra-ui/icons'

// TODO USE SMALL WATER ICON
export default createIcon({
  displayName: 'Water',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M42.9 90C26.4 90 13 76.6 13 60.1C13 56.2 14.5 51.5 17.6 46.1C19.9 42 23.2 37.3 27.2 32.4C30.1 28.9 33.3 25.3 36.7 21.7C38 20.4 39.1 19.3 39.8 18.5C40.2 18.1 40.5 17.8 40.7 17.6L42.1 16.2C42.5 15.8 43.1 15.8 43.5 16.2C44.2 16.9 44.5 17.2 44.6 17.3L45 17.7C45.2 17.9 45.5 18.2 45.9 18.6C46.7 19.3 47.7 20.4 49 21.8C52.4 25.4 55.6 29 58.5 32.5C62.5 37.4 65.8 42.1 68.1 46.2C71.1 51.6 72.7 56.3 72.7 60.2C72.8 76.6 59.4 90 42.9 90ZM42.4 18.8L42.1 19.1C41.9 19.3 41.6 19.6 41.2 19.9C40.5 20.6 39.4 21.7 38.1 23C34.7 26.5 31.5 30.1 28.7 33.5C24.7 38.4 21.6 42.9 19.3 46.9C16.5 52.2 15 56.5 15 60.1C15 75.5 27.5 88 42.9 88C58.3 88 70.8 75.5 70.8 60.1C70.8 56.6 69.3 52.2 66.5 47C64.2 43 61.1 38.4 57.1 33.6C54.3 30.2 51.1 26.5 47.7 23.1C46.4 21.8 45.4 20.7 44.6 20C44.2 19.6 43.9 19.3 43.7 19.2L43.5 19C43.5 19 43.5 19 43.4 18.9C43.4 18.9 43.3 18.8 42.9 18.4L42.4 18.8Z"
        fill="black"
      />
      <path
        d="M60.5 84C60 84 59.6 83.6 59.5 83.1C59.4 82.6 59.8 82.1 60.4 82C74.7 80.6 85.5 68.6 85.5 54.2C85.5 50.7 84 46.3 81.2 41.1C78.9 37.1 75.8 32.5 71.8 27.7C68.3 23.4 64.7 19.6 62.4 17.2C61.1 15.9 60.1 14.8 59.3 14.1C58.9 13.7 58.6 13.4 58.4 13.3L58.2 13C58.2 13 58.1 13 58.1 12.9C58 12.8 57.9 12.7 57.6 12.4L57 13L56.6 12.5L56.3 12.2L57.1 13L56.9 13.2C56.7 13.4 56.4 13.7 56 14C55.3 14.7 54.2 15.8 52.9 17.1C51.4 18.6 49.9 20.2 48.5 21.8C48.1 22.2 47.5 22.2 47.1 21.9C46.7 21.5 46.7 20.9 47 20.5C48.4 18.9 49.9 17.3 51.4 15.7C52.7 14.4 53.8 13.3 54.5 12.5C54.9 12.1 55.2 11.8 55.4 11.6L56.8 10.2C57.2 9.80002 57.8 9.80002 58.2 10.2C58.9 10.9 59.2 11.2 59.3 11.3L59.7 11.7C60 12 60.3 12.3 60.7 12.6C61.5 13.3 62.5 14.4 63.8 15.8C66.2 18.3 69.7 22.1 73.3 26.5C77.3 31.4 80.6 36.1 82.9 40.2C85.9 45.6 87.5 50.3 87.5 54.2C87.5 69.7 76 82.4 60.6 84C60.6 84 60.6 84 60.5 84Z"
        fill="black"
      />
      <path
        d="M58.7 79.5C58.4 79.5 58.2 79.4 58 79.2C57.6 78.8 57.6 78.2 58 77.8C63.3 72.5 66.2 65.5 66.2 58C66.2 57.7 66.2 57.4 66.2 57C66.2 56.4 66.5999 56 67.0999 55.9C67.7 55.9 68.1 56.3 68.2 56.8C68.2 57.2 68.2 57.5 68.2 57.9C68.2 65.9 65.1 73.5 59.4 79.1C59.2 79.4 58.9 79.5 58.7 79.5Z"
        fill="currentColor"
      />
    </>
  ),
})
