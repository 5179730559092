import { Box, Flex, Button, Heading, Divider, Text, Image, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { extractString } from '@miimosa/common'
import type { FrontCollectTypeType, FrontPartnerLogoType } from '@miimosa/common/types'
import { Icon, IconName, usePlatform } from '@miimosa/design-system'

type Props = {
  collectType: FrontCollectTypeType
  description: string
  usageDescription: string
  facebookUrl?: string | null
  instagramUrl?: string | null
  twitterUrl?: string | null
  linkedinUrl?: string | null
  websiteUrl?: string | null
  partnerLogos: FrontPartnerLogoType[]
  fullName?: string
  siret?: string | null
  bce?: string | null
  place?: string
}

const Description = ({
  collectType,
  description,
  usageDescription,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
  websiteUrl,
  partnerLogos,
  fullName,
  siret,
  bce,
  place,
}: Props) => {
  const { t } = useTranslation('project')
  const { t: tCommon } = useTranslation('common')
  const [showDescription, setShowDescription] = useState(false)
  const [showUsageDescription, setShowUsageDescription] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const descHeight = (!isMobile && 'full') || showDescription ? 'full' : '400px'
  const usageHeight = (!isMobile && 'full') || showUsageDescription ? 'full' : '400px'
  const siretIsValidRegexp = /^[0-9]{8,}$/
  const { platform } = usePlatform()

  const { query } = useRouter()
  const slug = extractString(query.slug)

  if (slug && slug == 'yooji-poursuit-sa-reinvention-de-l-alimentation-infantile') {
    siret = '753718923'
  }

  // Used to display embed iframes from WYSIWG
  useEffect(() => {
    // can not use ref because data comes from api :/
    document.querySelectorAll('oembed[url]').forEach((element) => {
      // force ts ignore because iframely is not set
      // ts-ignore ignores any ts errors on the next line
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.iframely && window.iframely.load(element, element.attributes.url.value)
    })
  }, [description])

  return (
    <Flex direction="column" bg="white" px={8} py={8} rowGap={8} border="solid 1px" borderColor="light_gray" w="full">
      <Heading>{collectType === 'lending' ? t('lending_presentation') : t('donation_presentation')}</Heading>
      <Flex position="relative">
        <Box
          className="ck ck-content __description project-tabs-content"
          id="ck"
          dangerouslySetInnerHTML={{ __html: description }}
          width="full"
          height={descHeight}
          overflow="hidden"
        />
        {isMobile && !showDescription && (
          <Flex
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            background="linear-gradient(180deg, rgba(255,255,255,0.4) 35%, rgba(255,255,255,1) 100%)"
            align="center"
            justify="center"
            height="200px"
          >
            {!showDescription && (
              <Button variant="primary" size="md" onClick={() => setShowDescription(true)}>
                {tCommon('see_more')}
              </Button>
            )}
          </Flex>
        )}
      </Flex>

      <Heading>{t('collect_purpose')}</Heading>
      <Flex position="relative">
        <Box
          className="ck ck-content __description project-tabs-content"
          id="ck"
          dangerouslySetInnerHTML={{ __html: usageDescription }}
          width="full"
          height={usageHeight}
          overflow="hidden"
        />
        {isMobile && !showUsageDescription && (
          <Flex
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            background="linear-gradient(180deg, rgba(255,255,255,0.4) 35%, rgba(255,255,255,1) 100%)"
            align="center"
            justify="center"
            height="200px"
          >
            {!showUsageDescription && (
              <Button variant="primary" size="md" onClick={() => setShowUsageDescription(true)}>
                {tCommon('see_more')}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {partnerLogos && partnerLogos.length > 0 && (
        <Flex direction="column" w="full">
          <Divider w="full" />
          <Flex direction="row" w="full" h="full" alignItems="center" justifyContent="flex-start">
            <Text mr={2}>{t('partners')}</Text>
            <Flex direction="row" columnGap={2} flexWrap="wrap">
              {partnerLogos.map(({ url }, i) => (
                <Image src={url} alt="" key={i} objectFit="cover" />
              ))}
            </Flex>
          </Flex>
        </Flex>
      )}
      {(facebookUrl || twitterUrl || instagramUrl || linkedinUrl || websiteUrl) && (
        <Flex direction="column" w="full" h="100px">
          <Divider w="full" />
          <Flex direction="row" w="full" h="full" alignItems="center" justifyContent="flex-start">
            <Text mr={2}>{t('social_follow')}</Text>
            <Flex direction="row" columnGap={2}>
              {facebookUrl && <Social url={facebookUrl} iconName="Facebook" />}
              {instagramUrl && <Social url={instagramUrl} iconName="Instagram" />}
              {twitterUrl && <Social url={twitterUrl} iconName="Twitter" />}
              {linkedinUrl && <Social url={linkedinUrl} iconName="LinkedIn" />}
              {websiteUrl && (
                <Social
                  url={
                    websiteUrl.startsWith('https://') || websiteUrl.startsWith('http://')
                      ? websiteUrl
                      : `https://${websiteUrl}`
                  }
                  iconName="Web"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      {collectType === 'lending' && (
        <>
          <Divider mb={6} />
          <Flex w="full" direction="column" rowGap={3}>
            <Heading>L&apos;entreprise</Heading>
            <Flex direction="column">
              <Flex direction="row" columnGap={1}>
                <Text size="md" fontWeight="bold">
                  Nom :
                </Text>
                <Text size="md"> {fullName}</Text>
              </Flex>
              <Flex direction="row" columnGap={1}>
                <Text size="md" fontWeight="bold">
                  Localisation
                </Text>
                <Text size="md">{place}</Text>
              </Flex>
              <Flex direction="row" columnGap={1}>
                {siret && siretIsValidRegexp.test(siret) ? (
                  <>
                    <Text size="md" fontWeight="bold">
                      {tCommon('siren').toUpperCase()}
                    </Text>
                    <Text size="md">{siret.padEnd(9)}</Text>
                  </>
                ) : (
                  bce && (
                    <>
                      <Text size="md" fontWeight="bold">
                        {tCommon('bce').toUpperCase()}
                      </Text>
                      <Text size="md">{bce}</Text>
                    </>
                  )
                )}
                {!siret && !bce && (
                  <>
                    <Text size="md" fontWeight="bold">
                      {platform === 'fr' ? tCommon('siren').toUpperCase() : tCommon('bce').toUpperCase()}
                    </Text>
                    <Text size="md">{tCommon('siret_creation')}</Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}

const Social = ({ url, iconName }: { url: string; iconName: IconName }) => {
  return (
    <Flex
      as="a"
      href={url}
      target="_blank"
      w={10}
      h={10}
      bgColor="#F3F3F3"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      aria-label={url}
    >
      <Icon name={iconName} size="4xs" />
    </Flex>
  )
}

export default Description
