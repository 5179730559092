'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'PinMapOutline',
  viewBox: '0 0 24 24',

  path: (
    <path
      fill="none"
      stroke="currentColor"
      d="M5 9.9c0-2.388.76-4.246 2.14-5.534C8.446 3.155 10.123 2.5 12 2.5c1.69 0 3.44.587 4.763 1.794C18.077 5.493 19 7.33 19 9.9c0 .861-.336 1.923-.91 3.09-.569 1.158-1.348 2.374-2.183 3.53-1.435 1.988-3.01 3.77-3.897 4.734-.863-.979-2.436-2.776-3.88-4.772-.842-1.163-1.63-2.383-2.208-3.537C5.34 11.78 5 10.733 5 9.9Zm3.5-.7c0 1.976 1.524 3.5 3.5 3.5s3.5-1.524 3.5-3.5-1.524-3.5-3.5-3.5-3.5 1.524-3.5 3.5Z"
    />
  ),
})
