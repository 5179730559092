'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'UpBrush3',
  viewBox: '0 0 15 15',
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.7483 1.34461C2.68297 1.15732 1.75903 0.966713 0.690397 0.638031C1.61104 0.687251 2.32125 0.812116 3.10381 1.00602C5.08978 1.24254 7.20722 1.05161 9.32796 1.00206C9.68307 1.06449 10.0973 0.630433 10.39 1.04797C10.6827 1.46552 10.4755 1.68255 10.3375 1.82723C10.4821 1.96531 10.6992 2.17243 10.4197 2.32042C10.0712 2.54075 9.64376 2.40928 9.21961 2.41919C7.94717 2.44892 6.59907 2.26823 5.25429 2.22892C3.48535 2.19952 1.77885 1.81501 0 1.36146C0.414238 0.927403 0.631268 1.13452 0.917337 1.2693C1.13106 1.33503 1.34149 1.25939 1.55521 1.32512C1.83798 1.31852 2.12405 1.45329 2.40681 1.44669C1.97936 1.31521 1.48287 1.25608 1.05542 1.12461C0.914034 1.12792 0.624661 0.851756 0.838387 0.917493C1.82807 0.89437 2.75531 1.22635 3.7483 1.34461Z"
        fill="#FF6C4A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.04546 12.1491C7.06861 12.6136 6.20508 12.9935 5.14363 13.3447C5.92256 12.8514 6.57379 12.5418 7.32399 12.2465C9.07977 11.2887 10.6951 9.90644 12.395 8.63746C12.7206 8.48263 12.8068 7.88885 13.2872 8.05964C13.7677 8.23042 13.7246 8.52731 13.6959 8.72523C13.8938 8.75396 14.1907 8.79706 14.0486 9.07958C13.8922 9.46107 13.4676 9.60154 13.1276 9.85533C12.1077 10.6167 10.9042 11.2504 9.78529 11.9974C8.3264 12.9983 6.71267 13.6735 4.99999 14.3343C5.08617 13.7405 5.38306 13.7836 5.69432 13.7277C5.90661 13.6575 6.0343 13.474 6.24659 13.4037C6.47324 13.2345 6.7845 13.1786 7.01115 13.0095C6.58657 13.1499 6.14762 13.3894 5.72304 13.5298C5.60972 13.6144 5.21387 13.557 5.42616 13.4867C6.21945 12.8945 7.16757 12.628 8.04546 12.1491Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
