'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'YoupiRight',
  viewBox: '0 0 24 84',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9328 4.10601C19.4268 5.09397 19.0263 6.29531 18.0384 6.78929C15.7298 7.9436 13.4501 9.26638 11.0986 10.631L11.0938 10.6338C8.76287 11.9865 6.36193 13.3798 3.89456 14.6135C2.90661 15.1075 1.70526 14.7071 1.21128 13.7191C0.717305 12.7311 1.11775 11.5298 2.10571 11.0358C4.44494 9.8662 6.74042 8.53543 9.09089 7.17139L9.13951 7.14317C11.4555 5.79914 13.8273 4.42268 16.2495 3.21158C17.2375 2.71761 18.4388 3.11805 18.9328 4.10601ZM1.36624 42.8035C1.45405 41.7025 2.41783 40.8811 3.5189 40.9689L3.7147 40.9845C10.6007 41.5338 14.0063 41.8054 21.6712 41.1808C22.7721 41.0911 23.7373 41.9109 23.827 43.0118C23.9167 44.1127 23.0969 45.0779 21.996 45.1676C14.0098 45.8184 10.3537 45.5267 3.39266 44.9715L3.20091 44.9562C2.09984 44.8684 1.27843 43.9046 1.36624 42.8035ZM1.12254 68.8649C1.71346 67.9317 2.94902 67.6543 3.88223 68.2452C9.19159 71.6071 11.973 73.6967 14.6411 75.7012C16.0029 76.7243 17.3352 77.7252 18.9591 78.8618C19.864 79.4952 20.0841 80.7422 19.4507 81.6471C18.8174 82.5521 17.5703 82.7722 16.6654 82.1388C14.8537 80.8707 13.4612 79.823 12.0775 78.7817C9.49339 76.8373 6.93959 74.9156 1.74231 71.6246C0.809096 71.0337 0.531616 69.7981 1.12254 68.8649Z"
      fill="currentColor"
    />
  ),
})
