'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Climat',
  viewBox: '0 0 841.9 595.3',
  path: (
    <g>
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M568.9,226.4c0.5,3.8-0.6,7.3-1.9,10.7c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,4.9
					c-3.5,3.4-6.6,7-10.4,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.7,6.7-9.4,9.3c-2.7,1.8-5.1,4-7.2,6.5c-2.5,3.2-5.1,6.2-7.7,9.3
					c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.7-4.6,4.3
					c-0.6,0.6-1.3,1.3-1.6,2.1c-1,2.4-2.8,4.4-4.4,6.4c-2,2.4-4.3,4.5-6.1,7.1c-0.6,0.9-1.5,1.5-2.4,1.9c-2.4,1.1-4.2,3-5.7,5.2
					c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.1,2.1-6.2,3.2
					c-0.8,0.4-1.6,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.1,1.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.6-2.3-2.4-3.9-1.6c-2.2,1-4.1,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.5-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.4,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.3,0.8,2.6,1.6,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.5-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.3-1.4c-0.4-0.4-1.5-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.3,1.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.5,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.6,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.5-0.9,0.5
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.9-0.5-1.4-1.1-1.6-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.3-0.1-2.5-0.5-3.4-1.6c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6-3-8.8-4.9
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.5-1.2-1-1.6c-2-2.3-3.9-4.5-6-6.6c-1.3-1.3-2.5-2.6-3.4-4.3c-0.5-1-1.3-2-2.2-2.7
					c-3.4-3.3-6.7-6.6-10.7-9.1c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.1-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.3-1.6-2.3-3.1-2.7c-1-0.3-2-0.7-2.9-1.2c-1-0.5-2-1.2-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.9-2-1.3c-1.8-1.2-3.7-2.4-5.5-3.8c-1.8-1.3-3.4-2.8-5.4-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.6-2.4-4.4-3.7-6.1-6c-0.6-0.9-1.3-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.3c-0.4-0.1-0.7-0.2-1.1-0.4
					c-1.2-0.3-2.1-1-2.9-2c-1.5-2.2-3.7-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3c-3.1-3.2-6-6.5-9.1-9.6
					c-3.2-3.2-6.6-6.3-9.9-9.4c-1-1-2-2.1-2.9-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4c0.2-0.4,0.3-0.7,0.4-1.1
					c0.2-0.7,0.2-1.3-0.3-1.8c-1.7-1.6-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3c0.4-0.4,0.5-1,0.4-1.5
					c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7c1.8-1.5,3.6-3,5.4-4.4
					c0.9-0.7,1.5-1.5,1.9-2.4c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.6-3.5,2.6-5.2c0.9-1.6,2.1-3,3.5-4.1c1.5-1.2,2.6-2.5,3.5-4.1
					c0.9-1.5,1.9-2.8,2.9-4.1c1-1.4,2.3-2.5,4-3c2.7-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.6-5.6c1.7-1.2,3.4-2.3,4.9-3.9
					c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,2-2.4,2.3-4c0.2-1,0.9-1.9,1.8-2.5c3.4-2.2,5.4-5.5,7.8-8.7
					c1.5-2,2.9-4,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.2-6.5c1.5-1.1,2.7-2.4,3.8-3.9c0.5-0.7,1.2-1.4,1.7-2.1
					c1.5-2,3.2-3.8,5.5-4.8c1.7-0.8,2.8-2.3,4-3.7c2.3-2.9,4.6-5.6,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8c0.5-0.3,1.1-0.7,1.6-1.1
					c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.3-4.2,6.3-6.3c1-1,2.1-2,3.5-2.3c1.2-0.3,2.1-1.1,2.7-2.1c2.2-3.1,4.8-5.9,7.5-8.5
					c0.9-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.5-0.9,0.9-1.3c0.5-0.8,1.3-1.2,2.3-1.3c3.3-0.2,5.9-2,8.1-4.3c0.5-0.5,1.1-1.2,1.6-1.6
					c0.9-0.8,1.9-1.3,3-1.7c1-0.3,2.1-0.3,3,0.2c3.2,1.8,6.5,3.4,9.7,4.9c1.1,0.5,1.9,1.4,2.3,2.6c0.2,0.7,0.4,1.5,0.7,2.2
					c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.3,1.5,2.6,2.9,4,4.3c0.6,0.7,1.1,1.5,1.8,2.1
					c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4c0.7,1.6,2.1,2.7,3.4,3.7
					c1.1,0.9,2.3,1.8,3.3,2.7c0.9,0.8,1.7,1.6,2.4,2.6c1.5,2,3.2,3.7,5.1,5.4c1,0.9,1.8,1.8,2.7,2.7c0.2,0.3,0.4,0.7,0.8,0.9
					c2.7,1.9,4.4,4.7,6.8,6.9c0.5,0.5,1.1,1.1,1.6,1.8c1.5,2.1,3.4,4,5.3,5.7c1.2,1,2.3,2.1,3.4,3.2c1.5,1.5,3,2.9,4.7,4.1
					c2.4,1.8,4.6,3.8,6.4,6.2c0.8,1,1.7,2,2.7,2.7c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4c4.1,3.8,8,7.8,12.4,11.3
					c1.4,1.2,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.4,4.4,6.7,6.6c0.3,0.2,0.5,0.5,0.8,0.9
					c2.6,3.3,5.7,6,8.7,9c2.2,2.3,4.3,4.5,6.3,6.9c2,2.6,3.6,5.4,5,8.4c0.5,1.2,0.7,2.5,0.7,3.8
					C568.9,224.8,568.9,225.6,568.9,226.4z M415.9,361.3c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.2,1.3-2.6,2.1-4.3,2.4c-0.4,0.1-0.7,0.3-1,0.5
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.5c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.8,0.1,2.3-0.7C415.3,363.4,415.9,362.4,415.9,361.3c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C416.6,359.9,416.2,360.5,415.9,361.3z M409.3,355.5
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.5-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-1.3-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C406.4,353.9,407.7,355.5,409.3,355.5z M395,359.3c0.4-0.1,0.8-0.1,1.2-0.2s0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
					C391.1,358.6,392.9,359.4,395,359.3z M398.2,353c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C397.9,353.4,398,353.1,398.2,353z M429.5,338.5c-0.1,0.1-0.2,0.2-0.2,0.3
					c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.2-0.6c-1-0.5-2-1-3-1.6
					C429.8,338.4,429.6,338.5,429.5,338.5z M388.6,362.4c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5s-0.2-0.5-0.4-0.5
					c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C388,362.3,388.3,362.4,388.6,362.4z M402.4,371.6
					c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.5-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.4,0.4,0.4
					c0.2,0.1,0.5-0.1,0.6-0.2C402.3,372.1,402.3,371.8,402.4,371.6z M400.3,344.7c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
					c-0.1,0.2,0.2,0.5,0.3,0.6C399.7,345.8,400.3,345.4,400.3,344.7z M392,364.9c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
					c0.2,0,0.5-0.2,0.5-0.4C392.2,365.5,392.1,365.2,392,364.9z M449.9,336.3c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4-0.2-0.8-0.6-0.9
					c-0.4-0.1-0.9,0.2-0.8,0.7C449.6,335.8,449.8,336,449.9,336.3z M416.9,348.3c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
					c0.2,0,0.5-0.1,0.6-0.2C417.4,348.6,417.2,348.4,416.9,348.3z M418.4,339.6c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
					c-0.2,0.1-0.4,0.2-0.4,0.4C417.8,339.6,418.1,339.7,418.4,339.6z"
            />
          </defs>
          <g>
            <path
              fill="#2466AF"
              d="M568.9,226.4c0.5,3.8-0.6,7.3-1.9,10.7c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,4.9
					c-3.5,3.4-6.6,7-10.4,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.7,6.7-9.4,9.3c-2.7,1.8-5.1,4-7.2,6.5c-2.5,3.2-5.1,6.2-7.7,9.3
					c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.7-4.6,4.3
					c-0.6,0.6-1.3,1.3-1.6,2.1c-1,2.4-2.8,4.4-4.4,6.4c-2,2.4-4.3,4.5-6.1,7.1c-0.6,0.9-1.5,1.5-2.4,1.9c-2.4,1.1-4.2,3-5.7,5.2
					c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.1,2.1-6.2,3.2
					c-0.8,0.4-1.6,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.1,1.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.6-2.3-2.4-3.9-1.6c-2.2,1-4.1,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.5-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.4,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.3,0.8,2.6,1.6,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.5-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.3-1.4c-0.4-0.4-1.5-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.3,1.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.5,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.6,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.5-0.9,0.5
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.9-0.5-1.4-1.1-1.6-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.3-0.1-2.5-0.5-3.4-1.6c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6-3-8.8-4.9
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.5-1.2-1-1.6c-2-2.3-3.9-4.5-6-6.6c-1.3-1.3-2.5-2.6-3.4-4.3c-0.5-1-1.3-2-2.2-2.7
					c-3.4-3.3-6.7-6.6-10.7-9.1c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.1-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.3-1.6-2.3-3.1-2.7c-1-0.3-2-0.7-2.9-1.2c-1-0.5-2-1.2-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.9-2-1.3c-1.8-1.2-3.7-2.4-5.5-3.8c-1.8-1.3-3.4-2.8-5.4-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.6-2.4-4.4-3.7-6.1-6c-0.6-0.9-1.3-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.3c-0.4-0.1-0.7-0.2-1.1-0.4
					c-1.2-0.3-2.1-1-2.9-2c-1.5-2.2-3.7-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3c-3.1-3.2-6-6.5-9.1-9.6
					c-3.2-3.2-6.6-6.3-9.9-9.4c-1-1-2-2.1-2.9-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4c0.2-0.4,0.3-0.7,0.4-1.1
					c0.2-0.7,0.2-1.3-0.3-1.8c-1.7-1.6-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3c0.4-0.4,0.5-1,0.4-1.5
					c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7c1.8-1.5,3.6-3,5.4-4.4
					c0.9-0.7,1.5-1.5,1.9-2.4c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.6-3.5,2.6-5.2c0.9-1.6,2.1-3,3.5-4.1c1.5-1.2,2.6-2.5,3.5-4.1
					c0.9-1.5,1.9-2.8,2.9-4.1c1-1.4,2.3-2.5,4-3c2.7-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.6-5.6c1.7-1.2,3.4-2.3,4.9-3.9
					c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,2-2.4,2.3-4c0.2-1,0.9-1.9,1.8-2.5c3.4-2.2,5.4-5.5,7.8-8.7
					c1.5-2,2.9-4,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.2-6.5c1.5-1.1,2.7-2.4,3.8-3.9c0.5-0.7,1.2-1.4,1.7-2.1
					c1.5-2,3.2-3.8,5.5-4.8c1.7-0.8,2.8-2.3,4-3.7c2.3-2.9,4.6-5.6,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8c0.5-0.3,1.1-0.7,1.6-1.1
					c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.3-4.2,6.3-6.3c1-1,2.1-2,3.5-2.3c1.2-0.3,2.1-1.1,2.7-2.1c2.2-3.1,4.8-5.9,7.5-8.5
					c0.9-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.5-0.9,0.9-1.3c0.5-0.8,1.3-1.2,2.3-1.3c3.3-0.2,5.9-2,8.1-4.3c0.5-0.5,1.1-1.2,1.6-1.6
					c0.9-0.8,1.9-1.3,3-1.7c1-0.3,2.1-0.3,3,0.2c3.2,1.8,6.5,3.4,9.7,4.9c1.1,0.5,1.9,1.4,2.3,2.6c0.2,0.7,0.4,1.5,0.7,2.2
					c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.3,1.5,2.6,2.9,4,4.3c0.6,0.7,1.1,1.5,1.8,2.1
					c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4c0.7,1.6,2.1,2.7,3.4,3.7
					c1.1,0.9,2.3,1.8,3.3,2.7c0.9,0.8,1.7,1.6,2.4,2.6c1.5,2,3.2,3.7,5.1,5.4c1,0.9,1.8,1.8,2.7,2.7c0.2,0.3,0.4,0.7,0.8,0.9
					c2.7,1.9,4.4,4.7,6.8,6.9c0.5,0.5,1.1,1.1,1.6,1.8c1.5,2.1,3.4,4,5.3,5.7c1.2,1,2.3,2.1,3.4,3.2c1.5,1.5,3,2.9,4.7,4.1
					c2.4,1.8,4.6,3.8,6.4,6.2c0.8,1,1.7,2,2.7,2.7c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4c4.1,3.8,8,7.8,12.4,11.3
					c1.4,1.2,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.4,4.4,6.7,6.6c0.3,0.2,0.5,0.5,0.8,0.9
					c2.6,3.3,5.7,6,8.7,9c2.2,2.3,4.3,4.5,6.3,6.9c2,2.6,3.6,5.4,5,8.4c0.5,1.2,0.7,2.5,0.7,3.8
					C568.9,224.8,568.9,225.6,568.9,226.4z M415.9,361.3c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.2,1.3-2.6,2.1-4.3,2.4c-0.4,0.1-0.7,0.3-1,0.5
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.5c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.8,0.1,2.3-0.7C415.3,363.4,415.9,362.4,415.9,361.3c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C416.6,359.9,416.2,360.5,415.9,361.3z M409.3,355.5
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.5-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-1.3-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C406.4,353.9,407.7,355.5,409.3,355.5z M395,359.3c0.4-0.1,0.8-0.1,1.2-0.2s0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
					C391.1,358.6,392.9,359.4,395,359.3z M398.2,353c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C397.9,353.4,398,353.1,398.2,353z M429.5,338.5c-0.1,0.1-0.2,0.2-0.2,0.3
					c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.2-0.6c-1-0.5-2-1-3-1.6
					C429.8,338.4,429.6,338.5,429.5,338.5z M388.6,362.4c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5s-0.2-0.5-0.4-0.5
					c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C388,362.3,388.3,362.4,388.6,362.4z M402.4,371.6
					c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.5-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.4,0.4,0.4
					c0.2,0.1,0.5-0.1,0.6-0.2C402.3,372.1,402.3,371.8,402.4,371.6z M400.3,344.7c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
					c-0.1,0.2,0.2,0.5,0.3,0.6C399.7,345.8,400.3,345.4,400.3,344.7z M392,364.9c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
					c0.2,0,0.5-0.2,0.5-0.4C392.2,365.5,392.1,365.2,392,364.9z M449.9,336.3c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4-0.2-0.8-0.6-0.9
					c-0.4-0.1-0.9,0.2-0.8,0.7C449.6,335.8,449.8,336,449.9,336.3z M416.9,348.3c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
					c0.2,0,0.5-0.1,0.6-0.2C417.4,348.6,417.2,348.4,416.9,348.3z M418.4,339.6c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
					c-0.2,0.1-0.4,0.2-0.4,0.4C417.8,339.6,418.1,339.7,418.4,339.6z"
            />
            <g>
              <path
                fill="#93CAE6"
                d="M313.6,331.3c5.1,12.1,16.9,27.4,27.1,35c25.2,18.7,59.7,22.9,89.9,23.5c14.4,0.3,28-2.3,39.5-12.3
						c9.3-8.1,22.9-22.2,29-32.4c20.8-34.1,30.2-66.1,24.6-105.5c-1.7-12.3-5.4-23-14-33c-12.1-13.8-28.7-22.6-47.1-26.6
						c-28.3-6.2-56.3-4.4-84.8-2.4c-13,1-26.4,2.1-37.4,9.4c-14,9.1-21,22.9-25.1,38c-3,11-4.4,21.2-6.6,32.1
						c-1.6,7.9,0.7,13.7,0.9,21.9C310.2,297,306.1,313.6,313.6,331.3z"
              />
              <path
                fill="#FFFFFF"
                d="M521,246.1c-6-19.6-17.9-37.2-33.3-50.6c-25-21.3-67.5-49.9-58.7,8.1c-8.1-2-22.9-4.1-24.5,7.2
						c-0.1,3.5,0.6,6.2-0.8,9.3c-1.1,2-2.3,3.1-4,4.2c-1.6,1.1-1.6,2.9-0.3,4.3c1.6,1.9,4.3,2.5,6.7,2.7c3.2,0.3,6.5,0.5,9.7,0.9
						c-1.6-0.3,0.4,0.1,0.7,0.1c5.3,0.9,10.9,2.1,15.3,5.4c-0.3-0.2-0.4-0.2-0.4-0.2c1.3,0.9,2.6,2,3.6,3c0,0,1.2,1.3,1.1,1.3
						c2.4,3.2,4.9,6.9,5.9,10.9c0-0.1-0.2,0.5-0.2,0.7c-0.2,0.2-0.4,0.7-0.7,0.9c-13.6,10.9-31.3,3.4-46.9,2.6
						c-7.1-0.6-14.5,0.2-20.8,3.6c-12.4,6.5-17.1,23.3-6.4,33.6c7.4,6.8,18,6.2,27.3,8c1.9,0.7,3.7,1.6,5.3,2.7
						c11.8,9.2,13,25.4,14.9,39.2c1.2,7.4,1.8,16.2,7.4,21.6c6.6,5.7,17,4.7,22.6-2c7.9-8.6,7.8-21,11-31.6
						c2.1-6.9,6-13.2,10.2-19.1c1.6-2.3,3.5-5,3.6-7.8c0.3-8.1-9.6-10.9-13-17.1c-4-5.5-4.4-18.9,3.4-21c0.3,0.3,0.3,0.2,0.1,0
						c0,0,0,0,0.1,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.1c-0.1,0-0.1,0-0.2,0c3,2,6.6,3.5,10.2,2.6
						c1.2-0.2,3-0.9,3.2-2.4c1-6.3,0.7-18.4,7.9-20.8l0,0c0.1-0.1,0.1-0.1-0.1-0.1c0-0.1,0.1,0,0.3,0c0,0,0,0,0.1,0l0,0
						c0.1,0,0.1,0.1,0.2,0.1l0,0c0,0,0.1,0,0.1,0.1l0,0c2.7,1.6,4.7,4.1,6.9,6.3c4.6,4.6,12.1,4.7,17.9,2.6
						c4.7-1.8,8.8-4.6,13.3-6.7C520.7,248.4,521.3,247.1,521,246.1z"
              />
              <path
                fill="#FFFFFF"
                d="M345.5,214.4c-0.7-6-0.7-13.2-2.4-19c-2.1-4.1-8.4-3.8-11.5-0.9c-16.6,14.4-27.7,33.3-35.1,53.9
						c-1.2,3.2,2.7,5.6,5.3,5.9c3.2,0.4,6.3,1.3,9.5,0.2c2.6-0.8,5.1-2.1,7.6-3.2c4.9-2.3,9.2-5.5,13.2-9.2c3.9-3.5,8-7.3,11.5-11.3
						C347.4,226.2,346,219.7,345.5,214.4z"
              />
              <path
                fill="#FFFFFF"
                d="M335.7,334.2c-2.3-5.5-7.1-9.6-12.2-12.6c-0.4-0.2-1.2-0.7-1.4-1.1l0.1,0.1c-2.4-3-3.1-6.7-3.4-10.5
						c-0.4-5.8-1.7-11-5-15.8c-4-5.9-10.1-10.4-16.3-13.7c-1.1-0.5-6.5-3.3-7.3-0.9c-7.9,23.3,10.9,76.3,31.3,90.1
						c1.6,1.2,3.4,2.1,5.4,2.5c0.8,0.2,3.1,0.5,3.5-0.7c2-7.1,4.1-14.1,5.8-21.2C337.4,345.1,337.9,339.4,335.7,334.2z"
              />
              <path
                fill="#4591CE"
                d="M529.2,229.1c-4.4-12.4-20.2-37-30.8-47c-13.4-12.7-32.7-18.2-51.7-19.4c-13.8-1-27.7-0.2-41.3,0.5
						c-9.9,0.5-17.1,4.6-26.6,7.4c-20.5,6.2-43.3,7.6-59.7,22.3c-11.2,10-27.7,39.4-31.8,53c-10.2,33.7-1,51.5,6.5,86.1
						c7.4,34.6,31.9,61.2,66.1,69.3c46.8,8.6,134.8-5.9,161.8-48.2c9.4-16.7,14.4-36.3,13.7-55C534.3,269.2,538.9,256.1,529.2,229.1
						z M514.4,330.9c-4.1,11.6-14.9,28.8-22.6,39.1c-9.6,12.7-22.9,18.2-37.8,21.1c-31.1,6-67.5,9.3-97.5-4.1
						c-17-8.2-36.6-24.9-42.9-43.5c4.1,7.5,14.3,23,20.7,20.5c3.9-1.5,3.6-6.5,5-9.9c1.4-4.9,2.9-9.9,4-14.9
						c3-13.2-0.6-22.7-10.7-28.4c-4.6-2.6-6.5-6.3-6.7-13.5c-0.5-11.9-7-21.2-19.4-27.6c-2.6-1.5-6.2,0-7.1,2.8
						c-0.3,1-0.6,2-0.9,3.1c-1.4-6.4-3.9-11-3.4-19.7c1.6-21.8,13.3-50,35.1-59.8c-9.9,11.4-17.6,25.2-23.7,42.3
						c-1.2,3,1.1,6.5,4.3,6.7c2.9,0.4,5.9,1.3,8.8,0.1c4.6-1.9,9.7-4,14-7.4c5-3.9,9.4-8.4,13.7-12.7l2.1-2.1c2-2,3.1-5.1,3-8
						c-0.5-8.8-0.9-17.5-2.3-26.2c30.2-8.8,60-17.4,92-16.9c-5.6,10.4-2.9,22.4-1.7,32.7c-8-1.6-25.8-8.4-25,5.7
						c0.6,5.9-1.1,9.8-5.8,12.8c-0.5,0.4-0.4,1.3,0.3,1.3c15.3,0.9,31.6,4,38.8,16.4c1.8,2.9,3.2,5.8,4.1,8.8
						c0.2,0.6-0.5,2.5-1.5,3.2c-14,11.7-33.2,5.5-50.7,3.8c-11.2-1-19.1,1.8-24.6,8.8c-3.2,4.1-4.6,8.5-4.2,13.2
						c0.8,8.8,5.5,13.6,14.6,14.9c3,0.4,6.2,0.9,9.1,1.3c10.7,1.4,18.3,7.3,23.2,17.9c6.8,14.7,4.6,28.8,9.8,43.7
						c2.5,7.3,9.1,8,14.3,2.9c5.2-4.9,7-11.6,8.3-17.4c0.8-3.7,1.6-7.4,2.4-11c1.9-8.9,6.6-16.4,11.3-23.2c4.9-7,4.5-10-2.3-15.2
						c-7.7-6-11-12.7-10.1-21.1c0.7-8.6,9-14.3,16.4-9.3c0.7,0.4,1.5,0.7,2.3,1c0.2,0.1,0.5,0.1,0.7-0.1c0.7-0.6,0.4-2.3,0.6-3.1
						c0.5-4.9,1.2-9.8,3.1-14.2c3-6.2,10.5-7.9,16-3.8c2.4,1.7,4.6,4,6.6,6.2c5,5.4,15.1-2.4,21.5-5.3c0.3-0.2,0.5-0.5,0.4-0.9
						c-8.8-29.7-32.4-53.2-58.6-67.8c24.9,5,46.8,20.3,54.7,44.7C530.8,257.5,528.2,291.9,514.4,330.9z"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#444444"
          d="M294.8,454.2c0.4,0,0.7,0,0.9,0c0.2,0,0.5,0,0.7,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.6,0
			c0.4,0,0.9,0,1.4,0c0.5,0,1,0,1.5-0.1c0.2,0,0.4,0,0.6,0.2c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0,0.9
			c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.3c-0.6,0-1,0-1.3,0.1c-0.3,0-0.7,0.1-1.2,0.1c-0.5,0-0.9-0.1-1.3-0.1
			c-0.3,0-0.7,0-0.9-0.1c-0.3,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.6,0h-1.9c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.4s0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.3c0-0.3,0-0.7,0-1
			c0-0.3,0-0.7,0-1c0-0.6,0-1.2,0.1-1.9c0.1-0.7,0.1-1.4,0.1-2.1c0-0.4,0-0.7,0-1c0-0.3,0-0.5,0-0.8v-10.2c0-0.3,0-0.8,0-1.3
			c0-0.5,0-1,0-1.5c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.2-0.5,0.5-0.5c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.5,0h1.1c0.4,0,0.7,0.3,0.7,0.9
			c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.6c0,0.9,0,1.8,0,2.7c0,0.9,0,1.8,0,2.6c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5-0.1,0.8V454.2z"
        />
        <path
          fill="#444444"
          d="M308.2,439.3l-0.2,4.3c0.2,0,0.5,0,0.8,0.1c0.3,0,0.7,0,1,0.1s0.7,0,1,0c0.3,0,0.5,0,0.7,0h1
			c0.3,0,0.6,0,0.9,0s0.4,0.1,0.4,0.3l0,1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.6,0.1-1,0.2
			c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1,0c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0-0.6,0c-0.3,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.1,0.3-0.1,0.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.5v0.6c0,0.5,0,1,0,1.6c0,0.5-0.1,1.1-0.3,1.7v1.7c0.1,0,0.3,0,0.4,0
			c0.1,0,0.3,0,0.4,0c0.8,0,1.5,0,2,0c0.5,0,1.1,0,1.7,0c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.1,0.6,0c0.1,0,0.3,0,0.5,0
			c0.2,0,0.4,0,0.5,0c0.1,0.2,0.2,0.5,0.2,0.7s0,0.4,0,0.7v1.3c0,0.1-0.3,0.2-0.8,0.2c-0.5,0-1.1,0.1-1.7,0.1c-0.6,0-1.4,0-2.5,0h-2
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0h-1.3c-0.5,0-0.7-0.2-0.7-0.7v-0.3c0.2-1,0.3-1.9,0.4-2.8c0-0.9,0.1-1.8,0.1-2.6v-1.9
			c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.8-0.1-1.1v-1.7c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.4c0-0.6,0-1.2,0.1-1.7c0.1-0.6,0.1-1.2,0.1-1.8
			c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4,0-0.7v-1.7c0-0.3,0-0.6,0-0.8s0-0.5,0.1-0.8l0.1-1.7v-0.2c0-0.2,0-0.3,0-0.5s0-0.4-0.1-0.6
			c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.2-0.2,0.5-0.2c0.4,0,0.8,0,1.2,0c0.4,0,0.9,0,1.3,0c0.4,0,0.8,0,1.1-0.1c0.4,0,0.8-0.1,1.1-0.1
			h0.6c0.3,0,0.6,0,1,0c0.4,0,0.7,0,1,0c0.3,0,0.6,0.1,0.9,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0,0.1,0,0.3,0.1,0.5s0,0.4,0,0.6
			c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0,0.4c0,0.5-0.3,0.7-0.8,0.7h-5.2V439.3z"
        />
        <path
          fill="#444444"
          d="M343.9,424.7l-1.7-0.3h-0.5c-1,0-2,0.3-2.9,0.8c-1,0.5-1.7,1.2-2.2,2.1c0,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.1-0.2,0.3-0.2,0.4v-0.1c-0.4,1.1-0.8,2.1-1,3c-0.2,1-0.4,2-0.6,3.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.7,0,1.1
			c0,0.9,0.1,1.8,0.3,2.7c0.2,1,0.5,1.9,0.8,2.8s0.8,1.8,1.2,2.7s1,1.6,1.5,2.3c0.2,0.3,0.5,0.6,0.9,0.8c0.4,0.2,0.9,0.4,1.4,0.6
			c0.5,0.1,1.1,0.3,1.6,0.3c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.6,0,1,0.1c0.4,0,0.8,0.1,1,0.1c0.6,0,1,0.2,1,0.6
			c0.1,0.4,0.2,1.4,0.4,2.7c0,0.1,0,0.4,0.1,0.8c0,0.4,0.1,0.8,0.1,1.2c0,0.4,0,0.8,0.1,1.2c0,0.4,0.1,0.7,0.1,0.8
			c0,0.5-0.1,0.8-0.4,1c-0.3,0.2-0.7,0.4-1.2,0.4c-0.5,0.1-1.1,0.1-1.8,0c-0.7,0-1.4-0.1-2.2-0.1c-1.7,0-3.3-0.4-4.9-1.1
			c-1.5-0.7-2.9-1.8-4.1-3c-1.2-1.3-2.3-2.7-3.2-4.3s-1.5-3.2-1.9-5l-0.5-2.1l-0.5-1.6c-0.3-1.1-0.4-2.3-0.4-3.7
			c0-1.1,0.1-2.2,0.2-3.3c0.1-1.1,0.3-2.1,0.5-2.8l0.4-2.1c0.1-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.6-1.2,1-1.8c0.4-0.6,0.9-1.2,1.4-1.8
			c0.5-0.6,1-1.3,1.5-2.1c1.3-1.3,2.8-2.4,4.5-3.3c1.7-0.9,3.5-1.3,5.5-1.3c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.4,0.3,2.1,0.6l0.8,0.3
			c0.5,0.2,1,0.4,1.3,0.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.6-0.4,1.7-1.3,3.2v-0.1l-0.9,2.1c0,0.1-0.1,0.3-0.2,0.6
			c-0.1,0.3-0.2,0.5-0.2,0.6L343.9,424.7z"
        />
        <path
          fill="#444444"
          d="M360.2,448.9c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.5,0,2.2-0.1c0.7-0.1,1.5-0.1,2.2-0.1
			c0.8,0,1.3,0,1.7,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.5
			c-0.1,0.7-0.2,1.2-0.5,1.4c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.6,0.1-2.3,0.1c-0.9,0-1.7,0-2.6-0.1
			c-0.8-0.1-1.7-0.1-2.5-0.1h-3.8c-0.7,0-1.2,0-1.5,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-1,0-1.9-0.1-2.9c0-1-0.1-2-0.1-3c0-1,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.8,0.2-2.8c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.8-0.1-1.3
			v-15.1c0-2,0-3.5-0.1-4.7c-0.1-1.2,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.2c0.4-0.2,1-0.2,1.8-0.2c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0
			h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,1
			c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5s0.1,1.7,0.1,2.5v-0.1c0.1,1,0.2,1.9,0.2,2.9s0,2-0.1,2.9c-0.1,1-0.1,1.9-0.1,2.9v1.9
			c0,0.9,0,1.7,0.1,2.2s0.1,1.1,0.1,1.5c0,0.4,0,0.7,0.1,0.9c0,0.2,0.1,0.4,0.1,0.6V448.9z"
        />
        <path
          fill="#444444"
          d="M377.2,457.4c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.4-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.8c0-1.7,0-3.4-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.5,0-2.9,0.1-4.4c0-1.5,0.1-2.9,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-1c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.1-1.2
			c0-0.4,0-0.7,0-0.9c0-0.5,0.1-1,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.4,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.6-0.1,4.3c0,1.8,0,3.9,0.1,6.6l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.3,0,3.4v2.2c0,0.6,0,1.4-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L377.2,457.4
			z"
        />
        <path
          fill="#444444"
          d="M410.4,440.5c0-0.1,0-0.4,0-0.9c0-0.5,0-1,0.1-1.6c0-0.5,0.1-1.1,0.1-1.6c0-0.5,0-0.8,0-1l-0.4,3.8
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1l-1.7,8.2l-1,4l-0.4,0.9l-0.2,0.5c-0.2,0.3-0.3,0.6-0.4,0.8c-0.1,0.2-0.2,0.7-0.3,1.5
			c-0.2,0.7-0.4,1.2-0.6,1.5c-0.2,0.3-0.5,0.5-0.9,0.6c-0.3,0.1-0.8,0.1-1.3,0.1c-0.5-0.1-1.1-0.1-1.8-0.1c-0.6,0-1,0-1.2-0.1
			c-0.2-0.1-0.4-0.4-0.6-1c-0.2-0.6-0.4-1.5-0.7-2.8c-0.3-1.3-0.7-3.1-1.4-5.4l-0.4-1.4c-0.1-0.1-0.1-0.2-0.1-0.4
			c0-0.1,0-0.3-0.1-0.4l-0.2-0.8l0.1,0.1c-0.2-0.7-0.4-1.4-0.6-2.2c-0.2-0.8-0.4-1.6-0.7-2.4s-0.4-1.6-0.7-2.4
			c-0.2-0.8-0.4-1.5-0.7-2.1l0.1,1.5l0.1,0.8c0.2,2.7,0.3,5.5,0.2,8.2c-0.1,2.8,0,5.5,0.2,8.2c0.1,0.8,0.1,1.4-0.1,1.8
			c-0.1,0.4-0.3,0.6-0.5,0.8c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.7-0.1-1.1-0.1c-0.7,0-1.5-0.1-2.2-0.2c-0.8-0.1-1.5-0.2-2.2-0.2
			h-0.9c-0.2-0.8-0.4-1.5-0.4-2.1c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.5,0-1,0-1.6c0.1-0.5,0.1-1,0.1-1.6l0.2-2.2c0-0.3,0-0.7-0.1-1.2
			c-0.1-0.5-0.1-1-0.1-1.4v-0.6c0.1-1.4,0.2-2.7,0.2-4.2c0-1.9,0-3.7-0.1-5.5c-0.1-1.8-0.1-3.7-0.2-5.5l-0.2-4.5
			c0-0.3-0.1-0.7-0.1-1.1c0-0.4,0-0.7,0-1v-3.6c-0.1-0.7,0-1.3,0.1-1.6c0.1-0.4,0.3-0.6,0.6-0.7c0.3-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.7,0,1,0c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.4,0.1,2.1,0.1c0.1,0,0.3,0,0.7-0.1c0.3,0,0.6-0.1,0.7-0.1c0.3,0,0.6,0.2,0.8,0.7
			c0.2,0.5,0.4,1.1,0.6,1.9c0.2,0.7,0.4,1.5,0.6,2.4c0.2,0.9,0.4,1.6,0.7,2.3c0.8,2.2,1.7,4.3,2.4,6.3c0.8,2,1.6,4.1,2.3,6.4
			l0.8,2.4l0.5-1.6v0.1c0.4-1.3,0.8-2.5,1.3-3.6c0.4-1.1,0.9-2.5,1.3-4c0.2-0.7,0.4-1.5,0.7-2.2c0.3-0.7,0.6-1.4,0.8-2.2l1-2.4v0.1
			c0.4-1.7,0.8-3,1.2-3.9c0.3-0.9,0.6-1.5,0.8-1.9c0.2-0.4,0.5-0.6,0.7-0.6c0.2,0,0.4,0,0.6,0h3c0.2,0,0.4,0,0.6,0
			c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1,0c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0.3,0.3,0.3,0.6c0.1,0.3,0.1,0.7,0.2,1.2
			c0,0.7,0.1,1.5,0.2,2.3c0.1,0.8,0.1,1.6,0.1,2.4c0,0.8,0,1.7-0.1,2.5c-0.1,0.8-0.1,1.7-0.1,2.5c0,1.6,0.1,3.3,0.2,5.1
			c0.2,1.7,0.2,3.5,0.2,5.2v1.5l-0.1,6.1c0,0.8-0.1,1.7-0.1,2.5c0,0.8,0,1.7,0,2.5c0,0.8,0,1.5,0.1,2.2c0.1,0.7,0.1,1.4,0.1,2.2
			c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0c-0.4-0.1-0.7-0.2-1.2-0.1
			c-0.4,0.1-0.8,0.1-1.2,0.1c-0.9,0-1.7,0-2.2,0s-1,0-1.4-0.1c-0.3-0.1-0.6-0.2-0.7-0.4c-0.1-0.2-0.2-0.5-0.1-1
			c0-0.1-0.1-0.2-0.1-0.3v-0.5v-0.2c0-1.3-0.1-2.7-0.1-4.2c0-1.5,0-2.9,0-4.2c0-0.4,0-0.7,0.1-0.9c0.1-0.3,0.1-0.5,0.1-0.8
			c0-0.7,0-1.4-0.1-2.1C410.5,442.1,410.4,441.4,410.4,440.5z"
        />
        <path
          fill="#444444"
          d="M441.6,456.9c-0.1-0.1-0.3-0.3-0.3-0.7c-0.1-0.4-0.1-0.7-0.1-0.9l-0.3-2c-0.1-1-0.3-2-0.5-3.2
			c-0.2-1.2-0.4-2.3-0.5-3.2c-0.8,0.2-1.5,0.3-1.9,0.4c-0.4,0.1-0.8,0.1-1.1,0.1c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.2-1.7-0.2
			l-0.2,0.9l-1.9,8.7c-0.1,0.3-0.2,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.7,0.1-1,0.1s-0.7,0-1.1,0c-0.5,0-1,0-1.4-0.1
			c-0.5-0.1-0.9-0.1-1.3-0.1c-0.5,0-0.9,0-1.3,0c-0.4,0-0.6-0.2-0.6-0.7c0-0.1,0-0.5,0.1-1c0.1-0.5,0.2-0.8,0.2-1c0-0.1,0-0.3,0-0.4
			c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.7,0.4-1.3c0.1-0.8,0.2-1.7,0.4-2.6c0.2-0.9,0.4-1.7,0.4-2.3l2.9-11
			c0.3-0.7,0.5-1.5,0.6-2.2c0.1-0.7,0.3-1.5,0.4-2.2l0.8-2.5l0.5-1.5c0.1-0.6,0.3-1.3,0.5-2.1c0.2-0.8,0.3-1.6,0.5-2.4
			c0.2-0.8,0.3-1.6,0.5-2.4c0.2-0.8,0.3-1.5,0.5-2.1c0.1-0.4,0.2-0.7,0.3-1c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.1,0.7-0.2,1.3-0.2
			c0.6,0,1.4-0.1,2.4-0.1c0.9,0,1.5,0,1.9,0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.5,0.4,0.9c0.1,0.4,0.2,0.9,0.4,1.6
			c0.2,0.6,0.4,1.3,0.6,2.1c0.2,0.8,0.4,1.6,0.7,2.4c0.2,0.8,0.4,1.6,0.6,2.4c0.2,0.8,0.4,1.4,0.5,2c0,0.2,0.1,0.4,0.1,0.7
			s0.1,0.6,0.2,0.9s0.2,0.7,0.3,1.2l1.9,7.1l3.7,13.6c0.1,0.3,0.2,0.6,0.2,1s0.2,0.6,0.3,1l0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.7
			c0,0.2,0,0.4,0,0.6c0,0.3-0.1,0.6-0.2,0.7c-0.1,0.1-0.4,0.3-1,0.5c-0.3,0.1-0.7,0.2-1.3,0.2c-0.6,0-1.2,0-1.7,0
			c-0.3,0-0.6,0-1,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1-0.1C441.9,457.2,441.7,457.1,441.6,456.9z M437.9,438.7
			c-0.1-0.6-0.2-1.3-0.3-2.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.9-0.3-1.7-0.5-2.5c-0.2-0.8-0.4-1.5-0.7-2.1l-1.2,9.2H437.9z"
        />
        <path
          fill="#444444"
          d="M456.1,425.5l-6.9,0.2l-0.3-2.2c0-1.4,0-2.4,0-3.2c0-0.8,0.1-1.4,0.2-1.9c0.1-0.4,0.3-0.7,0.5-0.9
			c0.2-0.1,0.6-0.2,1.1-0.3l2-0.2c0.7-0.1,1.4-0.1,2.1-0.2c0.7,0,1.4-0.1,2.1-0.1c1,0,1.9,0.1,2.9,0.2c0.9,0.1,1.8,0.2,2.8,0.2
			c0.7,0,1.4,0,2.1-0.1c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.7,0,1.2-0.1c0.5-0.1,1-0.1,1.4-0.1c0.5,0,0.9,0.1,1.2,0.2
			c0.3,0.1,0.6,0.3,0.6,0.7c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5,0,1,0,1.4c0,0.4,0,0.8-0.1,1.1c0,0.3-0.1,0.7-0.1,1.3c0,0.2,0,0.4,0,0.5
			c0,0.2,0,0.4,0.1,0.7c0.3,0.9-0.7,1.3-2.9,1.3c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.2-1.3,0.2l-0.2,1.9c0,0.4,0,0.8,0,1.2c0,0.4,0.1,0.7,0.1,1.2c0,1.2,0,2.5,0,3.8c0,1.3,0,2.6-0.1,3.9
			c0,1.3-0.1,2.6-0.1,3.8c-0.1,1.2-0.1,2.4-0.1,3.4l-0.1,1.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.7,0,1.3,0.1,1.9
			c0.1,0.6,0.1,1.2,0.1,1.7v3.2c0,0.5,0,0.9,0.1,1.4c0.1,0.4,0.1,0.8,0,1.2c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-1,0.3
			c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0.1-1.8,0.1c-1.4,0-2.4-0.1-3.1-0.2c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.7,0.2-1.1
			c0.2-0.9,0.3-1.8,0.3-2.8c0-0.9,0.1-1.8,0.1-2.7v-4.7c0-1,0.1-2.1,0.2-3.3c0.1-1.2,0.2-2.3,0.2-3.5c0-1,0-2-0.1-3
			c-0.1-1-0.1-2-0.2-3l0.1-2.7c-0.1-0.7-0.1-1.4-0.1-2.1C456,426.9,456.1,426.2,456.1,425.5z"
        />
        <path
          fill="#444444"
          d="M500.9,450.7l0.6-1.5c0.1-0.2,0.2-0.3,0.3-0.3c0.2,0,0.4,0,0.7,0.1c0.3,0.1,0.5,0.2,0.7,0.3
			c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.2,0.4,0.2,0.7c0,0.6-0.1,1.1-0.4,1.7c-0.3,0.6-0.6,1.1-0.9,1.5c-0.1,0.1-0.3,0.2-0.5,0.4
			c-0.2,0.2-0.3,0.3-0.5,0.5l0.2,0.4c0.5,1.1,0.8,1.8,1,2.2c0.2,0.4,0.3,0.7,0.3,0.8c0,0-0.2,0.1-0.5,0.1c-0.3,0-0.7,0.1-1.2,0.1
			c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.4-0.4l-0.4-1.3l-2,0.5c-0.4,0.1-0.9,0.2-1.6,0.3c-0.7,0.1-1.4,0.2-2,0.2
			c-1.8,0-3.2-0.5-4-1.4c-0.8-0.9-1.3-2.2-1.3-3.8c0-0.7,0.1-1.4,0.2-2.1c0.1-0.6,0.3-1.3,0.5-1.9c0.2-0.6,0.5-1.3,0.8-1.9
			c0.3-0.7,0.7-1.4,1.1-2.2l0.7-1.3c-0.4-0.5-0.8-1-1.2-1.5c-0.4-0.5-0.7-0.9-1-1.3c-0.3-0.4-0.5-0.9-0.7-1.3
			c-0.2-0.5-0.3-1-0.3-1.5c0-0.7,0.1-1.3,0.4-1.9c0.2-0.6,0.6-1.2,1-1.7c0.4-0.5,0.9-0.9,1.5-1.2c0.6-0.3,1.2-0.4,1.9-0.4
			c0.4,0,0.9,0.1,1.3,0.2c0.4,0.2,0.8,0.4,1.2,0.7c0.3,0.3,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1.2,0.3,2c0,0.7-0.1,1.3-0.3,1.8
			c-0.2,0.6-0.4,1.2-0.7,1.8l-0.5,1.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.5-0.3,0.8-0.3,1.1c0,0.5,0.1,0.9,0.4,1.3l1.3,2
			c0.2,0.3,0.5,0.7,0.7,1c0.2,0.4,0.4,0.7,0.7,1l0.6,0.7l0-0.1l1.8,2.5L500.9,450.7z M493.4,440.9c0.2-0.3,0.3-0.6,0.4-1
			c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.2-1.2c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.6-0.5-1.1-0.5
			c-0.2,0-0.4,0-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.7
			l1.1,2.2L493.4,440.9z M498,453.5c-0.7-0.9-1.4-2-2.2-3.2c-0.8-1.2-1.5-2.2-2.2-3.2c-0.2,0.2-0.3,0.3-0.4,0.5
			c-0.1,0.1-0.2,0.3-0.3,0.4l-0.6,1.2c0,0,0,0.2,0,0.3c0,0.2-0.1,0.4-0.1,0.7c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,0.8
			c0,0.6,0.1,1.1,0.2,1.5c0.2,0.4,0.3,0.7,0.6,0.9c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.1,0.5,0.1,0.7,0.1c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0
			l0.6-0.1c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.7-0.3,1-0.6L498,453.5z"
        />
        <path
          fill="#444444"
          d="M519.5,454.2c0.4,0,0.7,0,0.9,0c0.2,0,0.5,0,0.7,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.6,0
			c0.4,0,0.9,0,1.4,0c0.5,0,1,0,1.4-0.1c0.2,0,0.4,0,0.6,0.2c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0,0.9
			c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.3c-0.6,0-1,0-1.3,0.1c-0.3,0-0.7,0.1-1.2,0.1c-0.5,0-0.9-0.1-1.3-0.1
			c-0.3,0-0.7,0-0.9-0.1c-0.3,0-0.5,0-0.7,0s-0.4,0-0.6,0h-1.9c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.3c0-0.3,0-0.7,0-1
			c0-0.3,0-0.7,0-1c0-0.6,0-1.2,0.1-1.9c0.1-0.7,0.1-1.4,0.1-2.1c0-0.4,0-0.7,0-1c0-0.3,0-0.5,0-0.8v-10.2c0-0.3,0-0.8,0-1.3
			c0-0.5,0-1,0-1.5c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.2-0.5,0.5-0.5c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.5,0h1.1c0.5,0,0.7,0.3,0.7,0.9
			c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.6c0,0.9,0,1.8,0,2.7c0,0.9,0,1.8,0,2.6c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8V454.2z"
        />
        <path
          fill="#444444"
          d="M540.8,457.2c-0.3,0-0.5,0-0.7-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2-0.1-0.5-0.2-0.8
			c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.5-0.2-1-0.4-1.5c-0.1-0.5-0.2-1-0.3-1.4c-0.1-0.5-0.2-0.9-0.2-1.2c-0.4,0-0.7,0.1-0.9,0.1
			c-0.2,0-0.4,0-0.6,0s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.8,0.1c-0.2,0-0.5,0-0.8,0
			c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.5,0l-0.3,1.6c-0.1,0.8-0.3,1.6-0.5,2.3c-0.2,0.8-0.4,1.5-0.6,2.3l-0.1,0.5
			c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.5,0.1c-0.1,0-0.2,0-0.5,0s-0.5,0-0.8-0.1c-0.3,0-0.5-0.1-0.7-0.2
			c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.7l0.2-1c0.1-0.4,0.1-0.8,0.2-1.2
			c0.1-0.4,0.1-0.8,0.2-1.3v0c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.2-0.7,0.3-1.1l0.4-1.4c0.1-0.5,0.3-1.1,0.5-1.7s0.4-1.2,0.5-1.8
			c0.4-1.2,0.6-2.2,0.9-2.9c0.2-0.7,0.4-1.3,0.5-1.7c0.1-0.4,0.2-0.7,0.2-0.8c0-0.1,0.1-0.2,0.1-0.3c0.2-0.8,0.4-1.7,0.6-2.5
			c0.2-0.8,0.3-1.7,0.5-2.5c0.1-0.3,0.1-0.5,0.2-0.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.5,0,0.8,0
			c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.5c0.2,1,0.6,2,0.9,3.1c0.4,1,0.6,2.1,0.8,3.2
			c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.4,0.2,0.8l1.5,5.5l2.8,10.3v0.1c0,0.4-0.1,0.7-0.4,0.7c-0.2,0-0.6,0-1.1,0H540.8z
			 M534.6,440.9l-1.7,6.2h4.7c-0.1-0.8-0.3-1.5-0.5-2.3c-0.2-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.4-1.4-0.7-2.1s-0.5-1.5-0.8-2.2
			L534.6,440.9z"
        />
        <path
          fill="#444444"
          d="M289.5,518.6c-0.5-0.1-0.8-0.2-0.9-0.4c-0.1-0.2-0.1-0.6-0.1-1.2l0.1-1.9c0-0.3,0-0.5,0-0.8c0-0.3,0-0.6,0-1
			l0.1-5.9c0-0.5,0-1.1,0-1.7s0-1.1,0-1.7c0-1.2,0.1-2.4,0.1-3.6s0-2.4,0-3.6V494c0-0.6-0.1-1.2-0.2-1.8c-0.1-0.6-0.1-1.2-0.2-1.8
			c-0.1-0.5-0.1-1-0.2-1.5c-0.1-0.5-0.1-1-0.2-1.5c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1v-4.1c0.1-0.7,0.2-1.2,0.5-1.3
			c0.3-0.2,0.7-0.3,1.4-0.4c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.1,0c1.1,0,2.2,0.1,3.3,0.2c1.1,0.1,2.1,0.3,3.2,0.4
			c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.3,0.8,0.4l2.1,0.9c2.8,1.4,4.3,3.6,4.6,6.8c0.1,1.3,0,2.5-0.2,3.8
			c-0.1,0.9-0.4,1.8-0.7,2.6c-0.4,0.8-0.9,1.6-1.5,2.3l-1.2,1.3c1.2,0.4,2.2,1,3,1.9c0.8,0.9,1.5,1.8,2.2,2.8c1,1.5,1.5,3,1.5,4.5
			c0,1.1-0.2,2.2-0.7,3.3c-0.2,0.5-0.4,0.8-0.5,1.1c-0.1,0.3-0.2,0.5-0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.1
			c-0.8,1.5-1.8,2.7-2.8,3.6c-1.1,0.9-2.2,1.6-3.5,2.2c-0.8,0.4-1.6,0.7-2.4,1c-0.7,0.3-1.7,0.4-2.8,0.5c-0.2,0.1-0.5,0.2-0.7,0.1
			c-0.3,0-0.6,0-0.9,0c-1.1,0.1-2,0.2-2.8,0.2c-0.8,0-1.6,0-2.4,0c-0.2,0-0.3,0-0.4-0.1C289.7,518.7,289.6,518.6,289.5,518.6z
			 M295.9,489.6l0.1,5c0.4-0.1,0.8-0.4,1.2-0.8c0.4-0.4,0.7-0.8,1-1.2c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.5,0.3-0.9,0.4-1.4
			c0.1-0.5,0.2-1.1,0.2-1.7c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.6-1-1.3-1.4c-0.3-0.4-0.5-0.6-0.9-0.7c-0.3-0.1-0.7-0.2-1-0.2
			L295.9,489.6z M296.8,504.5c0,1.1,0,2.2,0.1,3.3c0,1.1,0,2.2-0.2,3.2l0.9-0.2c0.5-0.1,1-0.2,1.6-0.5c0.5-0.2,1-0.5,1.4-0.8
			c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4,0.1-0.8,0.1-1.2s0.1-0.8,0.1-1.2c0.1-1.1-0.3-2.1-1.3-3c-0.1-0.1-0.3-0.3-0.5-0.4
			c-0.2-0.1-0.3-0.3-0.5-0.3c-0.9-0.2-1.5-0.3-1.9-0.2s-0.6,0-0.6,0c0,0.5,0.1,0.9,0.1,1.3C296.8,503.6,296.8,504,296.8,504.5z"
        />
        <path
          fill="#444444"
          d="M319.2,518.5c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.4-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.8c0-1.7,0-3.4-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.5,0-2.9,0.1-4.4c0-1.5,0.1-3,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-1c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.1-1.2
			c0-0.4,0-0.7,0-0.9c0-0.5,0.1-1,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.4,0
			c0.6,0,1,0.2,1.3,0.4s0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.6c0,1.1,0,2.6-0.1,4.3c0,1.8,0,3.9,0.1,6.6l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.3,0,3.4v2.2c0,0.6,0,1.4-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L319.2,518.5
			z"
        />
        <path
          fill="#444444"
          d="M353.3,497.8c-0.2,1.4-0.4,2.7-0.5,4.2c-0.1,1.4-0.3,2.8-0.5,4.2c-0.3,1.4-0.6,2.7-1.2,3.9
			c-0.5,1.3-1.3,2.4-2.3,3.4c-0.4,0.3-0.8,0.7-1,1.2c-0.3,0.4-0.6,0.9-1.2,1.4c-0.8,0.8-1.8,1.5-2.8,2c-1,0.5-2.1,0.7-3.3,0.7
			c-0.9,0-1.7-0.1-2.6-0.4c-0.9-0.3-1.7-0.6-2.5-1c-1.3-0.5-2.4-1.3-3.4-2.3c-1-1-1.8-2.1-2.5-3.3c-0.7-1.2-1.2-2.5-1.6-3.8
			c-0.3-1.3-0.5-2.6-0.5-3.9c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.9,0-1.3c0-1.4,0.1-2.8,0.4-4.1c0.3-1.4,0.4-2.7,0.4-4
			c0-1.4,0.2-2.5,0.5-3.3c0.3-0.9,0.6-1.8,0.8-2.8l0.4-1.8c0.2-0.6,0.4-1.1,0.5-1.6c0.2-0.5,0.4-1,0.7-1.5c0.3-0.5,0.6-0.9,0.9-1.3
			c0.4-0.4,0.8-0.7,1.4-1.1c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.3,0.5-0.4l0.8-0.5h-0.1c0.6-0.5,1.3-0.8,1.9-1
			c0.7-0.2,1.4-0.3,2.1-0.3c0.9,0,1.9,0.1,2.7,0.3h-0.1c0.5,0.1,1.1,0.4,1.7,0.7c0.6,0.3,1.1,0.7,1.6,1.1c0.5,0.4,1,0.8,1.5,1.2
			c0.5,0.4,0.9,0.7,1.3,1.1h-0.1c1.5,1.5,2.7,3.1,3.5,4.8c0.8,1.7,1.2,3.6,1.3,5.6c0,0.7,0.1,1.2,0.3,1.7c0.1,0.5,0.3,0.8,0.3,1.1
			l0.3,2.2V497.8z M344.7,497.9c-0.1-0.8-0.2-1.6-0.3-2.4c-0.1-0.8-0.3-1.7-0.4-2.5c-0.2-0.8-0.4-1.6-0.7-2.3
			c-0.3-0.7-0.7-1.4-1.3-1.9v0.1c-0.7-0.7-1.3-1.2-1.8-1.5c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.7,0-1.3,0.3-1.8,0.9
			c-0.5,0.6-0.9,1.3-1.2,2.2c-0.3,0.9-0.5,1.8-0.6,2.8c-0.1,1-0.2,1.8-0.2,2.5v0.9c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.5v3.1
			l0.3,3.8c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.8,0.3,1.5,0.6,2.1s0.6,1.1,1,1.5c0.4,0.4,1,0.6,1.7,0.6c1.2,0,2.1-0.4,2.9-1.2
			c0.7-0.8,1.3-1.7,1.7-2.8c0.4-1.1,0.7-2.3,0.8-3.5c0.2-1.2,0.3-2.3,0.3-3.2L344.7,497.9z"
        />
        <path
          fill="#444444"
          d="M380.2,495.5c-0.1,1.9-0.3,3.8-0.5,5.5c-0.3,1.7-0.7,3.3-1.2,4.8c-0.5,1.5-1.3,2.9-2.1,4.2
			c-0.9,1.3-2,2.4-3.3,3.5c-1.3,0.9-2.7,1.8-4.4,2.8c-1.7,1-3.3,1.6-4.8,2c-0.9,0.2-1.6,0.4-2.2,0.6c-0.6,0.2-1.1,0.3-1.7,0.3
			c-0.5,0-0.9,0-1.3,0c-0.3,0-0.6,0-0.9-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.5-0.1-0.9l-0.2-8.2l-0.1-1
			c-0.1-0.4-0.1-1-0.1-1.6s0-1.2,0-1.9c0-1.3,0-2.6,0.1-4c0.1-1.3,0.1-2.6,0.1-3.9c0-0.8,0-1.6-0.1-2.5c-0.1-0.8-0.1-1.7-0.2-2.5
			l-0.1-2c0-0.1,0-0.3-0.1-0.6c0-0.3-0.1-0.7-0.1-1.1c0-1,0-2,0.1-3.1c0.1-1.1,0.2-2.1,0.2-3.1l0.1-3.5c0-0.4,0.1-0.8,0.2-1
			c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.6-0.1,1-0.1c0.4,0,1,0,1.7,0c1.8,0,3.5,0.2,5.2,0.6c1.7,0.4,3.4,1,4.9,1.8
			c1.5,0.8,2.9,1.7,4.2,2.8c1.2,1.1,2.2,2.3,3,3.7c0.8,1.4,1.4,2.9,1.9,4.4c0.5,1.5,0.8,2.8,0.8,4.1c0,0.1,0,0.3,0,0.4
			C380.2,495.4,380.2,495.5,380.2,495.5z M365.4,485.5c0,1.6,0,3.2,0,4.8c0,1.6,0,3.2,0.1,4.8l0.3,13.5c0.6-0.1,1.2-0.6,1.9-1.4
			c0.7-0.8,1.4-1.8,2-2.9c0.6-1.1,1.1-2.3,1.5-3.5c0.4-1.2,0.6-2.3,0.6-3.2v-0.7l-0.1-1c-0.1-0.3-0.1-0.7-0.1-1.1
			c0-0.4,0-0.7-0.1-1.1c0-0.5-0.1-1.1-0.3-1.9c-0.2-0.7-0.5-1.4-0.8-2.1c-0.3-0.7-0.7-1.3-1.1-1.9c-0.4-0.6-0.9-1-1.3-1.2l-0.4-0.2
			c-0.3-0.2-0.6-0.4-1-0.5c-0.4-0.2-0.8-0.3-1.1-0.4V485.5z"
        />
        <path
          fill="#444444"
          d="M388.8,518.5c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.4-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.8c0-1.7,0-3.4-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.5,0-2.9,0.1-4.4c0-1.5,0.1-3,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-1c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.1-1.2
			c0-0.4,0-0.7,0-0.9c0-0.5,0.1-1,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.4,0
			c0.6,0,1,0.2,1.3,0.4s0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.6c0,1.1,0,2.6-0.1,4.3c0,1.8,0,3.9,0.1,6.6l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.3,0,3.4v2.2c0,0.6,0,1.4-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L388.8,518.5
			z"
        />
        <path
          fill="#444444"
          d="M420,478.8c0.2,0,0.4,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0,0.8c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.5-0.2,0.6
			c-0.6,2.3-1.2,4.6-1.8,6.9c-0.6,2.3-1.3,4.6-2,6.8v-0.1l-0.7,2.5c-0.1,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.1,0.8-0.2,1.2l-0.7,3.2
			c-0.1,0.7-0.2,1.3-0.4,2c-0.2,0.7-0.3,1.3-0.4,2l-0.9,5c-0.1,0.5-0.2,0.9-0.4,1.4c-0.2,0.5-0.3,1-0.4,1.5l-0.3,1.4
			c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.3,0.7c-0.3,0.3-0.6,0.4-0.8,0.4s-0.6,0-1.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
			h-2.4c-0.4-0.1-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1l-1-2.4c-0.3-0.7-0.6-1.5-0.9-2.3c-0.3-0.8-0.4-1.6-0.5-2.2l-0.8-5.4
			c-0.5-3-0.9-5.9-1.4-8.8c-0.4-2.9-1-5.8-1.6-8.5l-1.7-6.7c-0.2-0.7-0.3-1.2-0.4-1.6c-0.1-0.4-0.1-0.7,0-1c0.1-0.2,0.3-0.4,0.6-0.5
			c0.3-0.1,0.8-0.2,1.5-0.2c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0,0.7,0c0.5,0,1,0,1.7,0
			c0.6,0,1.2,0,1.8,0.1c0.6,0.1,1,0.2,1.5,0.4c0.4,0.2,0.6,0.5,0.7,1c0.1,0.3,0.1,0.6,0.2,1s0.1,0.9,0.1,1.3c0,0.5,0.1,0.9,0.1,1.3
			c0,0.4,0.1,0.7,0.1,0.9c0.3,2.3,0.4,4.1,0.6,5.3c0.1,1.2,0.2,2.1,0.3,2.8c0.1,0.7,0.1,1.2,0.2,1.7c0,0.4,0,1,0.1,1.8v0.5
			c0,0.4,0,0.9,0.1,1.5c0.1,0.6,0.1,1.1,0.2,1.7c0.1,0.6,0.2,1.1,0.2,1.6c0.1,0.5,0.1,1,0.2,1.3c0.2-0.6,0.3-1.2,0.4-2
			c0.1-0.7,0.2-1.5,0.3-2.3c0.1-0.8,0.2-1.5,0.2-2.2c0.1-0.7,0.2-1.4,0.4-1.9c0-0.4,0.1-0.8,0.2-1.2c0.1-0.5,0.2-0.9,0.2-1.3
			c0.1-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.1-0.6,0.2-0.7l0.2-0.9c0.1-0.6,0.2-1.1,0.2-1.7c0-0.5,0.1-1.1,0.2-1.7l0.5-3.6
			c0.1-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.2-0.8,0.3-1.1c0.1-0.3,0.3-0.5,0.6-0.6c0.3-0.1,0.6-0.1,1.1,0c0.6,0,1.2-0.1,1.7-0.1
			s1.2,0,1.8,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0s0.5,0,0.9,0.1C418.9,478.7,419.3,478.7,420,478.8z"
        />
        <path
          fill="#444444"
          d="M433.4,490.1l-0.2,4.8l2.6,0.1c0.4,0,0.9,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.5l-0.1,4.2c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1s-0.6,0-1.1,0l-5.7,0.3c-0.4,0-0.5,0.3-0.5,0.7v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.6-0.3,2.2
			c0,0.2-0.1,0.4-0.1,0.7c1,0.1,1.8,0.2,2.6,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.6-0.1,1-0.1
			c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6s0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3.1c0,0.4-0.2,0.7-0.5,0.9
			c-0.3,0.2-0.8,0.3-1.6,0.3c-0.7,0.1-1.7,0.1-3,0.1c-1.2,0-2.7,0-4.6,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1
			c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0-1,0h-1.9c-1,0-1.6,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7
			c0-0.2,0-0.4,0-0.6s0-0.3,0-0.3c0.3-1.3,0.4-2.5,0.4-3.8c0-1.3,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6
			l-0.2-2.9c0-1.2,0.1-2.2,0.2-3.2c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.6c0-0.4,0-0.8,0-1.3s0-0.9,0.1-1.3
			l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1
			s1-0.4,2.1-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.4,0.2,2,0.2c0.8,0,1.6-0.1,2.4-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9
			c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0s1.2,0,1.7,0c0.5,0,1,0,1.4,0c0.5,0,0.8,0.6,0.8,1.7c0,1.1,0,2.6,0,4.6
			c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.3,0-2.3,0h-5.6V490.1z"
        />
        <path
          fill="#444444"
          d="M447.3,518.6c-0.1-0.7-0.1-1.6-0.1-2.5c0-0.9,0-1.7,0-2.3v-0.3c0-1.3,0.1-2.6,0.1-4c0-1.3,0-2.7,0-4
			c0-1.2,0-2.3,0-3.5c0-1.1,0-2.3-0.1-3.4v0.1c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1v-2.7c0-0.5,0-0.9,0-1.4c0-0.5,0-0.9,0-1.4
			v-2.9c0-0.5,0-1.3-0.1-2.2c-0.1-0.9-0.1-1.8-0.1-2.7s0.1-1.7,0.2-2.4c0.1-0.7,0.4-1.1,0.8-1.3c0.5-0.2,1.1-0.3,1.6-0.2
			c0.6,0,1.1,0.1,1.5,0.1c0.3,0,0.6,0,0.9,0.1c0.3,0,0.6,0.1,0.9,0.2c0.8,0.2,1.6,0.4,2.4,0.6c0.8,0.2,1.6,0.4,2.4,0.6
			c0.7,0.1,1.3,0.4,1.9,0.7c0.6,0.3,1.2,0.7,1.8,1.1c2.2,1.5,3.8,3.4,4.9,5.7c1.1,2.3,1.6,4.7,1.6,7.2c0,1.1-0.2,2.1-0.5,3.2
			c-0.3,1.1-0.8,2.1-1.3,3.1c-0.5,1-1.2,1.8-1.9,2.6c-0.7,0.7-1.5,1.3-2.3,1.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			l-0.9,0.3l6.2,8.7l1.3,1.8l1.5,3c0,0.3-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5,0.1
			l-5.6,0.3c-0.1,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.6-0.7-0.9-1.1s-0.7-0.9-1-1.4c-0.3-0.5-0.6-0.9-0.9-1.3l-1.2-1.7
			c-0.2-0.3-0.4-0.7-0.6-1.1c-0.2-0.5-0.4-0.9-0.6-1.5c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-0.9-0.4-1.3c0,0.8,0.1,1.9,0.2,3.1
			c0.1,1.2,0.2,2.4,0.2,3.6c0.1,1.2,0,2.1-0.1,3c-0.1,0.8-0.4,1.2-0.9,1.2c-0.4,0-0.9,0-1.5,0c-0.5,0-1.1,0-1.7,0
			c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0L447.3,518.6z M455.7,498.4c0.4-0.3,0.9-0.5,1.3-0.8c0.4-0.3,0.8-0.6,1.1-1
			c0.3-0.4,0.5-1,0.7-1.7c0.2-0.7,0.2-1.6,0.2-2.7c0-1.3-0.2-2.5-0.7-3.6c-0.5-1.1-1.3-1.9-2.5-2.4l-0.1,6.1V498.4z"
        />
        <path
          fill="#444444"
          d="M487.4,486.7c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.3-0.7,0.6-0.9,0.9c-0.2,0.4-0.3,0.8-0.4,1.3v1.2c0,0.9,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1.6,2.4,2.4
			l1.8,1.5l2.9,2.4c1.3,1.3,2.3,2.7,2.9,4.3c0.7,1.6,1,3.3,1,5.2c0,0.4,0,1-0.1,1.6c-0.1,0.6-0.2,1.3-0.4,1.9
			c-0.2,0.6-0.4,1.2-0.7,1.8c-0.3,0.6-0.5,1-0.9,1.3c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-1,0.6l-0.7,0.4
			c-0.4,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-0.9,0.4c-0.6,0.3-1.3,0.5-2,0.7c-0.7,0.2-1.5,0.3-2.3,0.3c-0.5,0-1.1-0.1-1.6-0.2
			c-0.5-0.1-1.1-0.2-1.7-0.3s-1.3-0.4-1.9-0.8c0,0-0.1,0-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.2-0.9-0.4-1.3-0.7
			c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.4-1c0.2-0.4,0.3-0.9,0.4-1.4c0.1-0.5,0.2-0.9,0.3-1.3
			c0.1-0.4,0.3-0.7,0.5-1c0.2-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0.1l2.3,0.7l1.4,0.4c0.3,0.1,0.6,0.2,0.9,0.2
			c0.3,0,0.6-0.1,1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.1-0.9c-0.3-1.1-0.6-2-1.1-2.6
			c-0.5-0.6-1-1-1.6-1.3l-1.9-1.5c-0.6-0.4-1.1-0.8-1.5-1.1c-0.4-0.3-0.9-0.7-1.4-1.1c-1.4-1.2-2.4-2.7-3.2-4.5
			c-0.7-1.8-1.1-3.5-1.1-5.3c0-1.2,0.1-2.4,0.4-3.6c0.3-1.1,0.6-2.4,1.1-3.7c0.4-1,1-1.9,1.8-2.7c0.8-0.8,1.6-1.5,2.6-2
			c1-0.5,2-1,3.1-1.3c1.1-0.3,2.1-0.4,3.2-0.4c0.5,0,1.3,0,2.2,0.1c0.9,0.1,1.8,0.2,2.6,0.3c0.8,0.2,1.5,0.4,2.1,0.7
			c0.5,0.3,0.7,0.7,0.6,1.3c-0.3,1-0.6,2.1-0.9,3.3s-0.8,2.4-1.5,3.7L487.4,486.7z"
        />
        <path
          fill="#444444"
          d="M502.1,518.5c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.4-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.8c0-1.7,0-3.4-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.5,0-2.9,0.1-4.4c0-1.5,0.1-3,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-1c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.1-1.2
			c0-0.4,0-0.7,0-0.9c0-0.5,0.1-1,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.4,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.6c0,1.1,0,2.6-0.1,4.3c0,1.8,0,3.9,0.1,6.6l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.3,0,3.4v2.2c0,0.6,0,1.4-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L502.1,518.5
			z"
        />
        <path
          fill="#444444"
          d="M516,486.6l-6.9,0.2l-0.3-2.2c0-1.4,0-2.4,0-3.2c0-0.8,0.1-1.4,0.2-1.9c0.1-0.4,0.3-0.7,0.5-0.9
			c0.2-0.1,0.6-0.2,1.1-0.3l2-0.2c0.7-0.1,1.4-0.1,2.1-0.2c0.7,0,1.4-0.1,2.1-0.1c1,0,1.9,0.1,2.9,0.2c0.9,0.1,1.8,0.2,2.8,0.2
			c0.7,0,1.4,0,2.1-0.1c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.7,0,1.2-0.1c0.5-0.1,1-0.1,1.4-0.1c0.5,0,0.9,0.1,1.2,0.2
			c0.3,0.1,0.6,0.3,0.6,0.7c0.1,0.4,0.1,0.8,0.1,1.3c0,0.5,0,1,0,1.4c0,0.4,0,0.8-0.1,1.1c0,0.3-0.1,0.7-0.1,1.3c0,0.2,0,0.4,0,0.5
			c0,0.2,0,0.4,0.1,0.7c0.3,0.9-0.7,1.3-2.9,1.3c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.2-1.3,0.2l-0.2,1.9c0,0.4,0,0.8,0,1.2c0,0.4,0.1,0.7,0.1,1.2c0,1.2,0,2.5,0,3.8c0,1.3,0,2.6-0.1,3.9
			c0,1.3-0.1,2.6-0.1,3.8c-0.1,1.2-0.1,2.4-0.1,3.4l-0.1,1.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.7,0,1.3,0.1,1.9
			c0.1,0.6,0.1,1.2,0.1,1.7v3.2c0,0.5,0,0.9,0.1,1.4c0.1,0.4,0.1,0.8,0,1.2c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-1,0.3
			c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0.1-1.8,0.1c-1.4,0-2.4-0.1-3.1-0.2c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.7,0.2-1.1
			c0.2-0.9,0.3-1.8,0.3-2.8s0.1-1.8,0.1-2.7v-4.7c0-1,0.1-2.1,0.2-3.3c0.1-1.2,0.2-2.3,0.2-3.5c0-1,0-2-0.1-3c-0.1-1-0.1-2-0.2-3
			l0.1-2.7c-0.1-0.7-0.1-1.4-0.1-2.1C515.9,488,516,487.3,516,486.6z"
        />
        <path
          fill="#444444"
          d="M545,490.1l-0.2,4.8l2.6,0.1c0.4,0,0.9,0,1.5,0s1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2c0.4,0.1,0.6,0.3,0.6,0.5
			l-0.1,4.2c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
			s-0.6,0-1.1,0l-5.7,0.3c-0.4,0-0.5,0.3-0.5,0.7v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.6-0.3,2.2c0,0.2-0.1,0.4-0.1,0.7
			c1,0.1,1.8,0.2,2.6,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.6-0.1,1-0.1c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0
			h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6
			c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3.1c0,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.8,0.3-1.6,0.3
			c-0.7,0.1-1.7,0.1-3,0.1c-1.2,0-2.7,0-4.6,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0-1,0h-1.9
			c-1,0-1.6,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7c0-0.2,0-0.4,0-0.6s0-0.3,0-0.3
			c0.3-1.3,0.4-2.5,0.4-3.8c0-1.3,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.9c0-1.2,0.1-2.2,0.2-3.2
			c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.6c0-0.4,0-0.8,0-1.3c0-0.5,0-0.9,0.1-1.3l0.2-2.5v-0.4
			c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1s1-0.4,2.1-0.4
			c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.4,0.2,2,0.2c0.8,0,1.6-0.1,2.4-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0
			c0.5,0,1.1,0,1.7,0s1.2,0,1.7,0s1,0,1.4,0c0.5,0,0.8,0.6,0.8,1.7c0,1.1,0,2.6,0,4.6c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.3,0-2.3,0H545
			V490.1z M547.1,466.8c0.5,0,1.1,0,1.6,0s1,0,1.5,0s0.8,0.1,1,0.2c0.3,0.1,0.4,0.2,0.3,0.4c-0.1,0.3-0.2,0.7-0.5,1.2
			c-0.2,0.5-0.5,1-0.8,1.6c-0.3,0.5-0.7,1.1-1,1.6c-0.4,0.5-0.7,1-1,1.3l-1.9,2.2c-0.3,0.3-0.4,0.5-0.4,0.6c0,0.1-0.1,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.1-0.4,0.1s-0.5,0-1.1,0c-0.2,0-0.4,0-0.5-0.1c-0.3,0-0.6-0.1-0.8-0.1c-0.3,0-0.5,0-0.8,0h-0.6c-0.6,0-1,0-1.3-0.1
			c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.2-0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0.1-0.7,0.3-1.3l0.4-0.7c0.2-0.5,0.5-1.1,0.8-1.8
			c0.3-0.7,0.6-1.4,0.9-2c0.3-0.6,0.6-1.2,1-1.6c0.3-0.4,0.7-0.7,1-0.7c0.1,0,0.3,0,0.6,0s0.6,0,0.9,0c0.3,0,0.6,0,0.8,0
			s0.5,0,0.6,0H547.1z"
        />
      </g>
    </g>
  ),
})
