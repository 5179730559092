'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Activity',
  viewBox: '0 0 90 94',
  path: (
    <>
      <path
        d="M37.9227 9.00964C38.2799 8.39084 38.9402 8.00964 39.6547 8.00964L52.3453 8.00964C53.0599 8.00964 53.7201 8.39084 54.0774 9.00964L60.4227 20C60.7799 20.6188 60.7799 21.3812 60.4227 22L54.0774 32.9904C53.7201 33.6092 53.0599 33.9904 52.3453 33.9904L39.6547 33.9904C38.9402 33.9904 38.2799 33.6092 37.9227 32.9904L31.5774 22C31.2201 21.3812 31.2201 20.6188 31.5774 20L37.9227 9.00964Z"
        stroke="#2A2A2A"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M60.9227 22.0096C61.2799 21.3908 61.9402 21.0096 62.6547 21.0096L75.3453 21.0096C76.0599 21.0096 76.7201 21.3908 77.0774 22.0096L83.4227 33C83.7799 33.6188 83.7799 34.3812 83.4227 35L77.0774 45.9904C76.7201 46.6092 76.0599 46.9904 75.3453 46.9904L62.6547 46.9904C61.9402 46.9904 61.2799 46.6092 60.9227 45.9904L54.5774 35C54.2201 34.3812 54.2201 33.6188 54.5774 33L60.9227 22.0096Z"
        stroke="#2A2A2A"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M60.9227 48.0096C61.2799 47.3908 61.9402 47.0096 62.6547 47.0096L75.3453 47.0096C76.0599 47.0096 76.7201 47.3908 77.0774 48.0096L83.4227 59C83.7799 59.6188 83.7799 60.3812 83.4227 61L77.0774 71.9904C76.7201 72.6092 76.0599 72.9904 75.3453 72.9904L62.6547 72.9904C61.9402 72.9904 61.2799 72.6092 60.9227 71.9904L54.5774 61C54.2201 60.3812 54.2201 59.6188 54.5774 59L60.9227 48.0096Z"
        stroke="#2A2A2A"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M14.9227 22.0096C15.2799 21.3908 15.9402 21.0096 16.6547 21.0096L29.3453 21.0096C30.0599 21.0096 30.7201 21.3908 31.0774 22.0096L37.4227 33C37.7799 33.6188 37.7799 34.3812 37.4227 35L31.0774 45.9904C30.7201 46.6092 30.0599 46.9904 29.3453 46.9904L16.6547 46.9904C15.9402 46.9904 15.2799 46.6092 14.9227 45.9904L8.57737 35C8.22011 34.3812 8.22011 33.6188 8.57737 33L14.9227 22.0096Z"
        stroke="#2A2A2A"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M37.9227 61.0096C38.2799 60.3908 38.9402 60.0096 39.6547 60.0096L52.3453 60.0096C53.0599 60.0096 53.7201 60.3908 54.0774 61.0096L60.4227 72C60.7799 72.6188 60.7799 73.3812 60.4227 74L54.0774 84.9904C53.7201 85.6092 53.0599 85.9904 52.3453 85.9904L39.6547 85.9904C38.9402 85.9904 38.2799 85.6092 37.9227 84.9904L31.5774 74C31.2201 73.3812 31.2201 72.6188 31.5774 72L37.9227 61.0096Z"
        stroke="#2A2A2A"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M14.6547 46.5096C13.4043 46.5096 12.2489 47.1767 11.6236 48.2596L5.27834 59.25C4.65312 60.3329 4.65312 61.6671 5.27834 62.75L11.6236 73.7404C12.2488 74.8233 13.4043 75.4904 14.6547 75.4904L27.3453 75.4904C28.5958 75.4904 29.7512 74.8233 30.3764 73.7404L36.7217 62.75C37.3469 61.6671 37.3469 60.3329 36.7217 59.25L30.3764 48.2596C29.7512 47.1767 28.5958 46.5096 27.3453 46.5096L14.6547 46.5096Z"
        stroke="#FF6C4A"
        strokeWidth="3"
        fill="none"
      />
    </>
  ),
})
