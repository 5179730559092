'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Minus',
  viewBox: '0 0 12 2',
  path: (
    <path
      d="M11.333 0.666992H0.666341C0.577936 0.666992 0.493151 0.702111 0.430639 0.764623C0.368127 0.827135 0.333008 0.91192 0.333008 1.00033C0.333008 1.08873 0.368127 1.17352 0.430639 1.23603C0.493151 1.29854 0.577936 1.33366 0.666341 1.33366H11.333C11.4214 1.33366 11.5062 1.29854 11.5687 1.23603C11.6312 1.17352 11.6663 1.08873 11.6663 1.00033C11.6663 0.91192 11.6312 0.827135 11.5687 0.764623C11.5062 0.702111 11.4214 0.666992 11.333 0.666992Z"
      fill="currentColor"
    />
  ),
})
