'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Mail',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M20.4 5.5H3.61C3.39904 5.49868 3.18991 5.53909 2.99464 5.61891C2.79936 5.69873 2.62179 5.81637 2.47216 5.96508C2.32252 6.11378 2.20377 6.29061 2.12274 6.48539C2.04171 6.68016 2 6.88904 2 7.1V16.9C2 17.3243 2.16857 17.7313 2.46863 18.0314C2.76869 18.3314 3.17565 18.5 3.6 18.5H20.4C20.8235 18.4974 21.229 18.328 21.5285 18.0285C21.828 17.729 21.9974 17.3235 22 16.9V7.1C21.9974 6.67646 21.828 6.27102 21.5285 5.97153C21.229 5.67204 20.8235 5.50262 20.4 5.5ZM20.4 6.5C20.5186 6.50267 20.6336 6.541 20.73 6.61L12.85 11.34C12.5794 11.4882 12.2736 11.56 11.9653 11.5477C11.657 11.5354 11.3579 11.4394 11.1 11.27L3.3 6.6C3.39087 6.53613 3.49894 6.50127 3.61 6.5H20.4ZM20.4 17.5H3.61C3.45087 17.5 3.29826 17.4368 3.18574 17.3243C3.07321 17.2117 3.01 17.0591 3.01 16.9V7.59L10.57 12.12C11.0041 12.403 11.5118 12.5525 12.03 12.55C12.4917 12.5501 12.9458 12.4331 13.35 12.21L21 7.62V16.9C21 17.0591 20.9368 17.2117 20.8243 17.3243C20.7117 17.4368 20.5591 17.5 20.4 17.5Z"
        fill="currentColor"
      />
    </>
  ),
})
