'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'BetterFood',
  viewBox: '0 0 841.9 595.3',
  path: (
    <g>
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M569,226.9c0.6,3.8-0.6,7.3-1.9,10.8c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,5
					c-3.5,3.4-6.7,7.1-10.5,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.8,6.8-9.5,9.3c-2.7,1.8-5.2,4-7.3,6.6
					c-2.5,3.2-5.2,6.3-7.7,9.4c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.5
					c-1.7,1.2-3.1,2.8-4.6,4.3c-0.6,0.6-1.3,1.4-1.7,2.2c-1,2.5-2.8,4.4-4.4,6.5c-2,2.5-4.3,4.5-6.1,7.2c-0.6,0.9-1.5,1.5-2.5,1.9
					c-2.5,1.1-4.2,3-5.7,5.2c-2.3,3.5-5.2,6.6-8.6,9.1c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.8,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9
					c-2.1,1-4.2,2.2-6.3,3.3c-0.8,0.4-1.7,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.7,0.4-2.8,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.2,1.3c-0.2-0.1-0.6-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.7-2.3-2.4-3.9-1.7c-2.2,1-4.2,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.6-0.5,1.2,0.2,1.7c0.6,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.5,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.4,0.8,2.6,1.7,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.6-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.6-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.6-2.2,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.8c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.4-1.4c-0.4-0.4-1.5-0.1-1.7,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.7-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.4,1.8c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.6,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.6-0.9,0.6
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3.1-0.6-4.4-1.4c-0.9-0.5-1.4-1.1-1.7-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.7c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6.1-3-8.9-5
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.6-1.2-1-1.7c-2-2.3-3.9-4.5-6-6.7c-1.3-1.4-2.5-2.6-3.4-4.3c-0.6-1-1.4-2-2.2-2.8
					c-3.4-3.3-6.8-6.6-10.8-9.2c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-3-4.2-4.7c-1.2-1.8-2.8-3.1-4.7-4.1c-1.2-0.6-2.2-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.4-1.7-2.3-3.1-2.8c-1-0.3-2-0.7-2.9-1.2c-1-0.6-2-1.2-3-1.8c-1.4-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.2-1.9-2.8c-0.7-0.4-1.4-0.9-2-1.3c-1.8-1.2-3.8-2.5-5.5-3.8s-3.4-2.8-5.5-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.7-2.4-4.4-3.7-6.1-6.1c-0.6-0.9-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.3-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
					c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.2-1-2.9-2c-1.5-2.2-3.7-3.8-5.8-5.2c-2.2-1.5-4.2-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
					c-3.1-3.2-6-6.6-9.2-9.7c-3.3-3.3-6.6-6.3-10-9.5c-1-1-2-2.1-3-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4
					c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.9-4.2-5.7c-0.4-0.5-0.4-1.4,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3
					c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7
					c1.8-1.5,3.6-3,5.5-4.5c0.9-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.7-3.6,2.6-5.3c0.9-1.6,2.1-3.1,3.5-4.2
					c1.5-1.2,2.6-2.5,3.5-4.2c0.9-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,4-3.1c2.8-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.7-5.7
					c1.7-1.2,3.4-2.3,4.9-3.9c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.7,1.4-2.3c1-1.2,2-2.5,2.3-4.1c0.2-1,0.9-1.9,1.8-2.5
					c3.4-2.2,5.5-5.6,7.9-8.7c1.5-2,2.9-4,4.9-5.6c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.5,3.8-4.7,6.2-6.5c1.5-1.1,2.7-2.5,3.8-3.9
					c0.6-0.7,1.2-1.4,1.7-2.2c1.5-2,3.2-3.8,5.5-4.9c1.7-0.8,2.8-2.3,4-3.8c2.3-2.9,4.7-5.7,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8
					c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.6-4.9c2.2-2.1,4.3-4.2,6.4-6.4c1-1,2.1-2,3.6-2.3c1.2-0.3,2.1-1.1,2.8-2.1
					c2.2-3.1,4.8-5.9,7.6-8.5c0.9-0.8,1.4-1.8,2.2-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.2,5.9-2,8.2-4.3
					c0.6-0.6,1.1-1.2,1.7-1.7c0.9-0.8,1.9-1.4,3.1-1.7c1-0.3,2.1-0.3,3.1,0.2c3.2,1.8,6.5,3.4,9.8,5c1.1,0.6,1.9,1.4,2.3,2.6
					c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.4,1.5,2.6,2.9,4,4.4
					c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.6,3.9,4.7,6.3c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.4,3.3c1.5,1.1,2.8,2.3,3.6,4.1
					c0.7,1.6,2.1,2.7,3.4,3.8c1.1,0.9,2.3,1.8,3.3,2.8c0.9,0.8,1.7,1.7,2.4,2.6c1.5,2,3.3,3.8,5.1,5.4c1,0.9,1.8,1.8,2.8,2.8
					c0.2,0.3,0.4,0.7,0.8,0.9c2.8,1.9,4.5,4.7,6.9,7c0.6,0.6,1.1,1.1,1.6,1.8c1.5,2.2,3.4,4,5.3,5.7c1.2,1,2.3,2.2,3.4,3.3
					c1.5,1.5,3.1,2.9,4.7,4.1c2.4,1.8,4.6,3.9,6.5,6.3c0.8,1,1.7,2,2.8,2.8c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.3,3.4
					c4.1,3.9,8.1,7.9,12.5,11.4c1.4,1.2,2.8,2.4,3.6,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.5,4.4,6.8,6.6
					c0.3,0.2,0.6,0.6,0.8,0.9c2.6,3.3,5.8,6.1,8.7,9.1c2.2,2.3,4.4,4.5,6.3,7c2,2.6,3.6,5.5,5,8.4c0.6,1.2,0.7,2.5,0.7,3.8
					C569,225.3,569,226.1,569,226.9z M430.6,361.6c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3.1,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.5,0.2c-1.2,1.4-2.6,2.2-4.3,2.5c-0.4,0.1-0.7,0.3-1,0.6
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.4,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.6c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.5,3-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.4,0.6,1.8,0.1,2.3-0.7C429.9,363.8,430.5,362.8,430.6,361.6c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.6-0.4C431.2,360.2,430.9,360.9,430.6,361.6z M423.8,355.8
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.6-0.7-1.1-1.1-1.6c-0.4-0.5-1.4-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C420.9,354.2,422.3,355.9,423.8,355.8z M409.4,359.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.2
					C405.6,359,407.3,359.8,409.4,359.6z M412.7,353.3c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C412.3,353.7,412.5,353.4,412.7,353.3z M444.3,338.7
					c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.3,1.8c0.2,0.1,0.6-0.1,0.6-0.3s-0.1-0.6-0.2-0.6
					c-1-0.6-2-1-3-1.6C444.5,338.6,444.4,338.7,444.3,338.7z M403,362.8c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5
					s-0.2-0.5-0.4-0.6c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C402.3,362.7,402.7,362.8,403,362.8
					z M416.9,372c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1
					c0,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.5-0.1,0.6-0.2C416.7,372.5,416.8,372.3,416.9,372z M414.8,344.9c-0.2-0.2-0.3-0.4-0.5-0.5
					c-0.6-0.1-1,0.2-1.1,0.8c-0.1,0.2,0.2,0.6,0.3,0.6C414.2,346.1,414.8,345.5,414.8,344.9z M406.4,365.2c-0.3,0.2-0.6,0.3-0.7,0.5
					c-0.2,0.4,0,0.9,0.4,0.9c0.2,0,0.6-0.2,0.6-0.4C406.7,365.9,406.5,365.6,406.4,365.2z M464.8,336.4c0.6,0.1,0.9-0.2,1-0.6
					c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.9,0.2-0.8,0.7C464.4,335.9,464.7,336.2,464.8,336.4z M431.6,348.5c-0.3,0-0.5,0.1-0.5,0.4
					c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5-0.1,0.6-0.2C432,348.9,431.8,348.6,431.6,348.5z M433,339.8c0.3-0.1,0.4-0.3,0.2-0.6
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.4,0.2-0.4,0.4C432.5,339.8,432.7,339.9,433,339.8z"
            />
          </defs>
          <g>
            <path
              fill="#EC6910"
              d="M569,226.9c0.6,3.8-0.6,7.3-1.9,10.8c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,5
					c-3.5,3.4-6.7,7.1-10.5,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.8,6.8-9.5,9.3c-2.7,1.8-5.2,4-7.3,6.6
					c-2.5,3.2-5.2,6.3-7.7,9.4c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.5
					c-1.7,1.2-3.1,2.8-4.6,4.3c-0.6,0.6-1.3,1.4-1.7,2.2c-1,2.5-2.8,4.4-4.4,6.5c-2,2.5-4.3,4.5-6.1,7.2c-0.6,0.9-1.5,1.5-2.5,1.9
					c-2.5,1.1-4.2,3-5.7,5.2c-2.3,3.5-5.2,6.6-8.6,9.1c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.8,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9
					c-2.1,1-4.2,2.2-6.3,3.3c-0.8,0.4-1.7,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.7,0.4-2.8,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.2,1.3c-0.2-0.1-0.6-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.7-2.3-2.4-3.9-1.7c-2.2,1-4.2,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.6-0.5,1.2,0.2,1.7c0.6,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.5,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.4,0.8,2.6,1.7,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.6-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.6-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.6-2.2,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.8c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.4-1.4c-0.4-0.4-1.5-0.1-1.7,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.7-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.4,1.8c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.6,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.6-0.9,0.6
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3.1-0.6-4.4-1.4c-0.9-0.5-1.4-1.1-1.7-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.7c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6.1-3-8.9-5
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.6-1.2-1-1.7c-2-2.3-3.9-4.5-6-6.7c-1.3-1.4-2.5-2.6-3.4-4.3c-0.6-1-1.4-2-2.2-2.8
					c-3.4-3.3-6.8-6.6-10.8-9.2c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-3-4.2-4.7c-1.2-1.8-2.8-3.1-4.7-4.1c-1.2-0.6-2.2-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.4-1.7-2.3-3.1-2.8c-1-0.3-2-0.7-2.9-1.2c-1-0.6-2-1.2-3-1.8c-1.4-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.2-1.9-2.8c-0.7-0.4-1.4-0.9-2-1.3c-1.8-1.2-3.8-2.5-5.5-3.8s-3.4-2.8-5.5-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.7-2.4-4.4-3.7-6.1-6.1c-0.6-0.9-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.3-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
					c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.2-1-2.9-2c-1.5-2.2-3.7-3.8-5.8-5.2c-2.2-1.5-4.2-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
					c-3.1-3.2-6-6.6-9.2-9.7c-3.3-3.3-6.6-6.3-10-9.5c-1-1-2-2.1-3-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4
					c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.9-4.2-5.7c-0.4-0.5-0.4-1.4,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3
					c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7
					c1.8-1.5,3.6-3,5.5-4.5c0.9-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.7-3.6,2.6-5.3c0.9-1.6,2.1-3.1,3.5-4.2
					c1.5-1.2,2.6-2.5,3.5-4.2c0.9-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,4-3.1c2.8-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.7-5.7
					c1.7-1.2,3.4-2.3,4.9-3.9c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.7,1.4-2.3c1-1.2,2-2.5,2.3-4.1c0.2-1,0.9-1.9,1.8-2.5
					c3.4-2.2,5.5-5.6,7.9-8.7c1.5-2,2.9-4,4.9-5.6c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.5,3.8-4.7,6.2-6.5c1.5-1.1,2.7-2.5,3.8-3.9
					c0.6-0.7,1.2-1.4,1.7-2.2c1.5-2,3.2-3.8,5.5-4.9c1.7-0.8,2.8-2.3,4-3.8c2.3-2.9,4.7-5.7,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8
					c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.6-4.9c2.2-2.1,4.3-4.2,6.4-6.4c1-1,2.1-2,3.6-2.3c1.2-0.3,2.1-1.1,2.8-2.1
					c2.2-3.1,4.8-5.9,7.6-8.5c0.9-0.8,1.4-1.8,2.2-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.2,5.9-2,8.2-4.3
					c0.6-0.6,1.1-1.2,1.7-1.7c0.9-0.8,1.9-1.4,3.1-1.7c1-0.3,2.1-0.3,3.1,0.2c3.2,1.8,6.5,3.4,9.8,5c1.1,0.6,1.9,1.4,2.3,2.6
					c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.4,1.5,2.6,2.9,4,4.4
					c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.6,3.9,4.7,6.3c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.4,3.3c1.5,1.1,2.8,2.3,3.6,4.1
					c0.7,1.6,2.1,2.7,3.4,3.8c1.1,0.9,2.3,1.8,3.3,2.8c0.9,0.8,1.7,1.7,2.4,2.6c1.5,2,3.3,3.8,5.1,5.4c1,0.9,1.8,1.8,2.8,2.8
					c0.2,0.3,0.4,0.7,0.8,0.9c2.8,1.9,4.5,4.7,6.9,7c0.6,0.6,1.1,1.1,1.6,1.8c1.5,2.2,3.4,4,5.3,5.7c1.2,1,2.3,2.2,3.4,3.3
					c1.5,1.5,3.1,2.9,4.7,4.1c2.4,1.8,4.6,3.9,6.5,6.3c0.8,1,1.7,2,2.8,2.8c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.3,3.4
					c4.1,3.9,8.1,7.9,12.5,11.4c1.4,1.2,2.8,2.4,3.6,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.5,4.4,6.8,6.6
					c0.3,0.2,0.6,0.6,0.8,0.9c2.6,3.3,5.8,6.1,8.7,9.1c2.2,2.3,4.4,4.5,6.3,7c2,2.6,3.6,5.5,5,8.4c0.6,1.2,0.7,2.5,0.7,3.8
					C569,225.3,569,226.1,569,226.9z M430.6,361.6c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3.1,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.5,0.2c-1.2,1.4-2.6,2.2-4.3,2.5c-0.4,0.1-0.7,0.3-1,0.6
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.4,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.6c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.5,3-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.4,0.6,1.8,0.1,2.3-0.7C429.9,363.8,430.5,362.8,430.6,361.6c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.6-0.4C431.2,360.2,430.9,360.9,430.6,361.6z M423.8,355.8
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.6-0.7-1.1-1.1-1.6c-0.4-0.5-1.4-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C420.9,354.2,422.3,355.9,423.8,355.8z M409.4,359.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.2
					C405.6,359,407.3,359.8,409.4,359.6z M412.7,353.3c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C412.3,353.7,412.5,353.4,412.7,353.3z M444.3,338.7
					c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.3,1.8c0.2,0.1,0.6-0.1,0.6-0.3s-0.1-0.6-0.2-0.6
					c-1-0.6-2-1-3-1.6C444.5,338.6,444.4,338.7,444.3,338.7z M403,362.8c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5
					s-0.2-0.5-0.4-0.6c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C402.3,362.7,402.7,362.8,403,362.8
					z M416.9,372c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1
					c0,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.5-0.1,0.6-0.2C416.7,372.5,416.8,372.3,416.9,372z M414.8,344.9c-0.2-0.2-0.3-0.4-0.5-0.5
					c-0.6-0.1-1,0.2-1.1,0.8c-0.1,0.2,0.2,0.6,0.3,0.6C414.2,346.1,414.8,345.5,414.8,344.9z M406.4,365.2c-0.3,0.2-0.6,0.3-0.7,0.5
					c-0.2,0.4,0,0.9,0.4,0.9c0.2,0,0.6-0.2,0.6-0.4C406.7,365.9,406.5,365.6,406.4,365.2z M464.8,336.4c0.6,0.1,0.9-0.2,1-0.6
					c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.9,0.2-0.8,0.7C464.4,335.9,464.7,336.2,464.8,336.4z M431.6,348.5c-0.3,0-0.5,0.1-0.5,0.4
					c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5-0.1,0.6-0.2C432,348.9,431.8,348.6,431.6,348.5z M433,339.8c0.3-0.1,0.4-0.3,0.2-0.6
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.4,0.2-0.4,0.4C432.5,339.8,432.7,339.9,433,339.8z"
            />
            <path
              fill="#F8B68D"
              d="M330.2,315c-2.2-8.6-5.8-14.3-6-22.9c-0.2-11.9-1.1-22.9-0.4-35.2c1-16.9,10-40.1,22.8-52.9
					c10.1-10.1,36.8-35.8,50.5-39.8c29.9-8.7,42.4,0.2,74,0.3c20.5,0.1,39.9,17,55.9,28.8c11.5,8.5,18,19,22.6,31.5
					c15,40.2,12.4,76-1.9,116.6c-7.9,22.3-22.9,41.1-46,48c-26.3,7.9-54.6,6.2-81.6,1.4c-17.7-3.1-35.3-7.6-51-16.4
					c-10.6-5.9-20.3-14-27.4-23.9C333.7,339.3,333.4,328,330.2,315z"
            />
            <path
              fill="#EC6910"
              d="M354.3,306.3c-1.8-5.5-5-11.4-5-18.1c-0.1-8.5-0.7-17.6,0.1-26.2c1.5-14.3,7.3-28,16.5-39
					c9.3-9.6,19.4-18.1,30.4-25.8c3.8-2.5,8.4-5.3,13.1-6.3c7.4-1.8,15.2-2.6,22.7-1.9c6,0.5,11.6,1.7,17.2,2.4
					c5.9,0.9,10.7,1,17,1.4c12.1,1.6,22.6,8.8,32.1,15.7l6.9,5c7.6,5.2,13.7,13.2,17.3,21.5c12.7,28.2,12.2,58.2,2.6,87.1
					c-6.5,21.9-21,39.7-44.9,44.4c-20.5,4.5-41.9,2.5-62-2.5c-11.4-3.3-21.8-9.7-32.2-15.2c-10.4-6.2-21.3-14.1-26.3-25.8
					C357.2,317.4,356.1,311.7,354.3,306.3L354.3,306.3z M368.7,302.7c1.7,8,1.2,16,4.5,23.4c5.8,12,17.9,20.9,31.2,23.3
					c5.4,1,10.9,1.4,16.4,2.1c16,2.6,32.5,4.7,48.7,2.6c9.9-1.3,20-3.7,27.7-10.5c7.9-6.6,12.9-16,16-25.6
					c8.4-23.1,10.6-48.3,3.3-71.9c-2.9-10.1-7.5-19.9-16.2-26.2l-6.8-5.3c-8.4-6.5-17.6-13.5-28.2-14.6c-5.1-0.2-11.7-0.1-17.1-0.7
					c-8.6-0.7-16.4-2.2-24.7-1.2c-4.7,0.6-9.5,1.6-13.9,3.4c-7.2,3.9-13.4,9.7-19.5,15.4c-4.8,4.7-9.8,9.4-14.2,14.6
					c-6.7,8.9-10.9,20.3-11.6,31.4c-0.3,8,0.5,17,0.6,25C365.2,292.7,367.6,297.2,368.7,302.7L368.7,302.7z"
            />
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000170982426497231188610000009143353122827158673_"
              d="M569,226.9c0.6,3.8-0.6,7.3-1.9,10.8
					c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,5c-3.5,3.4-6.7,7.1-10.5,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.8,6.8-9.5,9.3
					c-2.7,1.8-5.2,4-7.3,6.6c-2.5,3.2-5.2,6.3-7.7,9.4c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.5
					c-1.7,1.2-3.1,2.8-4.6,4.3c-0.6,0.6-1.3,1.4-1.7,2.2c-1,2.5-2.8,4.4-4.4,6.5c-2,2.5-4.3,4.5-6.1,7.2c-0.6,0.9-1.5,1.5-2.5,1.9
					c-2.5,1.1-4.2,3-5.7,5.2c-2.3,3.5-5.2,6.6-8.6,9.1c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.8,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9
					c-2.1,1-4.2,2.2-6.3,3.3c-0.8,0.4-1.7,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.7,0.4-2.8,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.2,1.3c-0.2-0.1-0.6-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.7-2.3-2.4-3.9-1.7c-2.2,1-4.2,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.6-0.5,1.2,0.2,1.7c0.6,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.5,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.4,0.8,2.6,1.7,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.6-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.6-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.6-2.2,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.8c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.4-1.4c-0.4-0.4-1.5-0.1-1.7,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.7-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.4,1.8c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.6,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.6-0.9,0.6
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3.1-0.6-4.4-1.4c-0.9-0.5-1.4-1.1-1.7-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.7c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6.1-3-8.9-5
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.6-1.2-1-1.7c-2-2.3-3.9-4.5-6-6.7c-1.3-1.4-2.5-2.6-3.4-4.3c-0.6-1-1.4-2-2.2-2.8
					c-3.4-3.3-6.8-6.6-10.8-9.2c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-3-4.2-4.7c-1.2-1.8-2.8-3.1-4.7-4.1c-1.2-0.6-2.2-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.4-1.7-2.3-3.1-2.8c-1-0.3-2-0.7-2.9-1.2c-1-0.6-2-1.2-3-1.8c-1.4-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.2-1.9-2.8c-0.7-0.4-1.4-0.9-2-1.3c-1.8-1.2-3.8-2.5-5.5-3.8s-3.4-2.8-5.5-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.7-2.4-4.4-3.7-6.1-6.1c-0.6-0.9-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.3-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
					c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.2-1-2.9-2c-1.5-2.2-3.7-3.8-5.8-5.2c-2.2-1.5-4.2-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
					c-3.1-3.2-6-6.6-9.2-9.7c-3.3-3.3-6.6-6.3-10-9.5c-1-1-2-2.1-3-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4
					c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.9-4.2-5.7c-0.4-0.5-0.4-1.4,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3
					c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7
					c1.8-1.5,3.6-3,5.5-4.5c0.9-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.7-3.6,2.6-5.3c0.9-1.6,2.1-3.1,3.5-4.2
					c1.5-1.2,2.6-2.5,3.5-4.2c0.9-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,4-3.1c2.8-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.7-5.7
					c1.7-1.2,3.4-2.3,4.9-3.9c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.7,1.4-2.3c1-1.2,2-2.5,2.3-4.1c0.2-1,0.9-1.9,1.8-2.5
					c3.4-2.2,5.5-5.6,7.9-8.7c1.5-2,2.9-4,4.9-5.6c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.5,3.8-4.7,6.2-6.5c1.5-1.1,2.7-2.5,3.8-3.9
					c0.6-0.7,1.2-1.4,1.7-2.2c1.5-2,3.2-3.8,5.5-4.9c1.7-0.8,2.8-2.3,4-3.8c2.3-2.9,4.7-5.7,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8
					c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.6-4.9c2.2-2.1,4.3-4.2,6.4-6.4c1-1,2.1-2,3.6-2.3c1.2-0.3,2.1-1.1,2.8-2.1
					c2.2-3.1,4.8-5.9,7.6-8.5c0.9-0.8,1.4-1.8,2.2-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.2,5.9-2,8.2-4.3
					c0.6-0.6,1.1-1.2,1.7-1.7c0.9-0.8,1.9-1.4,3.1-1.7c1-0.3,2.1-0.3,3.1,0.2c3.2,1.8,6.5,3.4,9.8,5c1.1,0.6,1.9,1.4,2.3,2.6
					c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.4,1.5,2.6,2.9,4,4.4
					c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.6,3.9,4.7,6.3c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.4,3.3c1.5,1.1,2.8,2.3,3.6,4.1
					c0.7,1.6,2.1,2.7,3.4,3.8c1.1,0.9,2.3,1.8,3.3,2.8c0.9,0.8,1.7,1.7,2.4,2.6c1.5,2,3.3,3.8,5.1,5.4c1,0.9,1.8,1.8,2.8,2.8
					c0.2,0.3,0.4,0.7,0.8,0.9c2.8,1.9,4.5,4.7,6.9,7c0.6,0.6,1.1,1.1,1.6,1.8c1.5,2.2,3.4,4,5.3,5.7c1.2,1,2.3,2.2,3.4,3.3
					c1.5,1.5,3.1,2.9,4.7,4.1c2.4,1.8,4.6,3.9,6.5,6.3c0.8,1,1.7,2,2.8,2.8c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.3,3.4
					c4.1,3.9,8.1,7.9,12.5,11.4c1.4,1.2,2.8,2.4,3.6,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.5,4.4,6.8,6.6
					c0.3,0.2,0.6,0.6,0.8,0.9c2.6,3.3,5.8,6.1,8.7,9.1c2.2,2.3,4.4,4.5,6.3,7c2,2.6,3.6,5.5,5,8.4c0.6,1.2,0.7,2.5,0.7,3.8
					C569,225.3,569,226.1,569,226.9z M430.6,361.6c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3.1,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.5,0.2c-1.2,1.4-2.6,2.2-4.3,2.5c-0.4,0.1-0.7,0.3-1,0.6
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.4,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.6c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.5,3-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.4,0.6,1.8,0.1,2.3-0.7C429.9,363.8,430.5,362.8,430.6,361.6c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.6-0.4C431.2,360.2,430.9,360.9,430.6,361.6z M423.8,355.8
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.6-0.7-1.1-1.1-1.6c-0.4-0.5-1.4-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C420.9,354.2,422.3,355.9,423.8,355.8z M409.4,359.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.2
					C405.6,359,407.3,359.8,409.4,359.6z M412.7,353.3c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C412.3,353.7,412.5,353.4,412.7,353.3z M444.3,338.7
					c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.3,1.8c0.2,0.1,0.6-0.1,0.6-0.3s-0.1-0.6-0.2-0.6
					c-1-0.6-2-1-3-1.6C444.5,338.6,444.4,338.7,444.3,338.7z M403,362.8c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5
					s-0.2-0.5-0.4-0.6c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C402.3,362.7,402.7,362.8,403,362.8
					z M416.9,372c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1
					c0,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.5-0.1,0.6-0.2C416.7,372.5,416.8,372.3,416.9,372z M414.8,344.9c-0.2-0.2-0.3-0.4-0.5-0.5
					c-0.6-0.1-1,0.2-1.1,0.8c-0.1,0.2,0.2,0.6,0.3,0.6C414.2,346.1,414.8,345.5,414.8,344.9z M406.4,365.2c-0.3,0.2-0.6,0.3-0.7,0.5
					c-0.2,0.4,0,0.9,0.4,0.9c0.2,0,0.6-0.2,0.6-0.4C406.7,365.9,406.5,365.6,406.4,365.2z M464.8,336.4c0.6,0.1,0.9-0.2,1-0.6
					c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.9,0.2-0.8,0.7C464.4,335.9,464.7,336.2,464.8,336.4z M431.6,348.5c-0.3,0-0.5,0.1-0.5,0.4
					c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5-0.1,0.6-0.2C432,348.9,431.8,348.6,431.6,348.5z M433,339.8c0.3-0.1,0.4-0.3,0.2-0.6
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.4,0.2-0.4,0.4C432.5,339.8,432.7,339.9,433,339.8z"
            />
          </defs>
          <g>
            <path
              fill="#FFFFFF"
              d="M426.6,195.8c-0.6,0.7-1.2,1.5-1.8,2.2c11.7,10.2,23.4,20.4,35.2,30.6c-0.6,0.7-1,1.4-1.6,2
					c-1.5-0.9-3.1-1.7-4.5-2.8c-9.4-7.3-18.8-14.7-28.2-22.1c-0.6-0.4-1.2-1.2-1.8-1.2c-1.8-0.1-3.6,0-5.4,0c0.5,1.7,0.5,4.1,1.7,5
					c9,7.3,18.2,14.3,27.3,21.4c1.9,1.5,3.7,3.1,5.5,4.6c-0.4,0.5-0.7,1-1.1,1.5c-4.5-3.1-9.2-6.3-13.6-9.5
					c-6.1-4.4-12.1-9-18.1-13.4c-1.2-0.9-2.4-1.8-3.8-2.3c-1.2-0.4-2.6,0.1-3.9,0.2c0.3,1.2,0.3,2.6,1,3.4c2.6,2.5,5.5,4.9,8.4,7.2
					c8.1,6.8,16.2,13.5,24.2,20.7c-2.2-1.2-4.4-2.3-6.4-3.7c-10-6.8-20.1-13.5-29.9-20.7c-6.5-4.7-11.4-10.8-12.4-19.4
					c-1.5-12.2-9.8-20.4-18-28.3c-16.7-16-34.1-31.2-50.8-47.1c-6.6-6.3-12-13.7-18-20.7c-3.7-4.4-7.4-8.6-10.7-13.3
					c-1-1.5-0.7-3.9-0.9-6c1.7,0.4,3.9,0.2,5.2,1.2c24,17.6,46.2,37.4,66,59.8c7.1,8.1,13.9,16.4,21,24.4c6,6.8,14,10.5,22.3,13.6
					c20.7,7.7,37.2,21.1,50.6,38.5c0.2,0.2,0.1,0.7,0.4,2.5C450.7,215,440,203.5,426.6,195.8z"
            />
            <path
              fill="#B4220F"
              d="M430.6,189.8c-0.6,0.7-1.2,1.5-1.8,2.2c11.7,10.2,23.4,20.4,35.2,30.6c-0.6,0.7-1,1.4-1.6,2
					c-1.5-0.9-3.1-1.7-4.5-2.8c-9.4-7.3-18.8-14.7-28.2-22.1c-0.6-0.4-1.2-1.2-1.8-1.2c-1.8-0.1-3.6,0-5.4,0c0.5,1.7,0.5,4.1,1.7,5
					c9,7.3,18.2,14.3,27.3,21.4c1.9,1.5,3.7,3.1,5.5,4.6c-0.4,0.5-0.7,1-1.1,1.5c-4.5-3.1-9.2-6.3-13.6-9.5
					c-6.1-4.4-12.1-9-18.1-13.4c-1.2-0.9-2.4-1.8-3.8-2.3c-1.2-0.4-2.6,0.1-3.9,0.2c0.3,1.2,0.3,2.6,1,3.4c2.6,2.5,5.5,4.9,8.4,7.2
					c8.1,6.8,16.2,13.5,24.2,20.7c-2.2-1.2-4.4-2.3-6.4-3.7c-10-6.8-20.1-13.5-29.9-20.7c-6.5-4.7-11.4-10.8-12.4-19.4
					c-1.5-12.2-9.8-20.4-18-28.3c-16.7-16-34.1-31.2-50.8-47.1c-6.6-6.3-12-13.7-18-20.7c-3.7-4.4-7.4-8.6-10.7-13.3
					c-1-1.5-0.7-3.9-0.9-6c1.7,0.4,3.9,0.2,5.2,1.2c24,17.6,46.2,37.4,66,59.8c7.1,8.1,13.9,16.4,21,24.4c6,6.8,14,10.5,22.3,13.6
					c20.7,7.7,37.2,21.1,50.6,38.5c0.2,0.2,0.1,0.7,0.4,2.5C454.7,209,444,197.5,430.6,189.8z"
            />
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000142867662870459408830000004298512866749060758_"
              d="M569,226.9c0.6,3.8-0.6,7.3-1.9,10.8
					c-0.7,1.8-1.8,3.5-3.4,4.8c-1.8,1.5-3.4,3.3-5,5c-3.5,3.4-6.7,7.1-10.5,10.1c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.8,6.8-9.5,9.3
					c-2.7,1.8-5.2,4-7.3,6.6c-2.5,3.2-5.2,6.3-7.7,9.4c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.8,5.1c-0.7,0.9-1.6,1.8-2.5,2.5
					c-1.7,1.2-3.1,2.8-4.6,4.3c-0.6,0.6-1.3,1.4-1.7,2.2c-1,2.5-2.8,4.4-4.4,6.5c-2,2.5-4.3,4.5-6.1,7.2c-0.6,0.9-1.5,1.5-2.5,1.9
					c-2.5,1.1-4.2,3-5.7,5.2c-2.3,3.5-5.2,6.6-8.6,9.1c-1.2,0.9-2.2,1.8-3.2,2.9c-1.8,1.9-3.8,3.7-5.3,5.7c-0.7,0.8-1.5,1.4-2.5,1.9
					c-2.1,1-4.2,2.2-6.3,3.3c-0.8,0.4-1.7,0.7-2.6,0.9c-0.9,0.1-1.8,0.1-2.7,0.3c-1.7,0.4-2.8,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.2,1.3c-0.2-0.1-0.6-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.9-1.8
					c-0.9-1.7-2.3-2.4-3.9-1.7c-2.2,1-4.2,1-6.3-0.2c-1.1-0.7-2.3-0.2-3.2,0.7c-0.5,0.6-0.5,1.2,0.2,1.7c0.6,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.5,0.9c0.7-0.4,1.6-0.4,2.3,0.1c1.4,0.8,2.6,1.7,4,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.6-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.6-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.6-2.2,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.8c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.9,0.3-1.1,0.1c-0.4-0.4-0.9-1-1.4-1.4c-0.4-0.4-1.5-0.1-1.7,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.7-0.9,2.3-2.3,2.7
					c-1.5,0.4-3,1-4.4,1.8c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.9,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.6,0,0.7c-0.9,1.5-1.8,2.9-2.7,4.3c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-2,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.9,0.1,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.2,0.2-0.6,0.6-0.9,0.6
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-2-0.2c-1.7,0.2-3.1-0.6-4.4-1.4c-0.9-0.5-1.4-1.1-1.7-2.1c-0.2-0.9-0.9-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.7c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6.1-3-8.9-5
					c-1-0.7-1.7-1.6-2.3-2.7c-0.3-0.6-0.6-1.2-1-1.7c-2-2.3-3.9-4.5-6-6.7c-1.3-1.4-2.5-2.6-3.4-4.3c-0.6-1-1.4-2-2.2-2.8
					c-3.4-3.3-6.8-6.6-10.8-9.2c-1-0.6-1.8-1.5-2.7-2.3c-1.5-1.5-3-3-4.2-4.7c-1.2-1.8-2.8-3.1-4.7-4.1c-1.2-0.6-2.2-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.4-1.7-2.3-3.1-2.8c-1-0.3-2-0.7-2.9-1.2c-1-0.6-2-1.2-3-1.8c-1.4-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.2-1.9-2.8c-0.7-0.4-1.4-0.9-2-1.3c-1.8-1.2-3.8-2.5-5.5-3.8s-3.4-2.8-5.5-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.7-2.4-4.4-3.7-6.1-6.1c-0.6-0.9-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.3-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
					c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.2-1-2.9-2c-1.5-2.2-3.7-3.8-5.8-5.2c-2.2-1.5-4.2-3.1-5.7-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
					c-3.1-3.2-6-6.6-9.2-9.7c-3.3-3.3-6.6-6.3-10-9.5c-1-1-2-2.1-3-3.2c-0.5-0.6-0.9-1.3-1.3-2c-0.6-1-0.9-2.2-0.3-3.4
					c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.9-4.2-5.7c-0.4-0.5-0.4-1.4,0.1-1.8c0.5-0.4,1-0.9,1.5-1.3
					c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.9-0.7-1.7-1-2.6c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.3-3.5,3.2-5.4c0.5-1.1,1.3-2,2.2-2.7
					c1.8-1.5,3.6-3,5.5-4.5c0.9-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.9-2.2c0.8-1.8,1.7-3.6,2.6-5.3c0.9-1.6,2.1-3.1,3.5-4.2
					c1.5-1.2,2.6-2.5,3.5-4.2c0.9-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,4-3.1c2.8-1,4.6-3,5.5-5.7c0.9-2.5,2.5-4.2,4.7-5.7
					c1.7-1.2,3.4-2.3,4.9-3.9c0.9-1,1.7-2,2.3-3.2c0.4-0.8,0.8-1.7,1.4-2.3c1-1.2,2-2.5,2.3-4.1c0.2-1,0.9-1.9,1.8-2.5
					c3.4-2.2,5.5-5.6,7.9-8.7c1.5-2,2.9-4,4.9-5.6c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.5,3.8-4.7,6.2-6.5c1.5-1.1,2.7-2.5,3.8-3.9
					c0.6-0.7,1.2-1.4,1.7-2.2c1.5-2,3.2-3.8,5.5-4.9c1.7-0.8,2.8-2.3,4-3.8c2.3-2.9,4.7-5.7,6.9-8.5c1.2-1.5,2.6-2.9,4.4-3.8
					c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.6-4.9c2.2-2.1,4.3-4.2,6.4-6.4c1-1,2.1-2,3.6-2.3c1.2-0.3,2.1-1.1,2.8-2.1
					c2.2-3.1,4.8-5.9,7.6-8.5c0.9-0.8,1.4-1.8,2.2-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.2,5.9-2,8.2-4.3
					c0.6-0.6,1.1-1.2,1.7-1.7c0.9-0.8,1.9-1.4,3.1-1.7c1-0.3,2.1-0.3,3.1,0.2c3.2,1.8,6.5,3.4,9.8,5c1.1,0.6,1.9,1.4,2.3,2.6
					c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,2,1.2c1.4,1.5,2.6,2.9,4,4.4
					c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.6,3.9,4.7,6.3c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.3,4.4,3.3c1.5,1.1,2.8,2.3,3.6,4.1
					c0.7,1.6,2.1,2.7,3.4,3.8c1.1,0.9,2.3,1.8,3.3,2.8c0.9,0.8,1.7,1.7,2.4,2.6c1.5,2,3.3,3.8,5.1,5.4c1,0.9,1.8,1.8,2.8,2.8
					c0.2,0.3,0.4,0.7,0.8,0.9c2.8,1.9,4.5,4.7,6.9,7c0.6,0.6,1.1,1.1,1.6,1.8c1.5,2.2,3.4,4,5.3,5.7c1.2,1,2.3,2.2,3.4,3.3
					c1.5,1.5,3.1,2.9,4.7,4.1c2.4,1.8,4.6,3.9,6.5,6.3c0.8,1,1.7,2,2.8,2.8c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.3,3.4
					c4.1,3.9,8.1,7.9,12.5,11.4c1.4,1.2,2.8,2.4,3.6,4.1c0.6,1.3,1.6,2.1,3,2.3c2,0.2,3.4,1.3,4.7,2.6c2.2,2.2,4.5,4.4,6.8,6.6
					c0.3,0.2,0.6,0.6,0.8,0.9c2.6,3.3,5.8,6.1,8.7,9.1c2.2,2.3,4.4,4.5,6.3,7c2,2.6,3.6,5.5,5,8.4c0.6,1.2,0.7,2.5,0.7,3.8
					C569,225.3,569,226.1,569,226.9z M430.6,361.6c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-2,1.3-3.1,1.6-4.8-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.5,0.2c-1.2,1.4-2.6,2.2-4.3,2.5c-0.4,0.1-0.7,0.3-1,0.6
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.4,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.9,0.6,1.3,0.9c1,0.7,2.2,0.7,3.4,0.6c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.5,3-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.4,0.6,1.8,0.1,2.3-0.7C429.9,363.8,430.5,362.8,430.6,361.6c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.6-0.4C431.2,360.2,430.9,360.9,430.6,361.6z M423.8,355.8
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.6-0.7-1.1-1.1-1.6c-0.4-0.5-1.4-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C420.9,354.2,422.3,355.9,423.8,355.8z M409.4,359.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.6-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.2
					C405.6,359,407.3,359.8,409.4,359.6z M412.7,353.3c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C412.3,353.7,412.5,353.4,412.7,353.3z M444.3,338.7
					c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.4c0.9,1,2,1.4,3.3,1.8c0.2,0.1,0.6-0.1,0.6-0.3s-0.1-0.6-0.2-0.6
					c-1-0.6-2-1-3-1.6C444.5,338.6,444.4,338.7,444.3,338.7z M403,362.8c0.4-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.4-0.5
					s-0.2-0.5-0.4-0.6c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C402.3,362.7,402.7,362.8,403,362.8
					z M416.9,372c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1
					c0,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.5-0.1,0.6-0.2C416.7,372.5,416.8,372.3,416.9,372z M414.8,344.9c-0.2-0.2-0.3-0.4-0.5-0.5
					c-0.6-0.1-1,0.2-1.1,0.8c-0.1,0.2,0.2,0.6,0.3,0.6C414.2,346.1,414.8,345.5,414.8,344.9z M406.4,365.2c-0.3,0.2-0.6,0.3-0.7,0.5
					c-0.2,0.4,0,0.9,0.4,0.9c0.2,0,0.6-0.2,0.6-0.4C406.7,365.9,406.5,365.6,406.4,365.2z M464.8,336.4c0.6,0.1,0.9-0.2,1-0.6
					c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.9,0.2-0.8,0.7C464.4,335.9,464.7,336.2,464.8,336.4z M431.6,348.5c-0.3,0-0.5,0.1-0.5,0.4
					c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5-0.1,0.6-0.2C432,348.9,431.8,348.6,431.6,348.5z M433,339.8c0.3-0.1,0.4-0.3,0.2-0.6
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.4,0.2-0.4,0.4C432.5,339.8,432.7,339.9,433,339.8z"
            />
          </defs>
          <g>
            <path
              fill="#FFFFFF"
              d="M403.3,254.4c-0.5-4-2.5-5.7-5.8-7.6c-16.2-9.3-32.1-19.1-48.1-28.7c-10.3-6.2-21-12-31-18.9
					c-7.1-5-13.4-11.3-19.7-17.3c-1-0.9-0.6-3.4-0.8-5.1c2,0,4.5-0.7,5.9,0.2c7.9,5,15.6,10.4,23.4,15.6
					c20.8,13.8,41.9,26.9,62.2,41.4c11.1,7.9,22.6,9.9,35.7,9.2c14-0.7,23.1,8.3,30.2,18.9c3.1,4.6,5.1,10.1,6.5,15.6
					c2.5,9.2-1.9,13.5-11.3,12.1c-12.9-1.8-24.6-6.9-35.7-12.7C409,270.1,404.4,263,403.3,254.4z"
            />
            <path
              fill="#B4220F"
              d="M407.4,248.3c-0.5-4-2.5-5.7-5.8-7.6c-16.2-9.3-32.1-19.1-48.1-28.7c-10.3-6.2-21-12-31-18.9
					c-7.1-5-13.4-11.3-19.7-17.3c-1-0.9-0.6-3.4-0.8-5.1c2,0,4.5-0.7,5.9,0.2c7.9,5,15.6,10.4,23.4,15.6
					c20.8,13.8,41.9,26.9,62.2,41.4c11.1,7.9,22.6,9.9,35.7,9.2c14-0.7,23.1,8.3,30.2,18.9c3.1,4.6,5.1,10.1,6.5,15.6
					c2.5,9.2-1.9,13.5-11.3,12.1c-12.9-1.8-24.6-6.9-35.7-12.7C413.1,264.1,408.4,257,407.4,248.3z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#444444"
          d="M336.7,454.6c0.4,0,0.7,0,0.9,0c0.2,0,0.5,0,0.7,0.1s0.4,0,0.6,0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.9,0,1.4,0
			c0.5,0,1,0,1.5-0.1c0.2,0,0.4,0,0.6,0.2c0.2,0.1,0.3,0.3,0.3,0.5c0,0.3,0,0.5,0,0.8c0,0.3,0,0.6,0,0.9c0,0.3-0.1,0.5-0.2,0.7
			c-0.1,0.2-0.3,0.3-0.6,0.3c-0.6,0-1,0-1.3,0.1c-0.3,0-0.7,0.1-1.2,0.1c-0.5,0-0.9-0.1-1.3-0.1c-0.4,0-0.7,0-0.9-0.1
			c-0.3,0-0.5,0-0.7,0s-0.4,0-0.6,0h-1.9c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.3-0.1-0.5
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.3c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1
			c0-0.6,0-1.3,0.1-1.9c0.1-0.7,0.1-1.4,0.1-2.1c0-0.4,0-0.7,0-1c0-0.3,0-0.5,0-0.8v-10.2c0-0.3,0-0.8,0-1.3c0-0.5,0-1,0-1.5
			c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.2-0.5,0.5-0.5c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.5,0h1.1c0.5,0,0.7,0.3,0.7,1c0,0.2,0,0.4,0,0.5
			c0,0.2,0,0.4,0,0.6c0,0.9,0,1.8,0,2.7c0,0.9,0,1.8,0,2.6c0,0.3,0,0.5,0,0.8s0,0.5-0.1,0.8V454.6z"
        />
        <path
          fill="#444444"
          d="M350.2,439.6l-0.2,4.3c0.2,0,0.5,0,0.8,0.1c0.3,0,0.7,0,1,0.1c0.3,0,0.7,0,1,0c0.3,0,0.5,0,0.7,0h1
			c0.3,0,0.6,0,0.9,0c0.3,0,0.4,0.1,0.4,0.3l0,1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.2,0.2-0.5,0.3
			c-0.3,0.1-0.6,0.1-1,0.2c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1,0c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0-0.6,0c-0.3,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.1,0.3-0.1,0.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.5v0.6c0,0.5,0,1,0,1.6c0,0.5-0.1,1.1-0.3,1.7v1.7c0.1,0,0.3,0,0.4,0
			c0.1,0,0.3,0,0.4,0c0.8,0,1.5,0,2,0c0.5,0,1.1,0,1.7,0c0.2,0,0.4,0,0.5,0.1s0.4,0.1,0.6,0c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0
			c0.1,0.3,0.2,0.5,0.2,0.7s0,0.4,0,0.7v1.3c0,0.1-0.3,0.2-0.8,0.2c-0.5,0-1.1,0.1-1.7,0.1c-0.6,0-1.4,0-2.5,0h-2
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0h-1.3c-0.5,0-0.7-0.2-0.7-0.7v-0.3c0.2-1,0.3-1.9,0.4-2.8c0-0.9,0.1-1.8,0.1-2.6v-1.9
			c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.8-0.1-1.2v-1.7c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.4c0-0.6,0-1.2,0.1-1.7c0.1-0.6,0.1-1.2,0.1-1.8
			c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4,0-0.7v-1.7c0-0.3,0-0.6,0-0.8s0-0.6,0.1-0.8l0.1-1.7v-0.2c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4-0.1-0.6
			c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.2-0.2,0.5-0.2c0.4,0,0.8,0,1.3,0c0.5,0,0.9,0,1.3,0c0.4,0,0.8,0,1.2-0.1c0.4,0,0.8-0.1,1.2-0.1
			h0.6c0.3,0,0.6,0,1,0c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0.1,0.9,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0,0.1,0,0.3,0.1,0.5c0,0.2,0,0.4,0,0.6
			c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.4c0,0.5-0.3,0.7-0.8,0.7h-5.3V439.6z"
        />
        <path
          fill="#444444"
          d="M394,440.9c0-0.1,0-0.4,0-0.9c0-0.5,0-1,0.1-1.6c0-0.6,0.1-1.1,0.1-1.6c0-0.5,0-0.8,0-1l-0.4,3.8
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1l-1.7,8.2l-1,4l-0.4,0.9l-0.2,0.6c-0.2,0.3-0.3,0.6-0.4,0.8c-0.1,0.2-0.2,0.7-0.3,1.5
			c-0.2,0.7-0.4,1.2-0.6,1.5c-0.2,0.3-0.5,0.5-0.9,0.6c-0.3,0.1-0.8,0.1-1.3,0.1c-0.5-0.1-1.1-0.1-1.8-0.1c-0.6,0-1,0-1.2-0.1
			c-0.2-0.1-0.4-0.4-0.6-1c-0.2-0.6-0.4-1.5-0.7-2.8c-0.3-1.3-0.7-3.1-1.4-5.4l-0.4-1.4c-0.1-0.1-0.1-0.2-0.1-0.4
			c0-0.1,0-0.3-0.1-0.4l-0.2-0.8l0.1,0.1c-0.2-0.7-0.4-1.4-0.6-2.2c-0.2-0.8-0.4-1.6-0.7-2.4c-0.2-0.8-0.4-1.6-0.7-2.4
			c-0.2-0.8-0.4-1.5-0.7-2.1l0.1,1.5l0.1,0.8c0.2,2.8,0.3,5.5,0.2,8.3c-0.1,2.8,0,5.5,0.2,8.3c0.1,0.8,0.1,1.5-0.1,1.8
			c-0.1,0.4-0.3,0.6-0.6,0.8c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.7-0.1-1.1-0.1c-0.7,0-1.5-0.1-2.3-0.2c-0.8-0.1-1.5-0.2-2.2-0.2
			h-0.9c-0.2-0.8-0.4-1.5-0.4-2.1c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.5,0-1,0-1.6c0.1-0.5,0.1-1.1,0.1-1.6l0.2-2.2c0-0.3,0-0.7-0.1-1.2
			c-0.1-0.5-0.1-1-0.1-1.4v-0.6c0.1-1.4,0.2-2.8,0.2-4.2c0-1.9,0-3.7-0.1-5.6c-0.1-1.8-0.1-3.7-0.2-5.6l-0.2-4.6
			c0-0.3-0.1-0.7-0.1-1.1c0-0.4,0-0.8,0-1v-3.6c-0.1-0.7,0-1.3,0.1-1.7c0.1-0.4,0.3-0.6,0.6-0.7c0.3-0.1,0.6-0.2,0.9-0.2
			c0.3,0,0.7,0,1,0c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.4,0.1,2.1,0.1c0.1,0,0.3,0,0.7-0.1c0.3,0,0.6-0.1,0.7-0.1c0.3,0,0.6,0.2,0.9,0.7
			c0.2,0.5,0.4,1.1,0.6,1.9c0.2,0.7,0.4,1.5,0.6,2.4c0.2,0.9,0.4,1.6,0.7,2.3c0.8,2.2,1.7,4.4,2.5,6.3c0.8,2,1.6,4.1,2.3,6.4
			l0.8,2.4l0.6-1.7v0.1c0.4-1.3,0.8-2.5,1.3-3.6c0.4-1.1,0.9-2.5,1.3-4c0.2-0.7,0.4-1.5,0.7-2.2c0.3-0.7,0.6-1.4,0.9-2.2l1-2.4v0.1
			c0.4-1.7,0.8-3,1.2-3.9c0.3-0.9,0.6-1.5,0.9-1.9c0.2-0.4,0.5-0.6,0.7-0.6c0.2,0,0.4,0,0.6,0h3c0.2,0,0.4,0,0.6,0
			c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1,0c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0.3,0.3,0.3,0.6c0.1,0.3,0.1,0.7,0.2,1.2
			c0,0.7,0.1,1.5,0.2,2.3c0.1,0.8,0.1,1.6,0.1,2.5c0,0.8,0,1.7-0.1,2.5c-0.1,0.8-0.1,1.7-0.1,2.5c0,1.7,0.1,3.4,0.2,5.1
			s0.2,3.5,0.2,5.2v1.5l-0.1,6.2c0,0.8-0.1,1.7-0.1,2.5c0,0.8,0,1.7,0,2.5c0,0.8,0,1.5,0.1,2.2c0.1,0.7,0.1,1.4,0.1,2.2
			c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0c-0.4-0.1-0.8-0.2-1.2-0.1
			c-0.4,0.1-0.8,0.1-1.2,0.1c-0.9,0-1.7,0-2.3,0c-0.6,0-1,0-1.4-0.1c-0.3-0.1-0.6-0.2-0.7-0.4c-0.1-0.2-0.2-0.5-0.1-1
			c0-0.1-0.1-0.2-0.1-0.3v-0.6v-0.2c0-1.3-0.1-2.7-0.1-4.2c0-1.5,0-3,0-4.2c0-0.4,0-0.7,0.1-0.9c0.1-0.3,0.1-0.5,0.1-0.8
			c0-0.7,0-1.4-0.1-2.1C394,442.4,394,441.7,394,440.9z"
        />
        <path
          fill="#444444"
          d="M413.7,457.8c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.4-0.2-2.1
			c-0.1-0.8-0.1-1.4-0.1-2.1c0-0.6,0-1.2,0.1-1.7c0.1-0.6,0.1-1.1,0.1-1.8c0-1.7,0-3.4-0.1-5.1c-0.1-1.7-0.1-3.4-0.1-5.2
			c0-1.5,0-2.9,0.1-4.4c0-1.5,0.1-3,0.2-4.4v0.1l0.1-5c0-0.2,0-0.5,0-1c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.9-0.1-1.2
			c0-0.4,0-0.7,0-0.9c0-0.6,0.1-1,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.4,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.6c0,1.1,0,2.6-0.1,4.4c0,1.8,0,4,0.1,6.6l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.8-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.3,0,3.4v2.2c0,0.6,0,1.4-0.1,2.2
			c0,0.8-0.1,1.7-0.1,2.4c0,0.3,0,0.7,0.1,1.1s0.1,0.8,0.1,1.3c0,0.6-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L413.7,457.8z"
        />
        <path
          fill="#444444"
          d="M432.1,429.2l-0.2,4.8l2.6,0.1c0.4,0,0.9,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.6l-0.1,4.2c0,0.2,0,0.5,0,0.9c0,0.4,0,0.8,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.6,0-1.1,0l-5.7,0.3c-0.4,0-0.6,0.3-0.6,0.7v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.6-0.3,2.3
			c0,0.2-0.1,0.4-0.1,0.7c1,0.1,1.8,0.2,2.6,0.4c0.8,0.2,1.5,0.3,2.3,0.3c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.6-0.1,1-0.1
			c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3.1c0,0.4-0.2,0.7-0.5,0.9
			c-0.3,0.2-0.8,0.3-1.6,0.3c-0.8,0.1-1.7,0.1-3,0.1c-1.2,0-2.8,0-4.6,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.8-0.1-1-0.1
			c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0-1,0h-1.9c-1,0-1.7,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3c0.3-1.3,0.4-2.6,0.4-3.8c0-1.3,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5
			c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.9c0-1.2,0.1-2.2,0.2-3.2s0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.6c0-0.4,0-0.8,0-1.3
			c0-0.5,0-0.9,0.1-1.3l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9
			c0-0.4,0.1-0.7,0.4-1c0.3-0.3,1-0.4,2.1-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.4,0.2,2,0.2c0.8,0,1.6-0.1,2.4-0.2
			c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0c0.6,0,1.1,0,1.7,0s1.2,0,1.7,0s1,0,1.4,0c0.6,0,0.8,0.6,0.9,1.7
			c0,1.1,0,2.6,0,4.6c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.3,0-2.3,0h-5.6V429.2z"
        />
        <path
          fill="#444444"
          d="M445.7,418c0-0.3,0.1-0.5,0.4-0.6c0.3-0.1,0.6-0.2,0.9-0.3c0.3,0,0.7-0.1,1-0.1s0.6,0,0.8,0c0.4,0,1,0,1.6,0
			c0.6,0,1.3,0,1.8,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,0.8,0.2,0.9,0.5c0.2,0.4,0.3,0.8,0.2,1.2c0,0.5,0,0.9,0,1.2
			c0,0.4,0,0.7-0.1,1c0,0.3-0.1,0.6-0.2,0.9l0.1-0.1c-0.2,1.1-0.3,2.1-0.3,3c0,0.9-0.1,1.8-0.4,2.8c-0.2,0.6-0.3,1.2-0.3,1.8
			c0,0.6-0.1,1.2-0.1,1.8c0,2.4,0,4.8-0.1,7.2c-0.1,2.4-0.1,4.8-0.1,7.2c0,1.1,0.2,2,0.6,2.6c0.4,0.6,1,0.9,1.9,0.9
			c1.3,0,2.3-0.4,2.9-1.3c0.6-0.8,0.9-1.8,1-3c0.1-1.5,0.1-3,0.1-4.6c0-1.6,0-3.1,0-4.7c0-1.5,0-3,0.1-4.4c0.1-1.4,0.2-2.8,0.4-4
			c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.2-1.8
			c-0.1-0.7-0.1-1.4-0.1-2.2c0-0.9,0-1.6,0.1-2.1c0-0.5,0.1-0.8,0.3-1c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.7,0,1.3,0
			c0.4,0,0.8,0.1,1.2,0.2v-0.1c0.1,0,0.3,0.1,0.5,0.1h0.9c0,0,0.2,0,0.5-0.1c1.3-0.1,2.1-0.1,2.5,0.3c0.4,0.3,0.5,1,0.4,2.1
			c-0.1,0.7-0.1,1.5,0,2.2s0.1,1.5,0.1,2.2c0,1.1,0,2.3-0.1,3.4c-0.1,1.1-0.1,2.3-0.1,3.5c0,1.1,0.1,2.3,0.2,3.5
			c0.1,1.2,0.2,2.4,0.2,3.6c0,1.2-0.2,2.4-0.5,3.6c-0.3,1.3-0.5,2.6-0.5,4.1c-0.2,0.9-0.4,1.9-0.6,3.1c-0.2,1.2-0.5,2.4-0.8,3.5
			c-0.3,1.1-0.8,2.1-1.5,3c-0.6,0.8-1.5,1.4-2.5,1.6l-1.7,0.4c-0.7,0.2-1.2,0.3-1.6,0.4c-0.4,0.1-0.8,0.2-1.1,0.2
			c-0.3,0.1-0.6,0.1-0.9,0.1s-0.6,0-1,0c-1.4,0-2.7-0.1-4-0.3c-1.3-0.2-2.4-0.7-3.4-1.3c-1-0.7-1.8-1.6-2.4-2.9
			c-0.6-1.2-0.9-2.9-0.9-5c0-0.7-0.1-1.4-0.2-2c-0.1-0.6-0.2-1.2-0.2-1.9v-0.2c0.1-3.1,0.1-6.2,0.2-9.2c0.1-3,0.1-6.1,0.2-9.2
			c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.1-0.9-0.1-1.5l0.1-1.9c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9l-0.1-1.3V418z"
        />
        <path
          fill="#444444"
          d="M473.2,417.8c1.1-0.4,2.2-0.6,3.3-0.7c1.1-0.1,2.2-0.2,3.2-0.2c0.4,0,0.8,0,1.4,0c0.6,0,1,0,1.4,0.1l0.4,2.5
			c0.3,1.8,0.7,3.6,1.1,5.3c0.4,1.7,0.9,3.6,1.5,5.7l0.8-3.6l1.6-3.4l1.2-3.3c0.1-0.4,0.3-0.9,0.3-1.2c0.1-0.4,0.2-0.7,0.4-1
			s0.4-0.6,0.7-0.7c0.3-0.2,0.7-0.2,1.3-0.2c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0.1l3.2,0.2c0.8,0.1,1.5,0.1,2,0.1c0.5,0,0.8,0.3,0.8,0.9
			c0,0.2-0.1,0.6-0.2,1.2c-0.2,0.6-0.3,1-0.4,1.2l-1.3,2.9c-0.3,0.7-0.6,1.3-0.9,1.9s-0.6,1.2-0.8,1.9l-2.1,4.5
			c-0.2,0.5-0.5,1-0.9,1.6c-0.4,0.6-0.7,1.1-1,1.7c-0.3,0.6-0.6,1.1-0.9,1.6c-0.3,0.5-0.4,0.9-0.4,1.2c0,0.1,0.1,0.3,0.2,0.5
			c0.1,0.2,0.2,0.4,0.4,0.7c0.2,0.2,0.3,0.5,0.5,0.8c0.2,0.3,0.3,0.5,0.4,0.7c0.4,1.2,1,2.4,1.7,3.6s1.5,2.4,2.2,3.7l2,3.7l1.8,3.2
			c0.1,0.3,0.4,0.7,0.7,1.2c0.3,0.5,0.4,1,0.4,1.3c0,0.3-0.1,0.5-0.4,0.6c-0.2,0.1-0.7,0.2-1.3,0.2c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.5,0-0.7-0.1l-6-0.2l-0.6-0.7c-0.3-0.4-0.6-0.8-0.7-1.2c-0.1-0.4-0.3-0.9-0.6-1.4h0.1l-0.4-0.7l-0.1-0.3
			c-0.3-0.6-0.8-1.5-1.3-2.7c-0.5-1.2-0.9-2.3-1.2-3.4l-0.8-3.1l-0.7,2.5c-0.5,1.3-1,2.6-1.5,4c-0.5,1.4-0.9,2.8-1.1,4.1
			c-0.1,0.7-0.2,1.2-0.4,1.6c-0.1,0.4-0.3,0.7-0.4,0.9c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.3,0-0.6,0-1.2,0
			c-0.5,0-1.1,0-1.7,0c-0.6,0-1.2,0-1.8-0.1c-0.6-0.1-1.2-0.1-1.6-0.2l-1.7-0.3c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.1-0.4,0.1-0.4
			l0.1-0.7l0.8-1.5c1-1.7,1.9-3.5,2.8-5.4c0.8-1.9,1.7-3.8,2.5-5.7v0.1l0.3-0.7c0.3-0.7,0.7-1.5,1.2-2.2c0.5-0.7,1-1.4,1.6-2.1
			l-1.1-1.8c-0.4-0.4-0.7-0.9-0.9-1.5c-0.2-0.6-0.3-1.1-0.5-1.5l-0.7-1.3c-0.4-0.7-0.8-1.4-1.3-2.1c-0.4-0.7-0.8-1.4-1.1-2.1v0.1
			l-0.7-1.7c-0.3-0.6-0.5-1.1-0.8-1.7c-0.3-0.5-0.5-1-0.7-1.5c-0.2-0.5-0.4-1-0.6-1.6s-0.2-1.2-0.2-1.8v-0.6L473.2,417.8z"
        />
        <path
          fill="#444444"
          d="M362.1,502.3c0-0.1,0-0.4,0-0.9c0-0.5,0-1,0.1-1.6c0-0.6,0.1-1.1,0.1-1.6c0-0.5,0-0.8,0-1l-0.4,3.8
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1l-1.7,8.2l-1,4l-0.4,0.9l-0.2,0.6c-0.2,0.3-0.3,0.6-0.4,0.8c-0.1,0.2-0.2,0.7-0.3,1.5
			c-0.2,0.7-0.4,1.2-0.6,1.5c-0.2,0.3-0.5,0.5-0.9,0.6c-0.3,0.1-0.8,0.1-1.3,0.1c-0.5-0.1-1.1-0.1-1.8-0.1c-0.6,0-1,0-1.2-0.1
			c-0.2-0.1-0.4-0.4-0.6-1c-0.2-0.6-0.4-1.5-0.7-2.8c-0.3-1.3-0.7-3.1-1.4-5.4l-0.4-1.4c-0.1-0.1-0.1-0.2-0.1-0.4
			c0-0.1,0-0.3-0.1-0.4l-0.2-0.8l0.1,0.1c-0.2-0.7-0.4-1.4-0.6-2.2c-0.2-0.8-0.4-1.6-0.7-2.4c-0.2-0.8-0.4-1.6-0.7-2.4
			c-0.2-0.8-0.4-1.5-0.7-2.1l0.1,1.5l0.1,0.8c0.2,2.8,0.3,5.5,0.2,8.3c-0.1,2.8,0,5.5,0.2,8.3c0.1,0.8,0.1,1.5-0.1,1.8
			c-0.1,0.4-0.3,0.6-0.6,0.8c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.7-0.1-1.1-0.1c-0.7,0-1.5-0.1-2.3-0.2c-0.8-0.1-1.5-0.2-2.2-0.2
			h-0.9c-0.2-0.8-0.4-1.5-0.4-2.1c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.5,0-1,0-1.6c0.1-0.5,0.1-1.1,0.1-1.6l0.2-2.2c0-0.3,0-0.7-0.1-1.2
			c-0.1-0.5-0.1-1-0.1-1.4v-0.6c0.1-1.4,0.2-2.8,0.2-4.2c0-1.9,0-3.7-0.1-5.6c-0.1-1.8-0.1-3.7-0.2-5.6l-0.2-4.6
			c0-0.3-0.1-0.7-0.1-1.1s0-0.8,0-1v-3.6c-0.1-0.7,0-1.3,0.1-1.7c0.1-0.4,0.3-0.6,0.6-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7,0,1,0
			c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.4,0.1,2.1,0.1c0.1,0,0.3,0,0.7-0.1c0.3,0,0.6-0.1,0.7-0.1c0.3,0,0.6,0.2,0.9,0.7
			c0.2,0.5,0.4,1.1,0.6,1.9c0.2,0.7,0.4,1.5,0.6,2.4c0.2,0.9,0.4,1.6,0.7,2.3c0.8,2.2,1.7,4.4,2.5,6.3c0.8,2,1.6,4.1,2.3,6.4
			l0.8,2.4l0.6-1.7v0.1c0.4-1.3,0.8-2.5,1.3-3.6c0.4-1.1,0.9-2.5,1.3-4c0.2-0.7,0.4-1.5,0.7-2.2s0.6-1.4,0.9-2.2l1-2.4v0.1
			c0.4-1.7,0.8-3,1.2-3.9c0.3-0.9,0.6-1.5,0.9-1.9c0.2-0.4,0.5-0.6,0.7-0.6c0.2,0,0.4,0,0.6,0h3c0.2,0,0.4,0,0.6,0
			c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1,0c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0.3,0.3,0.3,0.6c0.1,0.3,0.1,0.7,0.2,1.2
			c0,0.7,0.1,1.5,0.2,2.3c0.1,0.8,0.1,1.6,0.1,2.5c0,0.8,0,1.7-0.1,2.5c-0.1,0.8-0.1,1.7-0.1,2.5c0,1.7,0.1,3.4,0.2,5.1
			c0.2,1.7,0.2,3.5,0.2,5.2v1.5l-0.1,6.2c0,0.8-0.1,1.7-0.1,2.5c0,0.8,0,1.7,0,2.5c0,0.8,0,1.5,0.1,2.2c0.1,0.7,0.1,1.4,0.1,2.2
			c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0c-0.4-0.1-0.8-0.2-1.2-0.1
			c-0.4,0.1-0.8,0.1-1.2,0.1c-0.9,0-1.7,0-2.3,0c-0.6,0-1,0-1.4-0.1c-0.3-0.1-0.6-0.2-0.7-0.4c-0.1-0.2-0.2-0.5-0.1-1
			c0-0.1-0.1-0.2-0.1-0.3v-0.6v-0.2c0-1.3-0.1-2.7-0.1-4.2c0-1.5,0-3,0-4.2c0-0.4,0-0.7,0.1-0.9c0.1-0.3,0.1-0.5,0.1-0.8
			c0-0.7,0-1.4-0.1-2.1C362.1,503.9,362.1,503.1,362.1,502.3z"
        />
        <path
          fill="#444444"
          d="M393.4,518.8c-0.1-0.1-0.3-0.3-0.3-0.7c-0.1-0.4-0.1-0.7-0.1-0.9l-0.3-2c-0.1-1-0.3-2-0.5-3.2
			c-0.2-1.2-0.4-2.3-0.5-3.2c-0.8,0.2-1.5,0.3-1.9,0.4s-0.8,0.1-1.1,0.1c-0.7,0-1.3-0.1-1.9-0.2s-1.1-0.2-1.7-0.2l-0.2,0.9l-1.9,8.7
			c-0.1,0.3-0.2,0.4-0.4,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.7,0.1-1,0.1s-0.7,0-1.1,0c-0.5,0-1,0-1.4-0.1
			c-0.5-0.1-0.9-0.1-1.3-0.1c-0.5,0-0.9,0-1.3,0c-0.4,0-0.6-0.2-0.6-0.7c0-0.1,0-0.5,0.1-1c0.1-0.5,0.2-0.8,0.2-1c0-0.1,0-0.3,0-0.4
			c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.7,0.4-1.3c0.1-0.8,0.2-1.7,0.4-2.6c0.2-0.9,0.4-1.7,0.4-2.3l2.9-11
			c0.3-0.7,0.5-1.5,0.6-2.2c0.1-0.7,0.3-1.5,0.4-2.2l0.8-2.5l0.5-1.5c0.1-0.6,0.3-1.3,0.5-2.1c0.2-0.8,0.3-1.6,0.5-2.4
			c0.2-0.8,0.3-1.6,0.5-2.4c0.2-0.8,0.3-1.5,0.5-2.1c0.1-0.4,0.2-0.7,0.3-1c0.1-0.2,0.3-0.4,0.6-0.5c0.3-0.1,0.7-0.2,1.3-0.2
			c0.6,0,1.4-0.1,2.4-0.1c0.9,0,1.5,0,1.9,0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.5,0.4,0.9c0.1,0.4,0.2,0.9,0.4,1.6
			c0.2,0.6,0.4,1.3,0.6,2.1c0.2,0.8,0.4,1.6,0.7,2.4c0.2,0.8,0.4,1.6,0.6,2.4c0.2,0.8,0.4,1.5,0.5,2c0,0.2,0.1,0.4,0.1,0.7
			c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.7,0.3,1.2l1.9,7.2l3.7,13.7c0.1,0.3,0.2,0.7,0.2,1s0.2,0.6,0.3,1l0.2,0.5
			c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.4,0,0.6c0,0.3-0.1,0.6-0.2,0.7s-0.5,0.3-1,0.5c-0.3,0.1-0.7,0.2-1.4,0.2c-0.6,0-1.2,0-1.7,0
			c-0.3,0-0.6,0-1,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1-0.1C393.8,519.1,393.5,518.9,393.4,518.8z M389.7,500.5
			c-0.1-0.6-0.2-1.3-0.3-2.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.9-0.3-1.7-0.6-2.5c-0.2-0.8-0.4-1.5-0.7-2.1l-1.2,9.2H389.7z"
        />
        <path
          fill="#444444"
          d="M430.2,480.8c0,1.8,0,3.6,0.1,5.4c0,1.8,0.1,3.5,0.2,5.3c0,1.6,0,3.2-0.1,4.8c-0.1,1.6-0.1,3.2-0.1,4.7
			c0,0.3,0.1,0.6,0.1,1c0,0.4,0,0.8,0,1c0,0.3,0,0.7,0,1.1s0,0.8-0.1,1.2l0.1-0.2c0,1,0,1.8-0.1,2.5c0,0.7-0.1,1.3-0.1,1.9
			c0,0.6-0.1,1.1-0.1,1.6c0,0.5-0.1,1.1-0.1,1.7v2.1c0,0.4,0,0.7,0,1.1c0,0.3,0,0.7,0,1.1c0,0.9-0.1,1.5-0.4,1.7
			c-0.3,0.3-0.8,0.4-1.7,0.4c-0.4,0-0.9,0-1.4,0c-0.5,0-1.1,0-1.6,0c-0.5,0-1,0-1.5-0.1c-0.5,0-0.8-0.1-1-0.2
			c-0.5,0.1-0.9,0.1-1.2-0.1c-0.3-0.2-0.5-0.4-0.6-0.8c-0.1-0.4-0.2-0.8-0.4-1.3c-0.1-0.5-0.3-1-0.5-1.6c-0.3-1.1-0.6-2.1-0.8-3.1
			c-0.3-1-0.5-2-0.8-3c-0.3-1-0.6-1.9-0.9-2.8c-0.3-0.9-0.8-1.8-1.3-2.6c-0.4-0.6-0.7-1.3-0.8-2c-0.1-0.7-0.3-1.4-0.6-2l-0.9-2.7
			c0.1,0.9,0.1,1.9,0.2,3.1c0.1,1.2,0.1,2.4,0.2,3.6s0.1,2.4,0.2,3.6c0,1.2,0.1,2.2,0.1,3.2l0.2,5c0,0.9,0,1.6,0,2.1
			c0,0.5-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.4-0.7,0.5c-0.3,0.1-0.8,0.1-1.4,0.1c-0.3,0-0.7,0-1.2,0c-0.5,0-1,0-1.5,0
			c-0.5,0-1-0.1-1.4-0.1c-0.4-0.1-0.8-0.1-1-0.2c-0.1,0-0.3,0-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.2-1-0.3-1.9-0.5-2.9
			c-0.1-1-0.2-1.9-0.2-2.8c0-0.2,0-0.6,0.1-1.1c0-0.6,0.1-1.2,0.1-1.8c0-0.7,0-1.3,0.1-1.9c0-0.6,0-1.1,0-1.4c0-1,0-1.9,0.1-2.8
			c0-0.9,0.1-1.9,0.1-2.9v-1.6c0-3,0-6.2,0.1-9.6c0-3.3,0.1-6.5,0.3-9.7c0-0.5,0.1-0.8,0.2-1c0.1-0.2,0.3-0.3,0.5-0.4
			c0.2-0.1,0.5-0.1,0.8-0.1c0.3,0,0.7,0,1.2,0h2.9c0.2,0,0.4,0,0.7-0.1c0.3,0,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.7,0.3
			c0.2,0.2,0.4,0.5,0.5,0.8c0.1,0.3,0.2,0.6,0.2,1c0.1,0.3,0.1,0.7,0.1,1v0.1c0.3,1.3,0.7,2.5,1.2,3.7c0.5,1.2,1,2.4,1.6,3.6
			l0.6,1.4v-0.1c0.4,0.9,0.8,1.8,1.1,2.7c0.3,0.9,0.7,1.8,1.1,2.7l2.5,7.7c0,0,0-0.2,0-0.4c0-0.2,0-0.5-0.1-0.8s-0.1-0.5-0.1-0.8
			c0-0.2,0-0.4,0-0.4c-0.2-0.7-0.3-1.5-0.4-2.3c-0.1-0.8-0.1-1.5-0.1-2.3c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9v-7.2
			c0-1.1-0.1-2.2-0.4-3.2c-0.2-1-0.4-2.1-0.4-3.3v-1c0-0.5,0.2-0.9,0.5-1c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.6,0,0.9,0.1
			c0.3,0,0.6,0.1,0.9,0.1c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.1,1.2-0.1c0.7,0,1.5,0.1,2.3,0.4L430.2,480.8z"
        />
        <path
          fill="#444444"
          d="M450.6,510.4c0.1-0.7,0.1-1.6,0.1-2.6c0-1-0.1-2-0.4-3.1l-0.1-0.4h-3.7c-0.5,0-0.8-0.4-1-1.3
			c-0.2-0.9-0.3-2.4-0.3-4.5c0-0.5,0-0.9-0.1-1.3c-0.1-0.4,0-0.7,0-0.9c0.1-0.2,0.3-0.4,0.6-0.6c0.3-0.2,0.8-0.3,1.5-0.4
			c0.6-0.1,1.3-0.1,2.1-0.2c0.8-0.1,1.5-0.1,2.3-0.2c0.8,0,1.5-0.1,2.2-0.1c0.7,0,1.3,0,1.7,0c0.3,0,0.6,0,0.9,0
			c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,1,0.2,1.9c0,0.6,0,1.1-0.1,1.7c0,0.5-0.1,1.1-0.1,1.6
			c0,2.5,0.1,5,0.2,7.5c0.1,2.5,0.2,5,0.2,7.5c0,0.8,0,1.4,0,1.8c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.5,0.3-0.9,0.4s-1,0.2-1.8,0.4
			l-0.5,0.1c-0.5,0.1-1,0.2-1.6,0.2c-0.6,0.1-1.2,0.1-1.8,0.1c-0.5,0-1.1,0-1.8-0.1c-0.7-0.1-1.3-0.2-2-0.4c-0.7-0.2-1.4-0.4-2-0.6
			c-0.7-0.3-1.2-0.6-1.7-0.9c-0.4-0.3-0.9-0.7-1.4-1c-0.5-0.4-1-0.8-1.4-1.2c-0.5-0.4-0.9-0.8-1.2-1.2c-0.4-0.4-0.6-0.8-0.8-1.2
			c-0.8-2-1.6-4.2-2.3-6.6c-0.7-2.4-1-4.8-1-7.1c0-0.6,0-1.1,0.1-1.7c0-0.6,0.1-1.1,0.2-1.6v-0.4c0-0.1-0.1-0.3-0.1-0.4
			c0-0.1,0-0.3,0-0.4c0-1.9,0.3-3.6,1-5.3c0.7-1.6,1.7-3.3,2.9-5l0.4-0.7c0.8-1.3,1.8-2.4,3.1-3.3c1.2-0.9,2.6-1.5,4.1-1.8l0.8-0.2
			c0.8-0.1,1.6-0.3,2.4-0.4c0.8-0.1,1.6-0.2,2.5-0.2c1.1,0,2.1,0.2,3.1,0.5c0.9,0.3,1.5,0.7,1.8,1.2c0.1,0.3,0.1,0.6,0,1
			c-0.1,0.4-0.3,0.9-0.6,1.4c-0.3,0.5-0.5,1-0.8,1.5c-0.3,0.5-0.5,0.9-0.7,1.3c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.2
			l-0.3,0.6c-0.1,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1c-0.4,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.9-0.3-1.5-0.3
			c-0.6,0-1.2,0.1-1.8,0.3c-0.6,0.2-1.2,0.5-1.7,0.9c-0.5,0.4-1,0.8-1.4,1.3c-0.4,0.5-0.7,1.1-0.8,1.7l-0.7,1.4
			c-0.3,0.4-0.5,0.9-0.6,1.5c-0.1,0.6-0.2,1.2-0.2,1.9c0,0.7,0,1.3,0,1.9c0,0.6,0,1.1,0,1.5c0,2,0.1,3.7,0.4,5.3
			c0.3,1.5,0.8,3.1,1.4,4.7c0,0,0.1,0.1,0.2,0.2s0.2,0.2,0.4,0.3l0.1,0.1c0.4,0.5,1,0.8,1.9,1.1
			C449.1,510.2,449.9,510.3,450.6,510.4z"
        />
        <path
          fill="#444444"
          d="M473.2,490.6l-0.2,4.8l2.6,0.1c0.4,0,0.9,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.6l-0.1,4.2c0,0.2,0,0.5,0,0.9c0,0.4,0,0.8,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1s-0.6,0-1.1,0l-5.7,0.3c-0.4,0-0.6,0.3-0.6,0.7v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.6-0.3,2.3
			c0,0.2-0.1,0.4-0.1,0.7c1,0.1,1.8,0.2,2.6,0.4c0.8,0.2,1.5,0.3,2.3,0.3c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.6-0.1,1-0.1
			c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3.1c0,0.4-0.2,0.7-0.5,0.9
			s-0.8,0.3-1.6,0.3c-0.8,0.1-1.7,0.1-3,0.1c-1.2,0-2.8,0-4.6,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.8-0.1-1-0.1c-0.3,0-0.7,0-1,0
			c-0.3,0-0.6,0-1,0h-1.9c-1,0-1.7,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7c0-0.2,0-0.4,0-0.6
			c0-0.2,0-0.3,0-0.3c0.3-1.3,0.4-2.6,0.4-3.8c0-1.3,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.9
			c0-1.2,0.1-2.2,0.2-3.2c0.2-1,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.6c0-0.4,0-0.8,0-1.3c0-0.5,0-0.9,0.1-1.3
			l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1
			s1-0.4,2.1-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.4,0.2,2,0.2c0.8,0,1.6-0.1,2.4-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9
			c0.3,0,0.8,0,1.3,0c0.6,0,1.1,0,1.7,0s1.2,0,1.7,0c0.6,0,1,0,1.4,0c0.6,0,0.8,0.6,0.9,1.7c0,1.1,0,2.6,0,4.6
			c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.3,0-2.3,0h-5.6V490.6z"
        />
        <path
          fill="#444444"
          d="M487.1,519.4c-0.1-0.7-0.1-1.6-0.1-2.5c0-0.9,0-1.7,0-2.3v-0.3c0-1.3,0.1-2.7,0.1-4c0-1.3,0-2.7,0-4
			c0-1.2,0-2.3,0-3.5c0-1.1,0-2.3-0.1-3.4v0.1c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1v-2.7c0-0.5,0-0.9,0-1.4c0-0.5,0-0.9,0-1.4
			v-2.9c0-0.6,0-1.3-0.1-2.2c-0.1-0.9-0.1-1.8-0.1-2.7c0-0.9,0.1-1.7,0.2-2.4c0.1-0.7,0.4-1.1,0.8-1.3c0.5-0.2,1.1-0.3,1.7-0.2
			c0.6,0,1.1,0.1,1.5,0.1c0.3,0,0.6,0,0.9,0.1c0.3,0,0.6,0.1,0.9,0.2c0.8,0.2,1.6,0.4,2.4,0.6c0.8,0.2,1.6,0.4,2.4,0.6
			c0.7,0.1,1.3,0.4,1.9,0.7c0.6,0.3,1.2,0.7,1.8,1.1c2.2,1.5,3.8,3.5,4.9,5.7c1.1,2.3,1.6,4.7,1.6,7.2c0,1.1-0.2,2.1-0.5,3.3
			c-0.3,1.1-0.8,2.1-1.3,3.1c-0.6,1-1.2,1.8-1.9,2.6c-0.7,0.8-1.5,1.3-2.3,1.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			l-0.9,0.3l6.3,8.7l1.3,1.8l1.5,3c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1s-0.3,0-0.5,0.1
			l-5.6,0.3c-0.1,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.6-0.7-0.9-1.1c-0.3-0.4-0.7-0.9-1-1.4c-0.3-0.5-0.7-0.9-0.9-1.4l-1.2-1.7
			c-0.2-0.3-0.4-0.7-0.6-1.1c-0.2-0.5-0.4-0.9-0.6-1.5c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-0.9-0.4-1.3c0,0.8,0.1,1.9,0.2,3.1
			c0.1,1.2,0.2,2.4,0.2,3.6c0.1,1.2,0,2.1-0.1,3c-0.1,0.8-0.4,1.2-0.9,1.2c-0.4,0-0.9,0-1.5,0c-0.6,0-1.1,0-1.7,0s-1.2,0-1.7,0
			c-0.6,0-1,0-1.5,0L487.1,519.4z M495.7,499c0.4-0.3,0.9-0.5,1.3-0.8c0.4-0.3,0.8-0.6,1.1-1c0.3-0.4,0.6-1,0.7-1.7
			c0.2-0.7,0.2-1.6,0.2-2.7c0-1.3-0.2-2.5-0.7-3.6c-0.5-1.1-1.3-1.9-2.5-2.4l-0.1,6.1V499z"
        />
      </g>
    </g>
  ),
})
