'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 1061 60',
  path: (
    <>
      <path
        d="M1059.83 32.8284C1061.39 31.2663 1061.39 28.7337 1059.83 27.1716L1034.37 1.71573C1032.81 0.153631 1030.28 0.153631 1028.72 1.71573C1027.15 3.27783 1027.15 5.81049 1028.72 7.37258L1051.34 30L1028.72 52.6274C1027.15 54.1895 1027.15 56.7222 1028.72 58.2843C1030.28 59.8464 1032.81 59.8464 1034.37 58.2843L1059.83 32.8284ZM0 34H1057V26H0V34Z"
        fill="url(#paint0_linear_199_62)"
      />
      <defs>
        <linearGradient id="paint0_linear_199_62" x1="0" y1="30.5" x2="1057" y2="30.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E81BA" />
          <stop offset="0.5" stopColor="#E6352C" />
          <stop offset="1" stopColor="#B4CF53" />
        </linearGradient>
      </defs>
    </>
  ),
})
