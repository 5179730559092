'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Heart',
  viewBox: '0 0 18 16',
  path: (
    <>
      <path
        d="M15.9672 1.86594C15.0556 0.957047 13.8651 0.5 12.6719 0.5C11.4786 0.5 10.2855 0.957109 9.37396 1.86594L8.99925 2.24065L8.62454 1.86594C7.71565 0.957047 6.51982 0.5 5.32925 0.5C4.13336 0.5 2.94287 0.957109 2.03134 1.86594C0.210906 3.68637 0.210906 6.63885 2.03134 8.45929L2.40605 8.834V8.83665L8.47454 14.9027C8.76443 15.1924 9.23426 15.1924 9.5241 14.9025L15.9674 8.45929C17.7878 6.639 17.7878 3.68653 15.9674 1.86609L15.9672 1.86594Z"
        fill="currentColor"
      />
    </>
  ),
})
