'use client'

import { type UrlObject, format } from 'url'

import { Flex, HStack, BoxProps, Box, FlexProps } from '@chakra-ui/react'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { ChevronLeft, ChevronRight } from '../icons'

interface PaginationProps extends BoxProps {
  page: number
  pageCount: number
  anchor?: string
}

interface PaginationDotProps extends BoxProps {
  page: number
  current?: boolean
  anchor?: string
}

function routerToURL(router: UrlObject): string {
  // This is deprecated but clearly does a better job than anything else :(
  return format(router)
}

const PaginationDot: FC<PaginationDotProps> = ({ page, current, children, anchor, ...props }) => {
  const { pathname, query, push } = useRouter()
  const newRoute = { pathname, query: { ...query, page }, hash: anchor }
  const onClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    push(newRoute, undefined, { scroll: false })
  }

  const linkProps: FlexProps & { href?: string } = current
    ? {}
    : {
        onClick,
        href: routerToURL(newRoute),
        border: '1px',
        borderColor: 'dark_green',
        borderRadius: '4px',
        _hover: {
          backgroundColor: 'dark_green',
          color: 'white',
        },
      }

  return (
    <Box as="li" {...props} py="1">
      <Flex
        as="a"
        direction="row"
        align="center"
        justify="center"
        h={{ base: '30px', md: '40px' }}
        w={{ base: '30px', md: '40px' }}
        color="dark_green"
        fontWeight="bold"
        {...linkProps}
        fontSize={['12px', '16px']}
      >
        {children}
      </Flex>
    </Box>
  )
}

// TODO: add .. when there are two many pages
const Pagination: FC<PaginationProps> = ({ page, pageCount, anchor, ...props }) => {
  return (
    <HStack spacing="5px" as="ul" wrap="wrap" justifyContent="center" {...props}>
      {page > 0 && (
        <PaginationDot page={page - 1} anchor={anchor}>
          <ChevronLeft />
        </PaginationDot>
      )}
      {range(pageCount).map((k) => (
        <PaginationDot page={k} current={k === page} key={k} anchor={anchor}>
          {k + 1}
        </PaginationDot>
      ))}
      {page < pageCount - 1 && (
        <PaginationDot page={page + 1} anchor={anchor}>
          <ChevronRight />
        </PaginationDot>
      )}
    </HStack>
  )
}

export default Pagination
