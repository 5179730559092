import { GetServerSidePropsContext } from 'next'

import { Loans } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { ErrorV3, createV3Client, toV3Result } from './base'

export class DashboardAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  async getLoansSummaries(
    params: Loans.LenderLoanSummariesRequest
  ): Promise<Result<Loans.LenderLoanSummariesResponse, ErrorV3>> {
    return await this.call<Loans.LenderLoanSummariesResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/LenderLoanSummaries', params)
    )
  }

  async getPortfolioSummary(
    params: Loans.LenderPortfolioSummaryRequest
  ): Promise<Result<Loans.LenderPortfolioSummaryResponse, ErrorV3>> {
    return await this.call<Loans.LenderPortfolioSummaryResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/LenderPortfolioSummary', params)
    )
  }

  async getXirrProjection(): Promise<Result<Loans.XirrProjectionResponse, ErrorV3>> {
    return await this.call<Loans.XirrProjectionResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/XirrProjection', {})
    )
  }

  async getFinancialStatement(
    params: Loans.FinancialStatementRequest
  ): Promise<Result<Loans.FinancialStatementResponse, ErrorV3>> {
    return await this.call<Loans.FinancialStatementResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/FinancialStatement', params)
    )
  }

  async getExtract(
    params: Loans.ExtractLenderLoanSummariesXLSRequest
  ): Promise<Result<Loans.ExtractLenderLoanSummariesXLSResponse, ErrorV3>> {
    return await this.call<Loans.ExtractLenderLoanSummariesXLSResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/ExtractLenderLoanSummariesXLS', params)
    )
  }

  async getDashboardSummary(
    params: Loans.LenderLoanSummaryRequest
  ): Promise<Result<Loans.LenderLoanSummaryResponse, ErrorV3>> {
    return await this.call<Loans.LenderLoanSummaryResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/LenderLoanSummary', params)
    )
  }

  async getLendingNotes(params: Loans.LendingNotesRequest): Promise<Result<Loans.LendingNotesResponse, ErrorV3>> {
    return await this.call<Loans.LendingNotesResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/LendingNotes', params)
    )
  }

  async getAvailableRepayTermYears(
    params: Loans.AvailableRepayTermYearsRequest
  ): Promise<Result<Loans.AvailableRepayTermYearsResponse, ErrorV3>> {
    return await this.call<Loans.AvailableRepayTermYearsResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/AvailableRepayTermYears', params)
    )
  }

  async getRepayTermSummaries(
    params: Loans.RepayTermSummariesRequest
  ): Promise<Result<Loans.RepayTermSummariesResponse, ErrorV3>> {
    return await this.call<Loans.RepayTermSummariesResponse, ErrorV3>((c) =>
      c.post('/loans/dashboard/v1/RepayTermSummaries', params)
    )
  }

  async getTransactions(params: Loans.TransactionsRequest): Promise<Result<Loans.TransactionsResponse, ErrorV3>> {
    return await this.call<Loans.TransactionsResponse, ErrorV3>((c) =>
      c.post('loans/dashboard/v1/Transactions', params)
    )
  }

  async getTransactionsExport(
    params: Loans.TransactionsExportRequest
  ): Promise<Result<Loans.TransactionsExportResponse, ErrorV3>> {
    return await this.call<Loans.TransactionsExportResponse, ErrorV3>((c) =>
      c.post('loans/dashboard/v1/TransactionsExport', params)
    )
  }
}
