'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'CirclePlus',
  viewBox: '0 0 300.5 283.9',
  path: (
    <>
      <path
        fill="currentColor"
        d="M33.1,142c0-64.7,52.5-117.2,117.2-117.2S267.4,77.2,267.4,142S215,259.1,150.2,259.1S33.1,206.7,33.1,142z
		 M150.2,193.2c6.1,0,11-4.9,11-11v-29.3h29.3c6.1,0,11-4.9,11-11s-4.9-11-11-11h-29.3v-29.3c0-6.1-4.9-11-11-11s-11,4.9-11,11V131
		H110c-6.1,0-11,4.9-11,11s4.9,11,11,11h29.3v29.3C139.3,188.3,144.2,193.2,150.2,193.2z"
      />
    </>
  ),
})
