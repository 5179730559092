import { Box, Flex, Link, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import useSWR from 'swr'

import { createV3Client } from '@miimosa/api/v3/base'
import { s3ImageURL } from '@miimosa/common'
import type { PublicUser } from '@miimosa/common/types'
import { LinkV2, Image } from '@miimosa/design-system/components'
import { Notifier } from '@miimosa/protocol'

import { FunnelContext } from '@components/pages/funnel/FunnelProvider'
import { fetchLead } from '@components/pages/funnel/useFunnel'

export const LoggedItems = ({ user, onClose }: { user: PublicUser; onClose?: () => void }) => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const color = isMobile ? 'white' : 'black'
  const { setFunnelState } = useContext(FunnelContext)
  const { push } = useRouter()
  const donationDate = user.donation_lead?.is_completed
    ? `Don : terminée le ${new Date(Date.parse(user.donation_lead?.updated_at)).toLocaleString('fr-FR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`
    : `Don : commencée le ${new Date(Date.parse(user.donation_lead?.created_at)).toLocaleString('fr-FR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`

  const lendingDate = user.lending_lead?.is_completed
    ? `Prêt : terminée le ${new Date(Date.parse(user.lending_lead?.updated_at)).toLocaleString('fr-FR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`
    : `Prêt : commencée le ${new Date(Date.parse(user.lending_lead?.created_at)).toLocaleString('fr-FR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`

  const goToFunnel = async (leadId: string) => {
    const fetchedLead = await fetchLead(leadId)
    // if lead found by api
    if (fetchedLead) {
      const newFunnelState = {
        lead_id: leadId,
        step: fetchedLead.step,
        progress: {
          current_step: fetchedLead.progress?.current_step,
          step_count: fetchedLead.progress?.step_count,
          steps: fetchedLead.progress?.steps,
        },
        funnel_type: fetchedLead.funnel_type,
        salesforce_ref: fetchedLead.salesforce_ref,
      }
      setFunnelState(newFunnelState)
      return push('/funnel')
    }
    return
  }
  const client = createV3Client()
  const fetcher = (url: string) => client.post(url, {}).then((res) => res.data)
  const { data: unreadNotificationsCount } = useSWR<Notifier.CountUnreadNotificationsResponse>(
    '/notifier/v1/CountUnreadNotifications',
    fetcher,
    {
      refreshInterval: 5 * 60000, // milliseconds aka 5 min
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  return (
    <>
      <Flex direction="column" w="100%">
        <NextLink href={'/dashboard'}>
          <Text
            px={{ base: '20px', md: '32px' }}
            py="12px"
            size={{ base: 'xl', md: 'lg' }}
            color={color}
            _hover={{ color: isMobile ? 'white' : 'dark_green', fontWeight: 'bold', textDecor: 'underline' }}
            onClick={onClose ? onClose : undefined}
          >
            {t('header.dashboard')}
          </Text>
        </NextLink>
        <NextLink href={'/notifications'}>
          <Flex direction="row" columnGap={2} alignItems="center" px={{ base: '20px', md: '32px' }} py="12px">
            <Text
              size={{ base: 'xl', md: 'lg' }}
              color={color}
              _hover={{ color: isMobile ? 'white' : 'dark_green', fontWeight: 'bold', textDecor: 'underline' }}
              onClick={onClose ? onClose : undefined}
            >
              Notifications
            </Text>
            <Flex
              backgroundColor="dark_green"
              minW={'25px'}
              px={2}
              py={0.5}
              h="fit-content"
              borderRadius="70px"
              justifyContent="center"
            >
              <Text color="white">{unreadNotificationsCount?.count ?? 0}</Text>
            </Flex>
          </Flex>
        </NextLink>
        {user?.is_private_debt && (
          <LinkV2 color={color} path={`/private_debt_dashboard`}>
            <Text
              px={{ base: '20px', md: '32px' }}
              py="12px"
              size={{ base: 'xl', md: 'lg' }}
              color={color}
              _hover={{ color: isMobile ? 'white' : 'dark_green', fontWeight: 'bold', textDecor: 'underline' }}
              onClick={onClose ? onClose : undefined}
            >
              {t('header.dashboard_private_debt')}
            </Text>
          </LinkV2>
        )}
        <LinkV2 color={color} path={`/users/${user.slug}`} px={{ base: '20px', md: '32px' }}>
          <Text py="12px" size={{ base: 'xl', md: 'lg' }} _hover={{ color: isMobile ? 'white' : 'dark_green' }}>
            {t('header.followed_projects')}
          </Text>
        </LinkV2>
        {(user.donation_lead || user.lending_lead) && (
          <Flex
            direction="column"
            px={{ base: '20px', md: '32px' }}
            bg={{ base: 'dark_green', md: 'light_gray' }}
            py="12px"
          >
            <Text size={{ base: 'xl', md: 'lg' }} color={color}>
              Mes demandes
            </Text>
            <Flex direction="column" pl={{ base: 5, lg: 3 }} rowGap={1}>
              {user.donation_lead && (
                <Flex direction="row" alignItems="center" columnGap={3}>
                  <Box w="4px" h="4px" bg={{ base: 'white', md: 'black' }} borderRadius="50%" />
                  <Text
                    color={color}
                    size="md"
                    _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => goToFunnel(user.donation_lead.id)}
                  >
                    {donationDate}
                  </Text>
                </Flex>
              )}
              {user.lending_lead && (
                <Flex direction="row" alignItems="center" columnGap={3}>
                  <Box w="4px" h="4px" bg={{ base: 'white', md: 'black' }} borderRadius="50%" />
                  <Text
                    size="md"
                    color={color}
                    _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => goToFunnel(user.lending_lead.id)}
                  >
                    {lendingDate}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
        {((user.current_projects && user.current_projects.length > 0) ||
          (user.current_leads && user.current_leads.length > 0)) && (
          <Flex
            bg={{ base: 'dark_green', md: 'light_gray' }}
            px={{ base: '20px', md: '32px' }}
            py={3}
            direction="column"
          >
            <Text size={{ base: 'xl', md: 'lg' }} pt="8px" color={color}>
              {t('header.my_projects')}
            </Text>
            <Flex direction="column" rowGap={1} pl={{ base: 5, lg: 3 }}>
              <Flex direction="column" rowGap={1}>
                {user.current_projects?.map((project, index) => (
                  <Flex direction="row" alignItems="center" columnGap={3} key={index}>
                    <Box w="4px" h="4px" bg={{ base: 'white', md: 'black' }} borderRadius="50%" />
                    <LinkV2
                      color={color}
                      path={project.path}
                      size="sm"
                      _hover={{ base: { color: 'white' }, md: { color: 'dark_green' }, textDecoration: 'underline' }}
                    >
                      {project.title}
                    </LinkV2>
                  </Flex>
                ))}
              </Flex>
              <Flex direction="column" rowGap={1}>
                {user.current_leads?.map((lead, index) => (
                  <Flex direction="row" alignItems="center" columnGap={3} key={index}>
                    <Box w="4px" h="4px" bg={{ base: 'white', md: 'black' }} borderRadius="50%" />
                    <LinkV2
                      color={color}
                      path={lead.path}
                      size="sm"
                      _hover={{ base: { color: 'white' }, md: { color: 'dark_green' }, textDecoration: 'underline' }}
                    >
                      {t('header.lead_proposed_at', { date: Date.parse(lead.updated_at) })}
                    </LinkV2>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        )}
        <LinkV2
          color={color}
          display="flex"
          path={`/users/${user.slug}/referral_offers`}
          px={{ base: '20px', md: '32px' }}
          py="8px"
          _hover={{ fontWeight: '700', color: isMobile ? 'white' : 'dark_green' }}
          size={{ base: 'xl', md: 'md' }}
          alignItems="center"
        >
          <Image src={s3ImageURL('star.png')} alt="star" width="20" height="20" />
          <Text size={{ base: 'xl', md: 'lg' }} as="span" ml="6px">
            {t('header.referral')}
          </Text>
        </LinkV2>
        {user.is_admin && (
          <Link
            display="flex"
            href={`/admin`}
            px={{ base: '20px', md: '32px' }}
            py="8px"
            _hover={{ fontWeight: '700', color: 'black' }}
            size={{ base: 'xl', md: 'md' }}
            alignItems="center"
            color={color}
          >
            <Text size={{ base: 'xl', md: 'lg' }} as="span" ml="6px">
              {t('header.admin')}
            </Text>
          </Link>
        )}
      </Flex>
    </>
  )
}
