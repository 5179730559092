'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'WasteReduction',
  viewBox: '0 0 20 21',
  path: (
    <>
      <path
        d="M19.5 1.4H0.5C0.2 1.4 0 1.6 0 1.9C0 2.2 0.2 2.4 0.5 2.4H1.5L3 18.9C3 19.6 3.6 20.1 4.2 20.1H15.4C16.1 20.1 16.6 19.6 16.6 18.9L18.4 2.4H19.4C19.7 2.4 19.9 2.2 19.9 1.9C19.9 1.6 19.8 1.4 19.5 1.4ZM15.7 18.8C15.7 18.9 15.6 19.1 15.5 19.1H4.2C4.1 19.1 4 19 4 18.8L2.5 2.4H17.5L15.7 18.8Z"
        fill="currentColor"
      />
      <path
        d="M8.3 1H11.6C11.9 1 12.1 0.8 12.1 0.5C12.1 0.2 11.9 0 11.6 0H8.3C8.1 0 7.8 0.2 7.8 0.5C7.8 0.8 8.1 1 8.3 1Z"
        fill="currentColor"
      />
      <path
        d="M8.6 15L8.3 15.2C8 15.5 7.9 15.8 8.1 16C8.2 16.1 8.4 16.2 8.5 16.2C8.6 16.2 8.7 16.2 8.8 16.1L10.1 15.2C10.1 15.2 10.1 15.2 10.2 15.1C10.2 15.1 10.2 15.1 10.3 15V14.8C10.3 14.8 10.3 14.8 10.3 14.7V14.5C10.3 14.5 10.3 14.5 10.3 14.4L9.5 13.2C9.2 13 8.9 13 8.7 13.1C8.5 13.3 8.4 13.6 8.6 13.8L8.7 14C7.5 13.6 6.5 12.5 6.2 11.2C5.8 9.6 6.5 7.8 8 6.9C8.2 6.8 8.3 6.5 8.2 6.2C8.1 6 7.8 5.9 7.5 6C5.6 7.1 4.7 9.3 5.2 11.4C5.6 13.2 6.9 14.5 8.6 15Z"
        fill="currentColor"
      />
      <path
        d="M9.7 5.9V6.1C9.7 6.1 9.7 6.1 9.7 6.2L10.5 7.4C10.6 7.5 10.8 7.6 10.9 7.6C11 7.6 11.1 7.6 11.2 7.5C11.4 7.3 11.5 7 11.3 6.8L11.1 6.6C12.3 7 13.3 8.1 13.6 9.4C14 11.1 13.3 12.8 11.8 13.7C11.6 13.8 11.5 14.2 11.6 14.4C11.7 14.6 11.9 14.6 12 14.6C12.1 14.6 12.2 14.6 12.3 14.5C14.1 13.4 15 11.2 14.5 9.1C14.1 7.4 12.8 6 11.1 5.5L11.4 5.3C11.6 5.1 11.7 4.8 11.5 4.6C11.3 4.4 11 4.3 10.8 4.5L10 5.4C9.9 5.5 9.8 5.6 9.7 5.7C9.7 5.8 9.7 5.8 9.7 5.9C9.7 5.8 9.7 5.8 9.7 5.9Z"
        fill="currentColor"
      />
    </>
  ),
})
