'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'PinMap',
  viewBox: '0 0 11 14',
  path: (
    <>
      <path
        d="M5.98896 0.333344C4.65747 0.333344 3.45912 0.80001 2.52707 1.66668C1.52845 2.60001 0.99585 3.93334 0.99585 5.60001C0.99585 8.13334 5.05692 12.6 5.98896 13.6667C6.98759 12.6 10.9821 8.20001 10.9821 5.60001C10.9821 2.00001 8.38566 0.333344 5.98896 0.333344ZM5.98896 7.13334C4.85719 7.13334 3.99172 6.26668 3.99172 5.13334C3.99172 4.00001 4.85719 3.13334 5.98896 3.13334C7.12074 3.13334 7.98621 4.00001 7.98621 5.13334C7.98621 6.26668 7.12074 7.13334 5.98896 7.13334Z"
        fill="currentColor"
      />
    </>
  ),
})
