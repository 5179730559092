'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RightArrowInnocent',
  viewBox: '0 0 30 13',
  path: (
    <>
      <path
        d="M0.5 6.56939C0.5 6.03012 0.947715 5.59296 1.5 5.59296H26.5C27.0523 5.59296 27.5 6.03012 27.5 6.56939C27.5 7.10866 27.0523 7.54583 26.5 7.54583H1.5C0.947715 7.54583 0.5 7.10866 0.5 6.56939Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7929 12.714C21.4024 12.3327 21.4024 11.7144 21.7929 11.3331L26.7426 6.5L21.7929 1.66688C21.4024 1.28556 21.4024 0.667315 21.7929 0.285992C22.1834 -0.0953307 22.8166 -0.0953307 23.2071 0.285992L28.864 5.80955C29.2545 6.19088 29.2545 6.80912 28.864 7.19045L23.2071 12.714C22.8166 13.0953 22.1834 13.0953 21.7929 12.714Z"
        fill="currentColor"
      />
    </>
  ),
})
