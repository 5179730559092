'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AmountCircle',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="white"
      />
      <path
        d="M14.36 8.21C13.8905 8.01781 13.3873 7.92133 12.88 7.92622C12.3727 7.9311 11.8715 8.03726 11.4057 8.23845C10.94 8.43965 10.5191 8.73183 10.1679 9.09786C9.81656 9.46389 9.5419 9.8964 9.36 10.37H8.59C8.4574 10.37 8.33022 10.4227 8.23645 10.5164C8.14268 10.6102 8.09 10.7374 8.09 10.87C8.09256 11.0018 8.14607 11.1275 8.23928 11.2207C8.3325 11.3139 8.4582 11.3674 8.59 11.37H9.09C9.04415 11.5774 9.01407 11.788 9 12C9.00417 12.2112 9.02423 12.4218 9.06 12.63H8.56C8.4282 12.6326 8.3025 12.6861 8.20928 12.7793C8.11606 12.8725 8.06256 12.9982 8.06 13.13C8.06 13.2626 8.11268 13.3898 8.20645 13.4836C8.30022 13.5773 8.4274 13.63 8.56 13.63H9.34C9.62734 14.342 10.1184 14.9533 10.7517 15.3873C11.385 15.8214 12.1323 16.0589 12.9 16.07C13.2937 16.0704 13.685 16.0097 14.06 15.89C14.1844 15.8466 14.2871 15.7568 14.3468 15.6394C14.4064 15.522 14.4183 15.386 14.38 15.26C14.3608 15.1969 14.329 15.1383 14.2866 15.0877C14.2442 15.0372 14.192 14.9957 14.1332 14.9658C14.0743 14.936 14.0101 14.9183 13.9442 14.9138C13.8784 14.9094 13.8123 14.9183 13.75 14.94C13.4744 15.0239 13.1881 15.0677 12.9 15.07C12.403 15.0636 11.9164 14.9273 11.4884 14.6747C11.0604 14.4221 10.7058 14.0619 10.46 13.63H11.8C11.9326 13.63 12.0598 13.5773 12.1536 13.4836C12.2473 13.3898 12.3 13.2626 12.3 13.13C12.2974 12.9982 12.2439 12.8725 12.1507 12.7793C12.0575 12.6861 11.9318 12.6326 11.8 12.63H10.1C10.0067 12.2152 10.0067 11.7848 10.1 11.37H11.8C11.9318 11.3674 12.0575 11.3139 12.1507 11.2207C12.2439 11.1275 12.2974 11.0018 12.3 10.87C12.3 10.7374 12.2473 10.6102 12.1536 10.5164C12.0598 10.4227 11.9326 10.37 11.8 10.37H10.49C10.7358 9.93806 11.0904 9.57792 11.5184 9.32532C11.9464 9.07272 12.433 8.93644 12.93 8.93C13.2972 8.92733 13.6611 8.99876 14 9.14C14.1223 9.18713 14.258 9.18537 14.379 9.1351C14.5001 9.08483 14.5971 8.9899 14.65 8.87C14.6963 8.74384 14.692 8.60464 14.6379 8.48159C14.5839 8.35854 14.4843 8.26121 14.36 8.21Z"
        fill="white"
      />
    </>
  ),
})
