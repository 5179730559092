'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoMousline',
  viewBox: '0 0 444.8 215.8',
  path: (
    <>
      <g>
        <g>
          <path
            fill="#2B4F9E"
            d="M148.6,183.5c-10.3-0.9-20.5-1.5-30.8-1.7c-10.3-0.2-20.6,0-30.8,1.4c-5.1,0.7-10.1,1.6-15.1,3.1
			c-4.9,1.5-9.7,3.5-14,6.2c0,0-3.7,2.4-7.5,3.3c0,0-0.3,0.1-0.3,0.1s0.4-0.1,0.5-0.1c3.1-0.9,12.1-3.3,15.5-4.1
			c22.7-5.8,54.9-4.1,90.8-0.6v-6.7C154.1,184,151.3,183.7,148.6,183.5z"
          />
          <path
            fill="#FFFFFF"
            d="M240.9,192.7c-20.6-1.7-41.1-4.1-61.6-6.2c-7.5-0.8-14.9-1.6-22.4-2.3v6.7c1.1,0.1,2.1,0.2,3.2,0.3
			c40.3,4,85,10,126.1,10.2V195C271,194.9,255.9,194,240.9,192.7z"
          />
          <path
            fill="#E8423B"
            d="M426,64.1c0.5,6.4,0.8,12.8,0.7,19.2c0,2.6-0.2,5.2-0.3,7.8c-0.2,2.6-0.4,5.2-0.7,7.8
			c-0.6,5.2-1.4,10.4-2.6,15.5c-1.2,5.1-2.7,10.2-4.5,15.1c-1.9,4.9-4,9.8-6.6,14.4c-2.6,4.6-5.6,9-9,13.2
			c-3.4,4.1-7.2,7.9-11.2,11.3c-8.1,6.9-17.6,12.1-27.4,15.9c-4.9,1.9-10,3.4-15.1,4.7c-5.1,1.3-10.2,2.2-15.4,3
			c-5.2,0.8-10.3,1.4-15.5,1.9c-5.2,0.4-10.4,0.8-15.6,0.9c-5.6,0.2-11.1,0.2-16.7,0.2v6.4c3.4,0,6.7,0,10-0.1
			c23-0.5,44.6-3,63.3-9.2c55.6-18.2,67.7-67.8,67.8-107.4C427.3,77.5,426.8,70.5,426,64.1z"
          />
        </g>
        <g>
          <path
            fill="#FFE200"
            d="M419.4,31.6c-0.1-0.6-0.2-1.2-0.5-1.7c-0.4-1.1-1.2-2.1-2.1-2.8c-1-0.7-2.2-1.2-3.4-1.3c-0.7,0-1.4,0.1-2,0.3
			c-0.3,0.1-0.6,0.2-1,0.4l-1,0.5l-3.9,1.8c-1.3,0.6-2.7,1.1-4,1.7c-1.3,0.6-2.7,1.1-4,1.6c-2.7,1.1-5.5,2-8.2,3
			c-2.8,0.9-5.5,1.8-8.3,2.6c-11.2,3.2-22.7,5.3-34.3,6.6c-5.8,0.6-11.6,1-17.4,1.2c-5.8,0.2-11.7,0.2-17.5,0
			c-5.8-0.1-11.6-0.5-17.4-1c-5.8-0.5-11.6-1.1-17.4-1.8c-5.8-0.7-11.5-1.6-17.3-2.5c-5.7-0.9-11.4-1.9-17.2-3
			c-11.4-2.1-22.7-4.5-34.1-6.9c-22.6-4.7-45.3-9.4-68.1-11c-11.4-0.8-22.9-0.7-34.2,0.9l-2.1,0.3c-10.3,1.7-17.5,4.2-25.7,9.2
			c-0.6,0.4-1.3,0.8-1.9,1.1l-1.7,1.1C70,35,65.6,38.7,61.5,42.7l-3,3l-2.8,3.2c-1,1-1.9,2.2-2.7,3.3l-1.3,1.7
			c-0.4,0.6-0.9,1.1-1.3,1.7L47.8,59c-0.4,0.6-0.8,1.2-1.2,1.8l-1.2,1.8c-1.6,2.4-3,4.9-4.5,7.3c-0.7,1.2-1.4,2.5-2,3.8l-1,1.9
			c-0.3,0.6-0.6,1.3-0.9,1.9l-1.9,3.9l-1.7,3.9l-0.8,2l-0.8,2c-0.5,1.3-1,2.7-1.5,4c-0.9,2.7-1.9,5.4-2.7,8.2
			c-1.7,5.5-3.1,11.1-4.2,16.7l-0.8,4.2c-0.2,1.4-0.4,2.8-0.7,4.3l-0.3,2.1l-0.2,2.1c-0.2,1.4-0.3,2.9-0.5,4.3
			c-0.5,5.7-0.7,11.5-0.5,17.2c0.2,5.7,0.8,11.5,1.9,17.1c0.5,2.8,1.3,5.6,2.1,8.3c0.9,2.7,1.8,5.5,3.4,7.8c1.5,2.4,3.5,4.5,5.8,6.1
			c2.3,1.6,4.9,2.8,7.7,3.4c2.8,0.6,5.7,0.5,8.5-0.1c2.8-0.6,5.4-1.7,7.9-3.2c4.4-2.8,9.1-4.9,14.1-6.5c4.9-1.5,10-2.6,15.1-3.4
			c10.2-1.5,20.6-1.9,30.9-1.9c10.3,0,20.7,0.5,31,1.2c10.3,0.7,20.6,1.5,30.9,2.4c20.5,1.9,41,4,61.6,5.4
			c20.5,1.4,41.1,2.3,61.6,1.3c5.1-0.2,10.2-0.6,15.3-1.1c5.1-0.5,10.1-1.2,15.2-2c5-0.9,10-1.9,14.9-3.2c4.9-1.3,9.7-2.9,14.4-4.8
			c9.3-3.7,18.1-8.8,25.7-15.2c3.8-3.2,7.3-6.8,10.5-10.6c3.2-3.8,6-7.9,8.6-12.3c2.5-4.3,4.7-8.9,6.6-13.5
			c1.8-4.7,3.4-9.5,4.6-14.4c2.5-9.8,3.8-19.9,4.4-30.1c0.5-10.2,0.3-20.4-0.6-30.7c-0.4-5.1-1-10.2-1.8-15.3
			c-0.2-1.3-0.4-2.5-0.6-3.8L419.4,31.6z"
          />
          <path
            fill="#FFFFFF"
            d="M424.7,52.3C423.9,47.1,423,42,422,37c-0.3-1.3-0.5-2.5-0.8-3.8l-0.4-1.9c-0.1-0.6-0.3-1.3-0.7-1.9
			c-0.6-1.3-1.6-2.4-2.7-3.1c-1.2-0.7-2.5-1.2-3.8-1.3c-0.8,0-1.4,0.1-2.2,0.3c-0.4,0.1-0.7,0.2-1.1,0.4l-1,0.4l-4,1.6
			c-2.7,1-5.4,2-8.2,2.8c-2.7,0.9-5.5,1.7-8.3,2.5c-2.8,0.7-5.6,1.5-8.4,2.1c-5.6,1.3-11.2,2.4-16.9,3.2c-5.7,0.8-11.4,1.5-17.1,1.9
			c-5.7,0.4-11.5,0.6-17.2,0.7c-5.7,0-11.5-0.1-17.2-0.4c-11.5-0.6-22.9-1.7-34.3-3.4c-11.4-1.6-22.7-3.5-34.1-5.6
			c-11.3-2.1-22.7-4.3-34-6.6c-11.4-2.2-22.8-4.3-34.2-6.1c-11.5-1.8-23-3.3-34.7-4c-11.6-0.7-23.4-0.5-35.1,1.4l-2.2,0.4
			c-9.2,1.7-16,3.5-26.9,10c-0.6,0.4-1.3,0.8-1.9,1.2L72.7,29c-4.9,3.3-9.4,7.2-13.5,11.3l-3.1,3.2l-2.9,3.3c-1,1.1-1.9,2.3-2.8,3.4
			l-1.4,1.7c-0.5,0.6-0.9,1.2-1.3,1.8l-2.5,3.6c-0.4,0.6-0.8,1.2-1.2,1.8l-1.2,1.9c-1.6,2.4-3,5-4.5,7.5c-0.7,1.3-1.3,2.6-2,3.9
			l-1,1.9c-0.3,0.7-0.6,1.3-0.9,2l-1.8,4l-1.7,4l-0.8,2l-0.8,2.1c-0.5,1.4-1,2.7-1.5,4.1c-0.9,2.8-1.9,5.5-2.6,8.3
			c-1.7,5.6-2.9,11.3-4,17l-0.7,4.3c-0.2,1.4-0.4,2.9-0.6,4.3l-0.3,2.2l-0.2,2.2c-0.1,1.4-0.3,2.9-0.4,4.3
			c-0.4,5.8-0.5,11.6-0.2,17.4c0.3,5.8,1.1,11.6,2.2,17.3c0.6,2.7,1.1,4.6,2.3,8.4c1.2,3.7,2.4,6.2,3.6,8c1.7,2.5,3.7,4.6,6.1,6.3
			c2.4,1.7,5.2,2.9,8.1,3.4c2.9,0.6,5.9,0.5,8.8-0.2c2.9-0.7,5.6-1.9,8-3.4c4.3-2.7,9.1-4.7,14-6.2c4.9-1.4,10-2.4,15.1-3.1
			c10.2-1.3,20.5-1.6,30.8-1.4c10.3,0.2,20.6,0.9,30.8,1.7c10.3,0.8,20.5,1.9,30.7,3c20.5,2.1,41,4.5,61.6,6.2
			c20.6,1.7,41.3,2.8,62,2.2c5.2-0.2,10.4-0.5,15.6-0.9c5.2-0.4,10.4-1,15.5-1.9c5.2-0.8,10.3-1.8,15.4-3c5.1-1.3,10.1-2.8,15.1-4.7
			c9.8-3.8,19.3-9,27.4-15.9c4.1-3.4,7.8-7.2,11.2-11.3c3.4-4.1,6.4-8.5,9-13.2c2.6-4.6,4.8-9.5,6.6-14.4c1.8-5,3.3-10,4.5-15.1
			c1.1-5.1,2-10.3,2.6-15.5c0.3-2.6,0.5-5.2,0.7-7.8c0.1-2.6,0.3-5.2,0.3-7.8C426.8,72.9,426.1,62.5,424.7,52.3z M422.8,83.2
			c-0.6,10.2-1.9,20.3-4.4,30.1c-1.3,4.9-2.8,9.7-4.6,14.4c-1.9,4.7-4,9.2-6.6,13.5c-2.5,4.3-5.4,8.4-8.6,12.3
			c-3.2,3.8-6.7,7.4-10.5,10.6c-7.6,6.5-16.4,11.5-25.7,15.2c-4.7,1.9-9.5,3.4-14.4,4.8c-4.9,1.3-9.9,2.4-14.9,3.2
			c-5,0.9-10.1,1.5-15.2,2c-5.1,0.5-10.2,0.9-15.3,1.1c-20.5,0.9-41,0.1-61.6-1.3c-20.5-1.4-41-3.5-61.6-5.4
			c-10.3-0.9-20.6-1.8-30.9-2.4c-10.3-0.7-20.6-1.1-31-1.2c-10.3,0-20.7,0.4-30.9,1.9c-5.1,0.8-10.2,1.8-15.1,3.4
			c-4.9,1.6-9.7,3.7-14.1,6.5c-2.4,1.5-5.1,2.6-7.9,3.2c-2.8,0.6-5.7,0.6-8.5,0.1c-2.8-0.6-5.4-1.7-7.7-3.4
			c-2.3-1.6-4.2-3.8-5.8-6.1c-1.6-2.4-2.4-5.1-3.4-7.8c-0.8-2.7-1.6-5.5-2.1-8.3c-1.1-5.6-1.8-11.4-1.9-17.1
			c-0.2-5.7,0-11.5,0.5-17.2c0.1-1.4,0.3-2.9,0.5-4.3l0.2-2.1l0.3-2.1c0.2-1.4,0.4-2.8,0.7-4.3l0.8-4.2c1.2-5.6,2.5-11.2,4.2-16.7
			c0.8-2.8,1.8-5.5,2.7-8.2c0.5-1.3,1-2.7,1.5-4l0.8-2l0.8-2l1.7-3.9l1.9-3.9c0.3-0.6,0.6-1.3,0.9-1.9l1-1.9c0.7-1.3,1.3-2.5,2-3.8
			c1.5-2.5,2.8-5,4.5-7.3l1.2-1.8c0.4-0.6,0.8-1.2,1.2-1.8l2.5-3.5c0.4-0.6,0.9-1.1,1.3-1.7l1.3-1.7c0.9-1.1,1.8-2.2,2.7-3.3
			l2.8-3.2l3-3c4.1-3.9,8.4-7.7,13.2-10.7l1.7-1.1c0.6-0.4,1.3-0.8,1.9-1.1c8.2-5,15.3-7.5,25.7-9.2l2.1-0.3
			c11.3-1.6,22.8-1.7,34.2-0.9c22.9,1.6,45.5,6.3,68.1,11c11.3,2.3,22.7,4.7,34.1,6.9c5.7,1.1,11.4,2.1,17.2,3
			c5.7,0.9,11.5,1.7,17.3,2.5c5.8,0.7,11.6,1.3,17.4,1.8c5.8,0.5,11.6,0.8,17.4,1c5.8,0.1,11.7,0.1,17.5,0
			c5.8-0.2,11.6-0.6,17.4-1.2c11.6-1.2,23.1-3.4,34.3-6.6c2.8-0.8,5.6-1.7,8.3-2.6c2.7-1,5.5-1.9,8.2-3c1.4-0.5,2.7-1,4-1.6
			c1.3-0.6,2.7-1.1,4-1.7l3.9-1.8l1-0.5c0.3-0.2,0.6-0.3,1-0.4c0.7-0.2,1.3-0.3,2-0.3c1.2,0.1,2.4,0.6,3.4,1.3
			c1,0.7,1.7,1.7,2.1,2.8c0.2,0.6,0.4,1.1,0.5,1.7l0.3,1.9c0.2,1.3,0.4,2.5,0.6,3.8c0.8,5.1,1.4,10.2,1.8,15.3
			C423.1,62.8,423.3,73,422.8,83.2z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#D50911"
              d="M134.1,149.8h5.7c3.4,0,5.5,1.9,5.5,4.8v0c0,3.2-2.6,4.9-5.8,4.9h-2.9v4.6h-2.5V149.8z M139.6,157.3
				c1.9,0,3.1-1.1,3.1-2.6v0c0-1.7-1.2-2.6-3.1-2.6h-3v5.2H139.6z"
            />
            <path fill="#D50911" d="M146.4,149.3h2.7v2.4h-2.7V149.3z M146.5,153.3h2.5v10.9h-2.5V153.3z" />
            <path
              fill="#D50911"
              d="M150.4,158.8L150.4,158.8c0-3.1,2.4-5.7,5.7-5.7c2,0,3.3,0.8,4.4,1.9l-1.6,1.7c-0.8-0.8-1.6-1.4-2.8-1.4
				c-1.8,0-3.2,1.6-3.2,3.5v0c0,1.9,1.3,3.5,3.3,3.5c1.2,0,2-0.5,2.8-1.3l1.5,1.5c-1.1,1.2-2.3,2-4.5,2
				C152.8,164.5,150.4,161.9,150.4,158.8z"
            />
            <path
              fill="#D50911"
              d="M160.8,161.1L160.8,161.1c0-2.4,1.9-3.6,4.5-3.6c1.2,0,2.1,0.2,2.9,0.5v-0.3c0-1.5-1-2.4-2.7-2.4
				c-1.2,0-2.1,0.3-3.1,0.7l-0.7-2c1.2-0.5,2.4-0.9,4.1-0.9c1.6,0,2.9,0.4,3.6,1.2c0.8,0.8,1.2,1.9,1.2,3.4v6.5h-2.4v-1.4
				c-0.7,0.9-1.9,1.6-3.6,1.6C162.7,164.4,160.8,163.3,160.8,161.1z M168.3,160.3v-0.7c-0.6-0.2-1.5-0.4-2.5-0.4
				c-1.6,0-2.6,0.7-2.6,1.8v0c0,1.1,0.9,1.7,2.1,1.7C167.1,162.6,168.3,161.7,168.3,160.3z"
            />
            <path
              fill="#D50911"
              d="M172.4,153.3h2.5v2.5c0.7-1.6,1.9-2.7,3.9-2.7v2.6h-0.1c-2.2,0-3.7,1.4-3.7,4.3v4.2h-2.5V153.3z"
            />
            <path
              fill="#D50911"
              d="M178.9,158.8L178.9,158.8c0-3.7,2.5-5.7,5.1-5.7c1.8,0,2.9,0.9,3.7,1.9v-5.9h2.5v15.1h-2.5v-1.8
				c-0.8,1.1-1.9,2-3.7,2C181.4,164.4,178.9,162.4,178.9,158.8z M187.7,158.8L187.7,158.8c0-2.1-1.5-3.5-3.2-3.5
				c-1.7,0-3.1,1.3-3.1,3.5v0c0,2.1,1.4,3.5,3.1,3.5C186.2,162.3,187.7,160.9,187.7,158.8z"
            />
            <path
              fill="#D50911"
              d="M191.5,158.8L191.5,158.8c0-3.2,2.2-5.7,5.3-5.7c3.5,0,5.2,2.7,5.2,5.9c0,0.2,0,0.5,0,0.7h-8
				c0.3,1.8,1.5,2.8,3.1,2.8c1.2,0,2.1-0.5,3-1.3l1.5,1.3c-1,1.2-2.5,2-4.5,2C193.9,164.5,191.5,162.2,191.5,158.8z M199.6,158
				c-0.2-1.6-1.1-2.9-2.8-2.9c-1.5,0-2.6,1.2-2.8,2.9H199.6z"
            />
            <path fill="#D50911" d="M277.2,152.2l-2.6,0.7l-0.5-2.1l3.9-1.2h1.8v14.6h-2.5V152.2z" />
            <path
              fill="#D50911"
              d="M290.2,157.6c-0.8,1-1.9,1.5-3.4,1.5c-3.1,0-5.1-1.8-5.1-4.6v0c0-2.8,2.2-5,5.4-5c1.8,0,3,0.5,4,1.5
				c1.1,1.1,1.7,2.6,1.7,5.5v0c0,4.6-2.3,7.9-6.2,7.9c-2,0-3.4-0.7-4.6-1.7l1.4-1.9c1.1,0.9,2.1,1.4,3.3,1.4
				C289.2,162.2,290.2,159.5,290.2,157.6z M290.1,154.4L290.1,154.4c0-1.5-1.2-2.7-3-2.7c-1.8,0-2.9,1.2-2.9,2.7v0
				c0,1.5,1.2,2.6,3,2.6C289,157,290.1,155.8,290.1,154.4z"
            />
            <path
              fill="#D50911"
              d="M295.7,162.9c-1.1-1.1-1.8-2.6-1.8-5.5v0c0-4.4,2.1-7.9,6.2-7.9c1.9,0,3.2,0.6,4.4,1.6l-1.4,2
				c-1-0.8-1.9-1.3-3.1-1.3c-2.5,0-3.4,2.6-3.5,4.6c0.8-0.9,1.9-1.5,3.5-1.5c2.8,0,5,1.7,5,4.5v0c0,2.9-2.3,4.9-5.4,4.9
				C298,164.5,296.7,163.9,295.7,162.9z M302.6,159.7L302.6,159.7c0-1.5-1.2-2.6-3-2.6c-1.8,0-2.9,1.2-2.9,2.5v0
				c0,1.5,1.2,2.6,3,2.6C301.5,162.3,302.6,161.1,302.6,159.7z"
            />
            <path
              fill="#D50911"
              d="M305.8,161.9l1.8-1.7c1,1.2,2.2,1.9,3.8,1.9c1.4,0,2.5-0.9,2.5-2.2v0c0-1.4-1.3-2.2-3.3-2.2h-1.2l-0.4-1.6
				l3.9-4.2h-6.3v-2.2h9.6v1.9l-4,4.1c2.2,0.3,4.3,1.4,4.3,4v0c0,2.7-2,4.6-5.1,4.6C308.8,164.5,307,163.4,305.8,161.9z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D50911"
                  d="M401.5,91L401.5,91c0.4-0.2,0.7-0.5,0.7-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.1-0.5-0.3-1.2-0.3
						c-0.6,0-1,0-1.3,0.1v3.4h0.8v-1.4h0.4c0.4,0,0.6,0.2,0.7,0.5c0.1,0.4,0.2,0.7,0.3,0.8h0.9c-0.1-0.1-0.1-0.3-0.3-0.9
						C402,91.4,401.8,91.1,401.5,91z M400.6,90.7h-0.4v-1c0.1,0,0.2,0,0.4,0c0.5,0,0.7,0.2,0.7,0.5C401.4,90.6,401,90.7,400.6,90.7z
						"
                />
                <path
                  fill="#D50911"
                  d="M400.7,87.5c-2,0-3.5,1.5-3.5,3.4c0,1.9,1.6,3.5,3.5,3.5c2,0,3.5-1.5,3.5-3.5
						C404.2,89,402.7,87.5,400.7,87.5z M400.7,93.6c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.1-2.7,2.7-2.7s2.6,1.2,2.6,2.8
						C403.3,92.4,402.2,93.6,400.7,93.6z"
                />
                <path
                  fill="#D50911"
                  d="M131.7,139.1c-8.1,0-11.6-16.9-11.6-29.1c0-11.9,3.1-24.7,4.6-30.1c0.4-1.3-0.6-2.6-2-2.6h-8.8
						c-4.6,0-15.5,32.4-19,32.4c-1.3,0-1.8-1.3-1.8-3.2c0-5.9,3.4-22.1,3.4-34.1c0-7.4-1.8-12.3-8.7-12.3
						c-19.4,0-39.9,45.1-39.9,70.8c0,8.8,1.3,15.2,7.5,15.2c7.4,0,14.6-4.6,14.6-11.5c0-7-2.1-8.2-2.1-18.8c0-14.6,5.9-35.7,12-35.7
						c1.5,0,1.5,2.1,1.5,3.9c0,5.1-3,15.8-3,28.3c0,9.4,2.6,16.5,10.9,16.5c7.1,0,15.3-13.6,17.4-20.3c0.2,0,0.1,0,0.3,0.1
						c0,18.9,3.6,41.2,17.1,41.2c2.9,0,8.5-7,8.5-9.8C132.7,139.4,132.3,139.1,131.7,139.1z"
                />
                <path
                  fill="#D50911"
                  d="M396,117.9l-5.8-2.3c-1.7-0.7-3.7,0-4.6,1.7c-2.4,4.3-5.8,7-10.7,7c-1.2,0-2.9-0.5-3.9-1.1
						c0,0,18.1-16.5,20.4-18.7c2.6-2.5,2.7-6.5,0.4-9.1c-4.1-4.8-10.1-7.8-17-7.8c-13,0-22.8,10.8-22.8,24c0,4.8,1.3,9.2,3.5,13
						c-1.2,1.3-2.6,2.1-4,2.1c-3.5,0-4.6-3.1-4.6-6.7c0-2.8,0-13.9,0-13.9c0-11.4-7.2-18.6-18.6-18.6c-4.2,0-8.9,1.7-11.7,4.6
						l-3.3-2.9c-0.4-0.3-0.9-0.5-1.4-0.5h-3c-1.1,0-2,0.9-2,2l0,31.3c-0.6,2.8-2.8,4.6-5.1,4.6c-3.4,0-4.8-2.3-4.8-6.3l0-29.6
						c0-1.1-0.9-2.1-2.1-2.1h-8.2c-1.1,0-2.1,0.9-2.1,2.1l0,30.3c-0.3,3.1-2.5,5.6-5.1,5.6c-3.1,0-4.6-2.6-4.6-6.3l0-50.7
						c0-1.1-0.9-2.1-2.1-2.1h-8.2c-1.1,0-2.1,0.9-2.1,2.1l0,52.8c0,8.2,3.9,13.1,13,13.1c3.6,0,8.3-2.4,10.6-5.5
						c1.8,3.5,5.1,5.5,10.2,5.5c4.6,0,8.6-2.3,10.5-5.2v1.9c0,1.1,0.9,2.1,2.1,2.1h8.3c1.1,0,2.1-0.9,2.1-2.1l-0.1-26.1
						c0-4,3.3-7.5,7.2-7.5c5.4,0,8,4.4,8,9.6v9.3l0,4.5c0,9.3,4.4,13.5,12.6,13.5c5.4,0,9.8-2.5,12.9-5.5c3.9,3.4,9,5.5,14.7,5.5
						c10.6,0,18.4-6.3,22.2-14.9C397.6,119.6,397,118.4,396,117.9z M364.7,111.5c0-6.4,4.5-12.4,10.6-12.4c1.4,0,2.7,0.2,4,0.9
						c1.3,0.6,1.6,2.3,0.6,3.3l-14,14C365.1,115.6,364.7,113.6,364.7,111.5z"
                />
                <path
                  fill="#D50911"
                  d="M290.4,83.8c7.3,0,13-6,13-12.9c0-2.8-1.1-4.8-3.8-4.8c-3.1,0-4.4,2.3-6.1,2.3c-6.2,0-11.3,2.8-11.3,8.1
						C282.2,81.2,286.1,83.8,290.4,83.8z"
                />
                <path
                  fill="#D50911"
                  d="M234.1,100.3c0-2,2.2-3,4.9-3c2.7,0,5.1,0.7,7.7,2c2.4,1.2,5.3,0.3,6.4-2.2l1.7-3.9
						c0.5-1.1-0.1-2.4-1.2-2.8c-4.9-1.8-9.8-3-15.8-3c-8.9,0-16.6,5.9-16.6,13.9c0,15.3,25.2,12.8,25.2,19.9c0,2.4-2.3,3.7-5.2,3.7
						c-4.7,0-10.1-2.8-15.1-5.6c-1-0.6-2.3-0.1-2.9,0.9c0,0,0,0.1-0.1,0.1l-0.5,1.1c-1.1,2.4-2.8,4.6-4.6,4.6
						c-1.5,0-2.7-1.3-2.7-3.3l0-2.2V90.7c0-1.1-0.9-2.1-2.1-2.1h-8.3c-1.1,0-2.1,0.9-2.1,2.1l0.1,26.1c0,4-3.3,7.5-7.2,7.5
						c-5.4,0-8-4.4-8-9.6l0-24c0-1.1-0.9-2.1-2.1-2.1h-8.2c-1.1,0-2.1,0.9-2.1,2.1l0,1.4c0,5.2-2.4,8.7-6.4,10.7
						c-3.2-9-11.4-15.3-21.3-15.3c-13,0-22.8,10.8-22.8,24c0,13.3,9.9,24,22.8,24c13,0,22.8-10.8,22.8-24c0-0.8,0-1.5-0.1-2.3
						c1.9-0.5,3.5-1.2,5-2.1l0,9.8c0,11.4,7.2,18.6,18.6,18.6c4.2,0,8.9-1.8,11.7-4.7l0,0c1.6,3.1,4.3,4.6,8.1,4.6
						c3.5,0,6.7-2.5,8.5-5.4c5.6,3,11.9,5.4,17.5,5.4c10.2,0,19.5-4.6,19.5-15.4C259.5,105.1,234.1,105.7,234.1,100.3z M147.8,123.3
						c-5.7,0-9.8-5.3-9.8-11.8c0-6.5,4-11.8,9.8-11.8c1,0,2,0.2,2.9,0.5c-0.4,1.4-0.4,3.1,0,4.7c0.9,3.6,3.8,6.1,6.8,6.2
						c0,0.1,0,0.3,0,0.4C157.6,118,153.5,123.3,147.8,123.3z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#D50911"
            d="M207.6,158.9L207.6,158.9c0-3.5,2.5-5.6,5.1-5.6c2,0,3.3,1.1,4.1,2.3v-6.4h1.6v15.1h-1.6V162
			c-0.9,1.3-2.1,2.4-4.1,2.4C210.1,164.4,207.6,162.4,207.6,158.9z M216.8,158.8L216.8,158.8c0-2.5-1.9-4.2-3.9-4.2
			c-2.1,0-3.8,1.5-3.8,4.1v0c0,2.5,1.7,4.1,3.8,4.1C215,163,216.8,161.3,216.8,158.8z"
          />
          <path
            fill="#D50911"
            d="M220,158.9L220,158.9c0-3.1,2.1-5.6,5.1-5.6c3.2,0,5,2.5,5,5.6c0,0.2,0,0.3,0,0.5h-8.5
			c0.2,2.3,1.9,3.6,3.8,3.6c1.5,0,2.5-0.6,3.4-1.5l1,0.9c-1.1,1.2-2.4,2-4.4,2C222.4,164.4,220,162.2,220,158.9z M228.5,158.2
			c-0.2-1.9-1.3-3.6-3.4-3.6c-1.9,0-3.2,1.6-3.5,3.6H228.5z"
          />
          <path
            fill="#D50911"
            d="M231.7,153.5h1.6v2.1c0.9-1.3,2.1-2.4,4.1-2.4c2.6,0,5.1,2,5.1,5.5v0c0,3.5-2.5,5.6-5.1,5.6
			c-2,0-3.3-1.1-4.1-2.3v5.4h-1.6V153.5z M240.8,158.9L240.8,158.9c0-2.6-1.7-4.2-3.8-4.2c-2,0-3.9,1.7-3.9,4.1v0
			c0,2.5,1.9,4.1,3.9,4.1C239.1,163,240.8,161.4,240.8,158.9z"
          />
          <path
            fill="#D50911"
            d="M244,160.1v-6.6h1.6v6.2c0,2,1.1,3.2,3,3.2c1.8,0,3.2-1.3,3.2-3.3v-6.1h1.6v10.7h-1.6v-1.9
			c-0.7,1.2-1.8,2.1-3.7,2.1C245.5,164.4,244,162.7,244,160.1z"
          />
          <path fill="#D50911" d="M255.7,149.4h1.8v1.8h-1.8V149.4z M255.8,153.5h1.6v10.7h-1.6V153.5z" />
          <path
            fill="#D50911"
            d="M259,162.8l0.8-1.1c1.2,0.9,2.4,1.4,3.7,1.4c1.3,0,2.2-0.6,2.2-1.7v0c0-1.1-1.3-1.5-2.7-1.9
			c-1.7-0.5-3.5-1-3.5-3v0c0-1.8,1.5-3.1,3.6-3.1c1.3,0,2.7,0.5,3.8,1.2l-0.7,1.2c-1-0.6-2.1-1-3.2-1c-1.3,0-2.1,0.7-2.1,1.5v0
			c0,1,1.3,1.4,2.7,1.8c1.7,0.5,3.4,1.1,3.4,3.1v0c0,2-1.7,3.2-3.8,3.2C261.8,164.4,260.2,163.8,259,162.8z"
          />
        </g>
      </g>
    </>
  ),
})
