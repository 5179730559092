'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Twitter',
  viewBox: '0 0 12 10',
  path: (
    <>
      <path
        d="M0.5 8.87805C1.50397 9.60976 2.68254 10 3.94841 10C8.13889 10 10.5397 6.04878 10.3651 2.4878C10.8016 2.14634 11.1944 1.70732 11.5 1.17073C11.1071 1.36585 10.6706 1.5122 10.1905 1.56098C10.6706 1.26829 11.0198 0.731707 11.1944 0.146342C10.7579 0.439025 10.2778 0.634146 9.75397 0.780488C9.36111 0.292683 8.75 0 8.09524 0C6.65476 0 5.56349 1.5122 5.9127 3.12195C4.03572 3.02439 2.37698 2 1.24206 0.487805C0.630951 1.60976 0.936509 3.12195 1.94048 3.85366C1.54762 3.85366 1.19841 3.70732 0.936508 3.56098C0.892857 4.73171 1.67857 5.80488 2.76984 6.04878C2.46428 6.14634 2.11508 6.14634 1.76587 6.09756C2.07143 7.12195 2.90079 7.85366 3.86111 7.85366C2.85714 8.63415 1.67857 9.02439 0.5 8.87805Z"
        fill="currentColor"
      />
    </>
  ),
})
