'use client'

import { captureException } from '@sentry/nextjs'
import { parseCookies } from 'nookies'
import { useEffect, useState } from 'react'

import { MIIMOSA_SIGNATURE } from '@miimosa/common'
import type { PlatformCountryCode, PublicUser } from '@miimosa/common/types'

export const V2_USER = 'v2_user'

// TODO: we should make sure that this is only called once

function getStoredUser(): PublicUser | undefined {
  const item = localStorage?.getItem(V2_USER)
  if (!item || item == 'undefined') return

  try {
    return JSON.parse(item)
  } catch {
    return
  }
}

function setStoredUser(u: PublicUser | undefined) {
  if (!u) {
    localStorage?.removeItem(V2_USER)
    return
  }
  localStorage?.setItem(V2_USER, JSON.stringify(u))
}

async function retrieveUser(platform: PlatformCountryCode): Promise<PublicUser | undefined> {
  const res = await fetch(`/nextapi/users/decrypt?platform=${encodeURIComponent(platform)}`, {
    credentials: 'same-origin',
  })
  try {
    if (res.status === 200 || res.status === 304) {
      return await res.json()
    }
  } catch (err) {
    captureException(err)
  }

  return undefined
}

function useV2User(platform: PlatformCountryCode): PublicUser | undefined {
  // We can't use local storage directly because it should only be set within a useEffect
  // to avoir hydration errors
  const [user, setUser] = useState<PublicUser | undefined>()
  const signature = parseCookies()[MIIMOSA_SIGNATURE]

  useEffect(() => {
    let isMounted = true
    const storedUser = getStoredUser()
    // if signature is empty then there is no session cookie
    // we only do the call if the public user signature is different from the current one

    // Stored user is still accurate
    if (signature && storedUser?.signature === signature) {
      setUser(storedUser)
      return
    }

    retrieveUser(platform).then((u) => {
      if (isMounted) setUser(u)
      setStoredUser(u)
    })

    return () => {
      isMounted = false
    }
  }, [setUser, platform, signature])

  return user
}

export default useV2User
