'use client'

import { createIcon } from '@chakra-ui/icons'

const User = createIcon({
  displayName: 'User',
  viewBox: '0 0 19 19',
  d: 'M12.8333 11C15.1345 11 17 12.8655 17 15.1667V16.8333C17 17.2936 16.6269 17.6667 16.1667 17.6667C15.7064 17.6667 15.3333 17.2936 15.3333 16.8333V15.1667C15.3333 13.786 14.214 12.6667 12.8333 12.6667H6.16667C4.78595 12.6667 3.66667 13.786 3.66667 15.1667V16.8333C3.66667 17.2936 3.29357 17.6667 2.83333 17.6667C2.3731 17.6667 2 17.2936 2 16.8333V15.1667C2 12.8655 3.86548 11 6.16667 11H12.8333ZM9.5 1C11.8012 1 13.6667 2.86548 13.6667 5.16667C13.6667 7.46785 11.8012 9.33333 9.5 9.33333C7.19881 9.33333 5.33333 7.46785 5.33333 5.16667C5.33333 2.86548 7.19881 1 9.5 1ZM9.5 2.66667C8.11929 2.66667 7 3.78596 7 5.16667C7 6.54738 8.11929 7.66667 9.5 7.66667C10.8807 7.66667 12 6.54738 12 5.16667C12 3.78596 10.8807 2.66667 9.5 2.66667Z',
})

export default User
