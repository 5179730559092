'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'SolarAmarenco',
  viewBox: '0 0 76 57',
  path: (
    <>
      <path
        opacity="0.5"
        d="M17.9317 30.7873C15.9734 29.6115 15.9734 27.6874 17.9317 26.5109L56.8296 3.14615C58.7871 1.97043 60.3893 2.87931 60.3893 5.16589V52.1265C60.3893 54.413 58.7871 55.3219 56.8296 54.1462L17.9317 30.7873Z"
        fill="#F28C16"
      />
      <path
        d="M21.3032 47.6382H57.0348L73.8525 11.2491L71.3237 8.39794H35.5929L18.7754 44.7871L21.3032 47.6382ZM58.3455 49.2097H20.3419L16.6276 45.0274L34.2814 6.82642H72.2858L76.0001 11.0088L58.3455 49.2097Z"
        fill="#354774"
      />
      <path
        d="M21.3032 47.6382H57.0348L73.8525 11.2491L71.3237 8.39794H35.5929L18.7754 44.7871L21.3032 47.6382ZM58.3455 49.2097H20.3419L16.6276 45.0274L34.2814 6.82642H72.2858L76.0001 11.0088L58.3455 49.2097Z"
        fill="#354774"
      />
      <path d="M55.224 45.6925H17.7002V44.121H53.9109L70.9145 7.32935L72.6893 7.89064L55.224 45.6925Z" fill="#354774" />
      <path d="M56.9235 48.8895L53.8012 45.3715L55.3369 44.4419L58.4568 47.9599L56.9235 48.8895Z" fill="#354774" />
      <path
        d="M26.5921 39.848H50.3518L62.9133 12.6684H39.1536L26.5921 39.848ZM51.6636 41.4196H23.8286L37.8427 11.0969H65.6768L51.6636 41.4196Z"
        fill="#354774"
      />
      <path d="M62.4533 15.4739H36.6539V17.0454H62.4533V15.4739Z" fill="#354774" />
      <path d="M60.0084 20.3679H34.2133V21.9394H60.0084V20.3679Z" fill="#354774" />
      <path d="M57.7478 25.2622H31.951V26.8337H57.7478V25.2622Z" fill="#354774" />
      <path d="M55.4865 30.1587H29.6897V31.7302H55.4865V30.1587Z" fill="#354774" />
      <path d="M53.2225 35.053H27.3268V36.6245H53.2225V35.053Z" fill="#354774" />
      <path d="M41.2424 48.4241H39.3397V57H41.2424V48.4241Z" fill="#354774" />
      <path d="M52.8308 48.4241H50.928V57H52.8308V48.4241Z" fill="#354774" />
      <path d="M41.2424 48.4241H39.3397V57H41.2424V48.4241Z" fill="#354774" />
      <path d="M48.6815 48.4241H46.7804V57H48.6815V48.4241Z" fill="#354774" />
      <path
        d="M11.5728 7.58397C9.01089 7.58397 6.92375 9.30597 6.92375 11.4254C6.92375 13.5449 9.01089 15.2645 11.5728 15.2645C14.1347 15.2645 16.2217 13.5424 16.2217 11.4254C16.2217 9.30847 14.1372 7.58397 11.5728 7.58397ZM11.5728 16.836C7.95906 16.836 5.021 14.4088 5.021 11.4254C5.021 8.44206 7.95906 6.01245 11.5728 6.01245C15.1866 6.01245 18.122 8.43957 18.122 11.4254C18.122 14.4113 15.1832 16.836 11.5728 16.836Z"
        fill="#354774"
      />
      <path d="M12.5242 2.45776H10.6214V4.19307H12.5242V2.45776Z" fill="#354774" />
      <path d="M12.5242 18.6553H10.6214V20.3906H12.5242V18.6553Z" fill="#354774" />
      <path d="M2.82105 10.6379H0.720703V12.2094H2.82105V10.6379Z" fill="#354774" />
      <path d="M22.423 10.6379H20.3226V12.2094H22.423V10.6379Z" fill="#354774" />
      <path d="M4.71126 6.86712L3.22522 5.64151L4.5702 4.52979L6.05625 5.75539L4.71126 6.86712Z" fill="#354774" />
      <path d="M18.5719 18.3201L17.0859 17.0945L18.4309 15.9836L19.9169 17.2093L18.5719 18.3201Z" fill="#354774" />
      <path d="M4.5702 18.3201L3.22522 17.2085L4.71126 15.9829L6.05625 17.0937L4.5702 18.3201Z" fill="#354774" />
      <path d="M18.4317 6.86712L17.0867 5.75539L18.5727 4.52979L19.9177 5.64151L18.4317 6.86712Z" fill="#354774" />
      <path
        d="M33.7092 11.0521H31.2927C28.8495 11.0521 26.8654 9.41068 26.8654 7.3935C26.8981 6.6859 27.1368 6.00337 27.5522 5.43009C27.9677 4.8568 28.5415 4.41784 29.2031 4.16733C29.2911 2.47442 30.9855 1.11828 33.0584 1.11828C33.4375 1.11727 33.8151 1.1656 34.1817 1.2621C35.255 0.427548 36.5794 -0.0171619 37.9381 0.000765702C40.3863 0.000765702 42.4792 1.35195 43.1251 3.24358C45.5683 3.39325 47.5026 5.08446 47.5026 7.14239H45.5998C45.5998 5.76877 44.0981 4.67541 42.4593 4.81926L41.5087 4.91569L41.4017 4.12998C41.2226 3.36075 40.7755 2.68068 40.1407 2.21204C39.5058 1.74339 38.7248 1.51683 37.9381 1.5731C37.428 1.56351 36.9212 1.65741 36.4482 1.84918C35.9753 2.04095 35.546 2.32665 35.1862 2.68899L34.6882 3.21364L33.98 2.8927C32.66 2.29569 31.1018 3.1554 31.1018 4.30618C31.1054 4.38704 31.116 4.46749 31.1334 4.54653L31.2586 5.20425L30.4949 5.41293C30.0295 5.5067 29.6078 5.75157 29.2953 6.10963C28.9828 6.46769 28.797 6.91892 28.7665 7.3935C28.8308 8.0035 29.1314 8.56396 29.6038 8.95436C30.0761 9.34476 30.6825 9.53394 31.2927 9.48135H33.7068L33.7092 11.0521Z"
        fill="#354774"
      />
      <path
        d="M5.9724 26.1618C5.52894 26.1234 5.08819 26.2606 4.74457 26.544C4.40095 26.8274 4.18174 27.2344 4.1343 27.6776C4.13727 27.7464 4.14739 27.8146 4.1643 27.8813L4.28043 28.5324L3.52748 28.771C3.02908 28.8618 2.58494 29.1417 2.28728 29.5522C1.98963 29.9628 1.86134 30.4725 1.92935 30.9753C2.10996 31.4893 2.45635 31.9285 2.91377 32.2236C3.37118 32.5187 3.91377 32.6529 4.45575 32.605H15.0212C15.5624 32.6304 16.0993 32.4961 16.565 32.2188C17.0308 31.9416 17.4049 31.5335 17.6413 31.0452L17.6571 31.0086C17.8223 30.6442 17.8653 30.2361 17.7797 29.8453C17.6941 29.4544 17.4845 29.1017 17.1822 28.84C16.5598 28.3511 15.7725 28.1225 14.9856 28.2023L14.0417 28.2854L13.9338 27.5063C13.7629 26.778 13.3385 26.1346 12.7368 25.6912C12.1352 25.2478 11.3955 25.0335 10.6504 25.0868C10.1678 25.0772 9.68819 25.1658 9.24081 25.3475C8.79342 25.5292 8.38757 25.8001 8.04784 26.1436L7.54965 26.669L6.83906 26.3481C6.56797 26.2243 6.27357 26.1599 5.97564 26.1593M15.0247 34.174H4.46305C2.28633 34.174 0.395353 32.906 0.0632827 31.2264C-0.055296 30.4515 0.0972304 29.6595 0.49499 28.9844C0.892749 28.3094 1.5112 27.7927 2.24573 27.5221C2.34452 25.8899 3.9808 24.5879 5.98152 24.5879C6.34071 24.5868 6.69856 24.6311 7.0466 24.72C8.07948 23.9224 9.35136 23.4978 10.6555 23.5152C11.7064 23.4607 12.7488 23.7292 13.643 24.2849C14.5372 24.8406 15.2402 25.6568 15.658 26.6242C16.7099 26.6801 17.7173 27.0673 18.5363 27.7308C19.0778 28.1747 19.461 28.7823 19.6287 29.4627C19.7965 30.1431 19.7397 30.8597 19.4668 31.505L19.4486 31.5474C19.0483 32.3702 18.4163 33.0574 17.6303 33.5242C16.8444 33.991 15.939 34.2169 15.0263 34.174"
        fill="#354774"
      />
    </>
  ),
})
