'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Signature',
  viewBox: '0 0 232 48',
  path: (
    <>
      <path
        d="M19.6033 40.4236C19.6033 40.4236 19.5917 40.2007 19.6033 40.4236C20.6346 38.8048 23.0956 34.6522 25.9784 30.0304C26.1893 29.7958 26.1661 29.35 26.377 29.1154C25.0419 29.1857 23.4959 29.4907 22.6058 29.5376C22.3833 29.5493 22.3833 29.5493 22.3717 29.3264C22.3717 29.3264 22.36 29.1035 22.5826 29.0918C23.461 28.822 24.7961 28.7517 26.5646 28.435C26.5414 27.9892 26.7523 27.7546 26.7406 27.5318C26.5663 24.1885 21.8005 22.6516 21.5083 21.326C21.4037 19.32 30.7378 18.6048 38.0808 18.218C41.8635 18.0187 44.9788 17.8546 45.6579 18.0423C45.8805 18.0306 45.8921 18.2534 45.8921 18.2534C45.8921 18.2534 45.9037 18.4763 45.6812 18.4881C45.0136 18.5232 42.3318 18.441 38.7716 18.6286C31.6511 19.0037 21.6611 19.9769 21.7308 21.3143C22.0231 22.6398 26.7772 23.9539 26.9632 27.52C26.9748 27.7429 26.9864 27.9658 27.0096 28.4116L32.3651 19.86C32.3651 19.86 32.3651 19.86 32.5876 19.8483C32.8101 19.8366 32.8217 20.0595 32.8217 20.0595C32.8217 20.0595 32.8217 20.0595 32.8334 20.2824C31.1578 22.3821 29.3062 25.3851 27.4663 28.611C31.0149 28.2006 35.6878 27.9544 36.5778 27.9075C36.8003 27.8958 36.8003 27.8958 36.812 28.1187C36.812 28.1187 36.8236 28.3416 36.6011 28.3533C35.711 28.4002 30.5931 28.6698 27.0445 29.0802L19.8258 40.4119C19.8375 40.6348 19.8375 40.6348 19.6033 40.4236C19.615 40.6465 19.6033 40.4236 19.6033 40.4236Z"
        fill="#2A2A2A"
      />
      <path
        d="M19.6033 40.4236C19.6033 40.4236 19.5917 40.2007 19.6033 40.4236C20.6346 38.8048 23.0956 34.6522 25.9784 30.0304C26.1893 29.7958 26.1661 29.35 26.377 29.1154C25.0419 29.1857 23.4959 29.4907 22.6058 29.5376C22.3833 29.5493 22.3833 29.5493 22.3717 29.3264C22.3717 29.3264 22.36 29.1035 22.5826 29.0918C23.461 28.822 24.7961 28.7517 26.5646 28.435C26.5414 27.9892 26.7523 27.7546 26.7406 27.5318C26.5663 24.1885 21.8005 22.6516 21.5083 21.326C21.4037 19.32 30.7378 18.6048 38.0808 18.218C41.8635 18.0187 44.9788 17.8546 45.6579 18.0423C45.8805 18.0306 45.8921 18.2534 45.8921 18.2534C45.8921 18.2534 45.9037 18.4763 45.6812 18.4881C45.0136 18.5232 42.3318 18.441 38.7716 18.6286C31.6511 19.0037 21.6611 19.9769 21.7308 21.3143C22.0231 22.6398 26.7772 23.9539 26.9632 27.52C26.9748 27.7429 26.9864 27.9658 27.0096 28.4116L32.3651 19.86C32.3651 19.86 32.3651 19.86 32.5876 19.8483C32.8101 19.8366 32.8217 20.0595 32.8217 20.0595C32.8217 20.0595 32.8217 20.0595 32.8334 20.2824C31.1578 22.3821 29.3062 25.3851 27.4663 28.611C31.0149 28.2006 35.6878 27.9544 36.5778 27.9075C36.8003 27.8958 36.8003 27.8958 36.812 28.1187C36.812 28.1187 36.8236 28.3416 36.6011 28.3533C35.711 28.4002 30.5931 28.6698 27.0445 29.0802L19.8258 40.4119C19.8375 40.6348 19.8375 40.6348 19.6033 40.4236ZM19.6033 40.4236C19.615 40.6465 19.6033 40.4236 19.6033 40.4236Z"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M36.6583 33.7144C36.5304 31.2626 39.2256 27.3212 39.8467 26.3945C40.8895 24.9986 45.061 19.4149 47.5086 19.286C47.9537 19.2625 48.4103 19.4619 48.4335 19.9077C48.5498 22.1366 38.7207 30.4768 37.6546 31.4269C37.467 32.1073 37.0684 33.0223 37.1033 33.6909C37.1498 34.5825 37.6181 35.0048 38.7306 34.9462C40.9558 34.829 45.5008 32.1311 47.6562 30.6765L47.8788 30.6648C47.8788 30.6648 48.1013 30.6531 48.1129 30.876C48.1245 31.0989 47.902 31.1106 47.902 31.1106C45.7466 32.5651 41.4357 35.4742 38.988 35.6031C37.4304 35.6852 36.7164 34.8288 36.6583 33.7144ZM47.7892 20.3887C47.766 19.9429 47.5435 19.9546 47.321 19.9663C45.3183 20.0718 41.1353 25.4326 40.0924 26.8286C39.2721 28.2128 38.4517 29.5969 38.0648 30.7348C40.6188 28.3653 47.8706 21.9489 47.7892 20.3887Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M46.3194 34.9936C45.8743 35.017 45.8511 34.5712 45.8395 34.3484C45.8162 33.9026 46.0039 33.2222 46.2148 32.9876C46.625 32.2955 47.246 31.3688 48.3237 30.6415C48.5463 30.6298 48.5579 30.8527 48.5579 30.8527L48.5695 31.0756C47.9252 31.5565 47.2809 32.0375 46.8707 32.7296C46.6598 32.9642 46.2613 33.8791 46.2845 34.3249C46.2961 34.5478 46.3077 34.7707 46.7528 34.7472C47.6428 34.7004 49.1191 33.0581 49.5293 32.366C49.061 31.9437 48.8153 31.5096 48.792 31.0639C48.7688 30.6181 48.968 30.1606 49.4131 30.1371L49.6356 30.1254C50.0922 30.3249 50.1039 30.5478 50.1271 30.9935C50.1503 31.4393 50.3961 31.8733 50.1852 32.1079C50.4077 32.0962 50.6419 32.3074 51.0869 32.2839C52.6445 32.2019 54.8116 30.9702 55.9009 30.4659C55.9009 30.4659 55.9009 30.4659 56.1234 30.4541L56.3575 30.6653C56.3575 30.6653 56.3575 30.6653 56.3692 30.8882C55.514 31.6037 53.1244 32.8471 51.3443 32.9409C50.8992 32.9643 50.6767 32.976 50.2201 32.7766C49.599 33.7033 48.3336 35.111 46.9985 35.1813C46.5535 35.2047 46.5535 35.2047 46.3194 34.9936ZM49.9046 31.0052C49.893 30.7824 49.6588 30.5712 49.6588 30.5712C49.4363 30.5829 49.4479 30.8058 49.4596 31.0287C49.4712 31.2516 49.7169 31.6856 49.9511 31.8968C49.9395 31.6739 49.9162 31.2281 49.9046 31.0052Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.2227 34.4197C56.9886 34.2085 56.7544 33.9974 56.7428 33.7745C56.6847 32.6601 57.9385 31.0295 57.9036 30.3609L57.892 30.138L57.4121 29.4928C56.9787 29.7391 56.8218 30.1312 56.4 30.6004C56.1891 30.835 55.901 30.4664 55.901 30.4664C55.901 30.4664 55.8893 30.2435 56.1119 30.2318C56.7562 29.7508 56.9671 29.5162 57.3888 29.047C57.3656 28.6012 58.2208 27.8857 58.4433 27.874C58.6658 27.8623 58.6658 27.8623 58.6775 28.0851C58.6891 28.308 58.6891 28.308 58.4782 28.5426C58.2673 28.7772 58.0564 29.0119 57.8455 29.2465L58.5479 29.8799C58.5479 29.8799 58.5595 30.1028 58.5711 30.3257C58.6176 31.2173 57.3639 32.8478 57.4104 33.7393C57.422 33.9622 57.422 33.9622 57.6561 34.1734L57.8786 34.1617C59.4362 34.0796 64.3798 30.4667 65.2466 29.974C65.2466 29.974 65.2466 29.974 65.4691 29.9623C65.6917 29.9506 65.4691 29.9623 65.6917 29.9506L65.7033 30.1735C64.6256 30.9008 59.9045 34.5019 58.1244 34.5957C57.4568 34.6309 57.4568 34.6309 57.2227 34.4197Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.664 33.8564C63.4298 33.6453 63.4182 33.4224 63.4066 33.1995C63.3485 32.0851 64.8015 29.9971 65.4342 29.2932C65.4342 29.2932 65.4342 29.2932 65.6567 29.2815C65.8792 29.2698 65.8908 29.4927 65.8908 29.4927C65.8908 29.4927 65.8908 29.4927 65.9025 29.7156C65.2582 30.1965 63.8051 32.2845 63.8516 33.1761C63.8632 33.3989 63.8632 33.3989 64.0974 33.6101C64.0974 33.6101 64.3315 33.8213 64.554 33.8096C66.3341 33.7158 71.9569 30.2906 73.0346 29.5633L73.2571 29.5516C73.2571 29.5516 73.2571 29.5516 73.0462 29.7862C72.8353 30.0208 66.7908 33.9152 64.3431 34.0442C64.1206 34.0559 63.8981 34.0676 63.664 33.8564ZM66.9337 28.0968C67.1213 27.4164 67.7424 26.4897 68.41 26.4545C68.6325 26.4428 68.6441 26.6657 68.6441 26.6657C68.6441 26.6657 68.6557 26.8886 68.4332 26.9003C67.9882 26.9237 67.578 27.6158 67.3671 27.8504C67.1678 28.3079 67.1678 28.3079 66.9337 28.0968C66.9453 28.3196 66.9453 28.3196 66.9337 28.0968Z"
        fill="#2A2A2A"
      />
      <path
        d="M66.9337 28.0968C67.1213 27.4164 67.7424 26.4897 68.41 26.4545C68.6325 26.4428 68.6441 26.6657 68.6441 26.6657C68.6441 26.6657 68.6557 26.8886 68.4332 26.9003C67.9882 26.9237 67.578 27.6158 67.3671 27.8504C67.1678 28.3079 67.1678 28.3079 66.9337 28.0968ZM66.9337 28.0968C66.9453 28.3196 66.9453 28.3196 66.9337 28.0968ZM63.664 33.8564C63.4298 33.6453 63.4182 33.4224 63.4066 33.1995C63.3485 32.0851 64.8015 29.9971 65.4342 29.2932C65.4342 29.2932 65.4342 29.2932 65.6567 29.2815C65.8792 29.2698 65.8908 29.4927 65.8908 29.4927C65.8908 29.4927 65.8908 29.4927 65.9025 29.7156C65.2582 30.1965 63.8051 32.2845 63.8516 33.1761C63.8632 33.3989 63.8632 33.3989 64.0974 33.6101C64.0974 33.6101 64.3315 33.8213 64.554 33.8096C66.3341 33.7158 71.9569 30.2906 73.0346 29.5633L73.2571 29.5516C73.2571 29.5516 73.2571 29.5516 73.0462 29.7862C72.8353 30.0208 66.7908 33.9152 64.3431 34.0442C64.1206 34.0559 63.8981 34.0676 63.664 33.8564Z"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M75.645 32.5546C75.6101 31.8859 76.0203 31.1938 76.4189 30.2789C75.3528 31.229 72.7872 33.3756 71.4521 33.446C71.007 33.4694 70.5388 33.0471 70.5155 32.6013C70.4458 31.264 72.7773 28.9062 74.5574 28.8124C75.0024 28.789 75.4591 28.9884 75.9274 29.4108L75.939 29.6336C75.9506 29.8565 75.9506 29.8565 75.7281 29.8682L75.5056 29.88C75.2714 29.6688 74.8148 29.4694 74.5923 29.4811C73.0346 29.5631 71.1482 31.8975 71.1947 32.789C71.2063 33.0119 71.4404 33.2231 71.663 33.2114C72.9981 33.141 76.3956 29.8331 77.4617 28.8829C77.4617 28.8829 77.4617 28.8829 77.6842 28.8712L77.9184 29.0824L77.93 29.3053C77.1097 30.6895 76.0668 32.0854 76.1017 32.754C76.1133 32.9769 76.3474 33.1881 76.5699 33.1764C77.905 33.106 81.7709 30.2204 82.837 29.2703L83.0595 29.2585C83.0595 29.2585 83.282 29.2468 83.2936 29.4697C83.2936 29.4697 83.2936 29.4697 83.3052 29.6926C82.0166 30.6544 78.1508 33.5401 76.5932 33.6221C75.9024 33.2115 75.6682 33.0004 75.645 32.5546Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.1302 32.9885C80.5403 32.2964 82.3803 29.0705 83.013 28.3667C83.013 28.3667 83.013 28.3667 83.2355 28.3549C83.458 28.3432 83.4696 28.5661 83.4696 28.5661C83.4696 28.5661 83.4696 28.5661 83.4813 28.789C83.0595 29.2582 82.2275 30.4195 81.8173 31.1116C83.0943 29.9268 84.7931 28.2729 85.9057 28.2143C86.5732 28.1791 86.8422 29.0589 86.8887 29.9505L86.912 30.3962C86.9468 31.0649 86.9701 31.5106 87.1926 31.4989L87.4151 31.4872C88.7502 31.4169 92.8385 28.5195 93.4828 28.0386C93.4828 28.0386 93.4828 28.0386 93.7053 28.0269C93.7053 28.0269 93.7054 28.0269 93.9279 28.0151L93.9395 28.238C93.0843 28.9536 89.2185 31.8392 87.4383 31.933C87.2158 31.9447 86.9933 31.9564 86.9933 31.9564C86.0684 31.3347 86.819 28.6131 85.9289 28.66C84.5938 28.7304 81.4188 32.0266 80.7977 32.9533C80.7977 32.9533 80.7977 32.9533 80.5752 32.965C80.1418 33.2113 80.1418 33.2113 80.1302 32.9885Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M100.671 33.2481C100.636 32.5795 100.824 31.8991 101.223 30.9841L101.434 30.7495C101.656 30.7378 101.656 30.7378 101.668 30.9607C101.668 30.9607 101.668 30.9607 101.679 31.1836C101.269 31.8757 101.082 32.556 101.105 33.0018C101.209 35.0078 103.715 35.9932 106.83 35.8291C110.613 35.6299 115.427 33.8118 117.033 30.3747C117.443 29.6826 117.619 28.7794 117.584 28.1107C117.445 25.4361 113.815 24.2863 110.899 23.9929C109.047 26.996 106.962 29.7878 105.333 32.7791C105.333 32.7791 105.344 33.002 105.122 33.0137C105.122 33.0137 104.899 33.0254 104.888 32.8025L104.876 32.5796C106.528 30.0341 108.778 26.1161 110.22 23.8052C109.775 23.8287 109.33 23.8521 108.873 23.6527C108.651 23.6644 108.639 23.4415 108.639 23.4415C108.627 23.2186 108.627 23.2186 108.85 23.2069L110.407 23.1249C110.595 22.4445 110.783 21.7641 110.759 21.3183C110.632 18.8666 107.704 18.3503 107.623 16.7901C107.611 16.5672 107.599 16.3444 107.599 16.3444C108.385 14.2915 113.199 12.4734 117.872 12.2273C121.877 12.0163 125.273 12.9548 125.412 15.6295C125.447 16.2981 125.26 16.9785 125.072 17.6589C123.302 22.2221 116.884 23.2307 112.433 23.4651C115.138 23.9931 117.89 25.4126 118.018 27.8644C118.053 28.533 117.877 29.4363 117.478 30.3513C115.65 33.8001 110.847 35.841 106.842 36.052C103.527 36.6736 100.799 35.6999 100.671 33.2481ZM124.873 18.1164C125.06 17.436 125.26 16.9785 125.225 16.3098C125.097 13.8581 121.935 13.1307 118.152 13.33C113.702 13.5644 108.876 15.1596 108.314 17.2007C108.314 17.2007 108.325 17.4236 108.337 17.6465C108.406 18.9838 111.346 19.723 111.473 22.1747C111.485 22.3976 111.497 22.6205 111.508 22.8434C113.36 19.8403 115.223 17.0602 116.653 14.5264L116.875 14.5147C117.098 14.503 117.098 14.503 117.11 14.7259L117.121 14.9488C115.481 17.7172 113.418 20.9548 111.566 23.9578L112.902 23.8874C116.895 23.4536 123.091 22.4567 124.873 18.1164Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M141.098 34.0232C138.112 32.3925 132.868 25.9638 131.663 24.2393C131.651 24.0165 131.874 24.0047 131.874 24.0047C132.775 24.1807 133.677 24.3567 134.567 24.3098C138.795 24.0871 143.34 21.3892 144.501 17.9756C144.688 17.2952 144.876 16.6149 144.853 16.1691C144.713 13.4945 141.551 12.767 137.991 12.9546C133.318 13.2008 128.282 15.0306 128.375 16.8136C128.386 17.0365 128.386 17.0365 128.398 17.2594C129.135 18.5616 131.15 18.6789 131.266 20.9078C131.301 21.5764 131.113 22.2568 130.937 23.1601L135.472 15.9928C135.472 15.9928 135.472 15.9928 135.695 15.981C135.917 15.9693 135.917 15.9693 135.929 16.1922L135.94 16.4151L128.522 28.2042C127.093 30.738 125.64 32.826 125.03 33.9756L124.819 34.2102C124.819 34.2102 124.597 34.222 124.585 33.9991L124.574 33.7762C125.394 32.392 126.835 30.0811 128.476 27.3127C130.316 24.0868 131.113 22.2568 131.055 21.1424C130.95 19.1364 129.159 19.0073 128.421 17.7052C128.41 17.4823 128.176 17.2711 128.164 17.0483C128.059 15.0423 133.307 12.9779 137.98 12.7317C141.762 12.5324 145.381 13.4593 145.532 16.3568C145.567 17.0255 145.379 17.7058 145.191 18.3862C143.82 22.0344 139.064 24.9669 134.836 25.1897C134.168 25.2248 133.278 25.2717 132.599 25.084C134.039 27.0196 138.58 32.8148 141.332 34.2343L141.344 34.4572C141.543 33.9997 141.32 34.0114 141.098 34.0232Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M142.69 34.6103C142.456 34.3991 142.445 34.1763 142.433 33.9534C142.41 33.5076 142.609 33.0501 143.008 32.1351C144.449 29.8242 146.933 26.1174 149.429 22.6335C147.871 22.7155 146.56 23.2316 145.892 23.2668C145.67 23.2785 145.67 23.2785 145.658 23.0556C145.658 23.0556 145.646 22.8327 145.869 22.821C146.525 22.563 148.071 22.258 149.839 21.9414C152.124 18.692 154.421 15.6656 156.096 13.5658C156.096 13.5658 156.096 13.5658 156.319 13.5541C156.541 13.5424 156.541 13.5424 156.553 13.7653C156.553 13.7653 156.553 13.7653 156.565 13.9882C155.1 15.8533 152.803 18.8797 150.507 21.9062C152.943 21.5544 156.491 21.1439 158.494 21.0384L158.717 21.0267C158.939 21.015 158.939 21.015 158.951 21.2379C158.951 21.2379 158.962 21.4607 158.74 21.4725L158.295 21.4959C156.07 21.6131 152.521 22.0236 150.097 22.5983C147.612 26.3051 145.128 30.0119 143.464 32.3346C143.054 33.0267 142.855 33.4842 142.866 33.707C142.866 33.707 142.878 33.9299 143.1 33.9182C143.323 33.9065 143.557 34.1176 144.002 34.0942C144.892 34.0473 146.216 33.7541 147.973 33.2145C150.83 32.3935 154.344 31.3144 157.904 31.1269C158.572 31.0917 159.017 31.0682 159.685 31.0331C159.907 31.0214 159.907 31.0214 159.919 31.2442C159.919 31.2442 159.93 31.4671 159.708 31.4788C159.04 31.514 158.373 31.5492 157.928 31.5726C154.59 31.7485 151.065 32.6047 148.429 33.414C146.672 33.9535 145.126 34.2585 144.236 34.3054C143.369 34.798 142.924 34.8215 142.69 34.6103ZM154.281 12.9909C154.059 13.0027 154.059 13.0027 154.047 12.7798C154.047 12.7798 154.036 12.5569 154.258 12.5452C157.807 12.1347 161.578 11.7126 164.693 11.5485C166.473 11.4547 168.031 11.3726 169.6 11.5135C169.823 11.5017 169.823 11.5017 169.834 11.7246C169.846 11.9475 169.846 11.9475 169.623 11.9592C168.277 11.8067 166.719 11.8887 164.939 11.9825C161.601 12.1583 158.053 12.5688 154.281 12.9909Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M162.506 33.7898C164.135 30.7986 168.037 24.3351 167.793 19.6545C167.7 17.8714 166.94 16.1235 165.324 15.0911C162.795 13.6599 159.469 14.0586 158.989 13.4134L158.977 13.1905C158.861 10.9616 171.955 9.60136 183.526 8.9918C191.759 8.55808 199.125 8.61701 200.472 8.76957L200.706 8.98073C200.717 9.20361 200.717 9.20361 200.495 9.21534C198.914 8.85162 192.227 8.9804 184.661 9.37896C173.091 9.98852 159.775 11.3605 159.633 12.9324C159.645 13.1553 160.09 13.1319 160.758 13.0967C162.104 13.2492 164.119 13.3666 165.734 14.399C167.116 15.2202 168.31 16.7218 168.438 19.1735C168.542 21.1795 168.014 23.8893 166.408 27.3263C169.502 22.4699 172.584 17.3906 175.689 12.7571C175.689 12.7571 175.689 12.7571 175.912 12.7454C175.912 12.7454 176.134 12.7336 176.146 12.9565C176.146 12.9565 176.146 12.9565 176.158 13.1794C171.833 20.1121 167.275 26.8337 163.173 33.7547C162.963 33.9893 162.74 34.001 162.506 33.7898C162.74 34.001 162.517 34.0127 162.506 33.7898Z"
        fill="#2A2A2A"
      />
      <path
        d="M162.506 33.7898C164.135 30.7986 168.037 24.3351 167.793 19.6545C167.7 17.8714 166.94 16.1235 165.324 15.0911C162.795 13.6599 159.469 14.0586 158.989 13.4134L158.977 13.1905C158.861 10.9616 171.955 9.60136 183.526 8.9918C191.759 8.55808 199.125 8.61701 200.472 8.76957L200.706 8.98073C200.717 9.20361 200.717 9.20361 200.495 9.21534C198.914 8.85162 192.227 8.9804 184.661 9.37896C173.091 9.98852 159.775 11.3605 159.633 12.9324C159.645 13.1553 160.09 13.1319 160.758 13.0967C162.104 13.2492 164.119 13.3666 165.734 14.399C167.116 15.2202 168.31 16.7218 168.438 19.1735C168.542 21.1795 168.014 23.8893 166.408 27.3263C169.502 22.4699 172.584 17.3906 175.689 12.7571C175.689 12.7571 175.689 12.7571 175.912 12.7454C175.912 12.7454 176.134 12.7336 176.146 12.9565C176.146 12.9565 176.146 12.9565 176.158 13.1794C171.833 20.1121 167.275 26.8337 163.173 33.7547C162.963 33.9893 162.74 34.001 162.506 33.7898ZM162.506 33.7898C162.74 34.001 162.517 34.0127 162.506 33.7898Z"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M173.995 31.6199C173.527 31.1975 173.281 30.7635 173.246 30.0948C173.095 27.1973 175.966 22.3526 176.787 20.9684L176.775 20.7456C176.543 16.2879 182.237 9.95346 186.687 9.71901C187.355 9.68385 188.257 9.85984 188.725 10.2822C190.106 11.1034 190.609 12.1944 190.679 13.5317C190.749 14.869 190.174 16.6872 189.577 18.0597C188.369 20.5818 186.284 23.3736 184.175 25.7197L185.065 25.6728C187.735 25.5321 190.593 24.7111 192.526 23.2683C192.526 23.2683 192.526 23.2683 192.748 23.2566C192.748 23.2566 192.748 23.2566 192.971 23.2448C192.971 23.2448 192.971 23.2448 192.76 23.4794C190.604 24.934 187.758 25.9779 185.088 26.1186C184.643 26.142 184.198 26.1655 183.753 26.1889C182.476 27.3737 178.024 31.8546 175.354 31.9953C174.909 32.0188 174.452 31.8193 173.995 31.6199ZM183.296 25.9895C180.38 25.6961 177.851 24.2648 177.044 21.6254C176.423 22.5521 173.786 27.6079 173.914 30.0597C173.937 30.5054 174.183 30.9395 174.417 31.1507C174.651 31.3618 175.096 31.3384 175.319 31.3267C177.778 31.4206 182.242 27.1625 183.296 25.9895ZM190.445 13.3205C190.433 13.0976 190.41 12.6518 190.399 12.429C190.13 11.5491 189.65 10.9039 188.959 10.4933C188.268 10.0827 187.823 10.1062 186.933 10.1531C182.928 10.3641 177.667 16.4521 177.642 20.2529C180.138 16.769 182.88 13.7191 185.105 13.6018L185.327 13.5901C185.55 13.5784 185.561 13.8013 185.561 13.8013C185.561 13.8013 185.573 14.0242 185.35 14.0359L185.128 14.0476C183.125 14.1531 179.95 17.4493 177.689 21.1444C178.074 24.2531 181.27 25.6492 183.952 25.7314C186.26 22.9278 190.643 17.1095 190.445 13.3205Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        d="M186.201 31.4913C185.978 31.503 185.978 31.503 185.967 31.2801C185.967 31.2801 185.955 31.0573 186.177 31.0455C193.696 29.7554 204.935 9.9426 207.383 9.81366C207.606 9.80194 207.84 10.0131 207.863 10.4589C207.875 10.6818 207.898 11.1275 207.71 11.8079C207.171 14.2948 203.29 25.4506 203.452 28.571C203.476 29.0168 203.487 29.2397 203.71 29.2279L203.932 29.2162C206.602 29.0755 218.24 8.34773 222.808 6.0956C222.808 6.0956 223.031 6.08387 223.042 6.30676C223.042 6.30676 223.054 6.52965 222.831 6.54137C218.041 8.80522 206.86 29.7325 203.745 29.8966L203.522 29.9083C203.288 29.6972 203.042 29.2631 203.007 28.5944C202.798 24.5825 207.523 12.4883 207.418 10.4823C207.406 10.2594 207.406 10.2594 207.406 10.2594C205.404 10.3649 193.708 29.9783 186.201 31.4913Z"
        fill="#2A2A2A"
        stroke="#2A2A2A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
    </>
  ),
})
