'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LivingStandards',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 16.9 21 12 21Z"
        fill="currentColor"
      />
      <path
        d="M15.6 14.3C15.3 14.3 15.1 14.5 15.1 14.8C15.1 16.3 13.7 17.5 12 17.5C10.3 17.5 8.9 16.3 8.9 14.8C8.9 14.5 8.7 14.3 8.4 14.3C8.1 14.3 7.9 14.5 7.9 14.8C7.9 16.9 9.7 18.5 12 18.5C14.3 18.5 16.1 16.8 16.1 14.8C16.1 14.5 15.9 14.3 15.6 14.3Z"
        fill="currentColor"
      />
      <path
        d="M9.5 9.8C10 9.8 10.3 9.2 10.3 8.5C10.3 7.8 10 7.2 9.5 7.2C9 7.2 8.7 7.8 8.7 8.5C8.7 9.2 9.1 9.8 9.5 9.8Z"
        fill="currentColor"
      />
      <path
        d="M14.4 9.8C14.9 9.8 15.2 9.2 15.2 8.5C15.2 7.8 14.8 7.2 14.4 7.2C14 7.2 13.6 7.8 13.6 8.5C13.6 9.2 14 9.8 14.4 9.8Z"
        fill="currentColor"
      />
    </>
  ),
})
