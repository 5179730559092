'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LeftArrow',
  viewBox: '0 0 13 12',
  path: (
    <>
      <path fill="currentColor" d="M1.1 6.7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h11c.3 0 .5.2.5.5s-.2.5-.5.5h-11z" />
      <path
        fill="currentColor"
        d="M1.2 6.5c-.2.2-.6.2-.8 0-.1-.2-.1-.5 0-.7L5.3.9c.2-.2.5-.2.7 0 .2.2.2.5 0 .7L1.2 6.5z"
      />
      <path
        fill="currentColor"
        d="M.5 6.5c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l4.9 4.9c.2.2.2.5 0 .7-.2.2-.5.2-.7 0L.5 6.5z"
      />
    </>
  ),
})
