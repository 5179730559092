'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoNestle',
  viewBox: '0 0 107.73 28.56',
  path: (
    <>
      <path
        fill="currentColor"
        d="M81.32.35h-2.37c-.89.02-1.6.76-1.59 1.65v24.57c.02.87.72 1.57 1.59 1.59h2.37c.87-.01 1.58-.72 1.59-1.59V1.97c0-.88-.71-1.6-1.59-1.62ZM86.72 4.63h.03v.05h16.22c.73.02 1.33-.56 1.35-1.29V1.34c.03-.71-.51-1.31-1.22-1.34h-.14L86.77 1.59c-.52.05-.91.48-.91 1v1.16c0 .48.38.87.86.88ZM75.15 8.03h-3.62V1.96c.01-.84-.66-1.54-1.51-1.55H67.7c-.86-.02-1.57.67-1.59 1.53v6.1H65c-.62-.01-1.13.48-1.14 1.1v1.69c.01.62.52 1.11 1.14 1.1v-.04h1.12v9.84c-.05 3.51 2.75 6.39 6.25 6.44H74.84c.66 0 1.19-.53 1.19-1.19v-1.82c0-.64-.51-1.15-1.15-1.15h-.35c-1.54.12-2.89-1.04-3-2.58-.01-.14-.01-.28 0-.42v-9.09h3.58c.61 0 1.11-.49 1.12-1.1V9.15c.01-.61-.47-1.11-1.08-1.12ZM34.93 7.32c-6.49 0-10.2 5.1-10.2 10.51 0 5.71 4 10.73 10.27 10.73 4.28 0 8.57-2.28 9.35-6 .14-.5-.16-1.02-.66-1.16-.09-.02-.17-.04-.26-.03h-3.1c-.51-.02-.99.23-1.27.66-.92 1.35-2.5 2.12-4.13 2-2.53.01-4.61-1.98-4.71-4.5h13.15c.85.11 1.63-.49 1.74-1.34.02-.12.02-.25 0-.37 0-5.25-3.14-10.51-10.18-10.5Zm-4.65 8.26c.38-2.58 2.78-4.37 5.37-3.99 2.07.3 3.69 1.93 3.99 3.99h-9.36ZM103.36 21.38s-.1-.02-.15-.02h-3.1c-.51 0-.99.25-1.3.66-.89 1.36-2.45 2.13-4.07 2-2.53.02-4.61-1.97-4.71-4.49h13.12c.83.12 1.6-.46 1.72-1.29.02-.15.02-.3 0-.44 0-5.25-3.1-10.51-10.15-10.51-6.55 0-10.2 5.1-10.2 10.51 0 5.72 3.91 10.75 10.24 10.75 4.32 0 8.6-2.28 9.39-6 .11-.54-.25-1.07-.79-1.17Zm-8.62-9.83h.02c2.36-.07 4.38 1.66 4.68 4h-9.41c.32-2.34 2.35-4.07 4.71-4Z"
      />
      <path
        fill="currentColor"
        d="M22.95 4.82h38.76c.66.02 1.2-.5 1.22-1.16V1.87c0-.66-.53-1.19-1.19-1.19H19.25c-.96-.02-1.75.75-1.77 1.71v.05l.17 16.69S8.47 2.61 8.01 1.76C7.43.79 6.38.2 5.25.21H1.76C.79.22 0 1.01 0 1.98v24.45c0 .97.77 1.75 1.74 1.76H4.02c.97-.01 1.75-.79 1.76-1.76L5.59 9.29s9.27 16.69 9.67 17.34c.56 1 1.64 1.6 2.78 1.54h3.18c.96 0 1.73-.77 1.73-1.73V4.82Z"
      />
      <path
        fill="currentColor"
        d="M58.79 16.21c-1.24-.31-4.22-1.06-4.83-1.25-1.19-.27-2.25-.67-2.25-1.67.05-1.46 1.45-1.97 3.13-1.98 1.86 0 3.23.66 3.75 1.82.24.58.67.68 1.3.67h2.54c.5-.02.9-.42.91-.92 0-2.93-3.52-5.56-8.51-5.56s-8.53 2.43-8.53 6.36c0 3 2.38 4.8 5.29 5.53 1.34.33 3.55.94 4.67 1.19s2.25.67 2.25 1.67c0 1.61-1.71 2.15-3.56 2.15s-3.46-.76-4-2c-.27-.73-.79-.85-1.55-.85h-2.62c-.53.03-.94.47-.94 1v.31c0 2.82 3.83 5.82 9.09 5.82 3.76 0 9-1.54 9-6.55 0-2.92-1.94-5-5.14-5.74ZM106.83 25.55c0-.39-.17-.58-.72-.58h-.9v2.08h.26v-.91h.45l.58.93h.3l-.62-.93c.36-.04.65-.2.65-.58Zm-.98.32h-.37v-.67h.57c.26 0 .5 0 .5.34s-.41.34-.7.34Z"
      />
      <path
        fill="currentColor"
        d="M105.85 24.14c-1.01.03-1.81.87-1.78 1.88.01.47.2.91.53 1.24.35.35.84.55 1.34.53 1.01-.03 1.81-.87 1.78-1.88-.03-1.01-.87-1.81-1.88-1.78Zm.04 3.42c-.84 0-1.52-.68-1.53-1.52 0-.85.67-1.54 1.52-1.54.84 0 1.54.67 1.54 1.52v.11c-.03.82-.71 1.46-1.53 1.43Z"
      />
    </>
  ),
})
