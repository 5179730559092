'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Durable',
  viewBox: '0 0 841.9 595.3',
  path: (
    <g>
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M568.5,227c0.6,3.8-0.6,7.2-1.9,10.6c-0.7,1.8-1.8,3.5-3.3,4.7c-1.8,1.5-3.3,3.3-5,4.9
				c-3.4,3.3-6.6,7-10.3,10c-0.8,0.7-1.4,1.5-2,2.3c-2.6,3.6-5.7,6.7-9.4,9.2c-2.7,1.8-5.1,3.9-7.1,6.5c-2.5,3.2-5.1,6.2-7.7,9.3
				c-0.4,0.5-0.7,1.1-1.3,1.5c-2.1,1.2-3.4,3.1-4.7,5.1c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.8-4.6,4.2
				c-0.6,0.6-1.3,1.3-1.6,2.2c-1,2.4-2.8,4.4-4.4,6.4c-1.9,2.4-4.3,4.5-6.1,7.1c-0.6,0.9-1.5,1.4-2.5,1.9c-2.5,1.1-4.2,3-5.7,5.2
				c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.8-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.6-5.3,5.7c-0.6,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.2,2.1-6.2,3.2
				c-0.8,0.4-1.7,0.7-2.6,0.8c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.1,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
				c-0.2,0.8-1.3,1.4-2.2,1.3c-0.2,0-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.8-1.8c-0.9-1.6-2.3-2.4-3.9-1.7
				c-2.2,1-4.1,1-6.2-0.2c-1.1-0.6-2.3-0.2-3.1,0.7c-0.5,0.6-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1c1.3,1.6,2.6,1.9,4.4,0.9
				c0.7-0.4,1.6-0.4,2.3,0c1.3,0.8,2.6,1.6,3.9,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3-0.6,0.5-1,0.4
				c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.4-2.8,2.6
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.1,0,0.3,0.1,0.4c1.1,1,1.1,2.6,2,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.3,0.4-0.5,0.6
				c-0.2,0.3-0.8,0.3-1.1,0.1c-0.5-0.5-0.9-1-1.3-1.4c-0.4-0.4-1.4-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.9,2.3-2.3,2.7
				c-1.5,0.4-3,1-4.3,1.7c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.8,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.1-1.7
				c0.3-0.1,1,0.1,1,0.4c0,0.2,0.1,0.6,0,0.7c-0.9,1.4-1.8,2.9-2.7,4.3c-0.5,0.7-1.2,1-2,1.1c-0.6,0.1-1.3,0.1-1.9,0.1
				c-0.8,0-1.5-0.3-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
				c-0.4,0.8,0,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.7,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.3,0.2-0.6,0.6-0.9,0.6
				c-1.3,0.1-2.6,0.1-3.9,0c-0.6,0-1.3-0.2-1.9-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.8-0.5-1.4-1.1-1.7-2.1c-0.2-0.8-0.9-1.3-1.7-1.4
				c-0.3,0-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.6c-0.4-0.5-1-0.8-1.6-1.1c-0.5-0.2-0.9-0.5-1.4-0.6c-3.4-0.7-6-3-8.7-4.9
				c-1-0.7-1.7-1.6-2.2-2.6c-0.3-0.6-0.6-1.2-1-1.7c-2-2.2-3.9-4.5-6-6.7c-1.3-1.3-2.5-2.6-3.4-4.2c-0.6-1-1.3-1.9-2.2-2.7
				c-3.3-3.3-6.7-6.5-10.7-9c-1-0.6-1.8-1.4-2.6-2.3c-1.4-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.2-1.4-2.7-2.7
				c-0.1-0.2-0.2-0.5-0.4-0.7c-0.7-1.3-1.7-2.3-3.1-2.7c-1-0.3-1.9-0.7-2.9-1.2c-1-0.5-2-1.1-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8
				c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.8-1.9-1.3c-1.8-1.2-3.7-2.4-5.4-3.8c-1.8-1.3-3.4-2.8-5.4-3.7
				c-0.2-0.1-0.5-0.2-0.6-0.4c-1.6-2.4-4.4-3.6-6-6c-0.6-0.8-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
				c-0.4-0.1-0.7-0.2-1.1-0.3c-1.2-0.3-2.2-1-2.8-1.9c-1.5-2.2-3.6-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.6-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
				c-3.1-3.1-5.9-6.5-9.1-9.6c-3.2-3.2-6.6-6.2-9.8-9.4c-1-1-2-2.1-2.9-3.1c-0.5-0.6-0.9-1.3-1.3-1.9c-0.6-1.1-0.9-2.2-0.3-3.3
				c0.2-0.3,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.5,1-0.8,1.4-1.3
				c0.4-0.4,0.5-0.9,0.3-1.5c-0.3-0.9-0.7-1.7-1-2.5c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.2-3.5,3.2-5.4c0.5-1.1,1.3-1.9,2.2-2.7
				c1.8-1.5,3.6-3,5.4-4.4c0.8-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.8-2.2c0.8-1.8,1.6-3.5,2.5-5.2c0.9-1.6,2-3,3.5-4.2
				c1.4-1.2,2.6-2.5,3.5-4.1c0.8-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,3.9-3.1c2.8-1,4.5-3,5.5-5.7c0.8-2.5,2.5-4.2,4.6-5.6
				c1.7-1.2,3.4-2.3,4.8-3.9c0.9-1,1.7-1.9,2.3-3.1c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,1.9-2.4,2.2-4c0.2-1.1,0.9-1.9,1.8-2.5
				c3.4-2.2,5.4-5.6,7.8-8.6c1.5-2,2.9-4,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.2-6.4c1.5-1.1,2.7-2.4,3.8-3.9
				c0.5-0.7,1.1-1.4,1.7-2.1c1.5-2,3.1-3.7,5.5-4.8c1.7-0.8,2.8-2.3,4-3.7c2.3-2.8,4.6-5.6,6.9-8.4c1.2-1.5,2.6-2.9,4.4-3.8
				c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.2-4.2,6.3-6.3c1-1,2-2,3.5-2.3c1.2-0.3,2.1-1.1,2.8-2
				c2.2-3.1,4.7-5.8,7.5-8.4c0.8-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.3,5.8-2,8.1-4.3
				c0.6-0.5,1.1-1.1,1.6-1.7c0.9-0.8,1.9-1.4,3-1.7c1-0.3,2.1-0.3,3,0.3c3.2,1.8,6.4,3.4,9.7,4.9c1.1,0.5,1.9,1.4,2.3,2.5
				c0.3,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.3,1.5,0.4c0.8,0.2,1.4,0.6,2,1.2c1.3,1.4,2.6,2.9,3.9,4.3
				c0.6,0.7,1.1,1.4,1.8,2.1c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.4,1.1,2.9,2.2,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4
				c0.7,1.6,2,2.7,3.4,3.7c1.1,0.9,2.3,1.7,3.3,2.7c0.9,0.8,1.7,1.6,2.4,2.5c1.5,2,3.2,3.7,5.1,5.4c1,0.9,1.8,1.8,2.7,2.8
				c0.3,0.3,0.5,0.7,0.8,0.9c2.7,1.9,4.5,4.7,6.8,6.9c0.6,0.5,1.1,1.1,1.6,1.7c1.5,2.1,3.4,3.9,5.3,5.7c1.1,1,2.3,2.1,3.4,3.2
				c1.5,1.5,3,2.8,4.7,4.1c2.4,1.8,4.6,3.9,6.4,6.2c0.8,1,1.7,2,2.8,2.7c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4
				c4,3.8,8,7.8,12.3,11.3c1.4,1.1,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.3c1.9,0.2,3.3,1.3,4.7,2.6c2.2,2.2,4.4,4.4,6.7,6.5
				c0.3,0.3,0.6,0.5,0.8,0.8c2.6,3.3,5.7,6,8.6,9c2.2,2.2,4.3,4.5,6.2,6.9c2,2.5,3.6,5.4,5,8.3c0.6,1.2,0.7,2.5,0.7,3.8
				C568.4,225.4,568.4,226.2,568.5,227z M431.6,360.1c-0.6-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.6,0.4-1,0.6
				c-2,1.3-3,1.6-4.7-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.1,1.4-2.5,2.1-4.3,2.4c-0.4,0.1-0.7,0.3-1,0.5
				c-0.5,0.3-0.7,1.1-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.2c0.5,0,1,0.1,1.5,0c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
				c0.4,0.3,0.8,0.6,1.3,0.9c1,0.7,2.2,0.7,3.3,0.5c0.8-0.2,1.4-0.7,1.6-1.5c0.1-0.3,0-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2
				c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.7,0.1,2.3-0.7C431,362.3,431.6,361.3,431.6,360.1c0.3-0.1,0.7-0.1,1.1-0.1
				c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C432.3,358.8,432,359.4,431.6,360.1z M425,354.4
				c0.2-0.1,0.6-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0-0.6-0.1-0.7c-0.5-0.2-0.9-0.3-1.4-0.4
				c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1.1-0.3-1.5c-0.3-0.6-0.7-1.1-1.1-1.5c-0.4-0.5-1.4-0.3-1.6,0.3c-0.6,1.6-1.2,3.1-1.7,4.7
				C422.1,352.8,423.5,354.5,425,354.4z M410.7,358.1c0.4,0,0.8,0,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
				c-1.3-1.1-2.6-2.2-3.9-3.2c-0.3-0.2-0.7-0.3-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
				C406.9,357.5,408.7,358.3,410.7,358.1z M414,351.9c0-0.1,0-0.3,0-0.3c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
				c-0.1,0-0.1,0.2-0.1,0.3c0.3,1.4,1.3,2.2,2.6,2.1C413.6,352.3,413.8,352,414,351.9z M445.2,337.4c-0.1,0.1-0.2,0.2-0.2,0.3
				c0,0.1,0,0.3,0,0.4c0.8,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.3-0.6c-1-0.6-2-1.1-3-1.6
				C445.5,337.4,445.3,337.4,445.2,337.4z M404.4,361.3c0.4-0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.2-0.5-0.4-0.5
				c-0.6-0.3-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3C403.7,361.2,404.1,361.2,404.4,361.3z M418.2,370.5
				c0-0.2,0-0.5-0.2-0.7c-0.1-0.2-0.4-0.4-0.5-0.4c-0.2,0-0.4,0.3-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.2,0.3,0.4,0.4,0.5
				c0.2,0,0.5-0.1,0.6-0.2C418,370.9,418.1,370.7,418.2,370.5z M416,343.6c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
				c0,0.2,0.2,0.5,0.3,0.6C415.4,344.8,416.1,344.3,416,343.6z M407.8,363.7c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
				c0.2,0,0.5-0.2,0.5-0.4C408,364.4,407.9,364.1,407.8,363.7z M465.5,335.2c0.5,0.1,0.9-0.2,1-0.6c0-0.4-0.2-0.8-0.6-0.8
				c-0.4-0.1-0.8,0.3-0.8,0.7C465.2,334.7,465.4,335,465.5,335.2z M432.6,347.2c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
				c0.2,0,0.5,0,0.6-0.2C433.1,347.5,432.9,347.3,432.6,347.2z M434.1,338.6c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
				c-0.2,0.1-0.4,0.3-0.4,0.4C433.5,338.6,433.8,338.7,434.1,338.6z"
          />
        </defs>
        <g>
          <path
            fill="#7AB929"
            d="M568.2,227c0.6,3.8-0.6,7.2-1.9,10.6c-0.7,1.8-1.8,3.5-3.3,4.7c-1.8,1.5-3.3,3.3-5,4.9
				c-3.4,3.3-6.6,7-10.3,10c-0.8,0.7-1.4,1.5-2,2.3c-2.6,3.6-5.7,6.7-9.4,9.2c-2.7,1.8-5.1,3.9-7.1,6.5c-2.5,3.2-5.1,6.2-7.7,9.3
				c-0.4,0.5-0.7,1.1-1.3,1.5c-2.1,1.2-3.4,3.1-4.7,5.1c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.8-4.6,4.2
				c-0.6,0.6-1.3,1.3-1.6,2.2c-1,2.4-2.8,4.4-4.4,6.4c-1.9,2.4-4.3,4.5-6.1,7.1c-0.6,0.9-1.5,1.4-2.5,1.9c-2.5,1.1-4.2,3-5.7,5.2
				c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.8-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.6-5.3,5.7c-0.6,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.2,2.1-6.2,3.2
				c-0.8,0.4-1.7,0.7-2.6,0.8c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.1,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
				c-0.2,0.8-1.3,1.4-2.2,1.3c-0.2,0-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.8-1.8c-0.9-1.6-2.3-2.4-3.9-1.7
				c-2.2,1-4.1,1-6.2-0.2c-1.1-0.6-2.3-0.2-3.1,0.7c-0.5,0.6-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1c1.3,1.6,2.6,1.9,4.4,0.9
				c0.7-0.4,1.6-0.4,2.3,0c1.3,0.8,2.6,1.6,3.9,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3-0.6,0.5-1,0.4
				c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.4-2.8,2.6
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.1,0,0.3,0.1,0.4c1.1,1,1.1,2.6,2,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.3,0.4-0.5,0.6
				c-0.2,0.3-0.8,0.3-1.1,0.1c-0.5-0.5-0.9-1-1.3-1.4c-0.4-0.4-1.4-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.9,2.3-2.3,2.7
				c-1.5,0.4-3,1-4.3,1.7c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.2,0,0.8,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.1-1.7
				c0.3-0.1,1,0.1,1,0.4c0,0.2,0.1,0.6,0,0.7c-0.9,1.4-1.8,2.9-2.7,4.3c-0.5,0.7-1.2,1-2,1.1c-0.6,0.1-1.3,0.1-1.9,0.1
				c-0.8,0-1.5-0.3-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.9c-0.6,0.2-1,0.8-1.3,1.4
				c-0.4,0.8,0,1.7,0.9,1.8c1.3,0.2,2.6,0.3,3.9,0.5c0.9,0.1,1.7,0.5,2.5,1c0.5,0.4,0.9,1,0.6,1.3c-0.3,0.2-0.6,0.6-0.9,0.6
				c-1.3,0.1-2.6,0.1-3.9,0c-0.6,0-1.3-0.2-1.9-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.8-0.5-1.4-1.1-1.7-2.1c-0.2-0.8-0.9-1.3-1.7-1.4
				c-0.3,0-0.5-0.1-0.8-0.1c-1.4-0.1-2.5-0.6-3.4-1.6c-0.4-0.5-1-0.8-1.6-1.1c-0.5-0.2-0.9-0.5-1.4-0.6c-3.4-0.7-6-3-8.7-4.9
				c-1-0.7-1.7-1.6-2.2-2.6c-0.3-0.6-0.6-1.2-1-1.7c-2-2.2-3.9-4.5-6-6.7c-1.3-1.3-2.5-2.6-3.4-4.2c-0.6-1-1.3-1.9-2.2-2.7
				c-3.3-3.3-6.7-6.5-10.7-9c-1-0.6-1.8-1.4-2.6-2.3c-1.4-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.2-1.4-2.7-2.7
				c-0.1-0.2-0.2-0.5-0.4-0.7c-0.7-1.3-1.7-2.3-3.1-2.7c-1-0.3-1.9-0.7-2.9-1.2c-1-0.5-2-1.1-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8
				c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.8-1.9-1.3c-1.8-1.2-3.7-2.4-5.4-3.8c-1.8-1.3-3.4-2.8-5.4-3.7
				c-0.2-0.1-0.5-0.2-0.6-0.4c-1.6-2.4-4.4-3.6-6-6c-0.6-0.8-1.4-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.4
				c-0.4-0.1-0.7-0.2-1.1-0.3c-1.2-0.3-2.2-1-2.8-1.9c-1.5-2.2-3.6-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.6-5.3c-0.1-0.1-0.1-0.2-0.2-0.3
				c-3.1-3.1-5.9-6.5-9.1-9.6c-3.2-3.2-6.6-6.2-9.8-9.4c-1-1-2-2.1-2.9-3.1c-0.5-0.6-0.9-1.3-1.3-1.9c-0.6-1.1-0.9-2.2-0.3-3.3
				c0.2-0.3,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.4-0.3-1.8c-1.7-1.7-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.5,1-0.8,1.4-1.3
				c0.4-0.4,0.5-0.9,0.3-1.5c-0.3-0.9-0.7-1.7-1-2.5c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.2-3.5,3.2-5.4c0.5-1.1,1.3-1.9,2.2-2.7
				c1.8-1.5,3.6-3,5.4-4.4c0.8-0.7,1.5-1.5,1.9-2.5c0.3-0.7,0.5-1.5,0.8-2.2c0.8-1.8,1.6-3.5,2.5-5.2c0.9-1.6,2-3,3.5-4.2
				c1.4-1.2,2.6-2.5,3.5-4.1c0.8-1.5,1.9-2.8,2.9-4.2c1-1.4,2.3-2.5,3.9-3.1c2.8-1,4.5-3,5.5-5.7c0.8-2.5,2.5-4.2,4.6-5.6
				c1.7-1.2,3.4-2.3,4.8-3.9c0.9-1,1.7-1.9,2.3-3.1c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,1.9-2.4,2.2-4c0.2-1.1,0.9-1.9,1.8-2.5
				c3.4-2.2,5.4-5.6,7.8-8.6c1.5-2,2.9-4,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.2-6.4c1.5-1.1,2.7-2.4,3.8-3.9
				c0.5-0.7,1.1-1.4,1.7-2.1c1.5-2,3.1-3.7,5.5-4.8c1.7-0.8,2.8-2.3,4-3.7c2.3-2.8,4.6-5.6,6.9-8.4c1.2-1.5,2.6-2.9,4.4-3.8
				c0.6-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.2-4.2,6.3-6.3c1-1,2-2,3.5-2.3c1.2-0.3,2.1-1.1,2.8-2
				c2.2-3.1,4.7-5.8,7.5-8.4c0.8-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.8,1.4-1.2,2.3-1.3c3.3-0.3,5.8-2,8.1-4.3
				c0.6-0.5,1.1-1.1,1.6-1.7c0.9-0.8,1.9-1.4,3-1.7c1-0.3,2.1-0.3,3,0.3c3.2,1.8,6.4,3.4,9.7,4.9c1.1,0.5,1.9,1.4,2.3,2.5
				c0.3,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.3,1.5,0.4c0.8,0.2,1.4,0.6,2,1.2c1.3,1.4,2.6,2.9,3.9,4.3
				c0.6,0.7,1.1,1.4,1.8,2.1c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.4,1.1,2.9,2.2,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4
				c0.7,1.6,2,2.7,3.4,3.7c1.1,0.9,2.3,1.7,3.3,2.7c0.9,0.8,1.7,1.6,2.4,2.5c1.5,2,3.2,3.7,5.1,5.4c1,0.9,1.8,1.8,2.7,2.8
				c0.3,0.3,0.5,0.7,0.8,0.9c2.7,1.9,4.5,4.7,6.8,6.9c0.6,0.5,1.1,1.1,1.6,1.7c1.5,2.1,3.4,3.9,5.3,5.7c1.1,1,2.3,2.1,3.4,3.2
				c1.5,1.5,3,2.8,4.7,4.1c2.4,1.8,4.6,3.9,6.4,6.2c0.8,1,1.7,2,2.8,2.7c1.1,0.7,2,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4
				c4,3.8,8,7.8,12.3,11.3c1.4,1.1,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.3c1.9,0.2,3.3,1.3,4.7,2.6c2.2,2.2,4.4,4.4,6.7,6.5
				c0.3,0.3,0.6,0.5,0.8,0.8c2.6,3.3,5.7,6,8.6,9c2.2,2.2,4.3,4.5,6.2,6.9c2,2.5,3.6,5.4,5,8.3c0.6,1.2,0.7,2.5,0.7,3.8
				C568.2,225.5,568.2,226.3,568.2,227z M431.4,360.2c-0.6-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.6,0.4-1,0.6
				c-2,1.3-3,1.6-4.7-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.1,1.4-2.5,2.1-4.3,2.4c-0.4,0.1-0.7,0.3-1,0.5
				c-0.5,0.3-0.7,1.1-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.2c0.5,0,1,0.1,1.5,0c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
				c0.4,0.3,0.8,0.6,1.3,0.9c1,0.7,2.2,0.7,3.3,0.5c0.8-0.2,1.4-0.7,1.6-1.5c0.1-0.3,0-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2
				c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.7,0.1,2.3-0.7C430.8,362.4,431.3,361.4,431.4,360.2c0.3-0.1,0.7-0.1,1.1-0.1
				c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C432.1,358.8,431.7,359.5,431.4,360.2z M424.7,354.5
				c0.2-0.1,0.6-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0-0.6-0.1-0.7c-0.5-0.2-0.9-0.3-1.4-0.4
				c-0.6-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1.1-0.3-1.5c-0.3-0.6-0.7-1.1-1.1-1.5c-0.4-0.5-1.4-0.3-1.6,0.3c-0.6,1.6-1.2,3.1-1.7,4.7
				C421.8,352.8,423.2,354.5,424.7,354.5z M410.5,358.2c0.4,0,0.8,0,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
				c-1.3-1.1-2.6-2.2-3.9-3.2c-0.3-0.2-0.7-0.3-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
				C406.7,357.6,408.4,358.4,410.5,358.2z M413.7,351.9c0-0.1,0-0.3,0-0.3c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
				c-0.1,0-0.1,0.2-0.1,0.3c0.3,1.4,1.3,2.2,2.6,2.1C413.4,352.3,413.5,352.1,413.7,351.9z M444.9,337.5c-0.1,0.1-0.2,0.2-0.2,0.3
				c0,0.1,0,0.3,0,0.4c0.8,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.3-0.6c-1-0.6-2-1.1-3-1.6
				C445.2,337.4,445.1,337.5,444.9,337.5z M404.1,361.4c0.4-0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.2-0.5-0.4-0.5
				c-0.6-0.3-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3C403.5,361.2,403.8,361.3,404.1,361.4z M417.9,370.5
				c0-0.2,0-0.5-0.2-0.7c-0.1-0.2-0.4-0.4-0.5-0.4c-0.2,0-0.4,0.3-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.2,0.3,0.4,0.4,0.5
				c0.2,0,0.5-0.1,0.6-0.2C417.8,371,417.8,370.7,417.9,370.5z M415.8,343.7c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
				c0,0.2,0.2,0.5,0.3,0.6C415.2,344.8,415.8,344.3,415.8,343.7z M407.5,363.8c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
				c0.2,0,0.5-0.2,0.5-0.4C407.8,364.5,407.6,364.1,407.5,363.8z M465.3,335.3c0.5,0.1,0.9-0.2,1-0.6c0-0.4-0.2-0.8-0.6-0.8
				c-0.4-0.1-0.8,0.3-0.8,0.7C464.9,334.8,465.2,335.1,465.3,335.3z M432.4,347.3c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
				c0.2,0,0.5,0,0.6-0.2C432.8,347.6,432.7,347.4,432.4,347.3z M433.8,338.7c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
				c-0.2,0.1-0.4,0.3-0.4,0.4C433.3,338.6,433.5,338.7,433.8,338.7z"
          />
          <g>
            <path
              fill="#C8D872"
              d="M256.5,225.6c5.4,6.5,10.9,12.8,17.4,18c3.2,2.6,7.3,4.3,11.3,5.4c8.5,2.3,14.2,7.8,19.7,14.2
					c4.1,4.8,9.5,6.8,15.3,7.3c7.6,0.6,14.4,1.8,21,6.2c2.9,1.9,6.8,2.7,10.2,2.2c6-0.9,11.5-0.9,16.8,2c9.5,5.1,19.2,2.4,28-2.2
					c4-2,8.9-2.1,13.4-2.2c4.2-0.1,8.7,1.8,13,1.1c3.9-0.6,7.6-3,11-5.2c3.8-2.4,6.9-6.1,10.7-8.2c3.3-1.8,7.6-1.6,11.3-2.9
					c8.4-3,18.3-4.5,24.3-12.4c2.6-3.5,4.9-7.3,7.1-10.9c3.6-5.8,8.2-10,14.4-13.4c4.9-2.7,9.4-6.2,14.1-9.7
					c2.4-1.8,5.1-3.2,8.5-1.1c5,3,5.6,7.4,1.2,11.1c-4.4,3.8-9.1,7.2-13.8,10.1c-7.1,4.5-12.8,9.6-16.7,17.1
					c-6.1,11.3-16.9,17.3-28.9,19.4c-7.5,1.3-14.4,3.7-19.8,9.3c-8,8.4-18.8,10.9-29.8,9.6c-5.7-0.7-10.9,0.3-16.2,3
					c-11.6,6-24.7,5.8-36.8,1c-3.1-1.2-6.9-1.1-10.3-0.9c-6,0.4-12.1,0.2-17-3.9c-4.6-3.9-9.6-5.4-15.6-5.4c-8.5,0-16.8-2.6-22.8-9
					c-7.3-7.8-15.7-12.4-25.6-16.7c-5.5-2.4-10.6-7-13.9-12.1c-5.4-8.4-11.9-14.2-21.1-18.7c-8-4-8.4-8.1-1.2-13.9
					c0.9-0.7,3.1-1.2,3.9-0.7C245.5,217.4,256.8,225.2,256.5,225.6z"
            />
            <path
              fill="#C8D872"
              d="M265.5,201.2c4.7,5.7,9.6,11.2,15.2,15.8c2.8,2.3,6.4,3.7,9.9,4.7c7.5,2,12.4,6.9,17.3,12.5
					c3.6,4.2,8.3,6,13.5,6.4c6.7,0.5,12.7,1.6,18.4,5.4c2.5,1.7,6,2.4,9,2c5.3-0.7,10.1-0.8,14.7,1.7c8.3,4.5,16.8,2.1,24.6-1.9
					c6.5-3.3,13.6-1.6,20.4-0.9c1.8,0.2,3.7,0.3,5.4-0.4c1.8-0.7,3.2-2.3,4.9-3.3c6.4-4,15,0.5,22.2-1.7c2.3-0.7,4.3-2,6.5-2.8
					c2.2-0.8,4.9-0.9,6.8,0.6c2.1,1.7,2.1,5.2,0.7,7.5c-1.4,2.3-4,3.7-6.6,4.6c-4.4,1.6-9.1,2-13.7,1.3c-3.3-0.5-6.5-1.6-9.8-1.1
					c-3.9,0.5-7.2,3.1-10.6,4.9c-7.2,3.8-15.2,0.4-22.8,4.3c-10.2,5.3-21.7,5.1-32.3,0.9c-2.7-1.1-6-1-9.1-0.8
					c-5.3,0.3-10.6,0.2-14.9-3.4c-4.1-3.4-8.4-4.8-13.7-4.7c-7.5,0-14.7-2.3-20-7.9c-6.4-6.9-13.8-10.9-22.5-14.6
					c-4.8-2.1-9.3-6.1-12.2-10.7c-4.7-7.4-10.5-12.4-18.5-16.4c-7-3.5-7.4-7.1-1-12.2c0.8-0.7,2.7-1,3.4-0.6
					C255.8,194,265.7,200.9,265.5,201.2z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M496.4,251.3c5.3-6.5,7.9-15.7,6.8-23.8c-0.4-2.8-1.2-4.9-3.3-6.9c-1.7-1.6-4.3-3.5-6.5-4.3
						c-6.7-2.5-16.3-3.5-22.9-0.2c-7.8,3.8-12.4,15.3-11.2,23.4c1.5,9.8,11.5,17.3,21.1,17.1c4.9-0.1,10.5,0.4,14.3-3.3
						C495.2,252.7,495.8,252,496.4,251.3z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M499.9,192.1c-1.6-8.1-6-16.6-15.4-13.8c-12.7,3.8-25.5,7.3-38.2,11.3c-2.2,0.7-3.7,0.6-4.7-0.2L436,191
					c0.5,2-1,2-1.3,2.1c-3,0.5-7.1,1.9-9,0.5c-6-4.3-11.3-9.6-16.5-14.3c-0.7-0.6-1.1-1.6-0.9-2.6c0-0.2,0.1-0.3,0.1-0.4
					c0.2-0.4,0.4-0.7,0.6-1.1c1.1-2.1-0.5-4.6-2.9-4.3c-0.3,0-0.6,0.1-0.9,0.2c-10.9,2.7-21.8,5.6-32.7,8.6
					c-9.8,2.7-13.1,7.9-10.4,17.5c2,7,4.5,13.9,6.9,21.3c0.4,1.2,0,2.5-1,3.3c-5.9,4.5-19.9,23.2-9.7,29.9c1.4,0.9,3.1,1.1,4.8,1.1
					c5.6,0.1,11-1.2,16.6-1.9c10.1-1.3,20.3-0.3,30.5,0.6c11.1,0.9,5.2-11.7,13.1-14.7c4.9-1.8,11.4-2.1,16.6-3.2
					c3.8-0.8,7.1-2.2,11-2.6c4.7-0.4,9.4-0.3,13.6-2.6c3.2-1.8,5.5-4.6,8.3-6.9c8.1-6.6,17.8-1.2,26.9-3.8c0.5-0.2,1.1-0.4,1.4-0.8
					c0.2-0.4,0.2-0.8,0.2-1.2c-0.2-5.6-0.4-11.1-0.5-16.7C500.6,196.8,500.4,194.5,499.9,192.1z"
            />
            <path
              fill="#FFFFFF"
              d="M436,177.5c1.6,5.4,2.6,11.1,3.6,16.6c0.2,1.1,2.5,1.4,5.2,0.7c2.6-0.7,4.4-2.1,4.2-3.1
					c-0.4-1.6-4.7-21.1-5.8-20.9c-2.9,0.6-5.8,1.3-8.6,2.2C435.1,174.5,435.5,176,436,177.5z"
            />
            <path
              fill="#C8D872"
              d="M440.9,173.4c1.7,5.4,3.2,10.8,4.3,16.4c0.2,1.1,2.2,1.5,4.3,0.9c2.1-0.6,3.6-1.9,3.3-2.9
					c-1.8-6.3-3-12.5-4.2-18.9c-0.1-0.7-0.3-1.4-1.1-1.8c-1.1-0.5-2.9-0.1-4.4,0.5c-1.3,0.5-2.5,1-3.7,1.7
					C440,170.6,440.5,172,440.9,173.4z"
            />
            <path
              fill="#007933"
              d="M505.4,186.6c-1.6-8.1-6-16.6-15.4-13.8c-8.3,2.5-16.6,4.8-24.9,7.2c-7.9,2.3-15.6,5.2-23.6,7.3
					c-2.5,0.6-8.1,2.3-10.4,0.7c-6-4.3-11.3-9.6-16.5-14.3c-0.7-0.6-1.1-1.6-0.9-2.6c0-0.2,0.1-0.3,0.1-0.4c0.2-0.4,0.4-0.7,0.6-1.1
					c1.1-2.1-0.5-4.6-2.9-4.3c-0.3,0-0.6,0.1-0.9,0.2c-10.9,2.7-21.8,5.6-32.7,8.6c-9.8,2.7-13.1,7.9-10.4,17.5
					c2,7,4.5,13.9,6.9,21.3c0.4,1.2,0,2.5-1,3.3c-8.2,6.3-14.9,17.4-11.7,27.9c0.3,1.1,0.8,2.2,1.8,2.8c1.4-2.1,2-4.6,3.5-6.8
					c7.3-10.3,21.3-14.9,33.5-13.5c6.3,0.7,11.7,1.9,16.3,6.5c0.3,0.3,0.6,0.6,0.9,0.7c0.4,0.1,0.8,0,1.2-0.1
					c13.9-4,28.5-6.2,42.6-9.4c0.7-0.2,1.4,0,2,0.3c-0.7-0.3,3.5-5.5,4-5.9c1.9-1.9,4.2-3.3,6.7-4.3c5.6-2.4,11.6-2.8,17.5-1.3
					c2.3,0.6,4.6,1.2,6.8,2.2c0.4,0.2,0.7,0.3,1.1,0.4c0.3,0,0.7,0,1-0.1c2.1-0.4,4.1-0.8,6.2-1.2c0.2,0-0.8-19.3-0.9-20.9
					C506.1,191.3,505.9,188.9,505.4,186.6z"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M375.5,280.6c9.8,3.7,21.6,1.9,31.3-2.3c3.3-1.4,5.7-3.1,7.4-6.3c1.4-2.5,2.9-6.2,3.1-9
						c0.9-8.6-0.8-18.4-7.1-24.8c-7.4-7.5-23.8-5.3-33.4-0.9c-11.6,5.2-16.7,16.1-13.3,27c1.8,5.6,3.1,12.1,9,15.1
						C373.5,279.8,374.5,280.2,375.5,280.6z"
              />
            </g>
            <path
              fill="#C8D872"
              d="M493.2,187.6c-8.5,1.3-21.7,5.2-31.2,7.6c-1.3,0.3-2.6,0.6-3.7,0.8c-2.5,0.4-3.2,0-3.2-0.6
					c0-1.1,2.3-2.8,3.5-4.2c0.2-0.2,0.4-0.5,1.2-0.8c0.9-0.4,2.3-0.8,3.6-1.1c9.2-2.3,18.3-4.5,27.5-6.8c0.9-0.2,1.8-0.4,2.5-0.6
					c2.7-0.5,2.9,0.2,2.6,0.8C495.9,182.9,497.9,186.9,493.2,187.6z"
            />
            <path
              fill="#C8D872"
              d="M495.1,197.2c-8.5,1.3-21.7,5.2-31.2,7.6c-1.3,0.3-2.6,0.6-3.7,0.8c-2.5,0.4-3.2,0-3.2-0.6
					c0-1.1,2.3-2.8,3.5-4.2c0.2-0.2,0.4-0.5,1.2-0.8c0.9-0.4,2.3-0.8,3.6-1.1c9.2-2.3,18.3-4.5,27.5-6.8c0.9-0.2,1.8-0.4,2.5-0.6
					c2.7-0.5,2.9,0.2,2.6,0.8C497.9,192.5,499.9,196.5,495.1,197.2z"
            />
            <g>
              <path
                fill="#007933"
                d="M500,248.8c4.8-5.8,6.1-13.9,5-21.2c-0.3-2.5-1.1-4.4-3-6.1c-1.6-1.4-3.9-3.1-6-3.9
						c-6.1-2.3-13.8-3.1-19.9-0.2c-7.2,3.4-9.1,14.7-7.9,21.9c1.4,8.7,8.3,14.2,17,14.1c4.5-0.1,9.6,0.3,13.1-2.9
						C499,250,499.5,249.4,500,248.8z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M485.2,224c-2.1,0.2-4.2,0.7-5.8,1.5c-0.9,0.5-1.7,1.1-2.3,2c-0.6,1.1-0.7,2.4-0.7,3.6c0,2,0,4.1,0.5,6
					c0.4,1.3,1,2.4,1.8,3.5c3.1,4,6.5,6.5,11.1,4.8c4.1-1.5,6.8-6.6,7.4-10.7c0.4-2.9-0.4-6.2-2.7-8.1
					C492.3,224.5,488.6,223.8,485.2,224z"
            />
            <g>
              <path
                fill="#007933"
                d="M380.9,277.4c9,3.9,19.6,2.4,28.2-1.5c3-1.3,5-3,6.5-6c1.2-2.5,2.4-6,2.6-8.9c0.6-8.5-1.2-18.3-7-24.8
						c-6.9-7.7-21.7-5.9-30.2-1.8c-10.3,4.9-14.7,15.6-11.3,26.4c1.7,5.6,3.1,12.1,8.5,15.3C379,276.5,379.9,277,380.9,277.4z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M391.6,240.3c-3.1,0.2-6.1,1-8.4,2.2c-1.3,0.7-2.5,1.6-3.3,2.9c-0.9,1.5-1,3.4-1,5.2
					c-0.1,3.5,0,7.1,1.3,10.2c0.4,1,1,1.9,1.6,2.7c4.6,6.1,9.7,10.2,16.7,7.6c5.9-2.2,9.8-9.6,10.7-15.5c0.6-4.3-0.5-9-3.8-11.8
					C401.9,241,396.6,239.9,391.6,240.3z"
            />
            <path
              fill="#007933"
              d="M384.5,251.9c-0.7,1.9-1.1,3.9-1,5.7c0.1,1,0.3,2,0.8,2.8c0.7,1,1.8,1.5,2.9,2.1c2.4,1.2,5,2.2,7.7,2
					c1.3-0.1,2.6-0.6,3.8-1c3.5-1.2,6-3.2,6.3-7.2c0.3-4.2-3.1-8.6-6.5-10.7c-2.4-1.6-5.6-2.2-8.2-0.9
					C387.8,245.8,385.7,248.7,384.5,251.9z"
            />
            <path
              fill="#007933"
              d="M480.3,232.1c-0.5,1.4-0.8,2.8-0.7,4.1c0,0.7,0.2,1.4,0.6,2c0.5,0.7,1.3,1.1,2.1,1.5
					c1.7,0.8,3.6,1.6,5.5,1.4c0.9-0.1,1.8-0.4,2.7-0.7c2.5-0.9,4.3-2.3,4.5-5.1c0.2-3-2.2-6.1-4.6-7.7c-1.7-1.1-4-1.5-5.9-0.7
					C482.7,227.8,481.2,229.9,480.3,232.1z"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M422.2,196.3c-0.1-0.3-0.1-0.5-0.2-0.7c-0.7-1.7-2.6-3.5-3.7-4.9c-2.4-2.9-4.9-5.7-7.6-8.4
						c-2.8-2.8-6-4.5-9.8-5.7c-4.5-1.4-11.2,2-10.1,7.5c1.2,5.7,2.7,11.4,4.6,17c0,0.1,0.1,0.6,0.3,1.2c0.3,1.5,1.8,2.4,3.2,2
						l21.8-5.8C422.4,199.5,422.4,197.5,422.2,196.3z"
              />
              <path
                fill="#FFFFFF"
                d="M398.3,205.2c-0.6,0-1.2-0.2-1.8-0.5c-0.8-0.5-1.4-1.4-1.7-2.3l0-0.2c-0.1-0.5-0.2-0.8-0.2-0.9
						c-2-5.8-3.5-11.5-4.6-17.1c-0.5-2.4,0.3-4.7,2.3-6.4c2.4-2.1,6.1-3,9-2.1c4.4,1.4,7.5,3.2,10.2,5.9c2.9,3,5.4,5.8,7.6,8.5
						c0.3,0.4,0.7,0.8,1,1.2c1.1,1.2,2.3,2.5,2.8,3.9c0.1,0.2,0.2,0.5,0.2,0.9l0,0c0.2,0.9,0.3,2.5-0.6,3.2
						c-0.4,0.3-0.9,0.5-1.8,0.1l-21.5,5.7C398.9,205.2,398.6,205.2,398.3,205.2z M398.7,177.1c-1.9,0-3.9,0.7-5.3,2
						c-1,0.9-2.1,2.5-1.7,4.7c1.1,5.5,2.7,11.2,4.6,16.9c0,0.1,0.1,0.5,0.3,1.1l0,0.2c0.1,0.5,0.4,0.9,0.9,1.2
						c0.4,0.2,0.8,0.3,1.2,0.2l21.8-5.8c0.2-0.1,0.5,0,0.7,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0-0.2,0.1-0.7-0.1-1.3l0,0
						c0-0.2-0.1-0.4-0.1-0.5c-0.5-1.1-1.6-2.4-2.5-3.4c-0.4-0.4-0.8-0.9-1.1-1.3c-2.2-2.7-4.6-5.4-7.5-8.4c-2.4-2.5-5.3-4.2-9.4-5.5
						C400,177.2,399.4,177.1,398.7,177.1z"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#454545"
          d="M327.2,442.4c-0.1,1.2-0.2,2.3-0.3,3.4c-0.1,1.1-0.4,2.1-0.7,3c-0.3,0.9-0.7,1.8-1.3,2.6
			c-0.5,0.8-1.2,1.5-2,2.1c-1.2,0.9-2.5,1.7-3.8,2.3c-1.3,0.6-2.7,0.9-4.1,0.9c-0.5,0-0.8-0.1-1-0.2c-0.2-0.1-0.2-0.3-0.2-0.7
			c0-0.3,0-0.8,0.1-1.5c0.1-0.6,0.1-1.5,0.2-2.6l-0.1-0.9c-0.1-0.4-0.1-0.8-0.1-1.1s0-0.6,0-1c0-0.8,0-1.7,0.1-2.6
			c0-0.9,0.1-1.8,0.1-2.6c0-0.8,0-1.6-0.1-2.4c-0.1-0.9-0.1-1.6-0.1-2.1c0-0.5,0-0.9,0-1.1c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0-0.2
			c0,0,0-0.2,0-0.4V435c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6l0-1.1c0-0.2,0.1-0.5,0.3-0.7c0.2-0.2,0.6-0.4,1-0.4
			c1.1,0,2.1,0.1,3.2,0.4c1.1,0.3,2,0.6,2.9,1.1c0.9,0.5,1.7,1,2.4,1.7c0.7,0.6,1.3,1.3,1.7,2.1c0.4,0.9,0.8,1.8,1.2,2.6
			c0.4,0.9,0.5,1.7,0.5,2.4c0,0.1,0,0.1,0,0.2C327.2,442.2,327.2,442.3,327.2,442.4L327.2,442.4z M317.1,436.6c0,0.8,0,1.5,0,2.1
			c0,0.6,0,1.2,0,1.9c0,0.2,0,0.5,0,0.7c0,0.2,0,0.5,0,0.8l0.2,9.8c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3
			c0,0.1,0.2,0.1,0.3,0.1c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.5c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.4-0.4,0.6-0.7
			c0.4-0.4,0.8-0.8,1.1-1.3c0.3-0.5,0.6-1,0.9-1.6s0.5-1.1,0.6-1.7c0.2-0.6,0.2-1.2,0.2-1.8c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.5
			l-0.1-0.7c0-0.2,0-0.4-0.1-0.6s0-0.4,0-0.7c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.2-0.3-0.3-0.5-0.5-0.8
			c-0.2-0.3-0.4-0.6-0.7-1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.6-0.3c-0.4-0.2-0.9-0.4-1.4-0.5
			c-0.6-0.1-1.1-0.1-1.5-0.1c-0.2,0-0.4,0-0.4,0V436.6z"
        />
        <path
          fill="#454545"
          d="M334.3,438.8l-0.2,4.2c0.2,0,0.5,0,0.8,0.1c0.3,0,0.7,0,1,0.1c0.3,0,0.6,0,0.9,0.1c0.3,0,0.5,0,0.6,0h1
			c0.3,0,0.6,0,0.9,0.1c0.3,0,0.4,0.1,0.4,0.2l0,1.8c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.2,0.2-0.4,0.3
			c-0.3,0.1-0.6,0.1-1,0.2c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1,0c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0-0.6,0c-0.3,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.1,0.3-0.1,0.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.5v0.6c0,0.5,0,1,0,1.5c0,0.5-0.1,1.1-0.2,1.6v1.7c0.1,0,0.3,0,0.4,0
			s0.3,0,0.4,0c0.8,0,1.4,0,1.9,0c0.5,0,1,0,1.6,0c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.1,0.5,0c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0
			c0.1,0.2,0.2,0.5,0.2,0.7c0,0.2,0,0.4,0,0.6v1.3c0,0.1-0.3,0.2-0.8,0.2c-0.5,0-1,0-1.6,0c-0.6,0-1.4,0-2.4,0h-2
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0h-1.3c-0.5,0-0.7-0.2-0.7-0.7v-0.3c0.2-1,0.3-1.9,0.3-2.7c0-0.9,0-1.7,0-2.6v-1.9
			c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.8-0.1-1.1v-1.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.6,0-1.1,0.1-1.7c0.1-0.6,0.1-1.2,0.1-1.8
			c0-0.2,0-0.5,0-0.7s0-0.4,0-0.7v-1.7c0-0.3,0-0.6,0-0.8c0-0.3,0-0.5,0-0.8l0.1-1.7v-0.2c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
			c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.2-0.2,0.4-0.2c0.4,0,0.8,0,1.2,0c0.4,0,0.9,0,1.3,0c0.4,0,0.8,0,1.1-0.1c0.4,0,0.8-0.1,1.1-0.1
			h0.6c0.3,0,0.6,0,1,0c0.4,0,0.7,0,1,0c0.3,0,0.6,0.1,0.9,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.6
			c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0,0.4c0,0.4-0.3,0.7-0.8,0.7h-5.2V438.8z"
        />
        <path
          fill="#454545"
          d="M352.5,435.3c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.7-0.2-0.9-0.2c-0.7,0-1.2,0.2-1.7,0.6
			c-0.5,0.4-1,1.1-1.6,1.9c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.2,0,0.3c0,0,0,0.2,0,0.4c0,0.2,0,0.7,0.1,1.2
			c0.2,0.6,0.5,1.3,0.9,1.9c0.5,0.7,1.1,1.3,1.8,1.8l2.9,2.5c0.8,0.8,1.3,1.5,1.7,2.2c0.3,0.7,0.5,1.6,0.5,2.6c0,0.3,0,0.6-0.1,0.9
			c0,0.3-0.1,0.6-0.3,1l-0.2,0.6c-0.1,0.4-0.4,0.9-0.8,1.3c-0.4,0.4-0.9,0.8-1.4,1.1c-0.5,0.3-1.1,0.6-1.6,0.8
			c-0.6,0.2-1,0.3-1.4,0.3c-0.8,0-1.5-0.1-2.2-0.3c-0.7-0.2-1.3-0.4-1.9-0.6l-0.4-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0.2-1l0.5-1.2l2.3,0.7c0.3,0.2,0.7,0.2,1,0.2c0.4,0,0.7-0.1,1.1-0.2c0.4-0.2,0.7-0.4,0.9-0.6
			c0.3-0.3,0.5-0.5,0.7-0.9c0.2-0.3,0.3-0.7,0.3-1.1c0-0.3-0.1-0.7-0.2-1s-0.3-0.6-0.5-0.9c-0.9-0.8-1.5-1.4-1.8-1.7
			c-0.4-0.3-0.6-0.6-0.8-0.8c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1-0.1-0.4-0.3-0.8-0.7l-0.7-0.5c-0.4-0.3-0.8-0.7-1.1-1.1
			c-0.3-0.5-0.6-0.9-0.8-1.4c-0.2-0.5-0.3-1-0.4-1.5c-0.1-0.5-0.1-1-0.1-1.5c0-1,0.1-2,0.4-3c0.3-1,0.7-1.8,1.3-2.5
			c0.6-0.7,1.4-1.3,2.3-1.8c1-0.4,2.1-0.7,3.5-0.7c0.3,0,0.7,0,1,0c0.4,0,0.7,0.1,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3
			c0.2,0.1,0.3,0.3,0.3,0.5c0,0.3-0.1,0.6-0.2,1c-0.1,0.3-0.2,0.6-0.4,0.9s-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.3-0.3,0.3
			C352.7,435.3,352.6,435.3,352.5,435.3z"
        />
        <path
          fill="#454545"
          d="M376.3,444.6c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.8,0.1,1.5,0.3,2.1v-0.1c0.1,0.2,0.2,0.5,0.2,0.7
			c0,0.3,0,0.5,0,0.8l-0.2,5.7c0,0.5,0,0.9,0,1.1c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.5,0.1-0.9,0.2l-1.3-0.1
			c-0.7,0-1.5,0.1-2.2,0.1c-0.8,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.4-0.2-0.5-0.4c0-0.9,0.1-1.8,0.2-2.7
			c0.1-0.9,0.2-1.8,0.2-2.6c0-0.9,0-2-0.1-3.1c-0.1-1.1-0.1-2.1-0.1-3.1c0-1.5,0-3,0.1-4.5c0.1-1.5,0.1-3,0.1-4.6c0-0.9,0-1.7,0-2.6
			c0-0.8,0-1.7-0.1-2.5v0.1c-0.1-1.6-0.2-3.3-0.2-5.4c0-2,0.1-4,0.1-5.8c0-0.5,0.1-1,0.2-1.5c0.1-0.5,0.4-0.8,0.8-0.9l1.5-0.2
			c1-0.1,2.1-0.1,3.3-0.2c1.2-0.1,2.4-0.1,3.5-0.2c1.2-0.1,2.4-0.1,3.5-0.1c1.2,0,2.2-0.1,3.2-0.1c0.3,0,0.4,0,0.5,0.1
			c0.1,0.1,0.2,0.1,0.2,0.3s0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.2,0.7v-0.1c0.2,0.5,0.3,1,0.3,1.5l-0.2,3.3c-0.1,0.3-0.3,0.6-0.7,0.7
			c-0.4,0.1-0.9,0.2-1.4,0.2c-0.6,0-1.1,0-1.8-0.1c-0.6,0-1.2-0.1-1.7-0.1l-3.5,0.1c-0.4,0.1-0.6,0.2-0.6,0.2c0,0-0.1,0.2-0.1,0.4
			l0.3,10.1c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0,1.4-0.1c0.5-0.1,0.9-0.1,1.4-0.1l3-0.1c0.4,0,0.7,0.2,0.9,0.6
			c0.2,0.4,0.3,0.9,0.4,1.6c0.1,0.7,0.1,1.3,0.1,2.1c0,0.7,0,1.4,0,2c0,0.5,0,0.9-0.1,1.1c0,0.2-0.1,0.3-0.3,0.4
			c-0.2,0.1-0.4,0.1-0.8,0.1c-0.3,0-0.8-0.1-1.4-0.1c-0.9,0-1.8,0.1-2.7,0.2c-0.8,0.1-1.7,0.3-2.7,0.4V444.6z"
        />
        <path
          fill="#454545"
          d="M394.7,456.6c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.3-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.7c0-1.7,0-3.3-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.4,0-2.9,0.1-4.4c0-1.5,0.1-2.9,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-0.9c0-0.4,0-0.8-0.1-1.3c0-0.4-0.1-0.8-0.1-1.2
			c0-0.4,0-0.7,0-0.8c0-0.5,0.1-0.9,0.2-1.2c0.2-0.3,0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.7,0,2.3,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.5-0.1,4.3c0,1.7,0,3.9,0.1,6.5l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.4c0,1.1,0,2.2,0,3.4v2.2c0,0.6,0,1.3-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L394.7,456.6
			z"
        />
        <path
          fill="#454545"
          d="M412.3,448.2c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.2-0.1c0.7-0.1,1.4-0.1,2.2-0.1
			c0.8,0,1.3,0,1.7,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.7-0.1-2.5-0.1H406c-0.6,0-1.1,0-1.5,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-1-0.1-1.9-0.1-2.9c0-0.9,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.8,0.2-2.8c0-0.4,0-0.9-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3V425c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5c0,0.8,0.1,1.7,0.1,2.5v-0.1
			c0.1,0.9,0.2,1.9,0.2,2.9c0,1,0,1.9-0.1,2.9c-0.1,1-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1.1,0.1,1.4
			c0,0.4,0,0.7,0.1,0.9c0,0.2,0.1,0.4,0.1,0.6V448.2z"
        />
        <path
          fill="#454545"
          d="M429.1,456.6c-0.7-0.1-1.5-0.1-2.4-0.2c-0.9-0.1-1.7-0.1-2.4-0.2l-0.1-2.6c-0.1-0.7-0.1-1.3-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.7c0-1.7,0-3.3-0.1-5c-0.1-1.7-0.1-3.4-0.1-5.1
			c0-1.4,0-2.9,0.1-4.4s0.1-2.9,0.2-4.4v0.1l0.1-4.9c0-0.2,0-0.5,0-0.9c0-0.4,0-0.8-0.1-1.3c0-0.4-0.1-0.8-0.1-1.2
			c0-0.4,0-0.7,0-0.8c0-0.5,0.1-0.9,0.2-1.2s0.4-0.5,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1.1,0,1.7,0c1,0,1.8,0,2.3,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.5-0.1,4.3c0,1.7,0,3.9,0.1,6.5l0.2,4.2
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.2-0.3,2.3-0.3,3.4c0,1.1,0,2.2,0,3.4v2.2c0,0.6,0,1.3-0.1,2.2
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.6,1.6L429.1,456.6
			z"
        />
        <path
          fill="#454545"
          d="M447.1,428.5l-0.2,4.8l2.5,0.1c0.4,0,0.8,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.5l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1s-0.6,0-1.1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2
			c0,0.2-0.1,0.4-0.1,0.7c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.1,0.9-0.1
			c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8
			c-0.3,0.2-0.8,0.3-1.6,0.3c-0.7,0.1-1.7,0.1-2.9,0.1c-1.2,0-2.7,0-4.5,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1
			c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0h-1.8c-0.9,0-1.6,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3c0.3-1.3,0.4-2.5,0.4-3.8c0-1.2,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5
			c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.2,0.1-2.2,0.2-3.1c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5
			c0-0.4,0-0.8,0-1.3c0-0.5,0-0.9,0.1-1.3l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9
			c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.9-0.4,2-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2
			c0.8,0,1.6-0.1,2.3-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0c0.6,0,1.1,0,1.7,0c0.5,0,1,0,1.4,0
			c0.5,0,0.8,0.6,0.8,1.7c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.2,0-2.2,0h-5.5V428.5z M447.7,415.3
			c-0.6,0.1-1.2,0.1-1.7,0.1c-0.5,0-0.8-0.1-1-0.1s-0.4-0.2-0.8-0.6c-0.4-0.4-0.8-0.8-1.3-1.3c-0.5-0.5-1-1.1-1.5-1.7
			c-0.5-0.6-1-1.1-1.4-1.7l-0.4-0.7c-0.3-0.4-0.5-0.8-0.8-1.3c-0.2-0.5-0.3-0.9-0.3-1.1c0-0.6,0.4-0.9,1.3-1
			c0.8-0.1,1.9-0.2,3.1-0.2c0.5,0,0.9,0,1.4-0.1c0.5,0,0.9,0,1.4,0.1c0.4,0.1,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.6,1.3v-0.1
			c0.2,0.5,0.5,1.1,0.7,1.7c0.3,0.6,0.5,1.3,0.7,1.9c0.2,0.6,0.4,1.2,0.6,1.8c0.2,0.5,0.3,1,0.4,1.3c0.1,0.2,0.1,0.4,0.2,0.5
			c0.1,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.1-0.7,0.2C448.9,415.2,448.4,415.3,447.7,415.3z"
        />
        <path
          fill="#454545"
          d="M460.8,456.7c-0.1-0.7-0.1-1.5-0.1-2.4c0-0.9,0-1.7,0-2.3v-0.3c0-1.3,0.1-2.6,0.1-3.9c0-1.3,0-2.6,0-3.9
			c0-1.2,0-2.3,0-3.4c0-1.1,0-2.2-0.1-3.4v0.1c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1v-2.7c0-0.5,0-0.9,0-1.4c0-0.5,0-0.9,0-1.4
			v-2.9c0-0.5,0-1.3-0.1-2.1c-0.1-0.9-0.1-1.8-0.1-2.6c0-0.9,0.1-1.6,0.2-2.3c0.1-0.7,0.4-1.1,0.8-1.3c0.5-0.2,1-0.3,1.6-0.2
			c0.6,0,1.1,0.1,1.5,0.1c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.8,0.2,1.6,0.4,2.4,0.6c0.8,0.2,1.6,0.4,2.4,0.6
			c0.6,0.1,1.3,0.4,1.8,0.7c0.6,0.3,1.2,0.7,1.7,1.1c2.2,1.5,3.8,3.4,4.8,5.6c1,2.2,1.6,4.6,1.6,7.1c0,1-0.2,2.1-0.5,3.2
			s-0.8,2.1-1.3,3.1c-0.5,1-1.2,1.8-1.9,2.5c-0.7,0.7-1.5,1.3-2.2,1.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1l-0.9,0.3
			l6.2,8.6l1.2,1.7l1.5,2.9c0,0.3-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1s-0.3,0-0.5,0.1l-5.5,0.3
			c-0.1,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.6-0.6-0.9-1.1c-0.3-0.4-0.7-0.9-1-1.4c-0.3-0.5-0.6-0.9-0.9-1.3l-1.1-1.7
			c-0.2-0.3-0.4-0.7-0.6-1.1c-0.2-0.4-0.4-0.9-0.6-1.4c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-0.9-0.4-1.3c0,0.8,0.1,1.8,0.2,3.1
			c0.1,1.2,0.2,2.4,0.2,3.5c0.1,1.1,0,2.1-0.1,2.9c-0.1,0.8-0.4,1.2-0.9,1.2c-0.4,0-0.9,0-1.5,0c-0.5,0-1.1,0-1.7,0
			c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0L460.8,456.7z M469.2,436.7c0.4-0.3,0.8-0.5,1.2-0.8s0.8-0.6,1.1-1c0.3-0.4,0.5-1,0.7-1.7
			s0.2-1.6,0.2-2.7c0-1.3-0.2-2.5-0.7-3.6c-0.5-1.1-1.3-1.9-2.5-2.3l-0.1,6V436.7z"
        />
        <path
          fill="#454545"
          d="M495.5,428.5l-0.2,4.8l2.5,0.1c0.4,0,0.8,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.5l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.6,0-1.1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2
			c0,0.2-0.1,0.4-0.1,0.7c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.1,0.9-0.1
			c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8
			c-0.3,0.2-0.8,0.3-1.6,0.3c-0.7,0.1-1.7,0.1-2.9,0.1c-1.2,0-2.7,0-4.5,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1
			c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0h-1.8c-0.9,0-1.6,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.7
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3c0.3-1.3,0.4-2.5,0.4-3.8c0-1.2,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5
			c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.2,0.1-2.2,0.2-3.1c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5
			c0-0.4,0-0.8,0-1.3c0-0.5,0-0.9,0.1-1.3l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9
			c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.9-0.4,2-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2
			c0.8,0,1.6-0.1,2.3-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0c0.6,0,1.1,0,1.7,0c0.5,0,1,0,1.4,0
			c0.5,0,0.8,0.6,0.8,1.7c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.2,0-2.2,0h-5.5V428.5z"
        />
        <path
          fill="#454545"
          d="M522.9,425.1c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.3-0.6,0.6-0.9,0.9c-0.2,0.4-0.3,0.8-0.4,1.2v1.2c0,0.9,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1.5,2.4,2.4
			l1.7,1.5l2.8,2.3c1.3,1.3,2.2,2.7,2.9,4.2c0.7,1.5,1,3.3,1,5.2c0,0.4,0,1-0.1,1.6c-0.1,0.6-0.2,1.3-0.4,1.9
			c-0.2,0.6-0.4,1.2-0.7,1.8c-0.3,0.6-0.5,1-0.9,1.3c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-0.9,0.6l-0.7,0.4
			c-0.4,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-0.8,0.4c-0.6,0.3-1.3,0.5-1.9,0.7c-0.7,0.2-1.4,0.3-2.3,0.3c-0.5,0-1-0.1-1.6-0.2
			c-0.5-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.2-0.4-1.9-0.8c0,0-0.1,0-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.2-0.9-0.4-1.3-0.7
			c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.4-1c0.2-0.4,0.3-0.9,0.4-1.4c0.1-0.5,0.2-0.9,0.3-1.3
			c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0.1l2.3,0.7l1.4,0.4
			c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.1-0.9
			c-0.3-1.1-0.6-2-1.1-2.5c-0.5-0.6-1-1-1.5-1.3l-1.9-1.5c-0.6-0.4-1.1-0.8-1.5-1.1c-0.4-0.3-0.9-0.7-1.4-1.1
			c-1.4-1.2-2.4-2.7-3.1-4.5c-0.7-1.8-1.1-3.5-1.1-5.2c0-1.2,0.1-2.4,0.4-3.5c0.3-1.1,0.6-2.3,1.1-3.6c0.4-1,1-1.8,1.8-2.6
			c0.8-0.8,1.6-1.4,2.6-2c1-0.5,2-1,3-1.2c1.1-0.3,2.1-0.4,3.1-0.4c0.5,0,1.3,0,2.1,0.1c0.9,0.1,1.7,0.2,2.5,0.3
			c0.8,0.2,1.5,0.4,2,0.7c0.5,0.3,0.7,0.7,0.6,1.2c-0.3,1-0.6,2.1-0.8,3.3s-0.7,2.4-1.4,3.7L522.9,425.1z"
        />
        <path
          fill="#454545"
          d="M218.9,508.6c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.2-0.1c0.7-0.1,1.4-0.1,2.2-0.1
			c0.8,0,1.3,0,1.7,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.7-0.1-2.5-0.1h-3.8c-0.6,0-1.1,0-1.5,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-1-0.1-1.9-0.1-2.9c0-0.9,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.8,0.2-2.8c0-0.4,0-0.9-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.9c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5s0.1,1.7,0.1,2.5v-0.1c0.1,0.9,0.2,1.9,0.2,2.9
			c0,1,0,1.9-0.1,2.9c-0.1,1-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1.1,0.1,1.4c0,0.4,0,0.7,0.1,0.9
			c0,0.2,0.1,0.4,0.1,0.6V508.6z"
        />
        <path
          fill="#454545"
          d="M255.7,496.5c-0.2,1.3-0.4,2.7-0.5,4.1c-0.1,1.4-0.3,2.8-0.5,4.1c-0.3,1.4-0.6,2.7-1.2,3.9
			c-0.5,1.2-1.3,2.4-2.3,3.4c-0.4,0.3-0.8,0.7-1,1.1c-0.3,0.4-0.6,0.9-1.1,1.4c-0.8,0.8-1.8,1.5-2.8,1.9c-1,0.5-2.1,0.7-3.2,0.7
			c-0.9,0-1.7-0.1-2.6-0.4c-0.8-0.3-1.7-0.6-2.5-1c-1.3-0.5-2.4-1.3-3.3-2.2c-1-1-1.8-2-2.5-3.2c-0.7-1.2-1.2-2.4-1.5-3.7
			c-0.3-1.3-0.5-2.6-0.5-3.8c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.8,0-1.3c0-1.4,0.1-2.7,0.4-4.1c0.3-1.3,0.4-2.7,0.4-4
			c0-1.3,0.2-2.4,0.5-3.3c0.3-0.9,0.6-1.8,0.8-2.8l0.4-1.7c0.2-0.6,0.4-1.1,0.5-1.6c0.2-0.5,0.4-1,0.6-1.4c0.3-0.5,0.6-0.9,0.9-1.2
			c0.4-0.4,0.8-0.7,1.4-1.1c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.3,0.5-0.4l0.8-0.5h-0.1c0.6-0.5,1.2-0.8,1.9-1
			c0.6-0.2,1.3-0.3,2.1-0.3c0.9,0,1.8,0.1,2.7,0.3h-0.1c0.5,0.1,1.1,0.4,1.7,0.7c0.6,0.3,1.1,0.7,1.6,1.1c0.5,0.4,1,0.8,1.5,1.1
			c0.5,0.4,0.9,0.7,1.2,1.1h-0.1c1.5,1.4,2.7,3,3.4,4.7c0.8,1.7,1.2,3.6,1.3,5.5c0,0.7,0.1,1.2,0.3,1.7c0.1,0.5,0.3,0.8,0.3,1.1
			l0.3,2.2V496.5z M247.3,496.6c-0.1-0.8-0.2-1.6-0.3-2.4c-0.1-0.8-0.3-1.7-0.4-2.5c-0.2-0.8-0.4-1.6-0.7-2.3
			c-0.3-0.7-0.7-1.4-1.2-1.9v0.1c-0.6-0.6-1.2-1.2-1.8-1.5c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.7,0-1.3,0.3-1.7,0.9
			c-0.5,0.6-0.8,1.3-1.1,2.2c-0.3,0.9-0.5,1.8-0.6,2.7s-0.2,1.8-0.2,2.5v0.9c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.5v3.1l0.3,3.7
			c0.1,0.8,0.3,1.6,0.4,2.4c0.1,0.8,0.3,1.5,0.6,2.1c0.2,0.6,0.5,1.1,0.9,1.5c0.4,0.4,1,0.6,1.7,0.6c1.2,0,2.1-0.4,2.8-1.2
			c0.7-0.8,1.3-1.7,1.7-2.8c0.4-1.1,0.7-2.3,0.8-3.5c0.2-1.2,0.3-2.3,0.3-3.2L247.3,496.6z"
        />
        <path
          fill="#454545"
          d="M275.4,484.6l-1.7-0.3h-0.5c-1,0-1.9,0.3-2.9,0.8c-1,0.5-1.7,1.2-2.1,2.1c0,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.1-0.2,0.3-0.2,0.4v-0.1c-0.4,1-0.8,2-1,3c-0.2,0.9-0.4,2-0.6,3.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.7,0,1.1
			c0,0.9,0.1,1.8,0.3,2.7c0.2,0.9,0.5,1.9,0.8,2.8c0.4,0.9,0.8,1.8,1.2,2.6s0.9,1.6,1.5,2.3c0.2,0.3,0.5,0.6,0.9,0.8
			s0.9,0.4,1.4,0.6c0.5,0.1,1.1,0.3,1.6,0.3c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.6,0,1,0.1c0.4,0,0.8,0.1,1,0.1c0.6,0,0.9,0.2,1,0.6
			c0.1,0.4,0.2,1.3,0.4,2.7c0,0.1,0,0.4,0.1,0.8c0,0.4,0.1,0.8,0.1,1.2c0,0.4,0,0.8,0.1,1.2c0,0.4,0.1,0.7,0.1,0.8
			c0,0.5-0.1,0.8-0.4,1c-0.3,0.2-0.6,0.4-1.1,0.4c-0.5,0.1-1.1,0.1-1.8,0c-0.7,0-1.4-0.1-2.2-0.1c-1.7,0-3.3-0.4-4.8-1.1
			c-1.5-0.7-2.9-1.7-4.1-3c-1.2-1.2-2.2-2.7-3.1-4.2c-0.9-1.6-1.5-3.2-1.9-4.9l-0.5-2.1l-0.5-1.6c-0.3-1-0.4-2.3-0.4-3.6
			c0-1,0.1-2.1,0.2-3.2c0.1-1.1,0.3-2,0.5-2.8l0.4-2.1c0.1-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.6-1.2,1-1.8c0.4-0.6,0.8-1.1,1.4-1.7
			c0.5-0.6,1-1.3,1.5-2c1.3-1.3,2.7-2.3,4.4-3.2c1.7-0.9,3.5-1.3,5.4-1.3c0.7,0,1.4,0,2,0.1c0.7,0.1,1.4,0.3,2.1,0.6l0.8,0.3
			c0.5,0.2,1,0.4,1.3,0.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.6-0.4,1.6-1.2,3.2v-0.1l-0.9,2.1c0,0.1-0.1,0.3-0.2,0.6
			c-0.1,0.3-0.2,0.5-0.2,0.6L275.4,484.6z"
        />
        <path
          fill="#454545"
          d="M298.8,516.5c-0.1-0.1-0.3-0.3-0.3-0.7c-0.1-0.4-0.1-0.7-0.1-0.9l-0.3-1.9c-0.1-0.9-0.3-2-0.5-3.2
			c-0.2-1.2-0.4-2.2-0.5-3.2c-0.8,0.2-1.5,0.3-1.9,0.4c-0.4,0.1-0.8,0.1-1.1,0.1c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.2-1.7-0.2
			l-0.2,0.9l-1.9,8.6c-0.1,0.3-0.2,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.6,0.1-1,0.1s-0.7,0-1.1,0c-0.5,0-0.9,0-1.4-0.1
			c-0.5-0.1-0.9-0.1-1.3-0.1c-0.5,0-0.9,0-1.3,0c-0.4,0-0.6-0.2-0.6-0.7c0-0.1,0-0.5,0.1-0.9c0.1-0.5,0.2-0.8,0.2-0.9
			c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.7,0.4-1.3c0.1-0.8,0.2-1.7,0.4-2.6
			c0.2-0.9,0.4-1.7,0.4-2.3l2.9-10.8c0.3-0.7,0.5-1.4,0.6-2.2c0.1-0.7,0.3-1.4,0.4-2.2l0.8-2.5l0.5-1.5c0.1-0.6,0.3-1.3,0.5-2
			c0.2-0.8,0.3-1.6,0.5-2.4c0.2-0.8,0.3-1.6,0.5-2.3c0.2-0.8,0.3-1.4,0.5-2.1c0.1-0.4,0.2-0.7,0.3-0.9c0.1-0.2,0.3-0.4,0.5-0.5
			c0.3-0.1,0.7-0.2,1.2-0.2c0.6,0,1.3-0.1,2.4-0.1c0.9,0,1.5,0,1.9,0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.5,0.4,0.9
			c0.1,0.4,0.2,0.9,0.4,1.6c0.2,0.6,0.4,1.3,0.6,2.1c0.2,0.8,0.4,1.5,0.6,2.4c0.2,0.8,0.4,1.6,0.6,2.4c0.2,0.8,0.4,1.4,0.5,2
			c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.7,0.3,1.1l1.8,7l3.6,13.4c0.1,0.3,0.2,0.6,0.2,0.9s0.2,0.6,0.3,0.9
			l0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0,0.6c0,0.3-0.1,0.6-0.2,0.7s-0.4,0.3-0.9,0.5c-0.3,0.1-0.7,0.2-1.3,0.2
			c-0.6,0-1.2,0-1.7,0c-0.3,0-0.6,0-1,0.1c-0.4,0-0.8,0.1-1.2,0.1s-0.7,0-1-0.1C299.2,516.8,299,516.6,298.8,516.5z M295.2,498.5
			c-0.1-0.6-0.2-1.3-0.3-2.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.8-0.3-1.7-0.5-2.5c-0.2-0.8-0.4-1.5-0.7-2l-1.2,9H295.2z"
        />
        <path
          fill="#454545"
          d="M318.7,508.6c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.2-0.1c0.7-0.1,1.4-0.1,2.2-0.1
			c0.8,0,1.3,0,1.7,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.7-0.1-2.5-0.1h-3.8c-0.6,0-1.1,0-1.5,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-1-0.1-1.9-0.1-2.9c0-0.9,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.8,0.2-2.8c0-0.4,0-0.9-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.9c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5c0,0.8,0.1,1.7,0.1,2.5v-0.1
			c0.1,0.9,0.2,1.9,0.2,2.9c0,1,0,1.9-0.1,2.9c-0.1,1-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1.1,0.1,1.4
			c0,0.4,0,0.7,0.1,0.9c0,0.2,0.1,0.4,0.1,0.6V508.6z"
        />
        <path
          fill="#454545"
          d="M340,488.9l-0.2,4.8l2.5,0.1c0.4,0,0.8,0,1.5,0c0.6,0,1.2,0,1.8,0s1.1,0.1,1.5,0.2c0.4,0.1,0.6,0.3,0.6,0.5
			l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
			c-0.3,0-0.6,0-1.1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2c0,0.2-0.1,0.4-0.1,0.6
			c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.5,0
			c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1s0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6
			c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8c-0.3,0.2-0.8,0.3-1.6,0.3
			c-0.7,0.1-1.7,0.1-2.9,0.1s-2.7,0-4.5,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0h-1.8
			c-0.9,0-1.6,0-2.1-0.1c-0.4-0.1-0.6-0.6-0.6-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3
			c0.3-1.3,0.4-2.5,0.4-3.8c0-1.2,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.2,0.1-2.2,0.2-3.1
			c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5c0-0.4,0-0.8,0-1.3c0-0.5,0-0.9,0.1-1.3l0.2-2.5v-0.4
			c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1
			c0.3-0.3,0.9-0.4,2-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2c0.8,0,1.6-0.1,2.3-0.2
			c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0c0.6,0,1.1,0,1.7,0c0.5,0,1,0,1.4,0c0.5,0,0.8,0.6,0.8,1.7
			c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.2,0-2.2,0H340V488.9z"
        />
        <path
          fill="#454545"
          d="M367.4,485.5c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.3-0.6,0.6-0.9,0.9c-0.2,0.4-0.3,0.8-0.4,1.2v1.2c0,0.9,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1.5,2.4,2.4
			l1.7,1.5l2.8,2.3c1.3,1.3,2.2,2.7,2.9,4.2c0.7,1.5,1,3.3,1,5.2c0,0.4,0,1-0.1,1.6c-0.1,0.6-0.2,1.3-0.4,1.9
			c-0.2,0.6-0.4,1.2-0.6,1.8c-0.3,0.6-0.5,1-0.9,1.3c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-0.9,0.6l-0.7,0.4
			c-0.4,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-0.8,0.4c-0.6,0.3-1.3,0.5-1.9,0.7c-0.7,0.2-1.4,0.3-2.3,0.3c-0.5,0-1-0.1-1.6-0.2
			c-0.5-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.2-0.4-1.9-0.8c0,0-0.1,0-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.2-0.9-0.4-1.3-0.7
			c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.4-1c0.2-0.4,0.3-0.9,0.4-1.4c0.1-0.5,0.2-0.9,0.3-1.3
			c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0.1l2.3,0.7l1.4,0.4
			c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.1-0.9
			c-0.3-1.1-0.6-2-1.1-2.5c-0.5-0.6-1-1-1.5-1.3l-1.9-1.5c-0.6-0.4-1.1-0.8-1.5-1.1c-0.4-0.3-0.9-0.7-1.4-1.1
			c-1.4-1.2-2.4-2.7-3.1-4.5c-0.7-1.7-1.1-3.5-1.1-5.2c0-1.2,0.1-2.4,0.4-3.5c0.3-1.1,0.6-2.3,1.1-3.6c0.4-1,1-1.8,1.8-2.6
			c0.8-0.8,1.6-1.4,2.6-2c1-0.5,2-1,3-1.2c1.1-0.3,2.1-0.4,3.1-0.4c0.5,0,1.3,0,2.1,0.1c0.9,0.1,1.7,0.2,2.5,0.3
			c0.8,0.2,1.5,0.4,2,0.7s0.7,0.7,0.6,1.2c-0.3,1-0.6,2.1-0.8,3.3c-0.3,1.2-0.7,2.4-1.4,3.7L367.4,485.5z"
        />
        <path
          fill="#454545"
          d="M407.1,502.5l0.9,1.2l0.4-0.9c0.3-1.1,0.9-1.6,1.7-1.6c0.3,0,0.6,0.1,0.9,0.2v-0.1c0.5,0.1,1,0.3,1.6,0.4
			c0.6,0.1,1.1,0.3,1.5,0.5c0.5,0.2,0.9,0.3,1.2,0.5c0.3,0.2,0.5,0.4,0.5,0.6c0,0.5-0.1,1.1-0.3,1.9c-0.2,0.7-0.4,1.5-0.7,2.2
			c-0.3,0.7-0.6,1.4-0.9,2.1c-0.3,0.7-0.7,1.2-1,1.6l-0.5,0.5l2.7,4c0.1,0.2,0.2,0.4,0.2,0.8c0,0.3,0.1,0.6,0.1,0.7
			c0,0.4-0.1,0.6-0.4,0.8c-0.3,0.2-0.6,0.3-0.9,0.3c-0.4,0.1-0.8,0.1-1.3,0.1c-0.5,0-1,0-1.4,0c-0.9,0-1.6,0-2,0
			c-0.5,0-0.8-0.1-1-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.4-0.5-0.7-0.8l-0.6-0.8l-1.1,0.4c-0.7,0.3-1.6,0.5-2.5,0.7
			c-0.9,0.2-2,0.2-3.3,0.2c-1.7,0-3.1-0.2-4.3-0.7c-1.2-0.5-2.2-1.2-2.9-2c-0.8-0.8-1.3-1.8-1.7-3c-0.4-1.1-0.5-2.4-0.5-3.7
			c0-1.2,0.1-2.3,0.4-3.4c0.2-1.1,0.5-2.2,0.9-3.3c0.4-1.1,0.9-2.2,1.4-3.3c0.6-1.1,1.1-2.3,1.8-3.6c-0.6-0.8-1.1-1.6-1.7-2.2
			c-0.5-0.7-1-1.3-1.4-1.9c-0.4-0.6-0.7-1.3-0.9-2c-0.2-0.7-0.4-1.5-0.4-2.4c0-1.1,0.2-2.2,0.6-3.3c0.4-1.1,1-2.1,1.8-3
			c0.8-0.9,1.7-1.6,2.7-2.1c1-0.5,2.2-0.8,3.4-0.8c1.6,0,3.1,0.5,4.7,1.5l0.3,0.3c0.6,1,1.1,2.1,1.5,3.2s0.6,2.3,0.6,3.5
			c-0.1,1-0.3,1.9-0.6,2.8c-0.3,0.9-0.7,1.8-1,2.6l-0.9,1.8l0.1-0.1c-0.1,0.3-0.4,0.8-0.7,1.3c-0.3,0.5-0.5,1-0.5,1.3
			c0,0.1,0,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.5h-0.1l1.9,2.9c0.3,0.5,0.6,1,0.9,1.4c0.3,0.5,0.6,0.9,1,1.4v-0.1L407.1,502.5z
			 M398.4,488.9l0.1,0.2c0.3-1,0.5-1.8,0.6-2.5c0.1-0.7,0.1-1.4,0.1-2.2c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.4-0.5-0.5
			c-0.3,0-0.7,0.1-1.1,0.3c-0.5,0.2-0.7,0.7-0.7,1.4c0,0,0,0.2,0.1,0.4c0.1,0.2,0.1,0.3,0.1,0.4L398.4,488.9z M401.2,510.3
			c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.6-0.2,0.9-0.3l-4.4-6.6c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0.3-0.1,0.4-0.1,0.5l-0.1,0.5
			c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6c0,0.9,0.3,1.6,0.8,2.3c0.5,0.7,1.1,1.2,1.7,1.6L401.2,510.3z"
        />
        <path
          fill="#454545"
          d="M456.4,494.3c-0.1,1.9-0.3,3.7-0.5,5.4c-0.3,1.7-0.7,3.3-1.2,4.8c-0.5,1.5-1.2,2.9-2.1,4.1
			c-0.9,1.3-2,2.4-3.3,3.5c-1.3,0.9-2.7,1.8-4.4,2.8c-1.6,0.9-3.2,1.6-4.8,1.9c-0.9,0.2-1.6,0.4-2.1,0.6c-0.6,0.2-1.1,0.3-1.7,0.3
			c-0.5,0-0.9,0-1.3,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.5-0.1-0.9l-0.2-8.1l-0.1-1
			c-0.1-0.4-0.1-1-0.1-1.6c0-0.6,0-1.2,0-1.8c0-1.3,0-2.6,0.1-3.9c0.1-1.3,0.1-2.6,0.1-3.8c0-0.8,0-1.6-0.1-2.4
			c-0.1-0.8-0.1-1.7-0.2-2.5l-0.1-1.9c0-0.1,0-0.3-0.1-0.6c0-0.3-0.1-0.7-0.1-1.1c0-1,0-2,0.1-3.1c0.1-1.1,0.2-2.1,0.2-3.1l0.1-3.5
			c0-0.4,0.1-0.8,0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.6-0.1,1-0.1c0.4,0,1,0,1.7,0c1.7,0,3.5,0.2,5.2,0.6
			c1.7,0.4,3.3,1,4.8,1.7c1.5,0.8,2.9,1.7,4.1,2.8c1.2,1.1,2.2,2.3,3,3.7c0.8,1.4,1.4,2.8,1.9,4.3c0.5,1.5,0.8,2.8,0.8,4
			c0,0.1,0,0.3,0,0.4C456.5,494.1,456.4,494.2,456.4,494.3z M441.9,484.3c0,1.6,0,3.2,0,4.7c0,1.6,0,3.1,0.1,4.7l0.3,13.3
			c0.6-0.1,1.2-0.6,1.9-1.4c0.7-0.8,1.4-1.8,1.9-2.9s1.1-2.3,1.5-3.5c0.4-1.2,0.6-2.3,0.6-3.2v-0.7l-0.1-1c-0.1-0.3-0.1-0.7-0.1-1.1
			c0-0.4,0-0.7-0.1-1.1c0-0.5-0.1-1.1-0.3-1.8c-0.2-0.7-0.5-1.4-0.8-2.1s-0.7-1.3-1.1-1.9c-0.4-0.6-0.8-1-1.3-1.2l-0.4-0.2
			c-0.3-0.2-0.6-0.4-1-0.5c-0.4-0.2-0.8-0.3-1.1-0.4V484.3z"
        />
        <path
          fill="#454545"
          d="M460.6,477.9c0-0.3,0.1-0.5,0.4-0.6c0.3-0.1,0.5-0.2,0.9-0.3c0.3,0,0.7-0.1,1-0.1c0.3,0,0.6,0,0.8,0
			c0.4,0,1,0,1.6,0c0.6,0,1.2,0,1.8,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,0.8,0.2,0.9,0.5c0.2,0.4,0.3,0.8,0.2,1.2c0,0.5,0,0.9,0,1.2
			c0,0.4,0,0.7-0.1,1c0,0.3-0.1,0.6-0.2,0.9l0.1-0.1c-0.2,1.1-0.3,2.1-0.3,2.9c0,0.9-0.1,1.8-0.4,2.7c-0.2,0.6-0.3,1.2-0.3,1.8
			c0,0.6-0.1,1.2-0.1,1.8c0,2.4,0,4.7-0.1,7.1c-0.1,2.3-0.1,4.7-0.1,7.1c0,1.1,0.2,1.9,0.6,2.5c0.4,0.6,1,0.9,1.9,0.9
			c1.3,0,2.2-0.4,2.8-1.2c0.6-0.8,0.9-1.8,1-2.9c0.1-1.5,0.1-3,0.1-4.5c0-1.6,0-3.1,0-4.6c0-1.5,0-2.9,0.1-4.4
			c0.1-1.4,0.2-2.7,0.4-3.9c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.5-0.1-1.1-0.2-1.8s-0.1-1.4-0.1-2.2c0-0.9,0-1.6,0.1-2.1c0-0.5,0.1-0.8,0.3-1c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.7,0,1.3,0
			c0.4,0,0.8,0.1,1.2,0.2v-0.1c0.1,0,0.3,0.1,0.5,0.1h0.9c0,0,0.2,0,0.5-0.1c1.3-0.1,2.1-0.1,2.4,0.3c0.4,0.3,0.5,1,0.4,2.1
			c-0.1,0.7-0.1,1.5,0,2.2s0.1,1.5,0.1,2.2c0,1.1,0,2.2-0.1,3.4c-0.1,1.1-0.1,2.3-0.1,3.4c0,1.1,0.1,2.3,0.2,3.4
			c0.1,1.2,0.2,2.3,0.2,3.5c0,1.2-0.2,2.3-0.5,3.6c-0.3,1.2-0.5,2.6-0.5,4.1c-0.2,0.9-0.4,1.9-0.5,3.1c-0.2,1.2-0.4,2.3-0.8,3.5
			c-0.3,1.1-0.8,2.1-1.4,2.9c-0.6,0.8-1.4,1.4-2.4,1.6l-1.6,0.4c-0.7,0.2-1.2,0.3-1.6,0.4c-0.4,0.1-0.8,0.2-1.1,0.2
			c-0.3,0.1-0.6,0.1-0.9,0.1s-0.6,0-1,0c-1.4,0-2.7-0.1-4-0.3c-1.3-0.2-2.4-0.7-3.4-1.3c-1-0.7-1.8-1.6-2.3-2.8
			c-0.6-1.2-0.9-2.9-0.9-4.9c0-0.7-0.1-1.4-0.2-2c-0.1-0.6-0.2-1.2-0.2-1.8v-0.2c0.1-3.1,0.1-6.1,0.2-9.1c0.1-3,0.1-6,0.2-9.1
			c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.1-0.9-0.1-1.4l0.1-1.8c0-0.3,0.1-0.6,0.1-0.8c0-0.3,0-0.6,0-0.9l-0.1-1.3V477.9z"
        />
        <path
          fill="#454545"
          d="M490.5,517.1c-0.1-0.7-0.1-1.5-0.1-2.4c0-0.9,0-1.7,0-2.3V512c0-1.3,0.1-2.6,0.1-3.9c0-1.3,0-2.6,0-3.9
			c0-1.2,0-2.3,0-3.4c0-1.1,0-2.2-0.1-3.4v0.1c0-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.4,0-2.1v-2.7c0-0.5,0-0.9,0-1.4c0-0.5,0-0.9,0-1.4
			v-2.9c0-0.5,0-1.3-0.1-2.1c-0.1-0.9-0.1-1.8-0.1-2.6c0-0.9,0.1-1.6,0.2-2.3c0.1-0.7,0.4-1.1,0.8-1.3c0.5-0.2,1-0.3,1.6-0.2
			c0.6,0,1.1,0.1,1.5,0.1c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.8,0.2,1.6,0.4,2.4,0.6c0.8,0.2,1.6,0.4,2.4,0.6
			c0.6,0.1,1.3,0.4,1.8,0.7c0.6,0.3,1.2,0.7,1.7,1.1c2.2,1.5,3.8,3.4,4.8,5.6c1,2.2,1.6,4.6,1.6,7.1c0,1-0.2,2.1-0.5,3.2
			c-0.3,1.1-0.8,2.1-1.3,3.1c-0.5,1-1.2,1.8-1.9,2.5c-0.7,0.7-1.5,1.3-2.2,1.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			l-0.9,0.3l6.2,8.6l1.2,1.7l1.5,2.9c0,0.3-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1s-0.3,0-0.5,0.1
			l-5.5,0.3c-0.1,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.6-0.6-0.9-1.1c-0.3-0.4-0.7-0.9-1-1.4c-0.3-0.5-0.6-0.9-0.9-1.3l-1.1-1.7
			c-0.2-0.3-0.4-0.7-0.6-1.1c-0.2-0.5-0.4-0.9-0.6-1.4c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-0.9-0.4-1.3c0,0.8,0.1,1.8,0.2,3.1
			c0.1,1.2,0.2,2.4,0.2,3.5c0.1,1.1,0,2.1-0.1,2.9c-0.1,0.8-0.4,1.2-0.9,1.2c-0.4,0-0.9,0-1.5,0c-0.5,0-1.1,0-1.7,0
			c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0L490.5,517.1z M498.9,497.1c0.4-0.3,0.8-0.5,1.2-0.8c0.4-0.3,0.8-0.6,1.1-1
			c0.3-0.4,0.5-1,0.7-1.7c0.2-0.7,0.2-1.6,0.2-2.7c0-1.3-0.2-2.5-0.7-3.6c-0.5-1.1-1.3-1.9-2.5-2.3l-0.1,6V497.1z"
        />
        <path
          fill="#454545"
          d="M531.9,516.5c-0.1-0.1-0.3-0.3-0.3-0.7c-0.1-0.4-0.1-0.7-0.1-0.9l-0.3-1.9c-0.1-0.9-0.3-2-0.5-3.2
			c-0.2-1.2-0.4-2.2-0.5-3.2c-0.8,0.2-1.5,0.3-1.9,0.4c-0.4,0.1-0.8,0.1-1.1,0.1c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.2-1.7-0.2
			l-0.2,0.9l-1.9,8.6c-0.1,0.3-0.2,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.7,0.1-1,0.1s-0.7,0-1.1,0c-0.5,0-0.9,0-1.4-0.1
			c-0.5-0.1-0.9-0.1-1.3-0.1c-0.5,0-0.9,0-1.3,0c-0.4,0-0.6-0.2-0.6-0.7c0-0.1,0-0.5,0.1-0.9c0.1-0.5,0.2-0.8,0.2-0.9
			c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.7,0.4-1.3c0.1-0.8,0.2-1.7,0.4-2.6
			c0.2-0.9,0.4-1.7,0.4-2.3l2.9-10.8c0.3-0.7,0.5-1.4,0.6-2.2c0.1-0.7,0.3-1.4,0.4-2.2l0.8-2.5l0.5-1.5c0.1-0.6,0.3-1.3,0.5-2
			c0.2-0.8,0.3-1.6,0.5-2.4c0.2-0.8,0.3-1.6,0.5-2.3c0.2-0.8,0.3-1.4,0.5-2.1c0.1-0.4,0.2-0.7,0.3-0.9c0.1-0.2,0.3-0.4,0.5-0.5
			c0.3-0.1,0.7-0.2,1.2-0.2c0.6,0,1.3-0.1,2.4-0.1c0.9,0,1.5,0,1.9,0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.5,0.4,0.9
			c0.1,0.4,0.2,0.9,0.4,1.6c0.2,0.6,0.4,1.3,0.6,2.1c0.2,0.8,0.4,1.5,0.6,2.4c0.2,0.8,0.4,1.6,0.6,2.4c0.2,0.8,0.4,1.4,0.5,2
			c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.7,0.3,1.1l1.8,7l3.6,13.4c0.1,0.3,0.2,0.6,0.2,0.9
			c0.1,0.3,0.2,0.6,0.3,0.9l0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0,0.6c0,0.3-0.1,0.6-0.2,0.7s-0.4,0.3-0.9,0.5
			c-0.3,0.1-0.7,0.2-1.3,0.2c-0.6,0-1.2,0-1.7,0c-0.3,0-0.6,0-1,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.7,0-1-0.1
			C532.3,516.8,532.1,516.6,531.9,516.5z M528.3,498.5c-0.1-0.6-0.2-1.3-0.3-2.1c-0.1-0.8-0.3-1.6-0.4-2.5c-0.2-0.8-0.3-1.7-0.5-2.5
			c-0.2-0.8-0.4-1.5-0.7-2l-1.2,9H528.3z"
        />
        <path
          fill="#454545"
          d="M544.7,517.1c-0.5-0.1-0.8-0.2-0.9-0.4c-0.1-0.2-0.1-0.6-0.1-1.2l0.1-1.9c0-0.3,0-0.5,0-0.8c0-0.3,0-0.6,0-1
			l0.1-5.8c0-0.5,0-1.1,0-1.7s0-1.1,0-1.7c0-1.2,0.1-2.3,0.1-3.5c0-1.2,0-2.4,0-3.5v-2.9c0-0.6-0.1-1.2-0.2-1.8s-0.1-1.2-0.2-1.8
			c-0.1-0.5-0.1-0.9-0.2-1.4c-0.1-0.5-0.1-1-0.2-1.5c0-0.7-0.1-1.4-0.1-2c0-0.7,0-1.3,0-2v-4c0.1-0.7,0.2-1.2,0.5-1.3
			c0.3-0.2,0.7-0.3,1.4-0.4c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0c1.1,0,2.2,0.1,3.3,0.2c1.1,0.1,2.1,0.3,3.2,0.4
			c0.3,0.1,0.6,0.2,0.8,0.3c0.3,0.1,0.5,0.3,0.8,0.4l2.1,0.9c2.7,1.3,4.3,3.6,4.5,6.7c0.1,1.3,0,2.5-0.2,3.7
			c-0.1,0.9-0.4,1.8-0.7,2.6c-0.4,0.8-0.9,1.6-1.5,2.3l-1.2,1.3c1.2,0.4,2.2,1,3,1.9c0.8,0.8,1.5,1.8,2.2,2.7c1,1.5,1.5,3,1.5,4.4
			c0,1.1-0.2,2.2-0.7,3.2c-0.2,0.5-0.4,0.8-0.5,1.1c-0.1,0.3-0.2,0.5-0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.1
			c-0.8,1.5-1.7,2.7-2.8,3.5c-1.1,0.9-2.2,1.6-3.4,2.2c-0.8,0.4-1.6,0.7-2.3,1c-0.7,0.3-1.6,0.4-2.8,0.5c-0.2,0.1-0.5,0.2-0.7,0.1
			s-0.6,0-0.9,0c-1,0.1-2,0.2-2.8,0.2c-0.8,0-1.6,0-2.3,0c-0.2,0-0.3,0-0.4-0.1C544.9,517.1,544.8,517.1,544.7,517.1z M551,488.4
			l0.1,5c0.4-0.1,0.8-0.4,1.2-0.8c0.4-0.4,0.7-0.8,1-1.2c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.5,0.3-0.9,0.4-1.4c0.1-0.5,0.2-1.1,0.2-1.7
			c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.6-0.9-1.2-1.4c-0.3-0.4-0.5-0.6-0.9-0.7c-0.3-0.1-0.7-0.2-1-0.2L551,488.4z M551.9,503.1
			c0,1.1,0,2.2,0.1,3.3c0,1.1,0,2.1-0.2,3.2l0.9-0.2c0.5-0.1,1-0.2,1.5-0.5c0.5-0.2,1-0.5,1.4-0.8c0.4-0.3,0.7-0.7,0.7-1.1
			c0-0.4,0.1-0.8,0.1-1.2c0-0.4,0.1-0.8,0.1-1.2c0.1-1.1-0.3-2.1-1.2-3c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.5-0.3
			c-0.9-0.2-1.5-0.3-1.9-0.2c-0.4,0-0.6,0-0.6,0c0,0.5,0.1,0.9,0.1,1.3C551.9,502.3,551.9,502.7,551.9,503.1z"
        />
        <path
          fill="#454545"
          d="M578.2,508.6c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.2-0.1c0.7-0.1,1.4-0.1,2.2-0.1
			c0.8,0,1.3,0,1.7,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2s-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.7-0.1-2.5-0.1h-3.8c-0.6,0-1.1,0-1.5,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-1-0.1-1.9-0.1-2.9c0-0.9,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.8,0.2-2.8c0-0.4,0-0.9-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.9c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1s1-0.2,1.8-0.2c0.3,0,0.6,0,0.8,0
			c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9c0,0.4,0,0.9,0,1.6
			c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5s0.1,1.7,0.1,2.5v-0.1c0.1,0.9,0.2,1.9,0.2,2.9c0,1,0,1.9-0.1,2.9
			c-0.1,1-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1.1,0.1,1.4c0,0.4,0,0.7,0.1,0.9c0,0.2,0.1,0.4,0.1,0.6V508.6z"
        />
        <path
          fill="#454545"
          d="M599.5,488.9l-0.2,4.8l2.5,0.1c0.4,0,0.8,0,1.5,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.5l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1s-0.6,0-1.1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2
			c0,0.2-0.1,0.4-0.1,0.6c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1.1-0.1s0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.5,0
			c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.4,0.1,0.7
			c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8c-0.3,0.2-0.8,0.3-1.6,0.3
			c-0.7,0.1-1.7,0.1-2.9,0.1c-1.2,0-2.7,0-4.5,0c-0.5,0-1,0-1.4-0.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0
			h-1.8c-0.9,0-1.6,0-2.1-0.1c-0.4-0.1-0.7-0.6-0.7-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3
			c0.3-1.3,0.4-2.5,0.4-3.8c0-1.2,0.1-2.5,0.1-3.8v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.2,0.1-2.2,0.2-3.1
			c0.2-0.9,0.2-1.9,0.2-2.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5c0-0.4,0-0.8,0-1.3s0-0.9,0.1-1.3l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5
			c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.9-0.4,2-0.4c0,0,0.1,0,0.1,0
			c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2c0.8,0,1.6-0.1,2.3-0.2c0.7-0.1,1.6-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0
			c0.5,0,1.1,0,1.7,0s1.1,0,1.7,0c0.5,0,1,0,1.4,0c0.5,0,0.8,0.6,0.8,1.7c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1
			c-0.5,0-1.2,0-2.2,0h-5.5V488.9z"
        />
        <path
          fill="#454545"
          d="M626.9,485.5c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.3-0.6,0.6-0.9,0.9c-0.2,0.4-0.3,0.8-0.4,1.2v1.2c0,0.9,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1.5,2.4,2.4
			l1.7,1.5l2.8,2.3c1.3,1.3,2.2,2.7,2.9,4.2c0.7,1.5,1,3.3,1,5.2c0,0.4,0,1-0.1,1.6c-0.1,0.6-0.2,1.3-0.4,1.9
			c-0.2,0.6-0.4,1.2-0.7,1.8s-0.5,1-0.9,1.3c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-0.9,0.6l-0.7,0.4c-0.4,0.2-0.7,0.4-1.1,0.6
			c-0.4,0.2-0.7,0.4-0.8,0.4c-0.6,0.3-1.3,0.5-1.9,0.7c-0.7,0.2-1.4,0.3-2.3,0.3c-0.5,0-1-0.1-1.6-0.2c-0.5-0.1-1.1-0.2-1.7-0.3
			c-0.6-0.1-1.2-0.4-1.9-0.8c0,0-0.1,0-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.6-0.6-0.6-1.1
			c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.4-1c0.2-0.4,0.3-0.9,0.4-1.4c0.1-0.5,0.2-0.9,0.3-1.3c0.1-0.4,0.3-0.7,0.5-0.9
			c0.2-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0,0.3,0s0.2,0,0.4,0.1l2.3,0.7l1.4,0.4c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,1-0.2
			c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.1-0.9c-0.3-1.1-0.6-2-1.1-2.5s-1-1-1.5-1.3l-1.9-1.5
			c-0.6-0.4-1.1-0.8-1.5-1.1c-0.4-0.3-0.9-0.7-1.4-1.1c-1.4-1.2-2.4-2.7-3.1-4.5c-0.7-1.7-1.1-3.5-1.1-5.2c0-1.2,0.1-2.4,0.4-3.5
			c0.3-1.1,0.6-2.3,1.1-3.6c0.4-1,1-1.8,1.8-2.6c0.8-0.8,1.6-1.4,2.6-2c1-0.5,2-1,3-1.2c1.1-0.3,2.1-0.4,3.1-0.4
			c0.5,0,1.3,0,2.1,0.1c0.9,0.1,1.7,0.2,2.5,0.3c0.8,0.2,1.5,0.4,2,0.7s0.7,0.7,0.6,1.2c-0.3,1-0.6,2.1-0.8,3.3
			c-0.3,1.2-0.7,2.4-1.4,3.7L626.9,485.5z"
        />
      </g>
    </g>
  ),
})
