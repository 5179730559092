import { BoxProps, useRadio, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  iconName: IconName
  text: string
}

const IconCardRadio: FC<Props & BoxProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, iconName, text, ...restProps } = props
  const { getInputProps, getRadioProps } = useRadio(restProps)
  const input = getInputProps()
  const isSelected = input.checked
  const checkbox = getRadioProps()

  return (
    <Flex as="label" cursor="pointer">
      <input {...input} />
      <Flex
        {...checkbox}
        borderStyle="solid"
        borderWidth={isSelected ? '2px' : '1px'}
        borderColor={isSelected ? 'dark_green' : 'lighten'}
        borderRadius="4px"
        align="center"
        justify="center"
        width="256px"
        height="166px"
        position="relative"
        direction="column"
        px={{ base: 6, md: 8 }}
        py={{ base: 6, md: 6 }}
        _hover={{
          border: 'solid 2px #003F2C',
        }}
      >
        {isSelected && (
          <Flex
            backgroundColor="dark_green"
            align="center"
            justify="center"
            borderRadius="50%"
            width="20px"
            height="20px"
            position="absolute"
            right="8px"
            top="8px"
          >
            <Icon name="CheckMark" color="white" size="5xs" />
          </Flex>
        )}
        <Icon name={iconName} size="md" />
        <Text size="lg" mt="4" textAlign="center">
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}

export default IconCardRadio
