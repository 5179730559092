"use client"

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoPartnerMiimosa',
  viewBox: '0 0 189 48',
  path: (
    <>
      <path
        d="M105.752 33.0364C105.866 28.8179 107.128 25.1057 109.994 22.0684C112.46 19.3686 115.957 17.8499 119.626 17.8499C124.099 17.7374 127.94 19.1436 130.979 22.4621C133.1 24.7682 134.361 27.7492 134.591 30.8428C135.049 34.8363 133.903 38.886 131.38 42.092C126.736 47.9979 118.824 49.0666 112.574 45.2981C108.676 42.9357 106.612 39.4485 105.924 35.0612C105.809 34.2738 105.752 33.5988 105.752 33.0364ZM128.571 32.6427C128.571 30.899 128.341 29.1554 127.883 27.5243C127.252 25.1619 126.163 23.0246 124.271 21.4497C121.92 19.4248 118.48 19.2561 115.9 21.0559C114.352 22.2371 113.262 23.8683 112.804 25.7244C111.714 29.4366 111.6 33.3176 112.402 37.0861C112.804 39.2797 113.778 41.3046 115.212 42.9357C117.734 45.748 121.633 46.2542 124.557 44.0606C126.163 42.8232 127.309 41.1358 127.768 39.2235C128.341 37.0299 128.571 34.8363 128.571 32.6427Z"
        fill="currentColor"
      />
      <path
        d="M146.918 47.3229C144.28 47.2667 141.7 46.5355 139.464 45.2418C137.687 44.1731 136.311 42.7107 135.909 40.6859C135.623 39.7859 135.737 38.8298 136.253 38.0423C136.999 36.9736 138.145 36.6924 139.464 36.9174C140.554 37.0299 141.471 37.7048 141.872 38.661C142.216 39.5047 142.503 40.3484 142.675 41.2483C143.592 45.4106 148.064 46.3105 151.103 45.1293C152.995 44.3981 154.027 42.3733 153.568 40.4609C153.282 38.886 152.25 37.8173 150.988 36.8049C149.326 35.455 147.319 34.6675 145.484 33.6551C143.592 32.7552 141.758 31.6302 140.038 30.3928C138.604 29.2679 137.457 27.918 137.171 26.1744C136.655 23.4183 137.687 21.2247 140.152 19.5935C141.758 18.6374 143.592 18.0186 145.484 17.9062C148.58 17.4562 151.734 17.9624 154.543 19.3686C156.378 20.3247 157.697 21.6747 158.041 23.6995C158.155 24.262 158.098 24.8807 157.926 25.4994C157.582 26.7368 156.55 27.1868 154.944 27.018C153.683 26.9056 152.594 26.0056 152.364 24.7682C152.192 24.0932 152.02 23.4183 151.791 22.7433C150.988 20.2685 148.408 19.4248 146.516 19.5373C146.115 19.5373 145.771 19.5935 145.37 19.706C142.274 20.3247 141.7 23.587 143.076 25.6119C143.707 26.5118 144.567 27.243 145.542 27.8055C147.663 29.0429 149.842 30.1116 151.906 31.349C153.396 32.1927 154.772 33.1489 156.148 34.1051C157.811 35.2862 158.901 37.0861 159.13 39.111C159.359 41.8671 157.868 44.5106 155.346 45.748C152.823 46.9855 150.071 47.3229 146.918 47.3229Z"
        fill="currentColor"
      />
      <path
        d="M63.0948 40.7984C63.0948 41.6983 63.2668 42.542 63.6108 43.3857C63.7828 43.7794 64.0695 44.1731 64.2988 44.5106C64.5855 44.9043 64.9868 45.1856 65.2735 45.5231C65.6175 46.0293 65.5028 46.873 64.7575 46.9292C63.7828 46.9855 62.8081 46.9855 61.8334 46.9855C59.9414 46.9855 58.2214 46.9855 56.272 46.9855C55.6413 46.9855 55.2973 46.648 55.3547 46.0855C55.412 45.9168 55.4693 45.6918 55.6413 45.5793C55.756 45.4106 55.928 45.2981 56.1 45.1856C57.132 44.5106 57.648 43.3294 57.5907 42.1483C57.5334 40.1797 57.5907 37.9861 57.5907 36.0174C57.5907 35.0613 57.4187 34.1051 56.96 33.2051C55.8707 31.2928 53.692 30.2241 51.4559 30.5616C49.1052 30.8428 47.3279 32.8114 47.2705 35.1175C47.1559 37.5923 47.2132 40.2359 47.2132 42.7107C47.2132 43.3857 47.4999 44.0044 47.9585 44.5106C48.4172 45.0168 49.1626 45.2981 49.3346 45.973C49.3919 46.1418 49.3919 46.3105 49.3919 46.4792C49.3346 46.8167 48.9906 47.0417 48.6465 47.0417H46.9838C44.6905 47.0417 42.5691 47.0417 40.2757 47.0417C39.8171 47.0417 39.473 46.9855 39.301 46.5355C39.129 46.1418 39.301 45.6918 39.6451 45.4668C40.6771 44.6231 41.3651 43.4419 41.6518 42.1483C41.7091 41.6983 41.7664 41.1921 41.7664 40.7421C41.7664 36.1299 41.7664 31.5177 41.7664 26.9618C41.7664 25.4994 42.3971 25.1057 43.7731 25.7244C45.4358 26.5118 47.0412 27.3555 48.7612 28.0867C51.1119 29.2116 53.864 29.2116 56.2147 28.0867C57.9347 27.2993 59.5974 26.4556 61.3174 25.6681C62.4641 25.1057 63.1521 25.5556 63.1521 26.7931L63.0948 40.7984Z"
        fill="currentColor"
      />
      <path
        d="M60.2281 16.5C62.0628 16.5 63.4961 17.4562 63.9548 18.9186C64.6428 21.2247 63.1521 23.2496 60.5721 23.4183C58.5654 23.587 56.8454 22.4621 56.5014 20.7185C55.9853 18.4124 57.648 16.5 60.2281 16.5Z"
        fill="currentColor"
      />
      <path
        d="M44.5185 16.5C46.3532 16.5 47.7865 17.4562 48.2452 18.9186C48.9332 21.2247 47.4425 23.2496 44.8051 23.4183C42.7984 23.587 41.0784 22.4621 40.7344 20.7185C40.2757 18.4124 41.9384 16.5 44.5185 16.5Z"
        fill="currentColor"
      />
      <path
        d="M35.861 45.4106C35.6316 45.2418 35.4023 45.0731 35.173 44.9044C34.485 44.3981 34.0263 43.6669 33.9689 42.8795C33.5103 40.5734 33.5676 38.2673 33.5676 35.905C33.5676 33.4864 33.5676 31.0115 33.5676 28.5929C33.5676 26.7368 33.5676 24.8807 33.5676 23.0246C33.5676 21.7309 33.7969 20.5497 34.8863 19.6498C35.2303 19.4248 35.345 18.9748 35.173 18.5811C35.001 18.1874 34.5996 18.1311 34.2556 18.1311C32.7649 18.1311 31.7902 18.1311 30.2995 18.1311C29.2675 18.0186 28.2928 18.6374 27.9488 19.6498L19.75 37.5923L18.8327 39.561L14.5899 30.6741C12.8699 26.9056 11.0925 23.1933 9.3725 19.4811C9.08582 18.6936 8.28314 18.1874 7.48046 18.1874C5.81776 18.1311 4.15507 18.1874 2.49237 18.1874C1.68969 18.1874 1.34568 18.7498 1.74702 19.4248C1.91903 19.706 2.14836 19.931 2.3777 20.0998C2.89371 20.5497 3.18038 21.2247 3.18038 21.8996L3.12305 41.2483C3.12305 42.992 2.66437 44.5669 0.887006 45.4106C0.543 45.5793 0.428332 45.973 0.543001 46.3105C0.65767 46.648 0.944341 46.873 1.28835 46.873C3.06571 46.873 4.84308 46.873 6.56311 46.873C6.96445 46.873 7.30846 46.5917 7.36579 46.198C7.36579 46.0855 7.36579 46.0293 7.30846 45.9168C7.19379 45.6356 7.02179 45.4106 6.79245 45.2981C5.81776 44.6794 5.30175 43.6107 5.35909 42.4858L5.18708 24.8807L14.6473 44.2856C14.9913 45.0168 15.3926 45.7481 15.9086 46.423C16.482 47.2105 17.2273 47.1542 17.858 46.423C18.1447 46.0293 18.4313 45.5793 18.6607 45.1293L27.8915 24.5995L28.1782 42.6545C28.2928 43.7232 27.8342 44.7919 26.9168 45.4106C26.5155 45.6356 26.2861 46.0855 26.4581 46.5355C26.6301 47.0417 27.0888 47.098 27.5475 47.098C29.6689 47.098 31.2169 47.098 33.3383 47.098H35.4023C35.8036 47.1542 36.205 46.9292 36.3197 46.5355C36.377 46.0293 36.205 45.5793 35.861 45.4106Z"
        fill="currentColor"
      />
      <path
        d="M103.917 45.4106C103.688 45.2418 103.458 45.0731 103.229 44.9043C102.541 44.3981 102.082 43.6669 102.025 42.8795C101.566 40.5734 101.624 38.2673 101.624 35.9049C101.624 33.4864 101.624 31.0678 101.624 28.5929C101.624 26.7368 101.624 24.8807 101.624 23.0246C101.624 21.7309 101.853 20.5497 102.942 19.6498C103.286 19.4248 103.401 18.9748 103.229 18.5811C103.057 18.1874 102.713 18.1311 102.312 18.1311C100.821 18.1311 99.8461 18.1311 98.3554 18.1311C97.3234 18.0749 96.3487 18.6936 96.0047 19.6498L87.7486 37.5923L86.8312 39.561L82.5885 30.674C80.8685 26.9056 79.0911 23.1933 77.3711 19.4811C77.0844 18.6936 76.2817 18.1874 75.479 18.1874C73.8163 18.1311 72.1536 18.1874 70.4909 18.1874C69.6882 18.1874 69.3442 18.7499 69.8029 19.4248C69.9749 19.6498 70.2043 19.931 70.4336 20.0998C70.9496 20.5497 71.2363 21.2247 71.2363 21.8996L71.1789 41.2483C71.1789 42.992 70.7203 44.5669 68.9429 45.4106C68.5989 45.5793 68.4269 45.973 68.5989 46.3105C68.6562 46.648 69.0002 46.873 69.4016 46.873C71.1789 46.873 72.9563 46.873 74.6763 46.873C75.0777 46.873 75.4217 46.5917 75.479 46.1418C75.479 46.0293 75.479 45.973 75.4217 45.8605C75.307 45.5793 75.135 45.3543 74.8484 45.2418C73.8737 44.6231 73.3577 43.5544 73.415 42.4295L73.243 24.8244L82.7032 44.2294C83.0472 44.9606 83.4485 45.6918 83.9645 46.3667C84.5379 47.1542 85.2832 47.0979 85.9139 46.3667C86.2006 45.973 86.4872 45.523 86.7166 45.0731L96.0047 24.5432L96.2914 42.5982C96.4061 43.6669 95.8901 44.6794 95.0301 45.2981C94.6287 45.5231 94.3994 45.973 94.5714 46.423C94.7434 46.9292 95.202 46.9855 95.6607 46.9855C97.7821 46.9855 99.3301 46.9855 101.451 46.9855H103.516C103.974 47.0417 104.376 46.7605 104.49 46.3105C104.433 45.9168 104.204 45.5793 103.917 45.4106Z"
        fill="currentColor"
      />
      <path
        d="M188.256 45.5231C187.109 44.8481 186.192 43.8919 185.504 42.767C185.102 42.0358 184.873 41.1921 184.529 40.4609C184.185 39.7297 183.841 38.8298 183.44 38.0423C182.236 35.23 180.974 32.4177 179.77 29.6054C178.681 27.1305 177.592 24.6557 176.502 22.1809C176.158 21.3934 175.814 20.606 175.47 19.8185C175.184 18.8061 174.151 18.1311 173.062 18.1874H171.055C169.851 18.1874 169.221 18.6936 169.393 19.7623C169.45 20.2123 169.565 20.5497 169.679 20.9997C169.737 21.4497 169.737 21.9559 169.679 22.4059C169.507 23.5308 169.221 24.5995 168.762 25.6681V25.7244C168.705 25.8931 168.59 26.1181 168.533 26.2868L167.558 28.7054C166.985 30.2241 166.354 31.6865 165.781 33.2051L164.003 37.6486L162.57 41.2483C162.398 41.7546 162.169 42.2045 161.997 42.7107C161.825 43.1607 161.595 43.6107 161.309 44.0044C160.965 44.3419 160.621 44.6231 160.219 44.8481C159.933 45.0731 159.646 45.2981 159.302 45.5231C158.958 45.8043 158.901 46.2543 159.13 46.648C159.302 46.873 159.531 46.9855 159.761 46.9855H165.035C165.494 46.9855 165.838 46.648 165.838 46.198C165.838 45.973 165.723 45.6918 165.551 45.5793L165.035 45.1856C164.003 44.3981 164.118 43.0482 164.462 42.092L165.265 40.0672H165.551C167.042 40.0109 168.533 39.8984 170.023 39.6172C172.546 39.2235 174.954 38.3798 177.19 37.0861L177.248 37.2549L178.452 39.9547C178.738 40.6296 179.082 41.2483 179.369 41.9233C179.885 43.1607 179.77 44.5669 178.566 45.3543C178.222 45.523 177.993 45.8605 177.993 46.2543C177.993 46.7042 178.337 47.0417 178.796 47.0417H187.682C188.141 47.0417 188.485 46.648 188.485 46.2543C188.542 45.9168 188.428 45.6918 188.256 45.5231ZM166.067 38.0986L166.354 37.4236L169.278 29.7179L169.622 28.7617L170.138 27.468C170.195 27.2993 170.253 27.1305 170.367 26.9056L171.399 24.2057L173.005 27.468L173.234 27.918C173.693 28.9304 174.151 29.9428 174.61 30.9553L176.502 35.23C173.291 37.4236 168.246 38.0423 166.067 38.0986Z"
        fill="currentColor"
      />
      <path
        d="M5.61474 5.73163C5.27527 6.3931 4.81236 6.79399 4.02028 6.79399C3.04303 6.79399 2.44639 6.17261 2.39496 5.1804H6.21138C6.22166 5.12027 6.22166 5 6.22166 4.9098C6.22166 3.44655 5.35757 2.46437 3.93798 2.46437C2.53897 2.46437 1.5 3.49666 1.5 4.98998C1.5 6.47327 2.56983 7.51559 4.02028 7.51559C5.19298 7.51559 5.8822 6.88419 6.2731 6.13252L5.61474 5.73163ZM3.9277 3.17595C4.79179 3.17595 5.26499 3.64699 5.33699 4.50891H2.41553C2.52868 3.67706 3.10475 3.17595 3.9277 3.17595Z"
        fill="currentColor"
      />
      <path
        d="M7.47585 7.39532H8.32966V4.49889C8.32966 3.64699 8.94687 3.18597 9.72867 3.18597C10.5413 3.18597 10.9631 3.64699 10.9631 4.50891V7.39532H11.8169V4.41871C11.8169 3.21603 11.138 2.46437 9.92412 2.46437C9.20404 2.46437 8.66913 2.72494 8.32966 3.18597V2.58463H7.47585V7.39532Z"
        fill="currentColor"
      />
      <path
        d="M15.6531 9.5H16.5069V6.63363C16.8772 7.14477 17.4636 7.51559 18.3174 7.51559C19.6341 7.51559 20.6114 6.3931 20.6114 4.98998C20.6114 3.59688 19.6341 2.46437 18.3174 2.46437C17.4636 2.46437 16.8772 2.83519 16.5069 3.35635V2.58463H15.6531V9.5ZM18.1322 6.79399C17.2167 6.79399 16.5069 6.01225 16.5069 4.98998C16.5069 3.96771 17.2167 3.18597 18.1322 3.18597C19.1198 3.18597 19.7164 3.88753 19.7164 4.98998C19.7164 6.10245 19.1198 6.79399 18.1322 6.79399Z"
        fill="currentColor"
      />
      <path
        d="M23.3147 7.51559C23.8908 7.51559 24.4463 7.25501 24.8063 6.77394V7.39532H25.6601V4.37862C25.6601 3.13586 24.8372 2.46437 23.6542 2.46437C22.5226 2.46437 21.71 3.09577 21.4528 4.11804L22.2243 4.32851C22.4095 3.57684 22.8415 3.18597 23.6439 3.18597C24.3742 3.18597 24.8063 3.64699 24.8063 4.40869V5.04009C24.4874 4.74944 23.9833 4.46882 23.3147 4.46882C22.214 4.46882 21.4219 5.15033 21.4219 5.9922C21.4219 6.9343 22.214 7.51559 23.3147 7.51559ZM23.397 6.82405C22.7489 6.82405 22.3272 6.4833 22.3272 5.9922C22.3272 5.51114 22.7489 5.17038 23.397 5.17038C23.9936 5.17038 24.4977 5.49109 24.8063 5.84187V6.02227C24.4771 6.48329 23.9936 6.82405 23.397 6.82405Z"
        fill="currentColor"
      />
      <path
        d="M27.1253 7.39532H27.9791V5.03007C27.9791 3.95768 28.4935 3.2461 29.1313 3.2461C29.6148 3.2461 29.8411 3.49666 29.8411 4.06793C29.8411 4.1882 29.8205 4.31849 29.7999 4.4588L30.6023 4.53898C30.6846 4.28842 30.7154 4.06793 30.7154 3.85746C30.7154 3.02561 30.1188 2.50445 29.3782 2.50445C28.6684 2.50445 28.2363 2.84521 27.9791 3.39644V2.58463H27.1253V7.39532Z"
        fill="currentColor"
      />
      <path
        d="M33.3198 7.51559C34.0501 7.51559 34.6674 6.97439 35.0068 6.17261L34.3073 5.85189C34.0296 6.45323 33.7415 6.76392 33.3506 6.76392C32.9495 6.76392 32.7746 6.51336 32.7746 5.98218V3.30624H34.7702V2.58463H32.7746V1.15145H32.0956L31.9208 2.58463H31.1081V3.30624H31.9208V5.96214C31.9208 7.0245 32.394 7.51559 33.3198 7.51559Z"
        fill="currentColor"
      />
      <path
        d="M39.5292 5.73163C39.1897 6.3931 38.7268 6.79399 37.9347 6.79399C36.9575 6.79399 36.3608 6.17261 36.3094 5.1804H40.1258C40.1361 5.12027 40.1361 5 40.1361 4.9098C40.1361 3.44655 39.272 2.46437 37.8524 2.46437C36.4534 2.46437 35.4144 3.49666 35.4144 4.98998C35.4144 6.47327 36.4843 7.51559 37.9347 7.51559C39.1074 7.51559 39.7966 6.88419 40.1875 6.13252L39.5292 5.73163ZM37.8421 3.17595C38.7062 3.17595 39.1794 3.64699 39.2514 4.50891H36.33C36.4431 3.67706 37.0192 3.17595 37.8421 3.17595Z"
        fill="currentColor"
      />
      <path
        d="M41.3903 7.39532H42.2441V4.49889C42.2441 3.64699 42.8613 3.18597 43.6431 3.18597C44.4558 3.18597 44.8775 3.64699 44.8775 4.50891V7.39532H45.7313V4.41871C45.7313 3.21603 45.0524 2.46437 43.8386 2.46437C43.1185 2.46437 42.5836 2.72494 42.2441 3.18597V2.58463H41.3903V7.39532Z"
        fill="currentColor"
      />
      <path
        d="M48.7706 7.51559C49.3467 7.51559 49.9022 7.25501 50.2622 6.77394V7.39532H51.116V4.37862C51.116 3.13586 50.2931 2.46437 49.1101 2.46437C47.9785 2.46437 47.1659 3.09577 46.9087 4.11804L47.6802 4.32851C47.8654 3.57684 48.2974 3.18597 49.0998 3.18597C49.8302 3.18597 50.2622 3.64699 50.2622 4.40869V5.04009C49.9433 4.74944 49.4393 4.46882 48.7706 4.46882C47.6699 4.46882 46.8778 5.15033 46.8778 5.9922C46.8778 6.9343 47.6699 7.51559 48.7706 7.51559ZM48.8529 6.82405C48.2048 6.82405 47.7831 6.4833 47.7831 5.9922C47.7831 5.51114 48.2048 5.17038 48.8529 5.17038C49.4496 5.17038 49.9536 5.49109 50.2622 5.84187V6.02227C49.933 6.48329 49.4496 6.82405 48.8529 6.82405Z"
        fill="currentColor"
      />
      <path
        d="M52.5813 7.39532H53.4351V5.03007C53.4351 3.95768 53.9494 3.2461 54.5872 3.2461C55.0707 3.2461 55.297 3.49666 55.297 4.06793C55.297 4.1882 55.2764 4.31849 55.2558 4.4588L56.0582 4.53898C56.1405 4.28842 56.1714 4.06793 56.1714 3.85746C56.1714 3.02561 55.5747 2.50445 54.8341 2.50445C54.1243 2.50445 53.6922 2.84521 53.4351 3.39644V2.58463H52.5813V7.39532Z"
        fill="currentColor"
      />
      <path
        d="M57.5136 1.60245C57.8427 1.60245 58.0999 1.36191 58.0999 1.05123C58.0999 0.740534 57.8427 0.5 57.5136 0.5C57.1844 0.5 56.9272 0.740534 56.9272 1.05123C56.9272 1.36191 57.1844 1.60245 57.5136 1.60245ZM57.0918 7.39532H57.9456V2.58463H57.0918V7.39532Z"
        fill="currentColor"
      />
      <path
        d="M61.0164 7.51559C61.5925 7.51559 62.1479 7.25501 62.508 6.77394V7.39532H63.3618V4.37862C63.3618 3.13586 62.5388 2.46437 61.3559 2.46437C60.2243 2.46437 59.4116 3.09577 59.1545 4.11804L59.926 4.32851C60.1111 3.57684 60.5432 3.18597 61.3456 3.18597C62.0759 3.18597 62.508 3.64699 62.508 4.40869V5.04009C62.1891 4.74944 61.685 4.46882 61.0164 4.46882C59.9157 4.46882 59.1236 5.15033 59.1236 5.9922C59.1236 6.9343 59.9157 7.51559 61.0164 7.51559ZM61.0987 6.82405C60.4506 6.82405 60.0289 6.4833 60.0289 5.9922C60.0289 5.51114 60.4506 5.17038 61.0987 5.17038C61.6953 5.17038 62.1994 5.49109 62.508 5.84187V6.02227C62.1788 6.48329 61.6953 6.82405 61.0987 6.82405Z"
        fill="currentColor"
      />
      <path
        d="M66.3803 7.51559C67.1107 7.51559 67.7279 6.97439 68.0674 6.17261L67.3679 5.85189C67.0901 6.45323 66.8021 6.76392 66.4112 6.76392C66.01 6.76392 65.8351 6.51336 65.8351 5.98218V3.30624H67.8308V2.58463H65.8351V1.15145H65.1562L64.9813 2.58463H64.1687V3.30624H64.9813V5.96214C64.9813 7.0245 65.4545 7.51559 66.3803 7.51559Z"
        fill="currentColor"
      />
      <path
        d="M72.8201 7.51559C73.3962 7.51559 73.9517 7.25501 74.3117 6.77394V7.39532H75.1655V4.37862C75.1655 3.13586 74.3426 2.46437 73.1596 2.46437C72.0281 2.46437 71.2154 3.09577 70.9582 4.11804L71.7297 4.32851C71.9149 3.57684 72.3469 3.18597 73.1493 3.18597C73.8797 3.18597 74.3117 3.64699 74.3117 4.40869V5.04009C73.9928 4.74944 73.4888 4.46882 72.8201 4.46882C71.7194 4.46882 70.9274 5.15033 70.9274 5.9922C70.9274 6.9343 71.7194 7.51559 72.8201 7.51559ZM72.9024 6.82405C72.2544 6.82405 71.8326 6.4833 71.8326 5.9922C71.8326 5.51114 72.2544 5.17038 72.9024 5.17038C73.4991 5.17038 74.0031 5.49109 74.3117 5.84187V6.02227C73.9826 6.48329 73.4991 6.82405 72.9024 6.82405Z"
        fill="currentColor"
      />
      <path
        d="M77.826 7.39532H78.8135L80.7989 2.58463H79.8833L78.3403 6.5735L76.7561 2.58463H75.8406L77.826 7.39532Z"
        fill="currentColor"
      />
      <path
        d="M85.2876 5.73163C84.9481 6.3931 84.4852 6.79399 83.6931 6.79399C82.7158 6.79399 82.1192 6.17261 82.0678 5.1804H85.8842C85.8945 5.12027 85.8945 5 85.8945 4.9098C85.8945 3.44655 85.0304 2.46437 83.6108 2.46437C82.2118 2.46437 81.1728 3.49666 81.1728 4.98998C81.1728 6.47327 82.2426 7.51559 83.6931 7.51559C84.8658 7.51559 85.555 6.88419 85.9459 6.13252L85.2876 5.73163ZM83.6005 3.17595C84.4646 3.17595 84.9378 3.64699 85.0098 4.50891H82.0883C82.2015 3.67706 82.7776 3.17595 83.6005 3.17595Z"
        fill="currentColor"
      />
      <path
        d="M89.2369 7.51559C90.3993 7.51559 91.0577 6.85412 91.4589 6.07238L90.8005 5.64142C90.4507 6.34298 90.029 6.79399 89.2369 6.79399C88.2494 6.79399 87.6733 6.09243 87.6733 4.98998C87.6733 3.87751 88.2391 3.18597 89.1957 3.18597C89.8644 3.18597 90.461 3.52673 90.7182 4.70935L91.5 4.44877C91.2634 3.09577 90.317 2.46437 89.1957 2.46437C87.7967 2.46437 86.7783 3.54677 86.7783 4.98998C86.7783 6.5735 87.7967 7.51559 89.2369 7.51559Z"
        fill="currentColor"
      />
    </>
  ),
})
