import { Flex, Heading } from '@chakra-ui/react'
import { ReactElement } from 'react'

const HeadingIntro = ({ texts, subTexts }: { texts: string[]; subTexts?: ReactElement[] }) => {
  return (
    <Flex direction="column" w="full" rowGap={3}>
      {texts.map((t, i) => (
        <Heading size={{ base: 'xs', md: 'sm' }} w="full" key={`text_${i}`} color="dark_green">
          {t}
        </Heading>
      ))}
      {subTexts && subTexts.map((st) => st)}
    </Flex>
  )
}

export default HeadingIntro
