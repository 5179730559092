import { GetServerSidePropsContext } from 'next'

import { Search, Projects } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { ErrorV3, createV3Client, toV3Result } from './base'

export class ProjectsAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  async getProjectsFromSearch(
    params: Search.SearchProjectsRequest
  ): Promise<Result<Search.SearchProjectsResponse, ErrorV3>> {
    return await this.call<Search.SearchProjectsResponse, ErrorV3>((c) =>
      c.post('/search/v1/SearchProjects', params, { adapter: 'fetch', fetchOptions: { cache: 'no-cache' } })
    )
  }

  async getMerchantsFromSearch(
    params: Search.SearchMerchantsRequest
  ): Promise<Result<Search.SearchMerchantsResponse, ErrorV3>> {
    return await this.call<Search.SearchMerchantsResponse, ErrorV3>((c) => c.post('/search/v1/SearchMerchants', params))
  }

  async fetchProject(params: Projects.FetchProjectRequest): Promise<Result<Projects.FetchProjectResponse, ErrorV3>> {
    return await this.call<Projects.FetchProjectResponse, ErrorV3>((c) =>
      c.post('/twirp/miimosa.projects.v1.ProjectsService/FetchProject', params)
    )
  }

  async getFeaturedProjects(
    params: Search.FeaturedProjectsRequest
  ): Promise<Result<Search.FeaturedProjectsResponse, ErrorV3>> {
    return await this.call<Search.FeaturedProjectsResponse, ErrorV3>((c) =>
      c.post('search/v1/FeaturedProjects', params)
    )
  }
}
