'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Inclusive',
  viewBox: '0 0 841.9 595.3',
  path: (
    <g>
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M568.2,230.9c0.5,3.8-0.6,7.2-1.9,10.6c-0.7,1.8-1.8,3.5-3.3,4.7c-1.8,1.5-3.3,3.3-5,4.9
					c-3.5,3.3-6.6,7-10.3,10c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.7,6.7-9.4,9.2c-2.7,1.8-5.1,3.9-7.2,6.5c-2.5,3.2-5.1,6.2-7.6,9.3
					c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.7,5c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.7-4.5,4.2c-0.6,0.6-1.3,1.3-1.6,2.1
					c-1,2.4-2.8,4.4-4.4,6.4c-1.9,2.4-4.2,4.5-6.1,7.1c-0.6,0.8-1.5,1.5-2.4,1.9c-2.4,1.1-4.2,3-5.6,5.2c-2.3,3.5-5.1,6.5-8.5,9
					c-1.2,0.8-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.6-5.3,5.6c-0.7,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.1,2.1-6.2,3.2c-0.8,0.4-1.6,0.7-2.5,0.8
					c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.8-1.3,1.5-2.1,1.3
					c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.8-1.8c-0.9-1.6-2.2-2.4-3.9-1.6c-2.2,1-4.1,1-6.2-0.2
					c-1.1-0.7-2.2-0.2-3.2,0.7c-0.5,0.5-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1c1.3,1.6,2.7,1.9,4.4,0.9c0.7-0.4,1.6-0.4,2.2,0.1
					c1.3,0.8,2.6,1.6,3.9,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3-0.6,0.5-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7
					c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.5-2.8,2.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4
					c1.1,1,1,2.6,2.1,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.8,0.3-1.1,0.1c-0.4-0.4-0.8-1-1.3-1.4
					c-0.4-0.4-1.5-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.8,2.3-2.2,2.7c-1.5,0.4-3,1-4.3,1.8c-0.3,0.2-0.5,0.5-0.8,0.8
					c-0.2,0.2,0,0.8,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.5,0,0.7
					c-0.8,1.5-1.8,2.9-2.7,4.2c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-1.9,0.1c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2
					c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.8c-0.6,0.2-1,0.8-1.3,1.4c-0.4,0.8,0.1,1.6,0.9,1.8c1.3,0.2,2.5,0.3,3.9,0.5
					c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.8,1,0.6,1.3c-0.2,0.2-0.6,0.5-0.9,0.5c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-1.9-0.2
					c-1.7,0.2-3-0.5-4.4-1.3c-0.8-0.5-1.4-1.1-1.6-2.1c-0.2-0.8-0.8-1.3-1.7-1.4c-0.2-0.1-0.5-0.1-0.8-0.1c-1.3-0.1-2.5-0.5-3.4-1.6
					c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6-3-8.7-4.9c-1-0.7-1.7-1.6-2.2-2.7c-0.3-0.6-0.5-1.2-1-1.6
					c-1.9-2.2-3.9-4.5-5.9-6.6c-1.3-1.3-2.5-2.6-3.4-4.2c-0.5-1-1.3-1.9-2.2-2.7c-3.3-3.3-6.7-6.5-10.7-9c-1-0.6-1.8-1.5-2.7-2.2
					c-1.5-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.1-1.4-2.7-2.7c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.3-1.6-2.2-3.1-2.7
					c-1-0.3-1.9-0.7-2.9-1.2c-1-0.5-2-1.2-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.8-1.9-1.3
					c-1.8-1.2-3.7-2.4-5.5-3.8s-3.4-2.8-5.4-3.8c-0.2-0.1-0.5-0.2-0.6-0.4c-1.6-2.4-4.4-3.6-6.1-6c-0.6-0.8-1.3-1.6-2.2-2.2
					c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.3c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.1-1-2.9-1.9c-1.5-2.2-3.6-3.7-5.8-5.2
					c-2.2-1.5-4.1-3.1-5.6-5.3c-0.1-0.1-0.1-0.2-0.2-0.3c-3.1-3.2-5.9-6.5-9-9.6c-3.2-3.2-6.5-6.2-9.8-9.4c-1-1-2-2.1-2.9-3.2
					c-0.5-0.6-0.9-1.3-1.3-1.9c-0.6-1-0.8-2.2-0.3-3.3c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.3-0.3-1.8c-1.7-1.6-2.6-3.8-4.2-5.6
					c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.4,1-0.8,1.5-1.3c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.8-0.7-1.7-1-2.5c-0.3-0.9-0.2-1.8,0.3-2.6
					c1.2-1.7,2.2-3.5,3.2-5.3c0.5-1.1,1.3-1.9,2.2-2.7c1.8-1.5,3.6-3,5.4-4.4c0.8-0.7,1.5-1.5,1.9-2.4c0.3-0.7,0.5-1.5,0.8-2.2
					c0.8-1.8,1.6-3.5,2.5-5.2c0.8-1.6,2.1-3,3.5-4.1c1.5-1.2,2.5-2.5,3.5-4.1c0.8-1.5,1.9-2.8,2.9-4.1c1-1.4,2.3-2.5,3.9-3
					c2.7-1,4.5-3,5.5-5.6c0.8-2.5,2.5-4.2,4.6-5.6c1.7-1.2,3.4-2.3,4.9-3.9c0.8-1,1.7-1.9,2.2-3.2c0.4-0.8,0.8-1.6,1.4-2.3
					c1-1.2,1.9-2.4,2.2-4c0.2-1,0.9-1.9,1.8-2.5c3.3-2.2,5.4-5.5,7.8-8.6c1.5-1.9,2.9-3.9,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3
					c1.8-2.4,3.8-4.6,6.1-6.4c1.5-1.1,2.7-2.4,3.8-3.9c0.5-0.7,1.2-1.4,1.7-2.1c1.5-2,3.2-3.8,5.5-4.8c1.7-0.8,2.8-2.2,3.9-3.7
					c2.2-2.9,4.6-5.6,6.9-8.4c1.2-1.5,2.6-2.9,4.4-3.8c0.5-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.2-4.2,6.3-6.3
					c1-1,2.1-1.9,3.5-2.3c1.2-0.3,2.1-1.1,2.7-2.1c2.2-3.1,4.7-5.8,7.5-8.4c0.8-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.5-0.8,0.8-1.3
					c0.5-0.8,1.3-1.2,2.3-1.3c3.3-0.2,5.8-2,8.1-4.2c0.5-0.5,1.1-1.2,1.6-1.6c0.8-0.8,1.9-1.3,3-1.7c1-0.3,2.1-0.3,3,0.2
					c3.2,1.8,6.4,3.4,9.6,4.9c1.1,0.5,1.9,1.4,2.2,2.5c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4
					c0.8,0.2,1.5,0.6,1.9,1.2c1.3,1.5,2.6,2.9,3.9,4.3c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6
					c1.5,1.1,2.9,2.2,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4c0.7,1.6,2.1,2.7,3.3,3.7c1.1,0.9,2.2,1.8,3.3,2.7c0.8,0.8,1.7,1.6,2.4,2.5
					c1.5,2,3.2,3.7,5,5.3c1,0.8,1.8,1.8,2.7,2.7c0.2,0.3,0.4,0.7,0.8,0.8c2.7,1.9,4.4,4.7,6.8,6.9c0.5,0.5,1.1,1.1,1.6,1.8
					c1.5,2.1,3.4,3.9,5.3,5.6c1.2,1,2.2,2.1,3.3,3.2c1.5,1.5,3,2.9,4.7,4.1c2.4,1.8,4.5,3.8,6.4,6.2c0.8,1,1.7,1.9,2.7,2.7
					c1.1,0.7,1.9,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4c4.1,3.8,7.9,7.8,12.3,11.3c1.4,1.2,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.2
					c1.9,0.2,3.3,1.3,4.7,2.5c2.2,2.2,4.4,4.4,6.7,6.5c0.3,0.2,0.5,0.5,0.8,0.8c2.5,3.3,5.7,6,8.6,9c2.2,2.2,4.3,4.5,6.2,6.9
					c2,2.5,3.6,5.4,5,8.3c0.5,1.2,0.7,2.5,0.7,3.8C568.1,229.3,568.2,230.1,568.2,230.9z M431.6,363.8c-0.7-0.1-1.3-0.1-1.9-0.3
					c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6c-1.9,1.3-3,1.6-4.7-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2
					c-1.2,1.3-2.5,2.1-4.2,2.4c-0.4,0.1-0.7,0.3-1,0.5c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.3c0.5,0.1,1,0.1,1.5,0.1
					c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4c0.4,0.2,0.8,0.6,1.3,0.8c1,0.7,2.2,0.7,3.3,0.5c0.8-0.2,1.5-0.7,1.6-1.5
					c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.8,0.1,2.3-0.7
					C431,365.9,431.5,364.9,431.6,363.8c0.3-0.1,0.7-0.1,1.1-0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6
					c0-0.2-0.3-0.4-0.5-0.4C432.3,362.4,431.9,363,431.6,363.8z M425,358.1c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.2
					c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4c-0.5-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5
					c-0.2-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-1.3-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7C422.1,356.4,423.4,358.1,425,358.1z M410.7,361.8
					c0.4-0.1,0.8-0.1,1.2-0.2s0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4c-1.3-1.1-2.5-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2
					c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1C406.9,361.2,408.7,362,410.7,361.8z M414,355.5c0-0.1,0-0.3-0.1-0.4
					c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1
					C413.7,355.9,413.8,355.7,414,355.5z M445.1,341.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.4c0.8,1,2,1.4,3.2,1.8
					c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.2-0.6c-1-0.5-2-1-3-1.6C445.4,341.1,445.2,341.1,445.1,341.1z M404.4,364.9
					c0.4-0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.2-0.5-0.4-0.5c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6
					c0,0.1,0.1,0.2,0.2,0.2C403.8,364.8,404.1,364.9,404.4,364.9z M418.1,374.1c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.5-0.4
					c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.5-0.1,0.6-0.2
					C418,374.6,418.1,374.3,418.1,374.1z M416,347.3c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8c-0.1,0.2,0.2,0.5,0.3,0.6
					C415.4,348.4,416.1,347.9,416,347.3z M407.8,367.3c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9c0.2,0,0.5-0.2,0.5-0.4
					C408,368.1,407.9,367.7,407.8,367.3z M465.4,338.9c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4-0.2-0.8-0.6-0.8c-0.4-0.1-0.8,0.2-0.8,0.7
					C465.1,338.4,465.3,338.7,465.4,338.9z M432.6,350.9c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5-0.1,0.6-0.2
					C433.1,351.2,432.9,351,432.6,350.9z M434,342.3c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.4,0.2-0.4,0.4
					C433.5,342.2,433.7,342.4,434,342.3z"
            />
          </defs>
          <path
            fill="#FFDA02"
            d="M567.8,230.5
				c0.5,3.8-0.6,7.2-1.9,10.6c-0.7,1.8-1.8,3.5-3.3,4.7c-1.8,1.5-3.3,3.3-5,4.9c-3.5,3.3-6.6,7-10.3,10c-0.8,0.7-1.5,1.5-2.1,2.3
				c-2.6,3.6-5.7,6.7-9.4,9.2c-2.7,1.8-5.1,3.9-7.2,6.5c-2.5,3.2-5.1,6.2-7.6,9.3c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.7,5
				c-0.7,0.9-1.6,1.8-2.5,2.4c-1.7,1.2-3.1,2.7-4.5,4.2c-0.6,0.6-1.3,1.3-1.6,2.1c-1,2.4-2.8,4.4-4.4,6.4c-1.9,2.4-4.2,4.5-6.1,7.1
				c-0.6,0.8-1.5,1.5-2.4,1.9c-2.4,1.1-4.2,3-5.6,5.2c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.8-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.6-5.3,5.6
				c-0.7,0.8-1.5,1.4-2.5,1.9c-2.1,1-4.1,2.1-6.2,3.2c-0.8,0.4-1.6,0.7-2.5,0.8c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.2,3.1
				c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.8-1.3,1.5-2.1,1.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4
				c-0.3-0.6-0.5-1.2-0.8-1.8c-0.9-1.6-2.2-2.4-3.9-1.6c-2.2,1-4.1,1-6.2-0.2c-1.1-0.7-2.2-0.2-3.2,0.7c-0.5,0.5-0.5,1.2,0.2,1.6
				c0.5,0.4,1.2,0.6,1.6,1.1c1.3,1.6,2.7,1.9,4.4,0.9c0.7-0.4,1.6-0.4,2.2,0.1c1.3,0.8,2.6,1.6,3.9,2.5c0.1,0.1,0.2,0.2,0.2,0.3
				c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3-0.6,0.5-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2
				c-1.2,0.5-2.1,1.5-2.8,2.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.7c1.2,1.3,0.9,2.6-0.1,3.9
				c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.8,0.3-1.1,0.1c-0.4-0.4-0.8-1-1.3-1.4c-0.4-0.4-1.5-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7
				c0.1,1.6-0.8,2.3-2.2,2.7c-1.5,0.4-3,1-4.3,1.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.2,0,0.8,0.3,1c0.5,0.3,1,0.3,1.5,0.2
				c1.8-0.3,3.5-1,5.2-1.7c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.5,0,0.7c-0.8,1.5-1.8,2.9-2.7,4.2c-0.4,0.7-1.2,1-2,1.1
				c-0.7,0.1-1.3,0.1-1.9,0.1c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.8
				c-0.6,0.2-1,0.8-1.3,1.4c-0.4,0.8,0.1,1.6,0.9,1.8c1.3,0.2,2.5,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.8,1,0.6,1.3
				c-0.2,0.2-0.6,0.5-0.9,0.5c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-1.9-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.8-0.5-1.4-1.1-1.6-2.1
				c-0.2-0.8-0.8-1.3-1.7-1.4c-0.2-0.1-0.5-0.1-0.8-0.1c-1.3-0.1-2.5-0.5-3.4-1.6c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6
				c-3.5-0.7-6-3-8.7-4.9c-1-0.7-1.7-1.6-2.2-2.7c-0.3-0.6-0.5-1.2-1-1.6c-1.9-2.2-3.9-4.5-5.9-6.6c-1.3-1.3-2.5-2.6-3.4-4.2
				c-0.5-1-1.3-1.9-2.2-2.7c-3.3-3.3-6.7-6.5-10.7-9c-1-0.6-1.8-1.5-2.7-2.2c-1.5-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4
				c-1.2-0.6-2.1-1.4-2.7-2.7c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.3-1.6-2.2-3.1-2.7c-1-0.3-1.9-0.7-2.9-1.2c-1-0.5-2-1.2-3-1.8
				c-1.3-0.9-2.3-2.1-2.5-3.8c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.8-1.9-1.3c-1.8-1.2-3.7-2.4-5.5-3.8
				c-1.8-1.3-3.4-2.8-5.4-3.8c-0.2-0.1-0.5-0.2-0.6-0.4c-1.6-2.4-4.4-3.6-6.1-6c-0.6-0.8-1.3-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5
				c-0.4-0.7-1.1-1.1-1.8-1.3c-0.4-0.1-0.7-0.2-1.1-0.4c-1.2-0.3-2.1-1-2.9-1.9c-1.5-2.2-3.6-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.6-5.3
				c-0.1-0.1-0.1-0.2-0.2-0.3c-3.1-3.2-5.9-6.5-9-9.6c-3.2-3.2-6.5-6.2-9.8-9.4c-1-1-2-2.1-2.9-3.2c-0.5-0.6-0.9-1.3-1.3-1.9
				c-0.6-1-0.8-2.2-0.3-3.3c0.2-0.4,0.3-0.7,0.4-1.1c0.2-0.7,0.2-1.3-0.3-1.8c-1.7-1.6-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8
				c0.5-0.4,1-0.8,1.5-1.3c0.4-0.4,0.5-1,0.4-1.5c-0.3-0.8-0.7-1.7-1-2.5c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.2-3.5,3.2-5.3
				c0.5-1.1,1.3-1.9,2.2-2.7c1.8-1.5,3.6-3,5.4-4.4c0.8-0.7,1.5-1.5,1.9-2.4c0.3-0.7,0.5-1.5,0.8-2.2c0.8-1.8,1.6-3.5,2.5-5.2
				c0.8-1.6,2.1-3,3.5-4.1c1.5-1.2,2.5-2.5,3.5-4.1c0.8-1.5,1.9-2.8,2.9-4.1c1-1.4,2.3-2.5,3.9-3c2.7-1,4.5-3,5.5-5.6
				c0.8-2.5,2.5-4.2,4.6-5.6c1.7-1.2,3.4-2.3,4.9-3.9c0.8-1,1.7-1.9,2.2-3.2c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,1.9-2.4,2.2-4
				c0.2-1,0.9-1.9,1.8-2.5c3.3-2.2,5.4-5.5,7.8-8.6c1.5-1.9,2.9-3.9,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.1-6.4
				c1.5-1.1,2.7-2.4,3.8-3.9c0.5-0.7,1.2-1.4,1.7-2.1c1.5-2,3.2-3.8,5.5-4.8c1.7-0.8,2.8-2.2,3.9-3.7c2.2-2.9,4.6-5.6,6.9-8.4
				c1.2-1.5,2.6-2.9,4.4-3.8c0.5-0.3,1.1-0.7,1.6-1.1c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.2-4.2,6.3-6.3c1-1,2.1-1.9,3.5-2.3
				c1.2-0.3,2.1-1.1,2.7-2.1c2.2-3.1,4.7-5.8,7.5-8.4c0.8-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.5-0.8,0.8-1.3c0.5-0.8,1.3-1.2,2.3-1.3
				c3.3-0.2,5.8-2,8.1-4.2c0.5-0.5,1.1-1.2,1.6-1.6c0.8-0.8,1.9-1.3,3-1.7c1-0.3,2.1-0.3,3,0.2c3.2,1.8,6.4,3.4,9.6,4.9
				c1.1,0.5,1.9,1.4,2.2,2.5c0.2,0.7,0.4,1.5,0.7,2.2c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,1.9,1.2
				c1.3,1.5,2.6,2.9,3.9,4.3c0.6,0.7,1.1,1.5,1.8,2.1c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.2,4.3,3.3
				c1.5,1.1,2.8,2.3,3.6,4c0.7,1.6,2.1,2.7,3.3,3.7c1.1,0.9,2.2,1.8,3.3,2.7c0.8,0.8,1.7,1.6,2.4,2.5c1.5,2,3.2,3.7,5,5.3
				c1,0.8,1.8,1.8,2.7,2.7c0.2,0.3,0.4,0.7,0.8,0.8c2.7,1.9,4.4,4.7,6.8,6.9c0.5,0.5,1.1,1.1,1.6,1.8c1.5,2.1,3.4,3.9,5.3,5.6
				c1.2,1,2.2,2.1,3.3,3.2c1.5,1.5,3,2.9,4.7,4.1c2.4,1.8,4.5,3.8,6.4,6.2c0.8,1,1.7,1.9,2.7,2.7c1.1,0.7,1.9,1.7,2.8,2.7
				c1,1.2,2.1,2.3,3.2,3.4c4.1,3.8,7.9,7.8,12.3,11.3c1.4,1.2,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.2c1.9,0.2,3.3,1.3,4.7,2.5
				c2.2,2.2,4.4,4.4,6.7,6.5c0.3,0.2,0.5,0.5,0.8,0.8c2.5,3.3,5.7,6,8.6,9c2.2,2.2,4.3,4.5,6.2,6.9c2,2.5,3.6,5.4,5,8.3
				c0.5,1.2,0.7,2.5,0.7,3.8C567.8,229,567.8,229.7,567.8,230.5z M431.3,363.4c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7
				c-0.3,0.2-0.7,0.4-1,0.6c-1.9,1.3-3,1.6-4.7-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.2,1.3-2.5,2.1-4.2,2.4
				c-0.4,0.1-0.7,0.3-1,0.5c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4
				c0.7-0.1,1.3,0,1.9,0.4c0.4,0.2,0.8,0.6,1.3,0.8c1,0.7,2.2,0.7,3.3,0.5c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8
				c0.3-1.8,1.2-2.4,2.9-2c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.8,0.1,2.3-0.7C430.6,365.5,431.2,364.6,431.3,363.4
				c0.3-0.1,0.7-0.1,1.1-0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C432,362,431.6,362.7,431.3,363.4
				z M424.6,357.7c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7
				c-0.4-0.2-1-0.3-1.5-0.4c-0.5-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-1.3-0.3-1.6,0.4
				c-0.6,1.6-1.2,3.1-1.7,4.7C421.8,356.1,423.1,357.8,424.6,357.7z M410.4,361.4c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.4,0.9-0.6
				c0.3-0.3,0.1-1-0.3-1.4c-1.3-1.1-2.5-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
				C406.6,360.8,408.4,361.6,410.4,361.4z M413.7,355.2c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
				c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C413.3,355.6,413.5,355.3,413.7,355.2z M444.8,340.7c-0.1,0.1-0.2,0.2-0.2,0.3
				c-0.1,0.1,0,0.3,0.1,0.4c0.8,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.2-0.6c-1-0.5-2-1-3-1.6
				C445.1,340.7,444.9,340.7,444.8,340.7z M404.1,364.6c0.4-0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5s-0.2-0.5-0.4-0.5
				c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C403.4,364.4,403.8,364.5,404.1,364.6z M417.8,373.7
				c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.5-0.4s-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.4,0.4,0.5
				c0.2,0.1,0.5-0.1,0.6-0.2C417.7,374.2,417.7,373.9,417.8,373.7z M415.7,346.9c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
				c-0.1,0.2,0.2,0.5,0.3,0.6C415.1,348.1,415.8,347.6,415.7,346.9z M407.5,367c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
				c0.2,0,0.5-0.2,0.5-0.4C407.7,367.6,407.6,367.3,407.5,367z M465.1,338.5c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4-0.2-0.8-0.6-0.8
				c-0.4-0.1-0.8,0.2-0.8,0.7C464.7,338,465,338.4,465.1,338.5z M432.3,350.5c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
				c0.2,0,0.5-0.1,0.6-0.2C432.7,350.8,432.6,350.6,432.3,350.5z M433.7,341.9c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
				c-0.2,0.1-0.4,0.2-0.4,0.4C433.2,341.9,433.4,341.9,433.7,341.9z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000046298808341837212810000000834644965003743926_"
              d="M568.2,230.9c0.5,3.8-0.6,7.2-1.9,10.6
					c-0.7,1.8-1.8,3.5-3.3,4.7c-1.8,1.5-3.3,3.3-5,4.9c-3.5,3.3-6.6,7-10.3,10c-0.8,0.7-1.5,1.5-2.1,2.3c-2.6,3.6-5.7,6.7-9.4,9.2
					c-2.7,1.8-5.1,3.9-7.2,6.5c-2.5,3.2-5.1,6.2-7.6,9.3c-0.4,0.5-0.7,1.2-1.3,1.5c-2.1,1.2-3.4,3.2-4.7,5c-0.7,0.9-1.6,1.8-2.5,2.4
					c-1.7,1.2-3.1,2.7-4.5,4.2c-0.6,0.6-1.3,1.3-1.6,2.1c-1,2.4-2.8,4.4-4.4,6.4c-1.9,2.4-4.2,4.5-6.1,7.1c-0.6,0.8-1.5,1.5-2.4,1.9
					c-2.4,1.1-4.2,3-5.6,5.2c-2.3,3.5-5.1,6.5-8.5,9c-1.2,0.8-2.2,1.8-3.2,2.9c-1.8,1.9-3.7,3.6-5.3,5.6c-0.7,0.8-1.5,1.4-2.5,1.9
					c-2.1,1-4.1,2.1-6.2,3.2c-0.8,0.4-1.6,0.7-2.5,0.8c-0.9,0.1-1.8,0.1-2.7,0.3c-1.6,0.4-2.7,1.4-3.2,3.1c-0.1,0.4-0.1,0.8-0.2,1.2
					c-0.2,0.8-1.3,1.5-2.1,1.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.6-0.5-1.2-0.8-1.8
					c-0.9-1.6-2.2-2.4-3.9-1.6c-2.2,1-4.1,1-6.2-0.2c-1.1-0.7-2.2-0.2-3.2,0.7c-0.5,0.5-0.5,1.2,0.2,1.6c0.5,0.4,1.2,0.6,1.6,1.1
					c1.3,1.6,2.7,1.9,4.4,0.9c0.7-0.4,1.6-0.4,2.2,0.1c1.3,0.8,2.6,1.6,3.9,2.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.3-0.6,0.5-1,0.4c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.2-1.6-0.2-2.3,0.2c-1.2,0.5-2.1,1.5-2.8,2.6
					c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0.1,0.3,0.1,0.4c1.1,1,1,2.6,2.1,3.7c1.2,1.3,0.9,2.6-0.1,3.9c-0.2,0.2-0.4,0.4-0.5,0.6
					c-0.2,0.2-0.8,0.3-1.1,0.1c-0.4-0.4-0.8-1-1.3-1.4c-0.4-0.4-1.5-0.1-1.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,1.6-0.8,2.3-2.2,2.7
					c-1.5,0.4-3,1-4.3,1.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.2,0,0.8,0.3,1c0.5,0.3,1,0.3,1.5,0.2c1.8-0.3,3.5-1,5.2-1.7
					c0.3-0.1,1,0.1,1,0.4c0.1,0.2,0.1,0.5,0,0.7c-0.8,1.5-1.8,2.9-2.7,4.2c-0.4,0.7-1.2,1-2,1.1c-0.7,0.1-1.3,0.1-1.9,0.1
					c-0.8,0-1.5-0.4-2.1-0.9c-0.4-0.4-0.7-0.8-1-1.2c-0.5-0.5-1.1-0.7-1.8-0.6c-1,0.2-2,0.5-3,0.8c-0.6,0.2-1,0.8-1.3,1.4
					c-0.4,0.8,0.1,1.6,0.9,1.8c1.3,0.2,2.5,0.3,3.9,0.5c0.9,0.1,1.8,0.5,2.5,1c0.5,0.4,0.8,1,0.6,1.3c-0.2,0.2-0.6,0.5-0.9,0.5
					c-1.3,0.1-2.6,0.1-3.9,0c-0.7,0-1.3-0.2-1.9-0.2c-1.7,0.2-3-0.5-4.4-1.3c-0.8-0.5-1.4-1.1-1.6-2.1c-0.2-0.8-0.8-1.3-1.7-1.4
					c-0.2-0.1-0.5-0.1-0.8-0.1c-1.3-0.1-2.5-0.5-3.4-1.6c-0.4-0.5-1-0.7-1.6-1c-0.4-0.2-0.9-0.5-1.5-0.6c-3.5-0.7-6-3-8.7-4.9
					c-1-0.7-1.7-1.6-2.2-2.7c-0.3-0.6-0.5-1.2-1-1.6c-1.9-2.2-3.9-4.5-5.9-6.6c-1.3-1.3-2.5-2.6-3.4-4.2c-0.5-1-1.3-1.9-2.2-2.7
					c-3.3-3.3-6.7-6.5-10.7-9c-1-0.6-1.8-1.5-2.7-2.2c-1.5-1.5-3-2.9-4.1-4.6c-1.2-1.8-2.7-3.1-4.6-4c-1.2-0.6-2.1-1.4-2.7-2.7
					c-0.1-0.2-0.2-0.4-0.4-0.7c-0.7-1.3-1.6-2.2-3.1-2.7c-1-0.3-1.9-0.7-2.9-1.2c-1-0.5-2-1.2-3-1.8c-1.3-0.9-2.3-2.1-2.5-3.8
					c-0.1-1.3-0.9-2.1-1.9-2.8c-0.7-0.4-1.3-0.8-1.9-1.3c-1.8-1.2-3.7-2.4-5.5-3.8s-3.4-2.8-5.4-3.8c-0.2-0.1-0.5-0.2-0.6-0.4
					c-1.6-2.4-4.4-3.6-6.1-6c-0.6-0.8-1.3-1.6-2.2-2.2c-2.1-1.6-4.1-3.2-5.5-5.5c-0.4-0.7-1.1-1.1-1.8-1.3c-0.4-0.1-0.7-0.2-1.1-0.4
					c-1.2-0.3-2.1-1-2.9-1.9c-1.5-2.2-3.6-3.7-5.8-5.2c-2.2-1.5-4.1-3.1-5.6-5.3c-0.1-0.1-0.1-0.2-0.2-0.3c-3.1-3.2-5.9-6.5-9-9.6
					c-3.2-3.2-6.5-6.2-9.8-9.4c-1-1-2-2.1-2.9-3.2c-0.5-0.6-0.9-1.3-1.3-1.9c-0.6-1-0.8-2.2-0.3-3.3c0.2-0.4,0.3-0.7,0.4-1.1
					c0.2-0.7,0.2-1.3-0.3-1.8c-1.7-1.6-2.6-3.8-4.2-5.6c-0.4-0.5-0.4-1.3,0.1-1.8c0.5-0.4,1-0.8,1.5-1.3c0.4-0.4,0.5-1,0.4-1.5
					c-0.3-0.8-0.7-1.7-1-2.5c-0.3-0.9-0.2-1.8,0.3-2.6c1.2-1.7,2.2-3.5,3.2-5.3c0.5-1.1,1.3-1.9,2.2-2.7c1.8-1.5,3.6-3,5.4-4.4
					c0.8-0.7,1.5-1.5,1.9-2.4c0.3-0.7,0.5-1.5,0.8-2.2c0.8-1.8,1.6-3.5,2.5-5.2c0.8-1.6,2.1-3,3.5-4.1c1.5-1.2,2.5-2.5,3.5-4.1
					c0.8-1.5,1.9-2.8,2.9-4.1c1-1.4,2.3-2.5,3.9-3c2.7-1,4.5-3,5.5-5.6c0.8-2.5,2.5-4.2,4.6-5.6c1.7-1.2,3.4-2.3,4.9-3.9
					c0.8-1,1.7-1.9,2.2-3.2c0.4-0.8,0.8-1.6,1.4-2.3c1-1.2,1.9-2.4,2.2-4c0.2-1,0.9-1.9,1.8-2.5c3.3-2.2,5.4-5.5,7.8-8.6
					c1.5-1.9,2.9-3.9,4.9-5.5c0.8-0.6,1.5-1.5,2.1-2.3c1.8-2.4,3.8-4.6,6.1-6.4c1.5-1.1,2.7-2.4,3.8-3.9c0.5-0.7,1.2-1.4,1.7-2.1
					c1.5-2,3.2-3.8,5.5-4.8c1.7-0.8,2.8-2.2,3.9-3.7c2.2-2.9,4.6-5.6,6.9-8.4c1.2-1.5,2.6-2.9,4.4-3.8c0.5-0.3,1.1-0.7,1.6-1.1
					c1.9-1.6,3.8-3.2,5.5-4.9c2.2-2.1,4.2-4.2,6.3-6.3c1-1,2.1-1.9,3.5-2.3c1.2-0.3,2.1-1.1,2.7-2.1c2.2-3.1,4.7-5.8,7.5-8.4
					c0.8-0.8,1.4-1.8,2.1-2.8c0.3-0.4,0.5-0.8,0.8-1.3c0.5-0.8,1.3-1.2,2.3-1.3c3.3-0.2,5.8-2,8.1-4.2c0.5-0.5,1.1-1.2,1.6-1.6
					c0.8-0.8,1.9-1.3,3-1.7c1-0.3,2.1-0.3,3,0.2c3.2,1.8,6.4,3.4,9.6,4.9c1.1,0.5,1.9,1.4,2.2,2.5c0.2,0.7,0.4,1.5,0.7,2.2
					c0.6,1.5,1.7,2.6,3.3,3.1c0.5,0.2,1,0.2,1.5,0.4c0.8,0.2,1.5,0.6,1.9,1.2c1.3,1.5,2.6,2.9,3.9,4.3c0.6,0.7,1.1,1.5,1.8,2.1
					c1.9,1.8,3.5,3.8,4.6,6.2c0.5,1.1,1.4,1.9,2.3,2.6c1.5,1.1,2.9,2.2,4.3,3.3c1.5,1.1,2.8,2.3,3.6,4c0.7,1.6,2.1,2.7,3.3,3.7
					c1.1,0.9,2.2,1.8,3.3,2.7c0.8,0.8,1.7,1.6,2.4,2.5c1.5,2,3.2,3.7,5,5.3c1,0.8,1.8,1.8,2.7,2.7c0.2,0.3,0.4,0.7,0.8,0.8
					c2.7,1.9,4.4,4.7,6.8,6.9c0.5,0.5,1.1,1.1,1.6,1.8c1.5,2.1,3.4,3.9,5.3,5.6c1.2,1,2.2,2.1,3.3,3.2c1.5,1.5,3,2.9,4.7,4.1
					c2.4,1.8,4.5,3.8,6.4,6.2c0.8,1,1.7,1.9,2.7,2.7c1.1,0.7,1.9,1.7,2.8,2.7c1,1.2,2.1,2.3,3.2,3.4c4.1,3.8,7.9,7.8,12.3,11.3
					c1.4,1.2,2.7,2.4,3.5,4.1c0.6,1.3,1.6,2.1,3,2.2c1.9,0.2,3.3,1.3,4.7,2.5c2.2,2.2,4.4,4.4,6.7,6.5c0.3,0.2,0.5,0.5,0.8,0.8
					c2.5,3.3,5.7,6,8.6,9c2.2,2.2,4.3,4.5,6.2,6.9c2,2.5,3.6,5.4,5,8.3c0.5,1.2,0.7,2.5,0.7,3.8
					C568.1,229.3,568.2,230.1,568.2,230.9z M431.6,363.8c-0.7-0.1-1.3-0.1-1.9-0.3c-1.5-0.4-2.8-0.1-4.1,0.7c-0.3,0.2-0.7,0.4-1,0.6
					c-1.9,1.3-3,1.6-4.7-0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.6-1.8-0.6-2.4,0.2c-1.2,1.3-2.5,2.1-4.2,2.4c-0.4,0.1-0.7,0.3-1,0.5
					c-0.5,0.3-0.7,1.2-0.6,1.7c0.1,0.6,0.7,1.2,1.3,1.3c0.5,0.1,1,0.1,1.5,0.1c0.8-0.1,1.5-0.3,2.3-0.4c0.7-0.1,1.3,0,1.9,0.4
					c0.4,0.2,0.8,0.6,1.3,0.8c1,0.7,2.2,0.7,3.3,0.5c0.8-0.2,1.5-0.7,1.6-1.5c0.1-0.2,0.1-0.5,0.1-0.8c0.3-1.8,1.2-2.4,2.9-2
					c0.2,0.1,0.5,0.2,0.7,0.3c1.3,0.5,1.8,0.1,2.3-0.7C431,365.9,431.5,364.9,431.6,363.8c0.3-0.1,0.7-0.1,1.1-0.1
					c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.2-0.3-0.4-0.5-0.4C432.3,362.4,431.9,363,431.6,363.8z M425,358.1
					c0.2-0.1,0.7-0.1,1-0.3c1.2-0.7,2.4-1.5,3.6-2.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.6-0.1-0.7c-0.4-0.2-1-0.3-1.5-0.4
					c-0.5-0.1-0.9-0.4-1-1c-0.1-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-1.3-0.3-1.6,0.4c-0.6,1.6-1.2,3.1-1.7,4.7
					C422.1,356.4,423.4,358.1,425,358.1z M410.7,361.8c0.4-0.1,0.8-0.1,1.2-0.2s0.7-0.4,0.9-0.6c0.3-0.3,0.1-1-0.3-1.4
					c-1.3-1.1-2.5-2.2-3.9-3.2c-0.2-0.2-0.7-0.2-1.1-0.2c-1.1,0.2-1.9,0.8-2.5,1.7c-0.4,0.7-0.3,1.7,0.3,2.1
					C406.9,361.2,408.7,362,410.7,361.8z M414,355.5c0-0.1,0-0.3-0.1-0.4c-0.8-0.6-1.6-1.2-2.4-1.8c-0.1-0.1-0.4,0.1-0.6,0.2
					c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.4,1.3,2.2,2.6,2.1C413.7,355.9,413.8,355.7,414,355.5z M445.1,341.1c-0.1,0.1-0.2,0.2-0.2,0.3
					c-0.1,0.1,0,0.3,0.1,0.4c0.8,1,2,1.4,3.2,1.8c0.2,0.1,0.5-0.1,0.6-0.3c0.1-0.2-0.1-0.5-0.2-0.6c-1-0.5-2-1-3-1.6
					C445.4,341.1,445.2,341.1,445.1,341.1z M404.4,364.9c0.4-0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.2-0.5-0.4-0.5
					c-0.6-0.2-1.3-0.2-1.8,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.2C403.8,364.8,404.1,364.9,404.4,364.9z M418.1,374.1
					c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.5-0.4c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.3-0.2,0.7-0.2,1c0,0.2,0.2,0.4,0.4,0.4
					c0.2,0.1,0.5-0.1,0.6-0.2C418,374.6,418.1,374.3,418.1,374.1z M416,347.3c-0.2-0.2-0.3-0.4-0.5-0.5c-0.5-0.1-1,0.2-1.1,0.8
					c-0.1,0.2,0.2,0.5,0.3,0.6C415.4,348.4,416.1,347.9,416,347.3z M407.8,367.3c-0.3,0.2-0.6,0.3-0.7,0.5c-0.2,0.4,0,0.9,0.4,0.9
					c0.2,0,0.5-0.2,0.5-0.4C408,368.1,407.9,367.7,407.8,367.3z M465.4,338.9c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4-0.2-0.8-0.6-0.8
					c-0.4-0.1-0.8,0.2-0.8,0.7C465.1,338.4,465.3,338.7,465.4,338.9z M432.6,350.9c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.3,0.2,0.3
					c0.2,0,0.5-0.1,0.6-0.2C433.1,351.2,432.9,351,432.6,350.9z M434,342.3c0.3-0.1,0.4-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
					c-0.2,0.1-0.4,0.2-0.4,0.4C433.5,342.2,433.7,342.4,434,342.3z"
            />
          </defs>
          <g>
            <g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M476.9,184.6c5.5,6.2-10.4,12.6-10.6,25.7c0.1,12.9-1.2,19.6-4.5,25.1c-1.6,2.6-6.4,3.6-7.3,6.4
							c-1.2,3.4,4.2,6,6.6,7.6c6.7,4.5,14.3,8,22.1,10.1c2.8,0.8-6.5,12.9-7.7,13.7c-4.7,3.4-10.1,6.7-15.4,9.3
							c-4.4,2.2-9.5,3.9-14.2,2.4c-11.4-3.7-20.6-16-27.7-25.1c-1.6-2.1-3.2-4.2-5.1-6c-2.5-2.2-5.3-2.7-8.1-4.2
							c-2.8-1.5-5-3.9-6.7-6.5c-4.4-6.7-7.3-15.7-10.1-23.2c-3.2-8.6-5.4-17.4-7.6-26.2c-2.3-9-5.7-18.4-5-27.8
							c0.1-1.5,0.4-3.2,1.7-4.1c1.3-0.9,3.2-0.6,4.5,0.4c1.3,1,2.1,2.4,2.9,3.8c2.1,3.6,3.2,7.5,4.7,11.5c0.6,1.6,3.6,12.1,5,12.4
							c0,0,3.5,0.5,3.6-1.3c-0.1-1.7-4.5-25.3-4.2-25.5c-0.2,0.2-3.2-26.6-2.5-29.7c0.4-3,7.9-5.7,9.8,0.8c1.8,6.5,4,24.2,4.1,24.1
							c-0.2,0.2,6.1,24.9,7.4,25.8c1,1.2,3.1,0.5,2.8-1.9c-0.5-2.2-4.1-25.7-3.9-25.8c-0.2,0.1-3.6-23.5-2.7-26.9
							c0.7-3.2,7.9-5.9,9.2,0.5c1.2,6.5,4.5,24.9,4.7,24.8c-0.3,0.2,6.4,27.2,7.2,28.1c0.5,1.2,2.3-0.4,2.3-0.4
							c-1.2-8.1-2.1-16.3-2.7-24.7c-0.1,0.1,0.2-21.8,0.4-24.4c0-2.5,8.2-6.3,9.2,0.2c0.9,6.7,2,26,2.1,25.9
							c-0.3,0.2,4.7,30,5.8,34.7c0.7,5.1,2.9,8,4.4,6.8c1.5-1.2,6.4-11.2,11-14.4C466.8,183.5,474.3,181.5,476.9,184.6z"
                />
              </g>
              <g>
                <path
                  fill="#F7B300"
                  d="M483.7,180.3c5.5,6.2-10.4,12.6-10.6,25.7c0.1,12.9-1.2,19.6-4.5,25.1c-1.6,2.6-6.4,3.6-7.3,6.4
							c-1.2,3.4,4.2,6,6.6,7.6c6.7,4.5,14.3,8,22.1,10.1c2.8,0.8-6.5,12.9-7.7,13.7c-4.7,3.4-10.1,6.7-15.4,9.3
							c-4.4,2.2-9.5,3.9-14.2,2.4c-11.4-3.7-20.6-16-27.7-25.1c-1.6-2.1-3.2-4.2-5.1-6c-2.5-2.2-5.3-2.7-8.1-4.2
							c-2.8-1.5-5-3.9-6.7-6.5c-4.4-6.7-7.3-15.7-10.1-23.2c-3.2-8.6-5.4-17.4-7.6-26.2c-2.3-9-5.7-18.4-5-27.8
							c0.1-1.5,0.4-3.2,1.7-4.1c1.3-0.9,3.2-0.6,4.5,0.4s2.1,2.4,2.9,3.8c2.1,3.6,3.2,7.5,4.7,11.5c0.6,1.6,3.6,12.1,5,12.4
							c0,0,3.5,0.5,3.6-1.3c-0.1-1.7-4.5-25.3-4.2-25.5c-0.2,0.2-3.2-26.6-2.5-29.7c0.4-3,7.9-5.7,9.8,0.8c1.8,6.5,4,24.2,4.1,24.1
							c-0.2,0.2,6.1,24.9,7.4,25.8c1,1.2,3.1,0.5,2.8-1.9c-0.5-2.2-4.1-25.7-3.9-25.8c-0.2,0.1-3.6-23.5-2.7-26.9
							c0.7-3.2,7.9-5.9,9.2,0.5c1.2,6.5,4.5,24.9,4.7,24.8c-0.3,0.2,6.4,27.2,7.2,28.1c0.5,1.2,2.3-0.4,2.3-0.4
							c-1.2-8.1-2.1-16.3-2.7-24.7c-0.1,0.1,0.2-21.8,0.4-24.4c0-2.5,8.2-6.3,9.2,0.2c0.9,6.7,2,26,2.1,25.9
							c-0.3,0.2,4.7,30,5.8,34.7c0.7,5.1,2.9,8,4.4,6.8c1.5-1.2,6.4-11.2,11-14.4C473.6,179.2,481.1,177.2,483.7,180.3z"
                />
              </g>
              <g>
                <g>
                  <path
                    fill="#D67A04"
                    d="M450.2,201.9c-6.2,4.7-10.5,11.8-11.8,19.5c-0.4,2.2-0.5,4.5-0.4,6.7c0,0.3,2.1-0.1,2.1-0.5
								c-0.5-9.9,4.1-19.8,11.9-25.8c0.4-0.3-0.4-0.3-0.5-0.3C451.1,201.5,450.6,201.6,450.2,201.9L450.2,201.9z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#D57B02"
                  d="M307.2,232.3c-6.1,5.7,9.1,13.6,8,26.6c-0.5,5.1-1.6,11.1-0.1,16.1c1.9,6.7,6.8,13.8,13,17.1
							c1.3,0.7,2.7,1.5,3.3,2.8c0.8,1.7,0.2,3.6-0.5,5.4c-0.8,1.9-1.6,3.8-2.6,5.6c-0.3,0.5-0.7,1.2-1,1.7c-1,1.5-8.2,7.6-7.5,9.3
							c0.9,2,5.6,3.2,7.5,3.9c10.7,4.1,21.8,7.6,33.2,8.9c0.5,0.1,1,0.1,1.5-0.1c0.4-0.2,0.7-0.5,0.9-0.9c4-5.9,8.6-13.2,10.2-20.2
							c0.6-2.9,1-6,2.9-8.2c0.8-1,1.9-1.6,2.9-2.5c5.8-4.9,9.5-14.7,12.7-21.3c4.1-8.3,7.7-16.8,10.9-25.5
							c4.1-11.2,9.2-21.6,8.7-33.8c-0.1-1.5-0.3-3.3-1.5-4.1c-1.5-1-3.7-0.1-5,1.2c-1.8,1.8-2.7,4.1-3.6,6.3c-1,2.4-2,4.8-3,7.2
							c-0.8,1.8-1.7,3.7-2.6,5.5c-2.1,4.2-4.4,8.3-7.6,11.6c-0.1,0.2-0.3,0.3-0.5,0.4c-0.5,0.1-0.8-0.5-0.8-1.1
							c-0.7-4.8,2.3-10.9,3.6-15.6c0.2-0.8,3.1-8.9,2.5-9.4c0.2,0.2,5.8-26.2,5.4-29.4c-0.1-3-7.3-6.4-9.8-0.1
							c-2.4,6.4-6.4,23.7-6.4,23.6c0.2,0.2-8.5,24.1-9.9,25c-1.2,1-3.1,0.2-2.6-2.1c0.7-2.2,6.6-25.2,6.4-25.3
							c0.2,0.1,5.9-23.1,5.3-26.5c-0.3-3.3-7.2-6.6-9.2-0.4c-1.8,6.4-7,24.3-7,24.2c0.3,0.2-9,26.4-9.9,27.3
							c-0.5,1.1-2.2-0.6-2.2-0.6c1.9-7.9,3.6-16,5.2-24.3c0.1,0.1,1.9-21.6,2-24.3c0.2-2.5-7.5-7.1-9.2-0.6
							c-1.5,6.5-4.5,25.7-4.7,25.6c0.3,0.2-7.6,29.4-9.1,34c-1.2,5-3.6,7.7-5,6.4c-1.4-1.3-5.2-11.8-9.6-15.4
							C317.4,232.2,310.1,229.4,307.2,232.3z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M404.3,221.1c-0.2-0.1-0.5-0.1-0.7-0.4c-0.2-0.2-0.2-0.5-0.2-0.8c0.2-2.1,0.8-3.8,3.1-4.3
							c1.2-0.2,2.7-0.1,3.8,0.4c1.1,0.4,0.9,0.4,0.8,1.5c-0.1,1.2-0.3,3.6-1.3,4.4C408.7,222.6,405.5,221.4,404.3,221.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M353.2,190c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.2-0.5-0.2-0.8c-0.1-4.1,3.2-6.1,6.9-4.4
							c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.2,0.2,0.4,0.2,0.7c0.2,1.3,0.2,2.7,0.1,4.1c0,0.2-0.1,0.5-0.2,0.7
							C358.8,191.5,354.4,190.1,353.2,190z"
                />
                <path
                  fill="#FFFFFF"
                  d="M389.5,189.5c1.6-2.5,5.6-1.8,7.9-0.8c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.3,0.1,0.5
							c0.1,1.7-0.2,3.5-0.8,5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0-0.3-0.1c-1.2-0.5-2.5-0.3-3.7-0.7c-1.5-0.2-3.2-0.8-3.6-2.2
							C388.9,190.9,389.1,190.1,389.5,189.5z"
                />
                <path
                  fill="#FFFFFF"
                  d="M372.5,184.3c1.6-2.5,5.6-1.9,7.9-0.9c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.3,0.1,0.5
							c0.1,1.7-0.2,3.5-0.7,5.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0-0.3-0.1c-1.2-0.4-2.5-0.2-3.7-0.7
							c-1.5-0.2-3.2-0.7-3.6-2.2C372,185.7,372.2,184.9,372.5,184.3z"
                />
                <path
                  fill="#FFFFFF"
                  d="M315,235.9c0.1-0.1,0.3-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.3c-1.2-1.3-2.6-2.4-4.3-2.8
							c-1.9-0.5-4.1,0-5.7,1.2c-0.2,0.2-0.4,0.4-0.5,0.6c0,0.2,0.1,0.4,0.1,0.5c0.5,1.5,2.2,5.9,3.9,5.8c1-0.1,2.5-1.6,3.3-2.2
							C313,237.6,314,236.7,315,235.9z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#444444"
          d="M327.3,430.3c0-0.5,0.1-0.8,0.1-1.1c0.1-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.1-0.2,0.2-0.2s0.2,0,0.3,0
			c0.8,0,1.5,0,2.1,0.1c0.6,0.1,0.8,0.3,0.8,0.7c0,0.4,0,0.8-0.1,1.1c-0.1,0.4-0.1,0.8-0.1,1.3c-0.1,0.8-0.2,1.5-0.3,2.1
			c-0.1,0.6-0.1,1.1-0.2,1.5c0,0.4-0.1,0.7-0.1,1c0,0.3,0,0.5,0,0.6c0,1.6,0,3.2,0,4.7c0,1.6-0.1,3.1-0.1,4.7c0,1.2,0.2,2.2,0.7,2.9
			c0.5,0.8,1.2,1.1,2,1.1c0.8,0,1.4-0.2,1.9-0.5s0.9-0.8,1.2-1.4c0.3-0.6,0.5-1.2,0.6-1.9c0.1-0.7,0.2-1.5,0.2-2.3
			c0-0.8,0-1.5,0-2.3c0-0.8-0.1-1.5-0.1-2.1c0-0.4,0-0.8,0-1.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.4
			c0-0.4,0-0.9,0-1.4c0-0.5,0-1,0-1.6c0-0.5,0-1,0.1-1.4c0-0.4,0-0.8,0-1c0-0.4,0-0.7,0-1c0-0.3-0.1-0.6-0.1-0.9
			c0-0.4,0.1-0.6,0.2-0.7c0.1-0.1,0.3-0.1,0.6-0.1c0.6,0,1.1,0,1.4,0c0.3,0,0.5,0,0.7,0c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2,0,0.3
			c0,0.5,0,1,0.1,1.4c0,0.5,0,0.9,0,1.4c0,0.7,0,1.5-0.1,2.2c0,0.7-0.1,1.5-0.1,2.3c0,0.5,0,1,0.1,1.5c0,0.5,0.1,1,0.1,1.5
			c0,0.7,0,1.5-0.1,2.3c-0.1,0.8-0.1,1.7-0.2,2.6l-0.2,1.5c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.5-0.2,0.9-0.3,1.4
			c-0.1,0.4-0.2,0.9-0.4,1.3c-0.1,0.4-0.3,0.9-0.5,1.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.6,0.3-1,0.5
			c-0.5,0.2-1.1,0.3-1.8,0.5c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.5-0.1c-0.5,0-1.1-0.1-1.6-0.3
			c-0.5-0.2-1-0.4-1.4-0.7c-0.4-0.3-0.7-0.7-1-1.2c-0.1-0.1-0.1-0.4-0.2-0.7c-0.1-0.3-0.1-0.6-0.2-1c0-0.3-0.1-0.7-0.1-1
			c0-0.3,0-0.6,0-0.8c0,0,0-0.1,0-0.3c0-0.2,0-0.4,0-0.7c0-0.2,0-0.5-0.1-0.7c0-0.2,0-0.4,0-0.5c0.1-4,0.2-8.1,0.3-12.1
			c0-0.4,0-0.7-0.1-0.9c0-0.3-0.1-0.5-0.1-0.8c0-0.2,0-0.4,0-0.6C327.3,430.6,327.3,430.5,327.3,430.3z"
        />
        <path
          fill="#444444"
          d="M359,430.4v0.3c0,0.2,0,0.4,0,0.5c0,0.1,0,0.2,0,0.4c0,0.2,0,0.5,0,0.8c0,0.3,0,0.7,0,1.1c0,0.4,0,0.8,0,1.1
			c0,0.4,0,0.7,0,1.1c0,1.1,0,2.1-0.1,3.2c-0.1,1-0.1,2.1-0.1,3.2c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.6c0,1.3-0.1,2.3-0.1,2.9
			c0,0.6-0.1,1.1-0.1,1.5c0,0.3,0,0.6-0.1,0.9c0,0.3,0,0.6,0,1.1v2c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.3-0.3,0.3c-0.4,0-0.8,0-1.2,0
			c-0.4,0-0.8,0-1.2,0c-0.3,0-0.6,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.1-0.4-0.2-0.6l-0.2-0.5
			c-0.3-1.1-0.7-2.3-1-3.5c-0.4-1.2-0.8-2.5-1.5-4c-0.3-0.7-0.6-1.3-0.8-1.7c-0.2-0.4-0.4-0.8-0.5-1c-0.1-0.3-0.2-0.4-0.3-0.6
			s-0.1-0.2-0.2-0.3l-1.8-4c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0.1,0.8c0,0.3,0,0.6,0.1,1c0,0.3,0,0.7,0,0.9c0,0.5,0,1.1-0.1,1.7
			c0,0.6-0.1,1.3-0.1,2.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.6,0,0.9c0,0.6,0,1.1,0,1.7c0,0.6,0,1.1,0,1.7l0.1,3.3c0,0.6-0.1,0.9-0.4,1.1
			s-0.5,0.2-0.8,0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.5-0.1-0.9-0.1-1.4
			c0-0.4,0-0.9,0-1.3c0-1.2,0-2.2,0-3c0-0.8,0.1-1.5,0.1-2.1c0-0.6,0.1-1.1,0.1-1.5c0-0.4,0-0.9,0-1.3V441c0-2,0-4,0.1-6
			c0.1-2,0.2-4,0.3-6c0-0.2,0.1-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1h1.6c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0
			c0.1,0,0.3,0.1,0.4,0.2c0.2,0.2,0.2,0.5,0.2,0.9c0.2,0.8,0.5,1.7,0.9,2.5c0.3,0.8,0.7,1.7,1.1,2.5l0,0l0.4,0.9
			c0.2,0.6,0.5,1.2,0.7,1.8c0.3,0.6,0.5,1.1,0.7,1.7l1.6,3.2c0,0.1,0.1,0.3,0.2,0.6s0.3,0.8,0.5,1.3c0.2,0.5,0.4,1,0.6,1.6
			c0.2,0.6,0.4,1.1,0.5,1.6l-0.1-3.1c0-0.2,0-0.5,0.1-0.8c0-0.3,0-0.6,0-0.9c0-0.5,0-1.1-0.1-1.8c0-0.7,0-1.5,0-2.2l0.1-1.8
			c0-0.2,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4V435c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5v-0.7c0-0.4-0.1-0.8-0.1-1.3c0-0.4,0-0.9-0.1-1.4
			c0-0.2-0.1-0.5-0.1-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.2,0.3-0.3,0.5-0.3c0.3-0.1,0.5-0.1,0.8-0.1c0.7,0,1.1,0,1.3,0.1
			s0.3,0.2,0.4,0.3L359,430.4z"
        />
        <path
          fill="#444444"
          d="M381.2,451.6c0,0.4,0,0.8-0.1,1c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.1-0.6,0.1s-0.6,0-1,0
			c-0.8,0-1.6,0-2.6,0.1c-0.9,0-1.7-0.1-2.4-0.3c-0.3-0.8-0.6-1.6-0.7-2.3c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.4,0-0.8,0-1.2
			c0-0.4,0-0.8,0-1.2c0-0.6,0-1.1-0.1-1.6c-0.1-0.5-0.1-1.1-0.1-2c-0.3-1.3-0.4-2.7-0.3-4.2c0-1.5,0.1-3,0.1-4.5
			c0-0.8,0-1.6,0.1-2.4c0.1-0.8,0.1-1.5,0.1-2.3c0-1.5-0.1-3.1-0.2-4.8c-0.1-1.7-0.2-3.5-0.2-5.4c0-1,0-1.8-0.1-2.6
			c-0.1-0.8-0.1-1.4,0-2c0-0.6,0.1-1,0.3-1.3c0.2-0.3,0.4-0.5,0.8-0.5c0.5,0,1,0.1,1.7,0.1c0.7,0,1.4,0,2,0c0.7,0,1.3,0,1.8,0
			c0.5,0,0.9,0,1,0c1.3,0.1,2.7,0.4,4,0.7c1.3,0.3,2.5,1,3.6,1.9c1,1.1,1.8,2,2.5,2.9c0.7,0.9,1.2,1.7,1.5,2.7
			c0.4,0.9,0.6,1.9,0.7,3c0.1,1.1,0.2,2.4,0.2,3.9c0,1.3-0.1,2.3-0.2,3.1c-0.1,0.8-0.3,1.5-0.4,2c-0.2,0.5-0.3,0.9-0.5,1.2
			s-0.3,0.6-0.4,0.9l-0.5,1.2c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.5-0.4,0.8l-0.3,0.5c-0.6,1.1-1.4,2-2.4,2.8
			c-1,0.8-2,1.5-3,2.2l-0.4,0.3c-0.3,0.3-0.7,0.5-1.1,0.7c-0.4,0.2-0.8,0.4-1.2,0.6c-0.2,0.1-0.3,0.1-0.4,0.2
			c-0.1,0-0.3,0.1-0.5,0.3L381.2,451.6z M380.9,426.2c0,1.6,0,3.2,0,4.7c0,1.6,0,3.2-0.2,4.8c0.2,0,0.3-0.1,0.4-0.2
			c0.1-0.1,0.2-0.2,0.3-0.4l0.7-0.7c1-1,1.9-2,2.5-3c0.6-1,1-2,1-3.1c0-0.2,0-0.5,0-0.8c0-0.4,0-0.7-0.1-1l-0.3-1.2l-0.4-1
			c-0.2-0.5-0.4-1-0.6-1.3c-0.2-0.3-0.4-0.6-0.7-0.9c-0.3-0.3-0.6-0.5-1-0.8c-0.4-0.3-1-0.6-1.7-0.9c0.1,0.9,0.1,1.9,0,2.8
			C381,424.2,380.9,425.2,380.9,426.2z"
        />
        <path
          fill="#444444"
          d="M397.9,452.9c-0.1-0.7-0.1-1.5-0.1-2.4c0-0.9,0-1.6,0-2.3v-0.3c0-1.3,0.1-2.6,0.1-3.9c0-1.3,0-2.6,0-3.9
			c0-1.1,0-2.3,0-3.4c0-1.1,0-2.2-0.1-3.3v0.1c0-0.7-0.1-1.4-0.1-2c0-0.7,0-1.4,0-2v-2.6c0-0.5,0-0.9,0-1.4c0-0.4,0-0.9,0-1.4v-2.8
			c0-0.5,0-1.2-0.1-2.1c-0.1-0.9-0.1-1.7-0.1-2.6s0.1-1.6,0.2-2.3c0.1-0.7,0.4-1.1,0.8-1.3c0.5-0.2,1-0.3,1.6-0.2
			c0.6,0,1.1,0.1,1.4,0.1c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.8,0.2,1.6,0.4,2.3,0.6c0.8,0.2,1.5,0.4,2.3,0.6
			c0.6,0.1,1.3,0.4,1.8,0.7c0.6,0.3,1.1,0.7,1.7,1c2.1,1.5,3.7,3.4,4.8,5.6c1,2.2,1.6,4.6,1.6,7c0,1-0.2,2.1-0.5,3.2
			c-0.3,1.1-0.8,2.1-1.3,3c-0.5,0.9-1.2,1.8-1.9,2.5c-0.7,0.7-1.4,1.3-2.2,1.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
			l-0.9,0.3l6.1,8.5l1.2,1.7l1.5,2.9c0,0.3-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1
			c-0.2,0-0.3,0-0.5,0.1l-5.5,0.3c-0.1,0-0.3-0.1-0.6-0.4c-0.3-0.3-0.6-0.6-0.9-1.1c-0.3-0.4-0.7-0.9-1-1.4
			c-0.3-0.5-0.6-0.9-0.9-1.3l-1.1-1.7c-0.2-0.3-0.4-0.7-0.6-1.1c-0.2-0.4-0.4-0.9-0.6-1.4c-0.2-0.5-0.4-1-0.6-1.5
			c-0.2-0.5-0.3-0.9-0.4-1.3c0,0.8,0.1,1.8,0.2,3c0.1,1.2,0.2,2.4,0.2,3.5c0.1,1.1,0,2.1-0.1,2.9c-0.1,0.8-0.4,1.2-0.9,1.2
			c-0.4,0-0.9,0-1.4,0c-0.5,0-1.1,0-1.7,0c-0.6,0-1.2,0-1.7,0s-1,0-1.4,0L397.9,452.9z M406.2,433.1c0.4-0.3,0.8-0.5,1.2-0.8
			c0.4-0.3,0.7-0.6,1-1c0.3-0.4,0.5-1,0.7-1.7c0.2-0.7,0.2-1.6,0.2-2.6c0-1.3-0.2-2.5-0.7-3.5c-0.5-1.1-1.3-1.8-2.5-2.3l-0.1,6
			V433.1z"
        />
        <path
          fill="#444444"
          d="M447.6,432.5c-0.2,1.3-0.4,2.7-0.5,4.1c-0.1,1.4-0.3,2.8-0.5,4.1c-0.3,1.3-0.6,2.6-1.2,3.9
			c-0.5,1.2-1.3,2.4-2.3,3.4c-0.4,0.3-0.8,0.7-1,1.1c-0.3,0.4-0.6,0.9-1.1,1.4c-0.8,0.8-1.7,1.5-2.8,1.9c-1,0.5-2.1,0.7-3.2,0.7
			c-0.9,0-1.7-0.1-2.5-0.4c-0.8-0.3-1.7-0.6-2.4-1c-1.3-0.5-2.4-1.3-3.3-2.2c-0.9-0.9-1.8-2-2.4-3.2c-0.7-1.2-1.2-2.4-1.5-3.7
			c-0.3-1.3-0.5-2.6-0.5-3.8c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.8,0-1.3c0-1.4,0.1-2.7,0.4-4c0.3-1.3,0.4-2.6,0.4-3.9
			c0-1.3,0.2-2.4,0.5-3.3s0.6-1.8,0.8-2.7l0.4-1.7c0.2-0.6,0.4-1.1,0.5-1.6c0.2-0.5,0.4-1,0.6-1.4c0.3-0.4,0.6-0.9,0.9-1.2
			c0.4-0.4,0.8-0.7,1.3-1c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.3,0.5-0.4l0.8-0.5h-0.1c0.6-0.5,1.2-0.8,1.9-1c0.6-0.2,1.3-0.3,2-0.3
			c0.9,0,1.8,0.1,2.7,0.3h-0.1c0.5,0.1,1.1,0.4,1.6,0.7s1.1,0.7,1.6,1c0.5,0.4,1,0.8,1.5,1.1c0.5,0.4,0.9,0.7,1.2,1h-0.1
			c1.5,1.4,2.6,3,3.4,4.7c0.8,1.7,1.2,3.5,1.3,5.5c0,0.6,0.1,1.2,0.3,1.6s0.3,0.8,0.3,1.1l0.3,2.2V432.5z M439.3,432.6
			c-0.1-0.8-0.2-1.5-0.3-2.4c-0.1-0.8-0.3-1.6-0.4-2.4c-0.2-0.8-0.4-1.6-0.7-2.3c-0.3-0.7-0.7-1.3-1.2-1.9v0.1
			c-0.6-0.6-1.2-1.1-1.8-1.5c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.7,0-1.3,0.3-1.7,0.9c-0.5,0.6-0.8,1.3-1.1,2.2
			c-0.3,0.9-0.5,1.8-0.6,2.7c-0.1,0.9-0.2,1.8-0.2,2.4v0.9c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.5v3.1l0.3,3.7
			c0.1,0.8,0.3,1.6,0.4,2.4c0.1,0.8,0.3,1.5,0.6,2.1c0.2,0.6,0.5,1.1,0.9,1.5c0.4,0.4,0.9,0.6,1.7,0.6c1.1,0,2.1-0.4,2.8-1.2
			c0.7-0.8,1.3-1.7,1.7-2.8c0.4-1.1,0.7-2.2,0.8-3.5c0.2-1.2,0.3-2.3,0.3-3.2L439.3,432.6z"
        />
        <path
          fill="#444444"
          d="M448.3,450.1c-0.4-0.4-0.6-0.9-0.6-1.6c0-0.1,0-0.3,0.1-0.6c0.1-0.3,0.2-0.7,0.3-1.1c0.2-0.4,0.4-0.9,0.7-1.3
			c0.3-0.5,0.7-1,1.2-1.4c0.6-0.5,1-1,1.2-1.4c0.2-0.4,0.5-0.6,1-0.6c0.3,0,0.7,0.2,1.1,0.5c0.5,0.4,1,0.8,1.4,1.1
			c0.4,0.3,0.8,0.4,1.3,0.4s0.9-0.1,1.4-0.3c0.5-0.2,0.7-0.4,0.8-0.8l0.2-0.8c0.4-1.3,0.5-2.3,0.5-3.2s0-1.9,0-3
			c0-0.8-0.1-1.5-0.3-2.2c-0.2-0.7-0.3-1.5-0.3-2.2c0.1-0.6,0.1-1,0.2-1.4c0.1-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.3,0.1-0.4
			c0-0.1,0-0.1,0-0.2c0-2,0-4.2,0-6.4c0-2.3-0.1-4.3-0.4-6.2l-0.2-2.1c0-0.5,0.1-0.9,0.3-1.1c0.2-0.2,0.5-0.4,1-0.4
			c0.5-0.1,1.1-0.1,1.9,0c0.8,0,1.8,0.1,3,0.1c0.4,0,0.9,0,1.3,0.1c0.4,0,0.9,0.1,1.3,0.2c0.2,0.9,0.4,1.6,0.5,2.3
			c0.1,0.7,0.1,1.3,0.1,1.8c0,1-0.1,2-0.2,3c-0.1,1-0.2,2-0.2,3c0,1.1,0.1,2.1,0.2,3.2c0.1,1,0.2,2.1,0.2,3.3v11.1
			c0,1.1-0.1,2.4-0.4,3.8c-0.3,1.4-0.7,2.8-1.3,4.3l-0.6,0.8c-0.6,0.8-1.3,1.5-1.9,1.9s-1.3,0.8-1.9,1c-0.7,0.2-1.4,0.4-2.2,0.4
			c-0.8,0.1-1.7,0.1-2.8,0.1c-1.7,0-3.2-0.3-4.5-0.8C450.5,451.8,449.4,451.1,448.3,450.1z"
        />
        <path
          fill="#444444"
          d="M482.9,424.9l-0.2,4.7l2.5,0.1c0.4,0,0.8,0,1.4,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2s0.6,0.3,0.6,0.5
			l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
			s-0.6,0-1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2c0,0.2-0.1,0.4-0.1,0.6
			c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.4,0.1,0.7
			c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8c-0.3,0.2-0.8,0.3-1.6,0.3
			c-0.7,0.1-1.7,0.1-2.9,0.1s-2.7,0-4.5,0c-0.5,0-0.9,0-1.3-0.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0H476
			c-0.9,0-1.6,0-2-0.1c-0.4-0.1-0.6-0.6-0.6-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6s0-0.3,0-0.3
			c0.3-1.3,0.4-2.5,0.4-3.7c0-1.2,0.1-2.5,0.1-3.7v-3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.1,0.1-2.2,0.2-3.1
			c0.2-0.9,0.2-1.9,0.2-2.8c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5c0-0.4,0-0.8,0-1.3c0-0.4,0-0.9,0.1-1.3l0.2-2.5v-0.4
			c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1s0.9-0.4,2-0.4
			c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2c0.8,0,1.6-0.1,2.3-0.2c0.7-0.1,1.5-0.2,2.5-0.2h0.9
			c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0s1.1,0,1.7,0s1,0,1.3,0c0.5,0,0.8,0.6,0.8,1.6c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1
			c-0.5,0-1.2,0-2.2,0h-5.5V424.9z"
        />
        <path
          fill="#444444"
          d="M500.8,421.5l-6.8,0.2l-0.3-2.2c0-1.3,0-2.4,0-3.2c0-0.8,0.1-1.4,0.2-1.8c0.1-0.4,0.3-0.7,0.5-0.9
			c0.2-0.1,0.6-0.2,1.1-0.3l2-0.2c0.7-0.1,1.4-0.1,2-0.2c0.7,0,1.4-0.1,2.1-0.1c1,0,1.9,0.1,2.8,0.2c0.9,0.1,1.8,0.2,2.7,0.2
			c0.7,0,1.4,0,2.1-0.1c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.7,0,1.2-0.1c0.5-0.1,0.9-0.1,1.4-0.1s0.8,0.1,1.2,0.2
			c0.3,0.1,0.5,0.3,0.6,0.6c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5,0,0.9,0,1.4c0,0.4,0,0.7-0.1,1c0,0.3-0.1,0.7-0.1,1.3c0,0.2,0,0.4,0,0.5
			c0,0.2,0,0.4,0.1,0.6c0.3,0.9-0.7,1.3-2.8,1.2c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2
			c-0.4,0.1-0.8,0.2-1.3,0.2l-0.2,1.9c0,0.4,0,0.8,0,1.1c0,0.4,0.1,0.7,0.1,1.1c0,1.2,0,2.4,0,3.7c0,1.3,0,2.6-0.1,3.8
			c0,1.3-0.1,2.5-0.1,3.7c-0.1,1.2-0.1,2.3-0.1,3.4l-0.1,1.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.6,0,1.3,0.1,1.9
			c0.1,0.6,0.1,1.1,0.1,1.6v3.1c0,0.5,0,0.9,0.1,1.3c0.1,0.4,0.1,0.8,0,1.1c0,0.3-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-1,0.3
			c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0.1-1.8,0.1c-1.3,0-2.3-0.1-3-0.2c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.6,0.2-1.1
			c0.2-0.9,0.3-1.8,0.3-2.7c0-0.9,0.1-1.8,0.1-2.7v-4.6c0-1,0.1-2.1,0.2-3.2s0.2-2.3,0.2-3.4c0-1,0-2-0.1-3c-0.1-1-0.1-1.9-0.2-2.9
			l0.1-2.7c-0.1-0.7-0.1-1.4-0.1-2C500.7,422.9,500.8,422.2,500.8,421.5z"
        />
        <path
          fill="#444444"
          d="M225.7,480.6l-1.7-0.3h-0.5c-1,0-1.9,0.3-2.9,0.8c-0.9,0.5-1.7,1.2-2.1,2c0,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.1-0.2,0.3-0.2,0.4V484c-0.4,1-0.8,2-1,3c-0.2,0.9-0.4,2-0.6,3.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.7,0,1c0,0.9,0.1,1.8,0.3,2.7
			c0.2,0.9,0.5,1.8,0.8,2.7c0.4,0.9,0.8,1.8,1.2,2.6c0.4,0.8,0.9,1.6,1.5,2.3c0.2,0.3,0.5,0.5,0.9,0.8c0.4,0.2,0.9,0.4,1.4,0.6
			c0.5,0.1,1,0.3,1.6,0.3c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.6,0,1,0.1c0.4,0,0.8,0.1,1,0.1c0.6,0,0.9,0.2,1,0.6
			c0.1,0.4,0.2,1.3,0.3,2.7c0,0.1,0,0.4,0.1,0.8c0,0.4,0.1,0.7,0.1,1.2c0,0.4,0,0.8,0.1,1.2c0,0.4,0.1,0.7,0.1,0.8
			c0,0.5-0.1,0.8-0.3,1c-0.3,0.2-0.6,0.3-1.1,0.4c-0.5,0.1-1.1,0.1-1.7,0c-0.7,0-1.4-0.1-2.1-0.1c-1.7,0-3.3-0.4-4.8-1.1
			c-1.5-0.7-2.8-1.7-4-3c-1.2-1.2-2.2-2.6-3.1-4.2c-0.9-1.6-1.5-3.2-1.9-4.9l-0.5-2l-0.5-1.6c-0.3-1-0.4-2.2-0.4-3.6
			c0-1,0.1-2.1,0.2-3.2c0.1-1.1,0.3-2,0.5-2.7l0.4-2.1c0.1-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.6-1.2,1-1.7c0.4-0.6,0.8-1.1,1.3-1.7
			c0.5-0.6,1-1.3,1.5-2c1.3-1.3,2.7-2.3,4.4-3.2c1.7-0.9,3.5-1.3,5.4-1.3c0.7,0,1.4,0,2,0.1c0.7,0.1,1.3,0.3,2.1,0.6l0.8,0.3
			c0.5,0.2,1,0.4,1.3,0.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.6-0.4,1.6-1.2,3.2v-0.1l-0.9,2c0,0.1-0.1,0.3-0.2,0.6
			c-0.1,0.3-0.2,0.5-0.2,0.6L225.7,480.6z"
        />
        <path
          fill="#444444"
          d="M256.5,492.4c-0.2,1.3-0.4,2.7-0.5,4.1c-0.1,1.4-0.3,2.8-0.5,4.1c-0.3,1.3-0.6,2.6-1.2,3.9
			c-0.5,1.2-1.3,2.4-2.3,3.4c-0.4,0.3-0.8,0.7-1,1.1c-0.3,0.4-0.6,0.9-1.1,1.4c-0.8,0.8-1.7,1.5-2.8,1.9c-1,0.5-2.1,0.7-3.2,0.7
			c-0.9,0-1.7-0.1-2.6-0.4c-0.8-0.3-1.7-0.6-2.4-1c-1.3-0.5-2.4-1.3-3.3-2.2c-0.9-0.9-1.8-2-2.4-3.2c-0.7-1.2-1.2-2.4-1.5-3.7
			s-0.5-2.6-0.5-3.8c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.8,0-1.3c0-1.4,0.1-2.7,0.4-4c0.3-1.3,0.4-2.6,0.4-3.9
			c0-1.3,0.2-2.4,0.5-3.3s0.6-1.8,0.8-2.7l0.4-1.7c0.2-0.6,0.4-1.1,0.5-1.6c0.2-0.5,0.4-1,0.6-1.4c0.2-0.4,0.6-0.9,0.9-1.2
			c0.4-0.4,0.8-0.7,1.3-1c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.3,0.5-0.4l0.8-0.5h-0.1c0.6-0.5,1.2-0.8,1.9-1c0.6-0.2,1.3-0.3,2-0.3
			c0.9,0,1.8,0.1,2.7,0.3h-0.1c0.5,0.1,1.1,0.4,1.6,0.7c0.6,0.3,1.1,0.7,1.6,1s1,0.8,1.5,1.1c0.5,0.4,0.9,0.7,1.2,1H251
			c1.5,1.4,2.6,3,3.4,4.7c0.8,1.7,1.2,3.5,1.3,5.5c0,0.6,0.1,1.2,0.3,1.6c0.1,0.4,0.3,0.8,0.3,1.1l0.3,2.2V492.4z M248.2,492.5
			c-0.1-0.8-0.2-1.5-0.3-2.4c-0.1-0.8-0.3-1.6-0.4-2.4c-0.2-0.8-0.4-1.6-0.7-2.3c-0.3-0.7-0.7-1.3-1.2-1.9v0.1
			c-0.6-0.6-1.2-1.1-1.8-1.5c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.7,0-1.3,0.3-1.7,0.9c-0.5,0.6-0.8,1.3-1.1,2.2
			c-0.3,0.9-0.5,1.8-0.6,2.7c-0.1,0.9-0.2,1.8-0.2,2.4v0.9c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3-0.1,0.5v3.1l0.3,3.7
			c0.1,0.8,0.3,1.6,0.4,2.4s0.3,1.5,0.6,2.1c0.2,0.6,0.5,1.1,0.9,1.5c0.4,0.4,0.9,0.6,1.7,0.6c1.1,0,2.1-0.4,2.8-1.2
			s1.3-1.7,1.7-2.8c0.4-1.1,0.7-2.2,0.8-3.5c0.2-1.2,0.3-2.3,0.3-3.2L248.2,492.5z"
        />
        <path
          fill="#444444"
          d="M268.6,504.4c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.1-0.1c0.7-0.1,1.4-0.1,2.1-0.1
			c0.8,0,1.3,0,1.6,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.3c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.6-0.1-2.5-0.1h-3.8c-0.6,0-1.1,0-1.4,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-0.9-0.1-1.9-0.1-2.9c0-0.9,0.1-1.8,0.2-2.7c0.1-0.9,0.2-1.8,0.2-2.7c0-0.4,0-0.8-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.8c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5s0.1,1.7,0.1,2.5v-0.1c0.1,0.9,0.2,1.9,0.2,2.8
			c0,1,0,1.9-0.1,2.9c-0.1,0.9-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1,0.1,1.4c0,0.4,0,0.7,0.1,0.9
			c0,0.2,0.1,0.4,0.1,0.6V504.4z"
        />
        <path
          fill="#444444"
          d="M289.3,504.4c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.1-0.1c0.7-0.1,1.4-0.1,2.1-0.1
			c0.8,0,1.3,0,1.6,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.3c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.6-0.1-2.5-0.1H283c-0.6,0-1.1,0-1.4,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-0.9-0.1-1.9-0.1-2.9c0-0.9,0.1-1.8,0.2-2.7c0.1-0.9,0.2-1.8,0.2-2.7c0-0.4,0-0.8-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.8c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.4-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5s0.1,1.7,0.1,2.5v-0.1c0.1,0.9,0.2,1.9,0.2,2.8
			c0,1,0,1.9-0.1,2.9c-0.1,0.9-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1,0.1,1.4c0,0.4,0,0.7,0.1,0.9
			c0,0.2,0.1,0.4,0.1,0.6V504.4z"
        />
        <path
          fill="#444444"
          d="M310.5,484.8l-0.2,4.7l2.5,0.1c0.4,0,0.8,0,1.4,0c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0.1,1.5,0.2
			c0.4,0.1,0.6,0.3,0.6,0.5l-0.1,4.1c0,0.2,0,0.5,0,0.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.6,0-1,0l-5.6,0.3c-0.4,0-0.5,0.3-0.5,0.6v0.9c0,0.8,0,1.6,0,2.4c0,0.8-0.1,1.5-0.3,2.2
			c0,0.2-0.1,0.4-0.1,0.6c0.9,0.1,1.8,0.2,2.5,0.4c0.8,0.2,1.5,0.3,2.2,0.3c0.4,0,0.7,0,1-0.1c0.3-0.1,0.6-0.1,0.9-0.1
			c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0h0.3c0.3,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.6c0,0.3,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6v3c0,0.4-0.2,0.7-0.5,0.8
			c-0.3,0.2-0.8,0.3-1.6,0.3c-0.7,0.1-1.7,0.1-2.9,0.1c-1.2,0-2.7,0-4.5,0c-0.5,0-0.9,0-1.3-0.1c-0.4-0.1-0.7-0.1-1-0.1
			c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0h-1.8c-0.9,0-1.6,0-2-0.1c-0.4-0.1-0.6-0.6-0.6-1.5c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.6
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.3c0.3-1.3,0.4-2.5,0.4-3.7c0-1.2,0.1-2.5,0.1-3.7v-3c0-0.5,0-1-0.1-1.5
			c-0.1-0.5-0.1-1-0.2-1.6l-0.2-2.8c0-1.1,0.1-2.2,0.2-3.1c0.2-0.9,0.2-1.9,0.2-2.8c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1v-2.5
			c0-0.4,0-0.8,0-1.3c0-0.4,0-0.9,0.1-1.3l0.2-2.5v-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.5,0-0.9
			c0.1-0.4,0.1-0.7,0.1-0.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.9-0.4,2-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.7,0.1,1.3,0.2,1.9,0.2
			c0.8,0,1.6-0.1,2.3-0.2c0.7-0.1,1.5-0.2,2.5-0.2h0.9c0.3,0,0.8,0,1.3,0c0.5,0,1.1,0,1.7,0s1.1,0,1.7,0c0.5,0,1,0,1.3,0
			c0.5,0,0.8,0.6,0.8,1.6c0,1.1,0,2.6,0,4.5c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-1.2,0-2.2,0h-5.5V484.8z"
        />
        <path
          fill="#444444"
          d="M339.6,480.6l-1.7-0.3h-0.5c-1,0-1.9,0.3-2.9,0.8c-0.9,0.5-1.7,1.2-2.1,2c0,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.1-0.2,0.3-0.2,0.4V484c-0.4,1-0.8,2-1,3c-0.2,0.9-0.4,2-0.6,3.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.7,0,1c0,0.9,0.1,1.8,0.3,2.7
			c0.2,0.9,0.5,1.8,0.8,2.7c0.4,0.9,0.8,1.8,1.2,2.6s0.9,1.6,1.5,2.3c0.2,0.3,0.5,0.5,0.9,0.8c0.4,0.2,0.9,0.4,1.4,0.6
			c0.5,0.1,1,0.3,1.6,0.3c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.6,0,1,0.1c0.4,0,0.8,0.1,1,0.1c0.6,0,0.9,0.2,1,0.6
			c0.1,0.4,0.2,1.3,0.3,2.7c0,0.1,0,0.4,0.1,0.8c0,0.4,0.1,0.7,0.1,1.2c0,0.4,0,0.8,0.1,1.2c0,0.4,0.1,0.7,0.1,0.8
			c0,0.5-0.1,0.8-0.3,1c-0.3,0.2-0.6,0.3-1.1,0.4c-0.5,0.1-1.1,0.1-1.7,0c-0.7,0-1.4-0.1-2.1-0.1c-1.7,0-3.3-0.4-4.8-1.1
			c-1.5-0.7-2.8-1.7-4-3c-1.2-1.2-2.2-2.6-3.1-4.2c-0.9-1.6-1.5-3.2-1.9-4.9l-0.5-2l-0.5-1.6c-0.3-1-0.4-2.2-0.4-3.6
			c0-1,0.1-2.1,0.2-3.2c0.1-1.1,0.3-2,0.5-2.7l0.4-2.1c0.1-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.6-1.2,1-1.7c0.4-0.6,0.8-1.1,1.3-1.7
			c0.5-0.6,1-1.3,1.5-2c1.3-1.3,2.7-2.3,4.4-3.2c1.7-0.9,3.5-1.3,5.4-1.3c0.7,0,1.4,0,2,0.1c0.7,0.1,1.3,0.3,2.1,0.6l0.8,0.3
			c0.5,0.2,1,0.4,1.3,0.6c0.3,0.2,0.5,0.4,0.5,0.7c0,0.6-0.4,1.6-1.2,3.2v-0.1l-0.9,2c0,0.1-0.1,0.3-0.2,0.6
			c-0.1,0.3-0.2,0.5-0.2,0.6L339.6,480.6z"
        />
        <path
          fill="#444444"
          d="M351.9,481.4l-6.8,0.2l-0.3-2.2c0-1.3,0-2.4,0-3.2c0-0.8,0.1-1.4,0.2-1.8c0.1-0.4,0.3-0.7,0.5-0.9
			c0.2-0.1,0.6-0.2,1.1-0.3l2-0.2c0.7-0.1,1.4-0.1,2-0.2c0.7,0,1.4-0.1,2.1-0.1c1,0,1.9,0.1,2.8,0.2c0.9,0.1,1.8,0.2,2.7,0.2
			c0.7,0,1.4,0,2.1-0.1c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.7,0,1.2-0.1c0.5-0.1,0.9-0.1,1.4-0.1c0.4,0,0.8,0.1,1.2,0.2
			c0.3,0.1,0.5,0.3,0.6,0.6c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5,0,0.9,0,1.4c0,0.4,0,0.7-0.1,1c0,0.3-0.1,0.7-0.1,1.3c0,0.2,0,0.4,0,0.5
			s0,0.4,0.1,0.6c0.3,0.9-0.7,1.3-2.8,1.2c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2
			c-0.4,0.1-0.8,0.2-1.3,0.2l-0.2,1.9c0,0.4,0,0.8,0,1.1c0,0.4,0.1,0.7,0.1,1.1c0,1.2,0,2.4,0,3.7c0,1.3,0,2.6-0.1,3.8
			c0,1.3-0.1,2.5-0.1,3.7c-0.1,1.2-0.1,2.3-0.1,3.4l-0.1,1.8c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4c0,0.6,0,1.3,0.1,1.9
			c0.1,0.6,0.1,1.1,0.1,1.6v3.1c0,0.5,0,0.9,0.1,1.3c0.1,0.4,0.1,0.8,0,1.1c0,0.3-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-1,0.3
			c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0.1-1.8,0.1c-1.3,0-2.3-0.1-3-0.2c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.6,0.2-1.1
			c0.2-0.9,0.3-1.8,0.3-2.7c0-0.9,0.1-1.8,0.1-2.7v-4.6c0-1,0.1-2.1,0.2-3.2c0.1-1.1,0.2-2.3,0.2-3.4c0-1,0-2-0.1-3
			c-0.1-1-0.1-1.9-0.2-2.9l0.1-2.7c-0.1-0.7-0.1-1.4-0.1-2C351.8,482.8,351.8,482.1,351.9,481.4z"
        />
        <path
          fill="#444444"
          d="M375.7,512.7c-0.7-0.1-1.5-0.1-2.3-0.2c-0.9-0.1-1.7-0.1-2.3-0.2l-0.1-2.6c-0.1-0.6-0.1-1.3-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.7c0-1.6,0-3.3-0.1-4.9c-0.1-1.6-0.1-3.3-0.1-5
			c0-1.4,0-2.9,0.1-4.3c0-1.4,0.1-2.9,0.2-4.3v0.1l0.1-4.8c0-0.2,0-0.5,0-0.9c0-0.4,0-0.8-0.1-1.3c0-0.4-0.1-0.8-0.1-1.2
			c0-0.4,0-0.7,0-0.8c0-0.5,0.1-0.9,0.2-1.2c0.2-0.3,0.4-0.4,0.7-0.5s0.7-0.1,1.2-0.1c0.5,0,1,0,1.7,0c1,0,1.7,0,2.3,0
			c0.6,0,1,0.2,1.3,0.4s0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.5-0.1,4.2c0,1.7,0,3.9,0.1,6.4l0.2,4.1
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.1-0.3,2.3-0.3,3.4c0,1.1,0,2.2,0,3.3v2.1c0,0.6,0,1.3-0.1,2.1
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5-0.1,1.1-0.2,1.6c-0.2,0.6-0.3,1.1-0.6,1.6L375.7,512.7
			z"
        />
        <path
          fill="#444444"
          d="M392.6,500.8c0,0.2,0,0.3,0,0.5s0,0.3,0,0.4c0,0.8,0.1,1.4,0.3,2.1v-0.1c0.1,0.2,0.2,0.4,0.2,0.7s0,0.5,0,0.8
			l-0.2,5.7c0,0.5,0,0.9,0,1.1c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.5,0.1-0.9,0.2l-1.3-0.1
			c-0.7,0-1.5,0.1-2.2,0.1s-1.5,0-2.2,0c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.4-0.2-0.5-0.4c0-0.9,0.1-1.8,0.2-2.7
			c0.1-0.9,0.2-1.8,0.2-2.6c0-0.9,0-1.9-0.1-3c-0.1-1.1-0.1-2.1-0.1-3.1c0-1.5,0-3,0.1-4.5c0.1-1.5,0.1-3,0.1-4.6c0-0.9,0-1.7,0-2.6
			c0-0.8,0-1.7-0.1-2.5v0.1c-0.1-1.5-0.2-3.3-0.2-5.3c0-2,0.1-3.9,0.1-5.7c0-0.5,0.1-1,0.2-1.5s0.4-0.8,0.8-0.9l1.4-0.2
			c1-0.1,2.1-0.1,3.2-0.2c1.2-0.1,2.3-0.1,3.5-0.2c1.2-0.1,2.3-0.1,3.5-0.1c1.1,0,2.2-0.1,3.2-0.1c0.3,0,0.4,0,0.5,0.1
			c0.1,0.1,0.2,0.1,0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.2,0.7v-0.1c0.2,0.5,0.3,1,0.3,1.5l-0.2,3.3
			c-0.1,0.3-0.3,0.5-0.7,0.7s-0.9,0.2-1.4,0.2c-0.6,0-1.1,0-1.7-0.1c-0.6,0-1.2-0.1-1.7-0.1l-3.4,0.1c-0.4,0.1-0.6,0.2-0.6,0.2
			c0,0-0.1,0.2-0.1,0.4l0.3,10c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0,1.4-0.1c0.5-0.1,0.9-0.1,1.4-0.1l3-0.1
			c0.4,0,0.7,0.2,0.9,0.6c0.2,0.4,0.3,0.9,0.3,1.6c0.1,0.6,0.1,1.3,0.1,2.1c0,0.7,0,1.4,0,2c0,0.5,0,0.9-0.1,1
			c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.8,0.1c-0.3,0-0.8-0.1-1.4-0.1c-0.9,0-1.8,0.1-2.7,0.2c-0.8,0.1-1.7,0.3-2.7,0.4V500.8z
			"
        />
        <path
          fill="#444444"
          d="M435.9,498.3l0.9,1.2l0.4-0.9c0.3-1.1,0.9-1.6,1.7-1.6c0.3,0,0.6,0.1,0.9,0.2v-0.1c0.5,0.1,1,0.3,1.6,0.4
			c0.6,0.1,1.1,0.3,1.5,0.5c0.5,0.2,0.8,0.3,1.2,0.5c0.3,0.2,0.5,0.4,0.5,0.6c0,0.5-0.1,1.1-0.3,1.9c-0.2,0.7-0.4,1.5-0.7,2.2
			c-0.3,0.7-0.6,1.4-0.9,2.1c-0.3,0.7-0.7,1.2-1,1.6l-0.5,0.5l2.7,3.9c0.1,0.2,0.2,0.4,0.2,0.8c0,0.3,0.1,0.6,0.1,0.7
			c0,0.4-0.1,0.6-0.4,0.8c-0.3,0.2-0.6,0.3-0.9,0.3c-0.4,0.1-0.8,0.1-1.3,0.1c-0.5,0-1,0-1.4,0c-0.9,0-1.6,0-2,0
			c-0.4,0-0.8-0.1-1-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.4-0.5-0.7-0.8l-0.6-0.8l-1.1,0.4c-0.7,0.3-1.5,0.5-2.5,0.7
			c-0.9,0.2-2,0.2-3.3,0.2c-1.7,0-3.1-0.2-4.3-0.7c-1.2-0.5-2.1-1.1-2.9-2c-0.8-0.8-1.3-1.8-1.7-3c-0.4-1.1-0.5-2.3-0.5-3.6
			c0-1.2,0.1-2.3,0.3-3.4c0.2-1.1,0.5-2.2,0.9-3.2c0.4-1.1,0.9-2.2,1.4-3.3c0.6-1.1,1.1-2.3,1.7-3.5c-0.6-0.8-1.1-1.6-1.6-2.2
			c-0.5-0.6-1-1.3-1.4-1.9c-0.4-0.6-0.7-1.3-0.9-2c-0.2-0.7-0.3-1.5-0.3-2.4c0-1.1,0.2-2.2,0.6-3.3c0.4-1.1,1-2.1,1.8-3
			c0.8-0.9,1.7-1.6,2.7-2.1c1-0.5,2.1-0.8,3.3-0.8c1.6,0,3.1,0.5,4.6,1.4l0.3,0.3c0.6,1,1.1,2.1,1.5,3.2c0.4,1.1,0.6,2.2,0.6,3.5
			c-0.1,1-0.3,1.9-0.6,2.8c-0.3,0.9-0.7,1.8-1,2.6l-0.9,1.8l0.1-0.1c-0.1,0.3-0.4,0.7-0.7,1.3c-0.3,0.5-0.5,1-0.5,1.3
			c0,0.1,0,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.5h-0.1l1.9,2.9c0.3,0.5,0.6,1,0.9,1.4c0.3,0.4,0.6,0.9,1,1.4v-0.1L435.9,498.3z
			 M427.2,484.8l0.1,0.2c0.3-1,0.5-1.8,0.6-2.5c0.1-0.7,0.1-1.4,0.1-2.2c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.3,0-0.7,0.1-1.1,0.3c-0.4,0.2-0.7,0.7-0.7,1.3c0,0,0,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.4L427.2,484.8z M430,506.1
			c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.6-0.2,0.9-0.3l-4.4-6.6c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0.3-0.1,0.4-0.1,0.5l-0.1,0.5
			c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6c0,0.9,0.3,1.6,0.8,2.3c0.5,0.7,1.1,1.2,1.7,1.6L430,506.1z"
        />
        <path
          fill="#444444"
          d="M466.4,512.7c-0.7-0.1-1.5-0.1-2.3-0.2c-0.9-0.1-1.7-0.1-2.3-0.2l-0.1-2.6c-0.1-0.6-0.1-1.3-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.7c0-1.6,0-3.3-0.1-4.9c-0.1-1.6-0.1-3.3-0.1-5
			c0-1.4,0-2.9,0.1-4.3c0-1.4,0.1-2.9,0.2-4.3v0.1l0.1-4.8c0-0.2,0-0.5,0-0.9c0-0.4,0-0.8-0.1-1.3c0-0.4-0.1-0.8-0.1-1.2
			s0-0.7,0-0.8c0-0.5,0.1-0.9,0.2-1.2c0.2-0.3,0.4-0.4,0.7-0.5s0.7-0.1,1.2-0.1c0.5,0,1,0,1.7,0c1,0,1.7,0,2.3,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.5-0.1,4.2c0,1.7,0,3.9,0.1,6.4l0.2,4.1
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.1-0.3,2.3-0.3,3.4c0,1.1,0,2.2,0,3.3v2.1c0,0.6,0,1.3-0.1,2.1
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1s0.1,0.8,0.1,1.2c0,0.5-0.1,1.1-0.2,1.6c-0.2,0.6-0.3,1.1-0.6,1.6L466.4,512.7z"
        />
        <path
          fill="#444444"
          d="M498.9,475.2c0,1.8,0,3.5,0.1,5.3c0,1.7,0.1,3.4,0.2,5.2c0,1.5,0,3.1-0.1,4.7c-0.1,1.6-0.1,3.1-0.1,4.6
			c0,0.3,0.1,0.6,0.1,1c0,0.4,0,0.7,0,1c0,0.3,0,0.6,0,1.1c0,0.4,0,0.8-0.1,1.1l0.1-0.2c0,0.9,0,1.7-0.1,2.4c0,0.7-0.1,1.3-0.1,1.8
			c0,0.6-0.1,1.1-0.1,1.6c0,0.5-0.1,1.1-0.1,1.7v2c0,0.4,0,0.7,0,1c0,0.3,0,0.7,0,1c0,0.9-0.1,1.4-0.4,1.7c-0.3,0.3-0.8,0.4-1.6,0.4
			c-0.4,0-0.9,0-1.4,0c-0.5,0-1,0-1.5,0c-0.5,0-1,0-1.4-0.1c-0.4,0-0.8-0.1-1-0.2c-0.5,0.1-0.9,0.1-1.1-0.1
			c-0.3-0.2-0.4-0.4-0.6-0.8c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.5-0.3-1-0.5-1.5c-0.3-1-0.6-2-0.8-3c-0.3-1-0.5-2-0.8-2.9
			c-0.3-0.9-0.6-1.9-0.9-2.7c-0.3-0.9-0.8-1.7-1.3-2.5c-0.4-0.6-0.7-1.3-0.8-1.9c-0.1-0.7-0.3-1.3-0.5-2l-0.9-2.6
			c0.1,0.9,0.1,1.9,0.2,3c0.1,1.1,0.1,2.3,0.2,3.5c0.1,1.2,0.1,2.4,0.2,3.5c0,1.2,0.1,2.2,0.1,3.1l0.2,4.9c0,0.9,0,1.5,0,2.1
			s-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.4-0.6,0.5c-0.3,0.1-0.7,0.1-1.3,0.1c-0.3,0-0.7,0-1.2,0c-0.5,0-0.9,0-1.4,0
			c-0.5,0-0.9-0.1-1.3-0.1c-0.4-0.1-0.7-0.1-1-0.2c-0.1,0-0.3,0-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.2-0.9-0.3-1.9-0.5-2.8
			s-0.2-1.9-0.2-2.7c0-0.2,0-0.5,0.1-1.1c0-0.6,0.1-1.2,0.1-1.8c0-0.6,0-1.3,0.1-1.9c0-0.6,0-1.1,0-1.3c0-0.9,0-1.8,0.1-2.7
			c0-0.9,0.1-1.8,0.1-2.8v-1.6c0-3,0-6.1,0.1-9.3c0-3.2,0.1-6.4,0.3-9.4c0-0.5,0.1-0.8,0.2-1c0.1-0.2,0.3-0.3,0.5-0.4
			c0.2-0.1,0.5-0.1,0.8-0.1c0.3,0,0.7,0,1.2,0h2.8c0.2,0,0.4,0,0.7-0.1c0.3,0,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.7,0.3
			c0.2,0.2,0.3,0.4,0.5,0.8c0.1,0.3,0.2,0.6,0.2,1c0.1,0.3,0.1,0.7,0.1,0.9v0.1c0.3,1.2,0.7,2.4,1.2,3.6s1,2.4,1.6,3.5l0.5,1.3v-0.1
			c0.4,0.9,0.8,1.8,1.1,2.7c0.3,0.9,0.7,1.7,1,2.6l2.5,7.5c0,0,0-0.2,0-0.4c0-0.2,0-0.5-0.1-0.8c0-0.3-0.1-0.5-0.1-0.8
			c0-0.2,0-0.4,0-0.4c-0.2-0.7-0.3-1.4-0.4-2.2c-0.1-0.8-0.1-1.5-0.1-2.3c0-0.3,0-0.6,0-0.8s0-0.5,0-0.8v-7c0-1.1-0.1-2.1-0.3-3.1
			c-0.2-1-0.3-2.1-0.3-3.2v-1c0-0.5,0.2-0.8,0.5-1c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.6,0,0.9,0.1c0.3,0,0.6,0.1,0.9,0.1
			c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.1,1.2-0.1c0.7,0,1.4,0.1,2.3,0.4L498.9,475.2z"
        />
        <path
          fill="#444444"
          d="M521.5,480.6l-1.7-0.3h-0.5c-1,0-1.9,0.3-2.9,0.8c-0.9,0.5-1.7,1.2-2.1,2c0,0.1-0.1,0.3-0.2,0.4
			s-0.2,0.3-0.2,0.4V484c-0.4,1-0.8,2-1,3c-0.2,0.9-0.4,2-0.6,3.1c0,0.4,0,0.8,0,1.1c0,0.3,0,0.7,0,1c0,0.9,0.1,1.8,0.3,2.7
			c0.2,0.9,0.5,1.8,0.8,2.7c0.4,0.9,0.8,1.8,1.2,2.6s0.9,1.6,1.5,2.3c0.2,0.3,0.5,0.5,0.9,0.8c0.4,0.2,0.9,0.4,1.4,0.6
			c0.5,0.1,1,0.3,1.6,0.3c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.6,0,1,0.1c0.4,0,0.8,0.1,1,0.1c0.6,0,0.9,0.2,1,0.6
			c0.1,0.4,0.2,1.3,0.3,2.7c0,0.1,0,0.4,0.1,0.8c0,0.4,0.1,0.7,0.1,1.2c0,0.4,0,0.8,0.1,1.2s0.1,0.7,0.1,0.8c0,0.5-0.1,0.8-0.3,1
			c-0.3,0.2-0.6,0.3-1.1,0.4c-0.5,0.1-1.1,0.1-1.7,0c-0.7,0-1.4-0.1-2.1-0.1c-1.7,0-3.3-0.4-4.8-1.1c-1.5-0.7-2.8-1.7-4-3
			c-1.2-1.2-2.2-2.6-3.1-4.2c-0.9-1.6-1.5-3.2-1.9-4.9l-0.5-2l-0.5-1.6c-0.3-1-0.4-2.2-0.4-3.6c0-1,0.1-2.1,0.2-3.2
			c0.1-1.1,0.3-2,0.5-2.7l0.4-2.1c0.1-0.8,0.2-1.5,0.5-2.1s0.6-1.2,1-1.7c0.4-0.6,0.8-1.1,1.3-1.7c0.5-0.6,1-1.3,1.5-2
			c1.3-1.3,2.7-2.3,4.4-3.2c1.7-0.9,3.5-1.3,5.4-1.3c0.7,0,1.4,0,2,0.1c0.7,0.1,1.4,0.3,2.1,0.6l0.8,0.3c0.5,0.2,1,0.4,1.3,0.6
			c0.3,0.2,0.5,0.4,0.5,0.7c0,0.6-0.4,1.6-1.2,3.2v-0.1l-0.9,2c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.3-0.2,0.5-0.2,0.6L521.5,480.6z"
        />
        <path
          fill="#444444"
          d="M537.5,504.4c0.4,0,0.8,0.1,1.1,0.1c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.1-0.1c0.7-0.1,1.4-0.1,2.1-0.1
			c0.8,0,1.3,0,1.6,0.1c0.3,0.1,0.6,0.2,0.7,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3,0,0.7,0,1.1c0,1.5-0.1,3-0.2,4.4
			c-0.1,0.7-0.2,1.1-0.5,1.3c-0.2,0.2-0.7,0.4-1.3,0.4c-0.8,0.1-1.5,0.1-2.3,0.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.9,0-1.7,0-2.5-0.1
			c-0.8-0.1-1.6-0.1-2.5-0.1h-3.8c-0.6,0-1.1,0-1.4,0c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-0.5,0-0.9v-1.5
			c0-0.9,0-1.9-0.1-2.8c0-0.9-0.1-1.9-0.1-2.9c0-0.9,0.1-1.8,0.2-2.7c0.1-0.9,0.2-1.8,0.2-2.7c0-0.4,0-0.8-0.1-1.3
			c0-0.4-0.1-0.8-0.1-1.3v-14.8c0-1.9,0-3.5-0.1-4.6c-0.1-1.1,0-2,0.1-2.6c0.1-0.6,0.3-1,0.7-1.1c0.4-0.2,1-0.2,1.8-0.2
			c0.3,0,0.6,0,0.8,0c0.3,0,0.5,0,0.8,0h1.6c0.8,0,1.3,0,1.7,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9
			c0,0.4,0,0.9,0,1.6c0,0.4,0,0.7-0.1,0.9c0,0.3-0.1,0.5-0.1,0.8c0,0.8,0,1.7,0,2.5c0,0.8,0.1,1.7,0.1,2.5v-0.1
			c0.1,0.9,0.2,1.9,0.2,2.8c0,1,0,1.9-0.1,2.9c-0.1,0.9-0.1,1.9-0.1,2.8v1.8c0,0.9,0,1.6,0.1,2.2c0,0.6,0.1,1,0.1,1.4
			c0,0.4,0,0.7,0.1,0.9c0,0.2,0.1,0.4,0.1,0.6V504.4z"
        />
        <path
          fill="#444444"
          d="M549.7,473.9c0-0.3,0.1-0.4,0.4-0.6c0.3-0.1,0.5-0.2,0.9-0.3c0.3,0,0.7-0.1,1-0.1s0.6,0,0.8,0
			c0.4,0,1,0,1.6,0c0.6,0,1.2,0,1.8,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,0.8,0.2,0.9,0.5c0.2,0.4,0.3,0.8,0.2,1.2s0,0.8,0,1.2
			c0,0.4,0,0.7-0.1,1c0,0.3-0.1,0.6-0.2,0.9l0.1-0.1c-0.2,1.1-0.3,2-0.3,2.9c0,0.9-0.1,1.8-0.3,2.7c-0.2,0.6-0.3,1.2-0.3,1.8
			c0,0.6-0.1,1.2-0.1,1.8c0,2.4,0,4.7-0.1,7c-0.1,2.3-0.1,4.6-0.1,7c0,1.1,0.2,1.9,0.6,2.5s1,0.9,1.9,0.9c1.3,0,2.2-0.4,2.8-1.2
			c0.6-0.8,0.9-1.8,1-2.9c0.1-1.5,0.1-3,0.1-4.5c0-1.5,0-3.1,0-4.5c0-1.5,0-2.9,0.1-4.3s0.2-2.7,0.4-3.9c0-0.2,0.1-0.4,0.1-0.6
			c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.2-1.8c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.9,0-1.6,0.1-2
			c0-0.5,0.1-0.8,0.3-1c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.7,0,1.3,0c0.4,0,0.8,0.1,1.2,0.2v-0.1c0.1,0,0.3,0.1,0.5,0.1h0.9
			c0,0,0.2,0,0.5-0.1c1.3-0.1,2.1-0.1,2.4,0.3c0.4,0.3,0.5,1,0.4,2c-0.1,0.7-0.1,1.4,0,2.2s0.1,1.5,0.1,2.2c0,1.1,0,2.2-0.1,3.3
			c-0.1,1.1-0.1,2.2-0.1,3.4c0,1.1,0.1,2.2,0.2,3.4c0.1,1.1,0.2,2.3,0.2,3.5c0,1.1-0.2,2.3-0.5,3.5c-0.3,1.2-0.5,2.6-0.5,4
			c-0.2,0.9-0.4,1.9-0.5,3.1c-0.2,1.2-0.4,2.3-0.8,3.4c-0.3,1.1-0.8,2.1-1.4,2.9c-0.6,0.8-1.4,1.3-2.4,1.6l-1.6,0.4
			c-0.6,0.2-1.2,0.3-1.6,0.4c-0.4,0.1-0.8,0.2-1.1,0.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-1,0c-1.4,0-2.7-0.1-3.9-0.3
			c-1.3-0.2-2.4-0.7-3.3-1.3c-1-0.7-1.7-1.6-2.3-2.8c-0.6-1.2-0.9-2.8-0.9-4.9c0-0.7-0.1-1.4-0.2-2c-0.1-0.6-0.2-1.2-0.2-1.8v-0.2
			c0.1-3,0.1-6,0.2-9c0.1-3,0.1-5.9,0.2-9c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.1-0.8-0.1-1.4l0.1-1.8c0-0.3,0.1-0.6,0.1-0.8
			c0-0.3,0-0.6,0-0.9l-0.1-1.3V473.9z"
        />
        <path
          fill="#444444"
          d="M593,481.5c-0.3-0.1-0.7-0.1-1-0.2c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.3,0.2
			c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.3-0.6,0.6-0.9,0.9c-0.2,0.4-0.3,0.8-0.4,1.2v1.2c0,0.9,0.3,1.8,0.8,2.5c0.5,0.7,1.3,1.5,2.4,2.4
			l1.7,1.5l2.8,2.3c1.3,1.3,2.2,2.7,2.9,4.2c0.7,1.5,1,3.2,1,5.1c0,0.4,0,1-0.1,1.6c-0.1,0.6-0.2,1.3-0.4,1.9
			c-0.2,0.6-0.4,1.2-0.6,1.8c-0.3,0.6-0.5,1-0.9,1.3c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-0.9,0.6l-0.7,0.4
			c-0.4,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-0.8,0.4c-0.6,0.3-1.3,0.5-1.9,0.7c-0.7,0.2-1.4,0.3-2.3,0.3c-0.5,0-1-0.1-1.6-0.2
			c-0.5-0.1-1-0.2-1.6-0.3c-0.6-0.1-1.2-0.4-1.9-0.8c0,0-0.1,0-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.2-0.9-0.4-1.3-0.6
			c-0.4-0.3-0.6-0.6-0.6-1c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.4-1c0.2-0.4,0.3-0.9,0.4-1.3c0.1-0.5,0.2-0.9,0.3-1.3
			c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.5-0.3,0.9-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0.1l2.3,0.7l1.3,0.4
			c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,1-0.2c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.1-0.9
			c-0.3-1.1-0.6-2-1-2.5c-0.4-0.6-1-1-1.5-1.3l-1.9-1.4c-0.6-0.4-1.1-0.8-1.5-1.1c-0.4-0.3-0.9-0.7-1.4-1.1
			c-1.4-1.2-2.4-2.7-3.1-4.4c-0.7-1.7-1.1-3.5-1.1-5.2c0-1.2,0.1-2.4,0.4-3.5c0.3-1.1,0.6-2.3,1.1-3.6c0.4-1,1-1.8,1.8-2.6
			c0.8-0.8,1.6-1.4,2.6-2c0.9-0.5,2-0.9,3-1.2c1.1-0.3,2.1-0.4,3.1-0.4c0.5,0,1.2,0,2.1,0.1c0.9,0.1,1.7,0.2,2.5,0.3
			c0.8,0.2,1.5,0.4,2,0.7c0.5,0.3,0.7,0.7,0.6,1.2c-0.3,1-0.6,2.1-0.8,3.2s-0.7,2.4-1.4,3.6L593,481.5z"
        />
        <path
          fill="#444444"
          d="M607.4,512.7c-0.7-0.1-1.5-0.1-2.3-0.2c-0.9-0.1-1.7-0.1-2.3-0.2l-0.1-2.6c-0.1-0.6-0.1-1.3-0.2-2.1
			c-0.1-0.7-0.1-1.4-0.1-2c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.1-1.1,0.1-1.7c0-1.6,0-3.3-0.1-4.9c-0.1-1.6-0.1-3.3-0.1-5
			c0-1.4,0-2.9,0.1-4.3c0-1.4,0.1-2.9,0.2-4.3v0.1l0.1-4.8c0-0.2,0-0.5,0-0.9c0-0.4,0-0.8-0.1-1.3c0-0.4-0.1-0.8-0.1-1.2
			c0-0.4,0-0.7,0-0.8c0-0.5,0.1-0.9,0.2-1.2c0.2-0.3,0.4-0.4,0.7-0.5c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1,0,1.7,0c1,0,1.7,0,2.3,0
			c0.6,0,1,0.2,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0.1,0.6,0.1,1.4,0.1,2.5c0,1.1,0,2.5-0.1,4.2c0,1.7,0,3.9,0.1,6.4l0.2,4.1
			c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.1,0.7-0.1,0.9c-0.2,1.1-0.3,2.3-0.3,3.4c0,1.1,0,2.2,0,3.3v2.1c0,0.6,0,1.3-0.1,2.1
			c0,0.8-0.1,1.6-0.1,2.4c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5-0.1,1.1-0.2,1.6c-0.2,0.6-0.3,1.1-0.6,1.6L607.4,512.7
			z"
        />
        <path
          fill="#444444"
          d="M624.2,500.8c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.8,0.1,1.4,0.3,2.1v-0.1c0.1,0.2,0.2,0.4,0.2,0.7
			c0,0.3,0,0.5,0,0.8l-0.2,5.7c0,0.5,0,0.9,0,1.1c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.5,0.1-0.9,0.2l-1.3-0.1
			c-0.7,0-1.5,0.1-2.2,0.1c-0.8,0-1.5,0-2.2,0c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.4-0.2-0.5-0.4c0-0.9,0.1-1.8,0.2-2.7
			c0.1-0.9,0.2-1.8,0.2-2.6c0-0.9,0-1.9-0.1-3c-0.1-1.1-0.1-2.1-0.1-3.1c0-1.5,0-3,0.1-4.5c0.1-1.5,0.1-3,0.1-4.6c0-0.9,0-1.7,0-2.6
			c0-0.8,0-1.7-0.1-2.5v0.1c-0.1-1.5-0.2-3.3-0.2-5.3c0-2,0.1-3.9,0.1-5.7c0-0.5,0.1-1,0.2-1.5c0.1-0.5,0.4-0.8,0.8-0.9l1.4-0.2
			c1-0.1,2.1-0.1,3.2-0.2c1.2-0.1,2.3-0.1,3.5-0.2c1.2-0.1,2.3-0.1,3.5-0.1c1.1,0,2.2-0.1,3.2-0.1c0.2,0,0.4,0,0.5,0.1
			c0.1,0.1,0.2,0.1,0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.2,0.7v-0.1c0.2,0.5,0.3,1,0.3,1.5l-0.2,3.3
			c-0.1,0.3-0.3,0.5-0.7,0.7s-0.9,0.2-1.4,0.2c-0.6,0-1.1,0-1.7-0.1c-0.6,0-1.2-0.1-1.7-0.1l-3.4,0.1c-0.4,0.1-0.6,0.2-0.6,0.2
			c0,0-0.1,0.2-0.1,0.4l0.3,10c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0,1.4-0.1c0.5-0.1,0.9-0.1,1.4-0.1l3-0.1
			c0.4,0,0.7,0.2,0.9,0.6c0.2,0.4,0.3,0.9,0.3,1.6c0.1,0.6,0.1,1.3,0.1,2.1c0,0.7,0,1.4,0,2c0,0.5,0,0.9-0.1,1
			c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.8,0.1c-0.3,0-0.8-0.1-1.4-0.1c-0.9,0-1.8,0.1-2.7,0.2s-1.7,0.3-2.7,0.4V500.8z"
        />
      </g>
    </g>
  ),
})
