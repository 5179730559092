'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Climat',
  viewBox: '0 0 21 20',
  path: (
    <>
      <path
        d="M10.2 4.9C7.5 4.9 5.2 7.1 5.2 9.9C5.2 12.6 7.4 14.9 10.2 14.9C13 14.9 15.2 12.7 15.2 9.9C15.2 7.1 13 4.9 10.2 4.9ZM10.2 13.8C8 13.8 6.2 12 6.2 9.8C6.2 7.6 8 5.8 10.2 5.8C12.4 5.8 14.2 7.6 14.2 9.8C14.2 12 12.4 13.8 10.2 13.8Z"
        fill="currentColor"
      />
      <path
        d="M10.2 4.2C10.5 4.2 10.7 4 10.7 3.7V0.5C10.7 0.2 10.5 0 10.2 0C9.9 0 9.7 0.2 9.7 0.5V3.7C9.7 4 10 4.2 10.2 4.2Z"
        fill="currentColor"
      />
      <path
        d="M10.2 15.7C9.9 15.7 9.7 15.9 9.7 16.2V19.4C9.7 19.7 9.9 19.9 10.2 19.9C10.5 19.9 10.7 19.7 10.7 19.4V16.2C10.7 16 10.5 15.7 10.2 15.7Z"
        fill="currentColor"
      />
      <path
        d="M5 6.1C5.1 6.2 5.2 6.2 5.4 6.2C5.6 6.2 5.7 6.2 5.8 6.1C6 5.9 6 5.6 5.8 5.4L3.4 3.1C3.2 2.9 2.9 2.9 2.7 3.1C2.5 3.3 2.5 3.6 2.7 3.8L5 6.1Z"
        fill="currentColor"
      />
      <path
        d="M14.8 14.5C14.6 14.3 14.3 14.3 14.1 14.5C13.9 14.7 13.9 15 14.1 15.2L16.4 17.5C16.5 17.6 16.6 17.6 16.8 17.6C17 17.6 17.1 17.6 17.2 17.5C17.4 17.3 17.4 17 17.2 16.8L14.8 14.5Z"
        fill="currentColor"
      />
      <path
        d="M5 14.5L2.7 16.8C2.5 17 2.5 17.3 2.7 17.5C2.8 17.6 2.9 17.6 3.1 17.6C3.3 17.6 3.4 17.6 3.5 17.5L5.8 15.2C6 15 6 14.7 5.8 14.5C5.6 14.3 5.1 14.3 5 14.5Z"
        fill="currentColor"
      />
      <path
        d="M15.6 6.6C15.7 6.6 15.9 6.6 16 6.5L18.3 4.2C18.5 4 18.5 3.7 18.3 3.5C18.1 3.3 17.8 3.3 17.6 3.5L15.3 5.8C15 6 15 6.3 15.2 6.5C15.3 6.6 15.5 6.6 15.6 6.6Z"
        fill="currentColor"
      />
      <path
        d="M4.2 10.2C4.2 10 4 9.7 3.7 9.7H0.5C0.2 9.7 0 10 0 10.2C0 10.4 0.2 10.7 0.5 10.7H3.7C4 10.7 4.2 10.5 4.2 10.2Z"
        fill="currentColor"
      />
      <path
        d="M19.6 9.7H16.4C16.1 9.7 15.9 9.9 15.9 10.2C15.9 10.5 16.1 10.7 16.4 10.7H19.6C19.9 10.7 20.1 10.5 20.1 10.2C20.1 9.9 19.9 9.7 19.6 9.7Z"
        fill="currentColor"
      />
    </>
  ),
})
