'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Euro',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M50 74.1C49.4 74.1 49 73.7 49 73.1V45.9C49 45.3 49.4 44.9 50 44.9C50.6 44.9 51 45.3 51 45.9V73.1C51 73.7 50.6 74.1 50 74.1Z"
        fill="#FF6C4A"
      />
      <path
        d="M78.2 87.9H76.4C75.7 72 70.6 53.9 58.5 49.7C57.7 49.4 57.1 48.8 56.9 48C56.7 47.2 56.8 46.4 57.3 45.7C57.6 45.3 58.1 44.9 58.6 44.8C65.4 42.5 70.3 37.8 73.2 30.7C75.8 24.3 76.4 17.1 76.5 12.2H78.3C78.9 12.2 79.3 11.8 79.3 11.2C79.3 10.6 78.9 10.2 78.3 10.2H21.8C21.2 10.2 20.8 10.6 20.8 11.2C20.8 11.8 21.2 12.2 21.8 12.2H23.6C23.8 17.1 24.3 24.3 26.9 30.7C29.8 37.8 34.7 42.5 41.5 44.8C42.3 45.1 42.9 45.7 43.1 46.5C43.3 47.3 43.2 48.1 42.7 48.8C42.4 49.2 42 49.6 41.5 49.7C29.3 53.9 24.3 72 23.5 88H21.7C21.1 88 20.7 88.4 20.7 89C20.7 89.6 21.1 90 21.7 90H78C78.6 90 79 89.6 79 89C79 88.4 78.7 87.9 78.2 87.9ZM42.3 51.5C43.2 51.2 44 50.6 44.5 49.8C45.3 48.6 45.6 47.1 45.1 45.7C44.7 44.3 43.6 43.2 42.2 42.8C36 40.7 31.5 36.3 28.8 29.8C26.3 23.7 25.8 16.8 25.6 12.1H74.3C74.1 16.8 73.6 23.7 71.1 29.8C68.4 36.3 63.9 40.7 57.7 42.8C56.8 43.1 56 43.7 55.4 44.5C54.6 45.7 54.3 47.2 54.7 48.6C55.1 50 56.2 51.1 57.6 51.5C67 54.8 73.3 68.7 74.2 87.9H25.7C26.5 68.7 32.8 54.8 42.3 51.5Z"
        fill="#1D1D1B"
      />
    </>
  ),
})
