'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'TadaBottomLeft',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4966 12.6892C23.3659 12.3146 21.5181 11.9334 19.3808 11.2761C21.2221 11.3745 22.6425 11.6242 24.2076 12.012C28.1796 12.4851 32.4144 12.1032 36.6559 12.0041C37.3661 12.129 38.1946 11.2609 38.78 12.0959C39.3653 12.931 38.9511 13.3651 38.6749 13.6545C38.9643 13.9306 39.3983 14.3449 38.8394 14.6408C38.1424 15.0815 37.2875 14.8186 36.4392 14.8384C33.8943 14.8978 31.1981 14.5365 28.5086 14.4578C24.9707 14.399 21.5577 13.63 18 12.7229C18.8285 11.8548 19.2625 12.269 19.8347 12.5386C20.2621 12.6701 20.683 12.5188 21.1104 12.6502C21.676 12.637 22.2481 12.9066 22.8136 12.8934C21.9587 12.6304 20.9657 12.5122 20.1108 12.2492C19.8281 12.2558 19.2493 11.7035 19.6768 11.835C21.6561 11.7887 23.5106 12.4527 25.4966 12.6892Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0909 34.2982C32.1372 35.2272 30.4102 35.987 28.2873 36.6893C29.8451 35.7029 31.1476 35.0836 32.648 34.493C36.1595 32.5775 39.3902 29.8129 42.79 27.2749C43.4412 26.9653 43.6136 25.7777 44.5745 26.1193C45.5354 26.4608 45.4492 27.0546 45.3917 27.4505C45.7876 27.5079 46.3814 27.5941 46.0973 28.1592C45.7844 28.9221 44.9353 29.2031 44.2553 29.7107C42.2154 31.2334 39.8084 32.5008 37.5706 33.9949C34.6528 35.9965 31.4253 37.3469 28 38.6686C28.1723 37.4811 28.7661 37.5672 29.3886 37.4555C29.8132 37.315 30.0686 36.9479 30.4932 36.8074C30.9465 36.469 31.569 36.3573 32.0223 36.0189C31.1731 36.2998 30.2952 36.7787 29.4461 37.0596C29.2194 37.2288 28.4277 37.1139 28.8523 36.9735C30.4389 35.7891 32.3351 35.2559 34.0909 34.2982Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    w: '48px',
    h: '48px',
    color: 'orange',
    position: 'absolute',
    zIndex: 1,
  },
})
