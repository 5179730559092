'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'HealthWellBeing',
  viewBox: '0 0 20 17',
  path: (
    <>
      <path
        d="M11.7 12.5L9.6 14.7C9.2 15.1 9.1 15.1 9.1 15.1C9 15.1 8.7 14.8 8.6 14.7L2.6 8.6C1.2 7.2 0.699996 5.6 1.1 4C1.5 2.6 2.6 1.4 4 1.1C5.6 0.7 7.2 1.2 8.5 2.6L9 3L9.39999 2.6C11 1 12.8 0.6 14.5 1.3C16 2 17 3.6 17 5.3C17 5.6 17.2 5.8 17.5 5.8C17.8 5.8 18 5.6 18 5.3C18.1 3.2 16.8 1.3 14.9 0.4C12.9 -0.4 10.8 0 9 1.6C7.4 0.2 5.6 -0.3 3.8 0.2C2 0.6 0.599996 2 0.199996 3.8C-0.300004 5.7 0.299996 7.7 2 9.3L7.9 15.4C8.2 15.7 8.6 16.1 9.1 16.1C9.6 16.1 9.79999 15.9 10.4 15.3L12.5 13.1C12.7 12.9 12.7 12.6 12.5 12.4C12.2 12.3 11.9 12.3 11.7 12.5Z"
        fill="currentColor"
      />
      <path
        d="M19.8 8.9C19.6 8.7 19.3 8.8 19.1 9L18.5 9.8L17.4 7C17.3 6.8 17.2 6.7 17 6.7C16.8 6.7 16.6 6.7 16.5 6.9L13.5 10.6L13 9C12.9 8.8 12.8 8.7 12.6 8.7C12.4 8.7 12.2 8.8 12.1 8.9L11 10.5L9.3 10.4C9 10.4 8.8 10.6 8.8 10.9C8.8 11.2 9 11.4 9.3 11.4L11.3 11.5C11.5 11.5 11.6 11.4 11.7 11.3L12.4 10.3L12.9 11.8C13 12 13.1 12.1 13.3 12.1H13.4C13.5 12.1 13.7 12 13.8 11.9L16.7 8L17.8 10.8C17.9 11 18 11.1 18.2 11.1C18.4 11.1 18.6 11.1 18.7 10.9L19.9 9.5C20 9.4 20 9 19.8 8.9Z"
        fill="currentColor"
      />
    </>
  ),
})
