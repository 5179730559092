'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Association',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M75.2 41.5C74.1 41.5 73.1 41.7 72.1 41.9C56.3 19 31.3 17.7 31.1 17.7C30.6 17.7 30.1 18.1 30.1 18.7C30.1 19.3 30.5 19.7 31.1 19.7C31.3 19.7 55.1 21 70.2 42.6C65.9 44.5 62.9 48.9 62.9 53.9C62.9 58.1 65 61.9 68.3 64.1C51.6 80.4 32.4 80.4 29.4 80.3C28.9 75.9 25.2 72.5 20.7 72.5C19.9 72.5 19.1 72.6 18.4 72.8C9.4 49.5 19.6 26.1 19.7 25.9C19.9 25.4 19.7 24.8 19.2 24.6C18.7 24.4 18.1 24.6 17.9 25.1C17.8 25.4 7.3 49.5 16.6 73.6C13.8 75.1 12 78 12 81.3C12 86.1 15.9 90 20.7 90C25.2 90 28.9 86.6 29.4 82.3C29.6 82.3 29.9 82.3 30.3 82.3C35.6 82.3 54 81 69.9 65.1C71.5 65.8 73.2 66.3 75.1 66.3C81.9 66.3 87.5 60.8 87.5 53.9C87.5 47.1 82 41.5 75.2 41.5ZM20.7 88C17 88 14 85 14 81.3C14 77.6 17 74.6 20.7 74.6C24.4 74.6 27.4 77.6 27.4 81.3C27.4 85 24.4 88 20.7 88ZM75.2 64.3C69.5 64.3 64.8 59.6 64.8 53.9C64.8 48.2 69.5 43.5 75.2 43.5C80.9 43.5 85.6 48.2 85.6 53.9C85.6 59.6 80.9 64.3 75.2 64.3Z"
        fill="#2A2A2A"
      />
      <path
        d="M23 12C26.7 12 29.7 15 29.7 18.7C29.7 22.4 26.7 25.4 23 25.4C19.3 25.4 16.3 22.4 16.3 18.7C16.3 15 19.3 12 23 12ZM23 10C18.2 10 14.3 13.9 14.3 18.7C14.3 23.5 18.2 27.4 23 27.4C27.8 27.4 31.7 23.5 31.7 18.7C31.7 13.9 27.8 10 23 10Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
