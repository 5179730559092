'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Chart',
  viewBox: '0 0 108 108',
  path: (
    <>
      <circle cx="45" cy="63" r="45" fill="#FFF69A" />
      <path
        d="M34.776 71.064H11.448C11.124 71.064 10.8 71.388 10.8 71.712V96.552C10.8 96.876 11.124 97.2 11.448 97.2H34.776C35.1 97.2 35.424 96.876 35.424 96.552V71.712C35.532 71.388 35.208 71.064 34.776 71.064ZM33.372 95.04H12.96V73.224H33.372V95.04Z"
        fill="#2A2A2A"
      />
      <path
        d="M65.664 58.104H42.336C42.012 58.104 41.688 58.428 41.688 58.752V96.552C41.688 96.876 42.012 97.2 42.336 97.2H65.664C65.988 97.2 66.312 96.876 66.312 96.552V58.752C66.312 58.428 65.988 58.104 65.664 58.104ZM64.152 95.04H43.848V60.264H64.26V95.04H64.152Z"
        fill="#2A2A2A"
      />
      <path
        d="M96.552 10.8H73.224C72.9 10.8 72.576 11.124 72.576 11.448V96.552C72.576 96.876 72.9 97.2 73.224 97.2H96.552C96.876 97.2 97.2 96.876 97.2 96.552V11.448C97.2 11.124 96.876 10.8 96.552 10.8ZM95.04 95.04H74.628V12.96H95.04V95.04Z"
        fill="#2A2A2A"
      />
      <path
        d="M30.996 51.084C19.872 51.084 10.908 42.12 10.908 30.996C10.908 19.872 19.872 10.908 30.996 10.908C42.12 10.908 51.084 19.872 51.084 30.996C51.084 42.012 42.012 51.084 30.996 51.084ZM30.996 13.068C21.06 13.068 13.068 21.06 13.068 30.996C13.068 40.932 21.06 48.924 30.996 48.924C40.932 48.924 48.924 40.932 48.924 30.996C48.924 21.06 40.824 13.068 30.996 13.068Z"
        fill="#2A2A2A"
      />
      <path
        d="M35.424 23.6519C34.56 23.3279 33.588 23.1119 32.616 23.1119C29.592 23.1119 26.892 25.0559 25.704 27.7559H24.3C23.652 27.7559 23.22 28.1879 23.22 28.8359C23.22 29.4839 23.652 29.9159 24.3 29.9159H25.056C25.056 30.2399 24.948 30.6719 24.948 30.9959C24.948 31.3199 24.948 31.7519 25.056 32.0759H24.3C23.652 32.0759 23.22 32.5079 23.22 33.1559C23.22 33.8039 23.652 34.2359 24.3 34.2359H25.704C26.892 36.9359 29.484 38.8799 32.616 38.8799C33.372 38.8799 34.128 38.7719 34.884 38.5559C35.424 38.3399 35.748 37.7999 35.532 37.1519C35.316 36.6119 34.776 36.2879 34.128 36.5039C33.588 36.7199 33.048 36.7199 32.508 36.7199C30.672 36.7199 29.052 35.7479 28.08 34.2359H30.348C30.996 34.2359 31.428 33.8039 31.428 33.1559C31.428 32.5079 30.996 32.0759 30.348 32.0759H27.216C27.108 31.7519 27.108 31.3199 27.108 30.9959C27.108 30.6719 27.108 30.2399 27.216 29.9159H30.348C30.996 29.9159 31.428 29.4839 31.428 28.8359C31.428 28.1879 30.996 27.7559 30.348 27.7559H28.08C29.052 26.2439 30.672 25.2719 32.508 25.2719C33.156 25.2719 33.804 25.3799 34.452 25.7039C34.992 25.9199 35.64 25.7039 35.856 25.1639C36.288 24.5159 35.964 23.8679 35.424 23.6519Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
