'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LaureatAmarenco',
  viewBox: '0 0 74 70',
  path: (
    <>
      <path
        opacity="0.5"
        d="M64.1902 26.998C66.7016 27.7109 67.2706 29.8215 65.455 31.6891L29.3906 68.7777C27.5756 70.6441 25.5367 70.119 24.8605 67.6107L10.972 16.0968C10.2957 13.5885 11.797 12.1195 14.3075 12.8326L64.1902 26.998Z"
        fill="#F28C16"
      />
      <path
        d="M48.4605 55.53L50.4183 57.1351C51.9812 58.29 53.8764 58.9137 55.8235 58.9137C57.7706 58.9137 59.666 58.29 61.2289 57.1351L63.1876 55.53L61.2289 53.9248C59.6656 52.7707 57.7703 52.1477 55.8235 52.1477C53.8766 52.1477 51.9815 52.7707 50.4183 53.9248L48.4605 55.53ZM55.8225 60.7561C53.3038 60.7914 50.8507 59.9572 48.881 58.3956L45.3843 55.53L48.881 52.6625C50.868 51.1316 53.3104 50.3008 55.8239 50.3008C58.3375 50.3008 60.7798 51.1316 62.7669 52.6625L66.2638 55.53L62.7669 58.3956C60.7962 59.9574 58.3422 60.7916 55.8225 60.7561Z"
        fill="#354774"
      />
      <path
        d="M65.3704 51.9863L64.4532 50.3708C65.4291 50.0391 66.3152 49.4885 67.0429 48.7617C67.7706 48.0349 68.3204 47.1513 68.6497 46.1794L69.306 43.9738L66.6171 43.4308C64.662 43.0293 62.6281 43.2858 60.8357 44.1598C60.0209 44.5323 59.2925 45.0686 58.6964 45.7348C58.1003 46.4011 57.6494 47.1829 57.3721 48.031L56.4549 51.1035L54.3425 50.6805L55.2604 47.6052C55.6176 46.517 56.197 45.5141 56.9623 44.6591C57.7276 43.8041 58.6623 43.1156 59.7078 42.6368C62.0109 41.5123 64.6253 41.184 67.1372 41.7038L71.9358 42.6698L70.7596 46.6091C70.3374 47.8576 69.6316 48.9925 68.697 49.9259C67.7624 50.8593 66.6241 51.5659 65.3704 51.991"
        fill="#354774"
      />
      <path
        d="M60.1332 42.9437L59.4174 39.8335C59.1803 38.7781 59.1959 37.6822 59.4631 36.634C59.7302 35.5858 60.2414 34.6147 60.9554 33.7991C62.5687 31.9495 64.8061 30.7501 67.2465 30.4263L72.1051 29.6709L73.0202 33.6556C73.2836 34.8684 73.2205 36.1289 72.8371 37.3096C72.4537 38.4904 71.7634 39.5496 70.8364 40.3802L69.1853 39.2235C69.9066 38.5764 70.4435 37.7515 70.7416 36.832C71.0397 35.9126 71.0884 34.9311 70.883 33.9869L70.3721 31.7539L67.6501 32.174C65.75 32.4266 64.0085 33.3616 62.7537 34.8027C62.1981 35.438 61.8001 36.194 61.5917 37.01C61.3833 37.826 61.3702 38.6792 61.5537 39.5011L62.2723 42.6085L60.1332 42.9437Z"
        fill="#354774"
      />
      <path
        d="M61.7158 34.1143L59.1419 31.7585C55.5273 28.4537 55.8682 23.3456 59.9015 20.3836L63.5825 17.6755L66.8818 20.6971C67.9217 21.5802 68.6836 22.7428 69.076 24.0455C69.4684 25.3482 69.4746 26.7358 69.0941 28.042L66.9826 27.6087C67.2789 26.5917 67.2744 25.5115 66.9699 24.497C66.6653 23.4825 66.0734 22.5766 65.265 21.8877L63.4163 20.1975L61.3521 21.7149C60.6263 22.1848 60.0206 22.8162 59.5828 23.5593C59.145 24.3024 58.8874 25.1368 58.8301 25.9962C58.7729 26.8555 58.9177 27.7163 59.2532 28.5103C59.5886 29.3044 60.1055 30.0097 60.7627 30.5706L63.3366 32.9236L61.7158 34.1143Z"
        fill="#354774"
      />
      <path
        d="M57.7501 25.066L54.0918 24.0613C48.959 22.6516 46.1809 18.0778 47.8976 13.8638L49.4699 10.02L54.1555 11.3069C57.5736 12.2454 60.0848 14.639 60.7067 17.5528L58.5658 17.8616C58.2417 16.6653 57.5948 15.5798 56.6954 14.7224C55.7959 13.865 54.6781 13.2685 53.4624 12.9971L50.837 12.2785L49.955 14.4323C48.6192 17.7115 50.7831 21.2731 54.7774 22.3693L58.4385 23.373L57.7501 25.066Z"
        fill="#354774"
      />
      <path
        d="M31.8032 64.7218L29.739 64.1637C32.3747 57.5835 35.8221 53.8312 39.9884 53.0097C41.3335 52.7831 42.711 52.8338 44.0356 53.1586C45.3602 53.4835 46.6037 54.0756 47.6886 54.8981L46.158 56.1653C45.3544 55.5636 44.4376 55.1283 43.4619 54.885C42.4862 54.6417 41.4715 54.5955 40.4775 54.749C37.1544 55.4166 34.1512 58.8639 31.8032 64.7218Z"
        fill="#354774"
      />
      <path
        d="M10.0046 55.53L11.9633 57.1351C13.526 58.29 15.4211 58.9136 17.368 58.9136C19.3149 58.9136 21.2102 58.29 22.7729 57.1351L24.7317 55.53L22.7729 53.9248C21.2099 52.7708 19.3147 52.1478 17.368 52.1478C15.4213 52.1478 13.5264 52.7708 11.9633 53.9248L10.0046 55.53ZM17.3666 60.7561C14.8479 60.7911 12.3949 59.9569 10.4251 58.3956L6.92834 55.53L10.4251 52.6625C12.4121 51.1316 14.8544 50.3008 17.368 50.3008C19.8816 50.3008 22.3239 51.1316 24.3109 52.6625L27.8079 55.53L24.3109 58.3956C22.3405 59.9578 19.8863 60.7921 17.3666 60.7561Z"
        fill="#354774"
      />
      <path
        d="M7.8226 51.9862C6.56933 51.5606 5.43132 50.8538 4.49694 49.9205C3.56255 48.9872 2.85683 47.8524 2.43436 46.6042L1.25519 42.665L6.0565 41.699C8.56746 41.1791 11.1811 41.5074 13.4832 42.6319C14.5286 43.1108 15.4634 43.7993 16.2287 44.6543C16.994 45.5092 17.5734 46.5122 17.9306 47.6003L18.8515 50.6757L16.7419 51.0987L15.8209 48.0262C15.5429 47.1786 15.0918 46.3972 14.4959 45.731C13.9001 45.0649 13.1724 44.5283 12.3583 44.155C10.5653 43.2794 8.52967 43.0238 6.57411 43.4288L3.88521 43.9689L4.54979 46.1746C4.87907 47.1461 5.42854 48.0294 6.15571 48.7561C6.88288 49.4829 7.76826 50.0337 8.74355 50.366L7.8226 51.9862Z"
        fill="#354774"
      />
      <path
        d="M13.0588 42.9435L10.9236 42.6122L11.6394 39.5047C11.8229 38.6828 11.8099 37.8296 11.6014 37.0136C11.393 36.1976 10.995 35.4416 10.4394 34.8064C9.18454 33.3653 7.44282 32.4303 5.54281 32.1777L2.82262 31.7575L2.3101 33.9906C2.10495 34.935 2.15402 35.9164 2.45243 36.8359C2.75084 37.7554 3.28784 38.5803 4.00944 39.2272L2.35646 40.3838C1.43019 39.5528 0.740564 38.4935 0.357174 37.3128C-0.0262162 36.1322 -0.0896299 34.872 0.172891 33.6591L1.09083 29.6746L5.94824 30.43C8.38763 30.7528 10.6239 31.9526 12.2347 33.8027C12.9496 34.6178 13.4617 35.5886 13.7294 36.637C13.9971 37.6853 14.013 38.7816 13.7757 39.8372L13.0588 42.9435Z"
        fill="#354774"
      />
      <path
        d="M11.4741 34.1144L9.86008 32.9208L12.434 30.566C13.0912 30.0053 13.6081 29.3 13.9435 28.5061C14.2789 27.7123 14.4238 26.8517 14.3665 25.9924C14.3093 25.1332 14.0517 24.299 13.6139 23.5561C13.1761 22.8131 12.5703 22.1818 11.8445 21.7121L9.78312 20.1949L7.9345 21.8849C7.12516 22.5732 6.53263 23.479 6.22751 24.4936C5.92239 25.5082 5.9176 26.5888 6.21406 27.606L4.10258 28.0393C3.72206 26.7331 3.72829 25.3454 4.12066 24.0427C4.51303 22.74 5.27498 21.5774 6.3149 20.6943L9.61135 17.6729L13.2951 20.378C17.3247 23.3428 17.6627 28.4472 14.0548 31.7538L11.4741 34.1144Z"
        fill="#354774"
      />
      <path
        d="M15.445 25.066L14.7519 23.373L18.4081 22.3693C20.2314 21.9205 21.8123 20.7934 22.8251 19.2203C23.2708 18.5114 23.5399 17.7072 23.6102 16.8741C23.6806 16.041 23.5501 15.2032 23.2296 14.4303L22.3532 12.2785L19.7261 12.9971C18.5103 13.2683 17.3921 13.8648 16.4925 14.7222C15.5929 15.5795 14.9458 16.6652 14.6216 17.8616L12.4807 17.5528C13.1064 14.639 15.6138 12.2454 19.0319 11.3069L23.7233 10.02L25.2926 13.8638C25.704 14.8566 25.8711 15.9327 25.7801 17.0026C25.6891 18.0725 25.3426 19.1054 24.7695 20.0154C23.469 22.0365 21.4387 23.4846 19.0966 24.0613L15.445 25.066Z"
        fill="#354774"
      />
      <path
        d="M41.3889 64.7219C39.0409 58.8678 36.0416 55.4167 32.7137 54.7491C31.7197 54.5979 30.7054 54.6453 29.73 54.8885C28.7546 55.1317 27.8377 55.5658 27.033 56.1654L25.5026 54.8983C26.5874 54.0751 27.8307 53.4827 29.1556 53.1578C30.4804 52.833 31.8583 52.7826 33.2035 53.0099C37.3688 53.8313 40.8173 57.5836 43.4578 64.1638L41.3889 64.7219Z"
        fill="#354774"
      />
      <path
        d="M37.684 39.7474H35.5097V33.1379L34.264 33.5505L33.4485 31.8982L36.5505 30.937H37.6377L37.684 31.8292V39.7474Z"
        fill="#354774"
      />
      <path d="M39.5364 38.8552H33.8577V40.6398H39.5364V38.8552Z" fill="#354774" />
      <path
        d="M36.5969 27.1187C30.77 27.1187 26.0295 31.0088 26.0295 35.7884C26.0295 40.568 30.77 44.4592 36.5969 44.4592C42.4237 44.4592 47.1636 40.569 47.1636 35.7884C47.1636 31.0079 42.4237 27.1187 36.5969 27.1187ZM36.5969 46.2437C29.5709 46.2437 23.8533 41.5528 23.8533 35.7884C23.8533 30.024 29.5699 25.3342 36.5969 25.3342C43.6238 25.3342 49.3397 30.025 49.3397 35.7884C49.3397 41.5519 43.6229 46.2437 36.5969 46.2437Z"
        fill="#354774"
      />
    </>
  ),
})
