'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Mii',
  viewBox: '0 0 88 88',
  path: (
    <>
      <path
        d="M63.3459 60.396C63.3194 61.9888 63.6386 63.5677 64.2806 65.0194C64.615 65.7445 65.0323 66.4266 65.5236 67.0516C66.0506 67.712 66.7665 68.2201 67.2339 68.9212C67.8504 69.856 67.6316 71.3599 66.3091 71.431C64.6087 71.5326 62.9084 71.492 61.208 71.492C57.8967 71.492 54.9136 71.492 51.5924 71.492C50.4986 71.492 49.8622 70.8721 49.9715 69.917C50.0384 69.5628 50.1962 69.2331 50.4289 68.9619C50.6762 68.6899 50.9531 68.4479 51.2543 68.2404C52.1107 67.6254 52.8007 66.7986 53.2597 65.8373C53.7188 64.8761 53.932 63.8116 53.8794 62.7433C53.78 59.1666 53.8794 55.2139 53.8794 51.6372C53.9085 49.8807 53.5374 48.1414 52.7956 46.5566C51.8609 44.8654 50.4462 43.5035 48.7383 42.6509C47.0305 41.7982 45.1097 41.4949 43.2297 41.7809C41.2596 42.0358 39.4444 43.0047 38.1141 44.5113C36.7839 46.018 36.0268 47.9624 35.9807 49.9911C35.7718 54.4416 35.8514 59.2783 35.8613 63.7391C35.891 64.9434 36.3549 66.0941 37.164 66.9703C37.9098 67.834 39.2124 68.4335 39.5604 69.5614C39.6508 69.8393 39.6813 70.1339 39.6499 70.4251C39.5946 70.7254 39.4336 70.9946 39.1972 71.1819C38.9609 71.3691 38.6656 71.4613 38.3672 71.4412H35.4338C31.4563 71.4412 27.8069 71.4412 23.8195 71.4412C23.0637 71.4412 22.4074 71.2989 22.1091 70.5165C21.9802 70.1818 21.9651 69.8126 22.066 69.4681C22.167 69.1235 22.3783 68.8236 22.666 68.6164C24.4591 67.0947 25.6846 64.9884 26.1363 62.6518C26.2639 61.8114 26.327 60.962 26.3253 60.1115C26.3253 51.7998 26.3253 43.4914 26.3253 35.1864C26.3253 32.514 27.4489 31.7925 29.7957 32.9611C32.6694 34.3938 35.5133 35.8875 38.4169 37.2491C40.4336 38.2399 42.6426 38.7544 44.8803 38.7544C47.118 38.7544 49.3271 38.2399 51.3438 37.2491C54.3269 35.8367 57.2404 34.3023 60.1937 32.8595C62.1825 31.8434 63.3658 32.6664 63.3658 34.8917L63.3459 60.396Z"
        fill="currentColor"
      />
      <path
        d="M58.3243 16.5001C61.4665 16.5001 63.9922 18.2275 64.7778 20.8897C66.0009 25.0253 63.3558 28.6934 58.8712 29.0694C55.3809 29.364 52.4177 27.3217 51.8111 24.2124C50.9858 19.9041 53.8695 16.4798 58.3243 16.5001Z"
        fill="currentColor"
      />
      <path
        d="M31.0983 16.5001C34.2505 16.5001 36.7762 18.2275 37.5617 20.8897C38.7848 25.0253 36.1398 28.6934 31.5955 29.0694C28.1152 29.364 25.142 27.3217 24.5453 24.2124C23.7598 19.9041 26.6435 16.4798 31.0983 16.5001Z"
        fill="currentColor"
      />
    </>
  ),
})
