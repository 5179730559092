'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoPADV',
  viewBox: '0 0 200 84',
  path: (
    <>
      <g transform="matrix(1,0,0,1,-1217,-3.55271e-15)">
        <g id="LOGO-header" transform="matrix(0.873362,0,0,0.641221,1920.93,-16.6718)">
          <rect x="-806" y="26" width="229" height="131" fill="none"></rect>
          <clipPath id="_clip1">
            <rect x="-806" y="26" width="229" height="131"></rect>
          </clipPath>
          <g clip-path="url(#_clip1)">
            <g transform="matrix(1.17003,0,0,1.58723,-806.025,26)">
              <g>
                <path
                  d="M123.614,50.648C123.561,51.837 123.516,53 123.457,54.159C123.407,55.116 123.315,56.071 123.279,57.031C123.193,59.481 123.098,61.93 123.06,64.38C123.009,67.671 122.935,70.965 123.015,74.253C123.072,76.575 123.377,78.891 123.546,81.21C123.558,81.371 123.401,81.65 123.264,81.698C120.567,82.619 117.902,82.584 115.308,81.311C114.647,80.987 114.519,80.672 114.668,79.932C114.798,79.278 114.914,78.621 115.047,77.97C115.234,77.066 114.985,76.549 114.229,76.29C113.408,76.01 112.649,76.314 112.287,77.066C112.133,77.39 111.997,77.723 111.843,78.047C110.589,80.69 108.52,82.162 105.594,82.355C99.92,82.726 95.269,80.785 91.91,76.094C90.333,73.894 89.527,71.379 89.231,68.691C88.89,65.611 89.124,62.59 90.324,59.719C92.512,54.483 96.449,51.373 102.016,50.422C104.358,50.021 106.688,50.137 108.84,51.347C110.506,52.283 111.739,53.609 112.486,55.384C112.848,56.246 113.488,56.588 114.208,56.353C114.78,56.166 115.068,55.672 114.973,55.075C114.848,54.281 114.727,53.487 114.614,52.69C114.522,52.03 114.706,51.665 115.299,51.338C116.44,50.707 117.688,50.398 118.969,50.309C120.504,50.202 122.043,50.243 123.611,50.648M113.378,66.28C113.378,64.755 113.304,63.224 113.405,61.704C113.467,60.771 113.097,60.26 112.329,59.906C112.267,59.876 112.21,59.837 112.148,59.808C109.827,58.711 107.39,58.208 104.835,58.589C102.315,58.963 100.234,60.108 98.98,62.436C98.18,63.922 97.967,65.534 98.109,67.21C98.358,70.166 99.724,72.324 102.511,73.469C104.515,74.292 106.599,74.337 108.703,74.004C110.206,73.766 111.597,73.228 112.842,72.327C113.218,72.056 113.399,71.762 113.39,71.269C113.357,69.607 113.378,67.942 113.378,66.28"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M129.412,81.594C129.83,78.817 130.002,76.085 130.008,73.341C130.011,71.679 130.091,70.014 130.058,68.355C129.987,64.844 129.886,61.333 129.756,57.822C129.676,55.648 129.516,53.478 129.388,51.308C129.365,50.909 129.35,50.606 129.907,50.523C132.498,50.13 135.044,50.083 137.484,51.233C138.249,51.593 138.391,51.899 138.255,52.741C138.21,53.011 138.178,53.282 138.127,53.552C138.044,54.007 137.955,54.459 137.866,54.911C137.774,55.378 137.831,55.809 138.225,56.121C138.637,56.448 139.132,56.537 139.565,56.255C139.965,55.993 140.345,55.639 140.611,55.244C142.248,52.824 144.429,51.118 147.293,50.573C151.784,49.72 155.943,50.472 159.328,53.826C161.08,55.559 161.978,57.756 162.343,60.161C162.506,61.223 162.556,62.308 162.562,63.381C162.592,67.635 162.539,71.89 162.619,76.141C162.651,77.842 162.939,79.539 163.132,81.237C163.158,81.472 163.188,81.632 162.918,81.713C160.662,82.367 158.395,82.765 156.076,82.043C155.881,81.98 155.688,81.912 155.498,81.831C154.07,81.234 153.812,80.847 153.812,79.319C153.812,74.824 153.838,70.332 153.791,65.837C153.779,64.719 153.643,63.574 153.367,62.492C152.724,59.959 151.114,58.838 148.535,58.553C145.354,58.202 142.325,58.898 139.343,59.894C138.676,60.117 138.438,60.479 138.438,61.211C138.438,67.421 138.43,73.629 138.447,79.84C138.447,80.654 138.148,81.139 137.404,81.463C134.757,82.61 132.11,82.578 129.403,81.591"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M54.871,50.274C57.213,50.294 59.062,50.595 60.773,51.543C61.588,51.995 62.133,52.664 62.545,53.478C63.601,55.571 64.188,57.828 64.757,60.081C65.703,63.81 66.595,67.55 67.534,71.278C67.822,72.413 68.634,73.035 69.698,73.035C70.769,73.035 71.593,72.428 71.88,71.296C72.731,67.933 73.505,64.55 74.388,61.193C74.966,58.996 75.672,56.835 76.327,54.659C77.109,52.066 79.075,50.951 81.553,50.505C83.213,50.208 84.876,50.226 86.548,50.467C87.004,50.532 87.087,50.725 87.034,51.103C86.826,52.592 86.672,54.094 86.397,55.568C85.327,61.3 83.311,66.67 80.157,71.587C77.791,75.279 74.939,78.538 71.474,81.24C70.208,82.227 69.2,82.245 67.947,81.255C62.744,77.155 58.923,71.982 56.219,65.959C54.358,61.812 53.178,57.468 52.6,52.958C52.514,52.28 52.455,51.597 52.351,50.922C52.304,50.612 52.422,50.52 52.692,50.493C53.557,50.41 54.42,50.321 54.868,50.274"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M3.128,50.235C4.699,50.294 6.219,50.517 7.69,51.094C9.053,51.629 10.005,52.539 10.598,53.888C11.603,56.187 12.228,58.604 12.833,61.024C13.693,64.454 14.52,67.894 15.388,71.322C15.664,72.41 16.467,73.023 17.478,73.038C18.575,73.052 19.408,72.482 19.698,71.358C20.436,68.513 21.08,65.644 21.839,62.804C22.565,60.081 23.371,57.382 24.172,54.679C24.945,52.063 26.928,50.939 29.43,50.499C31.076,50.211 32.721,50.226 34.375,50.464C34.84,50.529 34.941,50.716 34.894,51.13C34.496,54.602 33.856,58.024 32.783,61.359C30.806,67.505 27.702,72.993 23.235,77.658C21.948,79.001 20.508,80.202 19.085,81.403C18.124,82.212 16.971,82.162 15.975,81.389C10.616,77.223 6.712,71.929 3.972,65.742C2.182,61.699 1.035,57.471 0.466,53.086C0.377,52.39 0.288,51.697 0.19,51.002C0.145,50.687 0.24,50.523 0.584,50.493C1.432,50.422 2.28,50.324 3.128,50.238"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M164.211,49.94C166.452,50.095 168.648,50.273 170.845,50.392C173.827,50.553 176.815,50.767 179.797,50.767C182.85,50.767 185.904,50.553 188.957,50.392C190.712,50.3 192.461,50.128 194.213,49.988C195.001,49.925 195.004,49.922 195.235,50.69C196,53.228 195.932,55.72 194.803,58.143C194.453,58.889 194.112,59.026 193.285,58.913C191.53,58.672 189.766,58.467 188.008,58.247C187.602,58.196 187.196,58.155 186.793,58.086C185.364,57.837 184.324,58.612 184.226,60.066C184.066,62.457 183.897,64.85 183.823,67.243C183.737,70.017 183.683,72.797 183.743,75.57C183.781,77.396 184.03,79.215 184.214,81.035C184.285,81.731 184.324,81.728 183.63,81.942C180.749,82.839 177.918,82.682 175.116,81.579C174.636,81.389 174.449,81.082 174.446,80.622C174.426,77.556 174.414,74.488 174.402,71.423C174.402,70.736 174.414,70.053 174.432,69.366C174.503,66.779 174.574,64.193 174.651,61.606C174.666,61.128 174.737,60.649 174.731,60.17C174.71,58.892 173.599,58 172.339,58.173C170.261,58.455 168.18,58.702 166.099,58.957C165.545,59.026 165.144,58.788 164.883,58.291C164.285,57.156 163.991,55.94 163.852,54.664C163.692,53.217 163.831,51.795 164.068,50.374C164.083,50.291 164.095,50.205 164.119,50.125C164.139,50.059 164.175,50.003 164.205,49.934"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M49.152,81.784C48.239,81.998 47.347,82.284 46.428,82.412C44.184,82.73 42.008,82.42 39.906,81.561C39.506,81.398 39.308,81.16 39.275,80.738C39.109,78.502 38.86,76.272 38.78,74.034C38.649,70.404 38.558,66.768 38.59,63.135C38.617,60.159 38.709,57.177 39.281,54.236C39.293,54.168 39.31,54.103 39.319,54.034C39.488,52.64 40.304,51.816 41.581,51.332C43.437,50.627 45.343,50.378 47.311,50.556C47.771,50.597 48.227,50.663 48.687,50.693C49.048,50.716 49.188,50.865 49.17,51.243C48.989,55.006 48.785,58.77 48.66,62.534C48.553,65.79 48.476,69.045 48.506,72.3C48.53,74.78 48.731,77.259 48.876,79.736C48.915,80.381 49.045,81.017 49.152,81.778"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M19.562,38.176L19.562,37.486C19.562,34.817 19.574,32.147 19.544,29.48C19.538,28.886 19.701,28.642 20.321,28.686C20.949,28.734 21.586,28.716 22.218,28.692C22.651,28.674 22.799,28.865 22.793,29.275C22.781,30.084 22.793,30.895 22.793,31.704C22.793,36.015 22.781,40.323 22.808,44.634C22.811,45.302 22.609,45.531 21.951,45.481C21.278,45.433 20.599,45.442 19.923,45.481C19.408,45.511 19.156,45.341 18.993,44.818C18.157,42.109 17.276,39.413 16.408,36.714C16.331,36.47 16.233,36.232 15.981,35.994C15.966,36.205 15.936,36.419 15.936,36.63C15.936,39.321 15.919,42.011 15.954,44.702C15.963,45.326 15.749,45.517 15.166,45.478C14.602,45.442 14.03,45.436 13.467,45.478C12.842,45.525 12.684,45.255 12.699,44.675C12.729,43.254 12.708,41.83 12.708,40.409L12.708,29.578C12.708,28.695 12.714,28.695 13.574,28.695C14.273,28.695 14.973,28.722 15.666,28.686C16.123,28.663 16.331,28.841 16.461,29.26C17.282,31.862 18.124,34.454 18.96,37.049C19.084,37.436 19.218,37.822 19.345,38.206L19.568,38.173"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M155.065,45.448C153.948,45.448 152.949,45.469 151.953,45.424C151.811,45.418 151.615,45.121 151.559,44.928C150.992,42.981 150.459,41.024 149.893,39.08C149.827,38.86 149.656,38.581 149.466,38.509C148.909,38.298 148.612,38.548 148.609,39.163C148.603,41.045 148.594,42.927 148.615,44.809C148.621,45.285 148.476,45.49 147.984,45.469C147.308,45.442 146.632,45.445 145.956,45.469C145.431,45.487 145.289,45.255 145.289,44.758C145.301,39.639 145.289,34.52 145.313,29.4C145.313,29.168 145.568,28.74 145.707,28.737C147.536,28.713 149.377,28.63 151.194,28.805C153.094,28.987 154.212,30.265 154.68,32.07C155.119,33.764 154.994,35.405 153.96,36.892C153.874,37.017 153.794,37.177 153.669,37.243C152.62,37.787 152.902,38.578 153.186,39.44C153.829,41.381 154.41,43.343 155.065,45.451M148.609,33.729C148.609,34.297 148.588,34.867 148.621,35.432C148.633,35.614 148.743,35.881 148.882,35.95C149.549,36.277 150.758,35.941 151.179,35.295C151.778,34.38 151.751,33.384 151.307,32.426C150.874,31.493 149.999,31.422 149.122,31.526C148.932,31.549 148.654,31.835 148.633,32.025C148.568,32.587 148.609,33.161 148.609,33.729"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M75.941,45.457C74.833,45.457 73.854,45.49 72.885,45.427C72.698,45.415 72.446,45.05 72.375,44.806C71.818,42.9 71.308,40.983 70.763,39.074C70.703,38.866 70.561,38.607 70.386,38.524C69.873,38.277 69.503,38.545 69.503,39.125C69.497,40.962 69.47,42.799 69.515,44.636C69.532,45.326 69.286,45.522 68.646,45.478C68.038,45.436 67.425,45.457 66.814,45.472C66.39,45.481 66.18,45.344 66.183,44.868C66.195,39.683 66.183,34.499 66.206,29.317C66.206,29.112 66.455,28.731 66.595,28.728C68.382,28.698 70.182,28.597 71.954,28.767C73.893,28.948 75.064,30.203 75.559,32.075C75.924,33.449 75.882,34.802 75.283,36.104C75.102,36.496 74.904,36.972 74.569,37.18C73.525,37.822 73.774,38.604 74.08,39.517C74.726,41.435 75.295,43.379 75.938,45.46M69.494,33.723C69.494,34.311 69.476,34.903 69.506,35.489C69.515,35.649 69.624,35.881 69.752,35.943C70.357,36.235 71.542,35.923 71.972,35.399C72.559,34.686 72.657,33.309 72.186,32.423C71.705,31.517 70.875,31.457 69.992,31.499C69.636,31.517 69.482,31.665 69.491,32.022C69.503,32.59 69.494,33.158 69.494,33.723Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M173.047,37.058C173.047,34.519 173.062,31.981 173.035,29.442C173.029,28.877 173.181,28.663 173.77,28.686C175.425,28.755 177.094,28.654 178.73,28.844C180.891,29.094 182.346,30.836 182.382,33.048C182.423,35.738 182.426,38.432 182.382,41.125C182.34,43.575 180.621,45.332 178.178,45.436C176.676,45.499 175.167,45.445 173.661,45.472C173.163,45.481 173.035,45.279 173.038,44.809C173.056,42.225 173.047,39.642 173.047,37.061M176.255,37.088C176.255,38.705 176.275,40.323 176.243,41.937C176.231,42.564 176.492,42.737 177.061,42.713C178.419,42.657 179.083,41.997 179.086,40.623C179.094,38.262 179.092,35.905 179.086,33.544C179.083,32.073 178.472,31.505 177.019,31.484C176.394,31.475 176.234,31.728 176.243,32.302C176.269,33.895 176.252,35.492 176.252,37.085"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M185.619,35.31C185.619,33.339 185.634,31.371 185.61,29.4C185.604,28.856 185.779,28.653 186.327,28.686C186.935,28.725 187.552,28.734 188.159,28.686C188.797,28.636 188.865,28.972 188.865,29.483C188.853,33.14 188.856,36.794 188.865,40.45C188.865,40.864 188.895,41.283 188.972,41.69C189.099,42.359 189.668,42.85 190.276,42.882C190.931,42.915 191.45,42.534 191.654,41.812C191.749,41.482 191.782,41.125 191.782,40.78C191.791,37.037 191.803,33.294 191.773,29.551C191.767,28.906 191.912,28.627 192.603,28.689C193.231,28.746 193.869,28.713 194.5,28.698C194.918,28.689 195.102,28.873 195.096,29.284C195.054,33.44 195.069,37.599 194.951,41.752C194.891,43.863 192.979,45.567 190.818,45.686C187.658,45.858 185.666,44.006 185.625,40.834C185.601,38.997 185.619,37.156 185.619,35.316L185.619,35.31Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M53.483,37.124L53.483,33.315C53.483,30.206 55.377,28.395 58.478,28.529C60.858,28.633 62.414,29.795 62.874,31.888C63.004,32.477 62.984,33.104 62.966,33.714C62.96,33.868 62.702,34.13 62.548,34.139C61.765,34.184 60.977,34.187 60.194,34.139C60.037,34.13 59.818,33.85 59.767,33.66C59.678,33.307 59.732,32.917 59.655,32.56C59.462,31.674 58.899,31.261 58.078,31.347C57.346,31.425 56.842,32.007 56.8,32.843C56.785,33.191 56.797,33.544 56.797,33.892C56.797,36.277 56.794,38.664 56.803,41.048C56.803,41.328 56.836,41.619 56.922,41.881C57.138,42.544 57.651,42.897 58.297,42.874C58.931,42.853 59.509,42.46 59.613,41.803C59.732,41.054 59.726,40.284 59.735,39.52C59.738,39.08 59.376,39.074 59.068,39.113C58.457,39.19 58.273,38.931 58.273,38.337C58.273,36.446 58.253,36.446 60.117,36.446C60.882,36.446 61.644,36.461 62.409,36.44C62.833,36.428 63.004,36.615 63.001,37.023C62.984,38.685 63.043,40.353 62.936,42.008C62.809,43.988 60.98,45.567 58.792,45.659C58.084,45.689 57.346,45.638 56.658,45.469C54.686,44.978 53.516,43.358 53.492,41.188C53.477,39.832 53.492,38.474 53.492,37.118"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M97.833,35.414C97.833,33.425 97.845,31.433 97.824,29.442C97.818,28.886 97.984,28.639 98.571,28.683C99.158,28.728 99.751,28.725 100.338,28.683C100.91,28.642 101.094,28.853 101.091,29.43C101.07,33.214 101.076,36.999 101.088,40.783C101.088,41.149 101.129,41.53 101.236,41.878C101.434,42.526 102.01,42.906 102.614,42.877C103.213,42.847 103.744,42.392 103.88,41.735C103.96,41.354 103.984,40.956 103.987,40.564C103.993,36.91 103.99,33.256 103.993,29.605C103.993,28.698 103.996,28.698 104.903,28.695C105.448,28.695 105.997,28.725 106.539,28.686C107.09,28.648 107.253,28.868 107.248,29.403C107.227,32.203 107.253,35.004 107.233,37.802C107.224,39.241 107.304,40.703 107.079,42.115C106.693,44.524 104.674,45.855 102.001,45.68C99.727,45.531 97.963,43.777 97.883,41.452C97.812,39.443 97.868,37.43 97.868,35.417L97.839,35.417L97.833,35.414Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M9.4,35.509C9.4,37.477 9.46,39.449 9.385,41.414C9.285,44.06 7.346,45.713 4.515,45.683C2.031,45.659 0.077,43.804 0.048,41.313C0,37.311 0.033,33.306 0.021,29.302C0.021,28.859 0.163,28.663 0.626,28.683C1.257,28.71 1.892,28.722 2.523,28.683C3.098,28.645 3.288,28.865 3.282,29.436C3.261,31.71 3.273,33.987 3.273,36.261C3.273,37.772 3.255,39.279 3.285,40.789C3.294,41.235 3.386,41.705 3.552,42.118C3.753,42.618 4.201,42.873 4.752,42.873C5.304,42.873 5.736,42.591 5.929,42.089C6.08,41.693 6.169,41.244 6.169,40.819C6.187,37.055 6.193,33.294 6.166,29.531C6.16,28.868 6.35,28.621 7.017,28.68C7.58,28.731 8.152,28.722 8.716,28.68C9.273,28.642 9.436,28.874 9.433,29.4C9.415,31.433 9.424,33.47 9.424,35.503L9.397,35.503L9.4,35.509Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M132.539,35.429L132.539,29.59C132.539,28.698 132.542,28.695 133.458,28.695L134.899,28.695C135.486,28.695 135.78,29.002 135.782,29.614C135.782,33.333 135.776,37.049 135.791,40.769C135.791,41.155 135.835,41.562 135.96,41.922C136.173,42.529 136.633,42.894 137.303,42.871C137.979,42.847 138.391,42.428 138.566,41.815C138.675,41.423 138.696,40.995 138.699,40.584C138.708,36.931 138.705,33.28 138.705,29.626C138.705,28.692 138.708,28.692 139.654,28.692C140.22,28.692 140.789,28.707 141.355,28.686C141.764,28.672 141.951,28.808 141.948,29.245C141.936,33.312 141.989,37.382 141.915,41.449C141.865,44.214 139.212,46.159 136.408,45.621C134.018,45.166 132.655,43.644 132.575,41.194C132.512,39.27 132.563,37.344 132.563,35.42L132.545,35.42L132.539,35.429Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M51.494,45.457C50.453,45.457 49.499,45.478 48.547,45.436C48.396,45.43 48.171,45.172 48.123,44.993C47.99,44.467 47.907,43.926 47.848,43.385C47.8,42.945 47.59,42.781 47.16,42.793C46.398,42.811 45.633,42.811 44.872,42.793C44.456,42.784 44.285,42.98 44.231,43.367C44.154,43.929 44.071,44.491 43.95,45.044C43.917,45.195 43.739,45.43 43.621,45.433C42.651,45.466 41.679,45.451 40.624,45.451C41.015,43.638 41.377,41.94 41.744,40.239C42.509,36.704 43.28,33.17 44.044,29.632C44.246,28.698 44.237,28.695 45.218,28.695C45.915,28.695 46.615,28.716 47.311,28.689C47.759,28.671 47.957,28.814 48.055,29.281C49.119,34.311 50.213,39.339 51.295,44.366C51.366,44.699 51.417,45.035 51.491,45.457M46.131,33.517C46.045,33.517 45.962,33.512 45.876,33.509C45.515,35.729 45.153,37.947 44.789,40.192L47.276,40.192C46.884,37.911 46.508,35.714 46.131,33.514"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M26.241,37.047L26.241,29.561C26.241,28.707 26.25,28.696 27.068,28.696C29.445,28.696 31.826,28.716 34.203,28.681C34.834,28.672 34.977,28.91 34.98,29.483C34.983,31.499 34.997,31.499 33.017,31.499C32.057,31.499 31.096,31.511 30.136,31.496C29.718,31.49 29.546,31.651 29.552,32.076C29.567,33.081 29.567,34.089 29.552,35.096C29.546,35.522 29.709,35.682 30.133,35.667C30.853,35.646 31.574,35.67 32.294,35.658C32.682,35.652 32.848,35.81 32.836,36.205C32.819,36.773 32.813,37.344 32.836,37.912C32.857,38.355 32.656,38.486 32.249,38.477C31.618,38.459 30.984,38.5 30.352,38.465C29.771,38.435 29.513,38.616 29.546,39.244C29.59,40.073 29.555,40.906 29.558,41.738C29.558,42.378 29.89,42.699 30.554,42.701C31.799,42.701 33.041,42.719 34.286,42.695C34.784,42.686 34.971,42.853 34.983,43.373C35.024,45.463 35.039,45.463 32.952,45.463C30.99,45.463 29.024,45.445 27.059,45.472C26.448,45.481 26.217,45.312 26.226,44.658C26.261,42.119 26.241,39.58 26.241,37.041"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M162.281,28.698C163.502,28.698 164.723,28.716 165.945,28.69C166.437,28.678 166.582,28.874 166.597,29.344C166.671,31.499 166.68,31.499 164.575,31.499C163.659,31.499 162.743,31.514 161.827,31.493C161.362,31.481 161.151,31.627 161.166,32.129C161.193,33.113 161.193,34.097 161.166,35.081C161.154,35.557 161.356,35.688 161.791,35.673C162.467,35.649 163.146,35.688 163.819,35.661C164.308,35.64 164.483,35.843 164.459,36.321C164.433,36.844 164.436,37.371 164.459,37.897C164.477,38.319 164.317,38.491 163.89,38.48C163.235,38.459 162.58,38.5 161.928,38.468C161.379,38.438 161.133,38.598 161.163,39.202C161.208,40.141 161.202,41.084 161.163,42.023C161.142,42.55 161.344,42.725 161.839,42.719C163.149,42.704 164.456,42.743 165.764,42.704C166.371,42.686 166.609,42.856 166.603,43.507C166.588,45.466 166.612,45.466 164.643,45.466C162.675,45.466 160.671,45.448 158.688,45.475C158.083,45.484 157.849,45.326 157.855,44.666C157.882,39.612 157.879,34.558 157.855,29.504C157.852,28.868 158.048,28.663 158.679,28.686C159.877,28.731 161.077,28.698 162.278,28.698"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M85.29,37.038C85.29,35.768 85.35,34.496 85.279,33.229C85.13,30.678 86.912,28.395 90.152,28.523C92.482,28.615 94.112,29.697 94.554,31.71C94.984,33.66 95.117,33.675 93.087,33.732C92.933,33.735 92.781,33.732 92.627,33.735C91.922,33.745 91.529,33.392 91.45,32.676C91.367,31.927 90.863,31.413 90.149,31.35C89.369,31.282 88.809,31.671 88.614,32.453C88.542,32.748 88.51,33.057 88.507,33.36C88.498,35.854 88.498,38.352 88.507,40.846C88.507,41.149 88.539,41.459 88.614,41.753C88.806,42.532 89.393,42.948 90.146,42.874C90.837,42.808 91.373,42.255 91.45,41.53C91.581,40.302 91.581,40.311 92.835,40.445C93.288,40.492 93.751,40.51 94.207,40.507C94.637,40.501 94.788,40.694 94.773,41.108C94.673,43.754 93.099,45.466 90.493,45.674C89.055,45.79 87.706,45.517 86.613,44.476C85.762,43.67 85.314,42.669 85.296,41.503C85.273,40.014 85.29,38.527 85.29,37.038L85.287,37.038L85.29,37.038Z"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M122.899,38.447C122.899,36.368 122.884,34.287 122.908,32.209C122.914,31.668 122.748,31.46 122.203,31.493C121.55,31.528 120.892,31.484 120.24,31.508C119.798,31.522 119.594,31.374 119.615,30.907C119.638,30.36 119.63,29.813 119.618,29.266C119.609,28.894 119.733,28.695 120.139,28.695C123.13,28.701 126.119,28.701 129.11,28.695C129.468,28.695 129.628,28.832 129.622,29.198C129.611,29.789 129.605,30.381 129.622,30.969C129.634,31.371 129.462,31.514 129.077,31.505C128.38,31.49 127.678,31.534 126.981,31.49C126.368,31.448 126.199,31.686 126.202,32.283C126.222,36.353 126.213,40.427 126.213,44.497C126.213,45.143 125.892,45.467 125.25,45.469C124.705,45.469 124.159,45.448 123.614,45.475C123.083,45.501 122.884,45.305 122.89,44.752C122.917,42.65 122.902,40.551 122.899,38.45"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M110.588,37.127C110.588,34.591 110.603,32.055 110.577,29.519C110.571,28.895 110.748,28.636 111.395,28.686C111.979,28.734 112.575,28.728 113.159,28.686C113.725,28.648 113.914,28.844 113.911,29.427C113.891,33.449 113.9,37.475 113.9,41.497C113.9,41.673 113.917,41.848 113.9,42.02C113.84,42.55 114.104,42.71 114.59,42.707C115.921,42.692 117.249,42.725 118.58,42.695C119.191,42.68 119.476,42.862 119.407,43.516C119.363,43.926 119.366,44.348 119.407,44.762C119.464,45.332 119.197,45.478 118.681,45.475C116.218,45.46 113.754,45.457 111.291,45.475C110.746,45.478 110.58,45.273 110.582,44.738C110.603,42.202 110.591,39.666 110.591,37.127"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M78.754,37.076C78.754,34.519 78.766,31.959 78.745,29.403C78.739,28.859 78.914,28.657 79.457,28.689C80.064,28.725 80.678,28.731 81.286,28.689C81.893,28.648 82.083,28.888 82.077,29.483C82.056,32.435 82.068,35.384 82.068,38.337L82.068,44.633C82.068,45.442 82.045,45.463 81.212,45.469C80.601,45.469 79.99,45.448 79.38,45.475C78.894,45.496 78.742,45.296 78.745,44.818C78.763,42.237 78.754,39.657 78.754,37.079"
                  fill="rgb(234,92,42)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M56.646,19.872L60.313,19.872C61.925,19.872 63.333,19.589 64.531,19.021C65.729,18.456 66.728,17.71 67.522,16.786C68.317,15.861 68.915,14.803 69.321,13.613C69.725,12.421 69.926,11.208 69.926,9.971C69.926,8.735 69.725,7.522 69.321,6.33C68.918,5.137 68.32,4.082 67.522,3.157C66.728,2.233 65.729,1.487 64.531,0.922C63.331,0.357 61.925,0.071 60.313,0.071L51.352,0.071C51.031,0.071 50.771,0.333 50.771,0.654L50.771,23.739C50.771,24.061 51.031,24.322 51.352,24.322L56.065,24.322C56.385,24.322 56.646,24.061 56.646,23.739L56.646,19.872ZM56.649,6.202C56.649,5.881 56.91,5.619 57.23,5.619L60.313,5.619C61.167,5.619 61.887,5.833 62.474,6.261C63.061,6.689 63.499,7.227 63.787,7.873C64.075,8.521 64.214,9.225 64.202,9.989C64.19,10.753 64.048,11.458 63.769,12.106C63.493,12.754 63.061,13.292 62.471,13.717C61.884,14.146 61.164,14.36 60.31,14.36L57.227,14.36C56.907,14.36 56.646,14.098 56.646,13.777L56.646,6.199L56.649,6.202Z"
                  fill="rgb(110,153,204)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M131.724,21.554C131.724,21.001 132.421,20.757 132.762,21.192L135.044,24.093C135.154,24.227 135.32,24.307 135.495,24.307L141.012,24.307C141.504,24.307 141.773,23.733 141.462,23.353L139.088,19.913C138.883,19.616 138.99,19.203 139.319,19.054C140.484,18.522 141.456,17.767 142.239,16.786C143.161,15.629 143.807,14.336 144.175,12.9C144.542,11.467 144.628,9.986 144.435,8.461C144.24,6.936 143.777,5.548 143.051,4.299C142.325,3.05 141.317,2.022 140.024,1.213C138.732,0.404 137.176,0 135.356,0L126.738,0C126.418,0 126.157,0.262 126.157,0.583L126.157,23.716C126.157,24.037 126.418,24.298 126.738,24.298L131.143,24.298C131.463,24.298 131.724,24.037 131.724,23.716L131.724,21.551L131.724,21.554ZM131.724,6.166C131.724,5.845 131.985,5.583 132.305,5.583L135.356,5.583C136.162,5.583 136.844,5.797 137.395,6.225C137.949,6.654 138.359,7.198 138.622,7.855C138.886,8.515 139.02,9.219 139.02,9.968C139.02,10.718 138.886,11.425 138.622,12.085C138.356,12.745 137.949,13.289 137.395,13.714C136.841,14.143 136.162,14.357 135.356,14.357L132.305,14.357C131.985,14.357 131.724,14.095 131.724,13.774L131.724,6.166Z"
                  fill="rgb(159,102,56)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M120.629,3.645L116.016,3.645C115.696,3.645 115.435,3.907 115.435,4.228L115.435,16.905C115.435,18.037 115.171,18.991 114.641,19.765C114.11,20.541 113.443,21.117 112.634,21.498C111.827,21.878 110.956,22.075 110.022,22.086L109.832,22.086C108.899,22.075 108.03,21.878 107.221,21.498C106.415,21.117 105.745,20.538 105.214,19.765C104.683,18.989 104.42,18.037 104.42,16.905L104.42,4.231C104.42,3.91 104.159,3.648 103.839,3.648L99.226,3.648C98.906,3.648 98.645,3.91 98.645,4.231L98.645,16.905C98.743,21.962 100.987,24.331 102.451,24.316L117.403,24.316C118.868,24.331 121.112,21.962 121.21,16.905L121.21,4.231C121.21,3.91 120.949,3.648 120.629,3.648"
                  fill="rgb(140,175,73)"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M93.65,18.673C93.016,17.124 92.144,15.76 91.035,14.582C89.927,13.358 88.628,12.403 87.14,11.722C86.497,11.428 85.833,11.202 85.145,11.036C84.238,10.816 83.295,10.7 82.308,10.7C81.321,10.7 80.296,10.822 79.353,11.062C78.704,11.226 78.072,11.446 77.462,11.722C75.962,12.403 74.657,13.358 73.549,14.582C72.44,15.763 71.574,17.124 70.952,18.673C70.329,20.222 70.018,21.899 70.018,23.703L70.018,23.721C70.018,24.031 70.27,24.283 70.578,24.283L75.161,24.283C75.47,24.283 75.722,24.031 75.722,23.721L75.722,23.703C75.722,22.663 75.899,21.697 76.258,20.808C76.617,19.919 77.106,19.137 77.728,18.468C78.327,17.844 79.012,17.347 79.786,16.976C80.559,16.607 81.392,16.42 82.293,16.42C83.195,16.42 84.028,16.604 84.801,16.976C85.572,17.344 86.26,17.844 86.859,18.468C87.457,19.14 87.938,19.919 88.293,20.808C88.649,21.697 88.83,22.663 88.83,23.703L88.83,23.709C88.83,24.025 89.076,24.283 89.39,24.283L94.041,24.283C94.35,24.283 94.601,24.037 94.604,23.727L94.604,23.703C94.604,21.899 94.287,20.225 93.653,18.673"
                  fill="rgb(247,176,61)"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  ),
})
