'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'HeartOutlineNestle',
  viewBox: '0 0 20 19',
  path: (
    <>
      <path
        fill="transparent"
        d="M9.61843 3.21024L10.0001 3.66124L10.3818 3.21024C10.9653 2.52078 11.6275 1.83576 12.3545 1.32534C13.0807 0.815427 13.8424 0.5 14.6342 0.5C17.6365 0.5 19.5 3.13512 19.5 5.29337C19.5 7.68644 18.1343 10.0556 16.1786 12.2477C14.3246 14.3259 12.0002 16.1789 9.99999 17.6639C7.99987 16.179 5.67549 14.3262 3.82144 12.248C1.86578 10.056 0.5 7.6867 0.5 5.29337C0.5 3.13492 2.36339 0.5 5.36582 0.5H5.36602C6.15784 0.5 6.91951 0.815428 7.64573 1.32534C8.37267 1.83576 9.03495 2.52078 9.61843 3.21024Z"
        stroke="currentColor"
      />
    </>
  ),
})
