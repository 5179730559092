'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ChevronLeftCircle',
  viewBox: '0 0 48 48',
  path: (
    <>
      <circle fill="transparent" r="23" transform="matrix(-1 0 0 1 24 24)" stroke="currentColor" strokeWidth="2" />
      <path
        d="M18.174 24.58a.537.537 0 0 0 .163.41l10.5 10.5a.582.582 0 0 0 .996-.415.595.595 0 0 0-.167-.414L19.574 24.58l10.091-10.092a.583.583 0 0 0-.414-.989.583.583 0 0 0-.414.172l-10.5 10.5a.535.535 0 0 0-.163.409Z"
        fill="currentColor"
      />
    </>
  ),
})
