'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LateDefaultRate',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M57.5676 56L67.3116 46.256C67.7596 45.808 67.7596 45.1361 67.3116 44.6881C66.8636 44.2401 66.1916 44.2401 65.7436 44.6881L55.9996 54.432L46.2556 44.6881C45.8076 44.2401 45.1356 44.2401 44.6876 44.6881C44.2396 45.1361 44.2396 45.808 44.6876 46.256L54.4316 56L44.6876 65.744C44.2396 66.192 44.2396 66.864 44.6876 67.312C44.9116 67.536 45.2476 67.648 45.4716 67.648C45.6956 67.648 46.0316 67.536 46.2556 67.312L55.9996 57.568L65.7436 67.312C65.9676 67.536 66.3036 67.648 66.5276 67.648C66.7516 67.648 67.0876 67.536 67.3116 67.312C67.7596 66.864 67.7596 66.192 67.3116 65.744L57.5676 56Z"
        fill="#FF6C4A"
      />
      <path
        d="M55.9992 13.44C79.5192 13.44 98.5592 32.48 98.5592 56C98.5592 79.52 79.5192 98.56 55.9992 98.56C32.4792 98.56 13.4392 79.52 13.4392 56C13.4392 32.48 32.4792 13.44 55.9992 13.44ZM55.9992 11.2C31.2472 11.2 11.1992 31.248 11.1992 56C11.1992 80.752 31.2472 100.8 55.9992 100.8C80.7512 100.8 100.799 80.752 100.799 56C100.799 31.248 80.7512 11.2 55.9992 11.2Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
