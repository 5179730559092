'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Success',
  viewBox: '0 0 108 108',
  path: (
    <>
      <path
        d="M51.9399 32.06C57.4224 32.06 62.7817 33.6857 67.3403 36.7317C71.8988 39.7776 75.4517 44.1068 77.5498 49.172C79.6479 54.2372 80.1968 59.8107 79.1272 65.1879C78.0576 70.5651 75.4176 75.5043 71.5409 79.381C67.6641 83.2577 62.7249 85.8978 57.3478 86.9674C51.9706 88.0369 46.397 87.488 41.3319 85.3899C36.2667 83.2919 31.9374 79.7389 28.8915 75.1804C25.8456 70.6219 24.2199 65.2625 24.2199 59.78C24.2322 52.432 27.1567 45.3885 32.3525 40.1926C37.5483 34.9968 44.5918 32.0723 51.9399 32.06V32.06ZM51.9399 29.7267C45.9939 29.7174 40.1788 31.4722 35.2304 34.7689C30.282 38.0655 26.4228 42.756 24.1409 48.2467C21.8591 53.7374 21.2573 59.7816 22.4116 65.6145C23.566 71.4473 26.4246 76.8067 30.6258 81.0144C34.8269 85.2221 40.1818 88.089 46.0129 89.2524C51.8439 90.4158 57.889 89.8234 63.3833 87.5501C68.8775 85.2768 73.574 81.4248 76.8784 76.4816C80.1827 71.5383 81.9465 65.726 81.9465 59.78C81.9465 51.8174 78.7866 44.1803 73.1606 38.5456C67.5346 32.9108 59.9024 29.739 51.9399 29.7267V29.7267Z"
        fill="fill"
      />
      <path
        d="M52.314 42.6066C48.9174 42.6066 45.5971 43.6138 42.773 45.5009C39.9488 47.3879 37.7477 50.07 36.4479 53.208C35.1481 56.346 34.808 59.799 35.4706 63.1303C36.1333 66.4616 37.7689 69.5216 40.1706 71.9233C42.5723 74.3251 45.6323 75.9607 48.9636 76.6233C52.2949 77.286 55.7479 76.9459 58.8859 75.6461C62.0239 74.3462 64.706 72.1451 66.5931 69.321C68.4801 66.4968 69.4873 63.1765 69.4873 59.78C69.4873 55.2253 67.678 50.8572 64.4573 47.6366C61.2367 44.416 56.8686 42.6066 52.314 42.6066ZM52.314 74.62C49.3789 74.62 46.5097 73.7496 44.0693 72.119C41.6289 70.4883 39.7268 68.1706 38.6036 65.459C37.4804 62.7473 37.1865 59.7635 37.7591 56.8848C38.3317 54.0061 39.7451 51.3619 41.8205 49.2865C43.8959 47.2111 46.5401 45.7977 49.4188 45.2251C52.2975 44.6525 55.2813 44.9464 57.993 46.0696C60.7046 47.1928 63.0223 49.0949 64.653 51.5353C66.2836 53.9757 67.154 56.8449 67.154 59.78C67.154 63.7201 65.592 67.4995 62.8103 70.29C60.0286 73.0804 56.2541 74.6543 52.314 74.6666V74.62Z"
        fill="fill"
      />
      <path
        d="M101.405 21.4667C101.358 21.2768 101.265 21.1013 101.135 20.955C101.005 20.8087 100.842 20.6961 100.659 20.6267L93.7987 18.1067L91.2786 11.2933C91.2168 11.1059 91.1066 10.9381 90.9591 10.807C90.8116 10.6759 90.632 10.5861 90.4387 10.5467C90.2467 10.5065 90.0477 10.5154 89.8601 10.5725C89.6725 10.6296 89.5024 10.7331 89.3653 10.8733L76.112 24.1267C67.3037 18.3087 56.6459 15.9759 46.2126 17.5821C35.7793 19.1884 26.3166 24.6187 19.6661 32.8166C13.0155 41.0144 9.65274 51.3933 10.2321 61.9337C10.8114 72.474 15.2915 82.4218 22.8004 89.8414C30.3094 97.261 40.3101 101.622 50.8567 102.075C61.4032 102.528 71.7411 99.0409 79.8587 92.2926C87.9763 85.5443 93.293 76.0174 94.7741 65.5656C96.2553 55.1137 93.795 44.4847 87.872 35.7467L101.079 22.54C101.223 22.4056 101.329 22.2356 101.386 22.0472C101.443 21.8588 101.45 21.6585 101.405 21.4667ZM92.8653 59.78C92.8868 68.8158 89.89 77.6 84.3503 84.7386C78.8107 91.8772 71.538 95.9539 62.7798 98.1766C54.0216 100.399 44.7719 99.6332 36.4987 96C28.2254 92.3668 21.7885 86.4526 17.4987 78.5C13.2089 70.5474 11.6979 61.3899 13.2056 52.4807C14.7133 43.5715 18.8311 36.0983 25.4987 30C32.1663 23.9017 40.1234 20.1568 49.1314 19.4481C58.1394 18.7394 67.1261 21.0594 74.6653 26.04C74.6653 26.04 74.6653 26.04 74.6653 26.32L77.232 32.6667L51.332 58.94L52.9653 60.62L79.0053 34.58L85.5853 37.3333H85.9587C90.427 43.9694 92.8302 51.7799 92.8653 59.78ZM85.6787 34.6267L80.592 32.6667L79.5653 32.2933L79.052 30.8933L77.2786 26.2267L89.7387 14L91.792 19.6C91.8502 19.7609 91.9431 19.907 92.0641 20.0279C92.185 20.1489 92.3311 20.2418 92.492 20.3L97.9987 22.2133L85.6787 34.6267Z"
        fill="fill"
      />
      <path
        d="M52.3129 64.0734C54.684 64.0734 56.6062 62.1512 56.6062 59.78C56.6062 57.4089 54.684 55.4867 52.3129 55.4867C49.9417 55.4867 48.0195 57.4089 48.0195 59.78C48.0195 62.1512 49.9417 64.0734 52.3129 64.0734Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
