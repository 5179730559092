'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'BrushHeading',
  viewBox: '0 0 222 10',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M205.461 0.7C211.011 0.7 216.339 1.4 221.445 0C185.259 0 148.851 1.04308e-07 110.667 1.4C82.9167 2.45 55.3887 2.8 27.1947 4.2C19.6467 4.55 10.7667 4.2 2.5527 5.25C-2.5533 5.95 1.2207 6.3 4.3287 6.65C1.4427 7 -2.7753 8.05 2.5527 8.75C9.2127 9.8 18.0927 8.4 25.6407 8.05C69.8187 6.65 115.773 4.2 158.175 3.5C175.047 3.15 191.253 3.15 209.679 2.45C187.923 2.1 168.831 2.45 145.299 2.8C142.857 2.8 136.641 2.45 136.419 2.8C137.307 1.75 144.855 2.45 145.521 2.45C166.167 2.1 185.925 2.45 206.793 1.75C211.899 1.75 204.129 1.05 202.353 1.05C192.807 1.05 183.039 1.05 173.493 1.05C179.709 1.05 185.925 0.7 191.919 0.7C196.359 1.05 201.021 0.7 205.461 0.7Z"
      fill="currentColor"
    />
  ),
})
