'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'SeedMousline',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M1268,4994c-15-3-37-17-49-32c-20-26-21-34-16-227c5-203,12-259,47-396c89-340,294-540,613-595
		c117-21,318-10,429,22c13,4,25-11,51-63c119-238,193-511,243-903c30-236,29-881-3-1043l-5-29l-61,7c-150,17-271,63-470,178
		c-294,170-657,304-934,345c-195,29-406-5-598-97c-120-57-207-114-317-207c-90-77-185-183-193-217c-3-12-4-212-3-444
		c3-412,4-423,24-443c17-17,44-23,160-35c325-34,530-73,739-138c160-50,245-85,709-286c229-100,457-195,507-211
		c224-74,493-82,724-24c84,22,149,46,491,182c166,67,339,131,385,142c324,83,586,287,728,567c63,126,651,1472,651,1492
		c0,11-11,33-24,48c-24,27-26,28-118,26c-169-5-340-68-454-167c-28-24-217-236-419-470l-369-426l-44,50c-30,34-67,61-119,87l-77,38
		l-374,3l-374,3l6,47c3,26,8,79,11,117c14,162,55,354,115,536c31,93,93,253,105,267c1,1,21-5,45-13c181-64,362-60,503,11
		c74,38,193,162,240,251c104,199,156,435,160,728c2,143,2,145-24,170c-25,26-26,26-190,26c-252,0-407-30-577-112
		c-180-87-304-237-352-425c-37-142-28-358,20-511l20-61l-29-64c-18-38-31-57-34-48c-3,8-10,74-17,145c-31,350-119,687-251,961
		l-58,121l26,63c68,170,92,373,58,503c-88,337-471,531-1090,551C1358,4996,1283,4996,1268,4994z M1625,4819c494-57,756-241,755-529
		c0-57-20-163-40-215l-11-29l-41,49c-109,132-269,261-415,334c-92,46-138,40-163-19c-22-53-1-81,101-136c49-26,113-65,142-86
		c109-82,268-251,250-269c-16-16-190-27-277-18c-155,17-256,65-356,169c-129,134-210,401-210,691v69h88
		C1496,4830,1576,4825,1625,4819z M3770,3650c0-186-54-442-123-585c-56-116-118-185-204-227c-60-29-75-32-157-32
		c-80,0-196,21-214,38c-3,4,21,46,53,94c55,81,204,239,318,338c53,46,62,84,27,122s-64,38-128,1c-76-45-262-234-337-342
		c-57-82-60-85-66-59c-11,46-10,208,2,264c18,86,74,193,133,251c125,125,333,193,604,196l92,1V3650z M4900,2433
		c0-13-535-1223-582-1314c-55-110-148-225-241-299c-84-67-242-145-357-176c-52-14-248-85-435-159c-402-158-431-168-554-189
		c-171-29-360-16-529,35c-37,12-254,102-482,201s-467,201-530,226C900,872,652,928,227,975l-57,6v359v358l91,86
		c148,141,308,237,479,286c116,34,305,38,439,10c231-47,555-175,783-308c196-114,288-153,448-188c62-14,159-18,576-23l501-6l36-25
		c21-14,50-43,65-64c24-34,27-49,27-115s-4-81-27-114c-14-22-43-51-65-65l-38-27l-665-6c-566-5-678-8-755-23c-82-15-223-51-230-58
		c-2-2,9-34,24-73c31-82,26-80,166-45c88,23,105,23,775,29c675,6,686,7,739,28c140,57,214,151,244,307l12,64l409,471
		c341,393,419,478,475,515C4762,2409,4900,2459,4900,2433z"
        />
      </g>
    </>
  ),
})
