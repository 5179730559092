'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ArrowMousline',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M3121,4995c-16-14-35-42-42-62c-11-35-9-41,59-177c39-78,69-143,67-145s-50,8-107,23c-215,54-276,61-543,61
		c-197,0-270-4-345-18c-480-90-860-286-1180-607c-582-582-784-1430-526-2205c63-190,126-319,164-339c64-33,137-12,166,48
		c23,48,21,57-28,166c-330,729-188,1561,364,2134c507,526,1222,717,1930,514c58-16,109-33,113-37s-62-54-146-110
		c-146-97-167-116-182-168c-8-30,17-85,50-111c46-36,91-29,176,26c483,316,479,312,479,384c0,20-51,128-152,321
		c-164,312-176,327-250,327C3164,5020,3142,5012,3121,4995z"
        />
        <path
          fill="currentColor"
          d="M4335,3566c-74-33-83-104-28-222c235-508,231-1120-12-1627c-96-200-217-371-379-533c-312-312-698-500-1139-555
		c-142-17-433-7-569,20c-114,23-308,77-321,90c-4,4,62,61,148,126c133,101,157,123,166,156c15,48,5,84-31,120c-23,24-37,29-76,29
		c-46,0-55-6-300-189c-216-163-254-195-264-226c-7-20-9-48-5-63s81-150,172-300c172-284,195-312,256-312c69,0,123,61,114,130
		c-2,20-33,83-71,144c-37,60-65,110-63,112s47-7,99-21c577-146,1215-37,1714,293c732,486,1102,1366,933,2217
		c-36,181-122,435-182,539C4457,3564,4393,3593,4335,3566z"
        />
      </g>
    </>
  ),
})
