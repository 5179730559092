'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Refresh',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5958 15.2529C14.3964 14.346 15.6401 12.4546 16.2108 10.9377C16.4053 10.4208 16.982 10.1594 17.4989 10.3539C18.0158 10.5484 18.2772 11.1251 18.0827 11.642C17.4098 13.4304 15.9034 15.8264 13.4954 17.0392C10.9757 18.3082 7.73505 18.1542 3.96486 15.296C3.52475 14.9623 3.43845 14.335 3.77211 13.8949C4.10576 13.4548 4.73301 13.3685 5.17312 13.7022C8.48044 16.2095 10.9069 16.1035 12.5958 15.2529Z"
        fill="#003F2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31893 17.9187C2.12271 17.8424 1.99525 17.6515 2.00014 17.441L2.13925 11.4426C2.14565 11.1665 2.37464 10.9479 2.6507 10.9543L7.64936 11.0703C7.84332 11.0748 8.01716 11.1911 8.09536 11.3686C8.17355 11.5462 8.14198 11.7529 8.01435 11.899L2.87659 17.7815C2.73811 17.9401 2.51516 17.9949 2.31893 17.9187Z"
        fill="#003F2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45818 4.43286C5.67906 5.38125 4.47956 7.30096 3.94413 8.83076C3.76168 9.35204 3.1912 9.62671 2.66992 9.44427C2.14864 9.26182 1.87397 8.69134 2.05641 8.17006C2.68765 6.36652 4.13816 3.93624 6.51737 2.66796C9.00701 1.34081 12.2503 1.41969 16.0858 4.18973C16.5335 4.51309 16.6343 5.13817 16.311 5.5859C15.9876 6.03362 15.3625 6.13445 14.9148 5.81109C11.5502 3.38113 9.12687 3.54334 7.45818 4.43286Z"
        fill="#003F2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3188 2.03408C18.515 2.11032 18.6424 2.30128 18.6376 2.51174L18.4984 8.51013C18.492 8.78619 18.2631 9.0048 17.987 8.9984L12.9883 8.88247C12.7944 8.87797 12.6205 8.76168 12.5423 8.58413C12.4641 8.40658 12.4957 8.19982 12.6233 8.05369L17.7611 2.17123C17.8996 2.01268 18.1225 1.95785 18.3188 2.03408Z"
        fill="#003F2C"
      />
    </>
  ),
})
