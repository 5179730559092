'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Lock',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 6.00002H12.9333C12.9864 5.99913 13.0392 6.00883 13.0885 6.02853C13.1378 6.04824 13.1826 6.07756 13.2205 6.11479C13.2584 6.15202 13.2884 6.19642 13.3089 6.24539C13.3294 6.29436 13.34 6.34692 13.34 6.40002V14.2667C13.34 14.3198 13.3294 14.3723 13.3089 14.4213C13.2884 14.4703 13.2584 14.5147 13.2205 14.5519C13.1826 14.5891 13.1378 14.6185 13.0885 14.6382C13.0392 14.6579 12.9864 14.6676 12.9333 14.6667H3.07334C3.02081 14.6667 2.9688 14.6563 2.92027 14.6362C2.87174 14.6161 2.82764 14.5867 2.7905 14.5495C2.75335 14.5124 2.72389 14.4683 2.70379 14.4198C2.68369 14.3712 2.67334 14.3192 2.67334 14.2667V6.40002C2.67334 6.29393 2.71548 6.19219 2.7905 6.11718C2.86551 6.04216 2.96725 6.00002 3.07334 6.00002H5.33334V3.94668C5.38948 3.29265 5.70061 2.68693 6.19955 2.26034C6.69849 1.83375 7.34519 1.62052 8.00001 1.66668C8.65522 1.61875 9.30288 1.83136 9.80222 2.25829C10.3016 2.68522 10.6122 3.29198 10.6667 3.94668V6.00002ZM6.67173 2.73307C6.29808 3.03449 6.05702 3.47001 6.00001 3.94668V6.00002H10V3.94668C9.94463 3.46929 9.70401 3.03274 9.32995 2.731C8.95589 2.42926 8.4783 2.28646 8.00001 2.33335C7.52206 2.28821 7.04539 2.43165 6.67173 2.73307ZM8.00667 8.56665C8.55895 8.56665 9.00667 9.01437 9.00667 9.56665V10.9C9.00667 11.4523 8.55895 11.9 8.00667 11.9C7.45438 11.9 7.00667 11.4523 7.00667 10.9V9.56665C7.00667 9.01437 7.45438 8.56665 8.00667 8.56665Z"
        fill="currentColor"
      />
    </>
  ),
})
