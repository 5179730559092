'use client'

import { RefObject, useEffect, useState } from 'react'

export default function useIntersectionObserver<T extends Element>(reference: RefObject<T>) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleIntersect: IntersectionObserverCallback = (entries) => {
      setIsVisible(entries[0].isIntersecting)
    }

    // Create the observer, passing in the callback
    const observer = new IntersectionObserver(handleIntersect)

    // If we have a ref value, start observing it
    if (reference.current) {
      observer.observe(reference.current)
    }

    // If unmounting, disconnect the observer
    return () => observer.disconnect()
  }, [reference])

  return isVisible
}
