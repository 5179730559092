'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'FavIcon',
  viewBox: '0 0 64 64',
  path: (
    <>
      <path
        d="M45.0697 43.9244C45.0505 45.0828 45.2826 46.2311 45.7495 47.2868C45.9928 47.8142 46.2962 48.3103 46.6535 48.7648C47.0368 49.2451 47.5575 49.6146 47.8974 50.1245C48.3458 50.8044 48.1867 51.8981 47.2248 51.9498C45.9882 52.0237 44.7515 51.9942 43.5149 51.9942C41.1067 51.9942 38.9372 51.9942 36.5217 51.9942C35.7262 51.9942 35.2634 51.5434 35.3429 50.8487C35.3915 50.5911 35.5063 50.3514 35.6756 50.1541C35.8554 49.9563 36.0568 49.7803 36.2758 49.6294C36.8987 49.1821 37.4005 48.5808 37.7343 47.8817C38.0682 47.1826 38.2233 46.4084 38.185 45.6315C38.1127 43.0302 38.185 40.1556 38.185 37.5543C38.2062 36.2769 37.9363 35.0119 37.3968 33.8594C36.717 32.6294 35.6881 31.6389 34.4461 31.0188C33.204 30.3987 31.8071 30.1781 30.4398 30.3861C29.007 30.5715 27.6868 31.2761 26.7194 32.3719C25.7519 33.4676 25.2013 34.8817 25.1678 36.3571C25.0159 39.5939 25.0737 43.1115 25.081 46.3557C25.1026 47.2315 25.4399 48.0684 26.0283 48.7057C26.5707 49.3338 27.5181 49.7698 27.7712 50.5901C27.837 50.7922 27.8592 51.0065 27.8363 51.2182C27.7961 51.4366 27.679 51.6325 27.5071 51.7686C27.3352 51.9048 27.1204 51.9719 26.9034 51.9572H24.77C21.8773 51.9572 19.2232 51.9572 16.3232 51.9572C15.7736 51.9572 15.2963 51.8538 15.0794 51.2847C14.9856 51.0413 14.9746 50.7728 15.048 50.5222C15.1215 50.2716 15.2751 50.0535 15.4843 49.9028C16.7884 48.7962 17.6797 47.2643 18.0083 45.565C18.101 44.9537 18.1469 44.336 18.1457 43.7175C18.1457 37.6726 18.1457 31.6301 18.1457 25.5901C18.1457 23.6465 18.9629 23.1219 20.6696 23.9717C22.7596 25.0137 24.8279 26.1 26.9396 27.0902C28.4062 27.8109 30.0128 28.185 31.6402 28.185C33.2677 28.185 34.8742 27.8109 36.3409 27.0902C38.5105 26.063 40.6294 24.9472 42.7773 23.8978C44.2236 23.1588 45.0842 23.7574 45.0842 25.3758L45.0697 43.9244Z"
        fill="#003F2C"
      />
      <path
        d="M41.4177 12.0001C43.7029 12.0001 45.5398 13.2563 46.1111 15.1925C47.0006 18.2002 45.077 20.8679 41.8154 21.1414C39.277 21.3557 37.122 19.8703 36.6808 17.609C36.0806 14.4757 38.1778 11.9853 41.4177 12.0001Z"
        fill="#003F2C"
      />
      <path
        d="M21.6169 12.0001C23.9094 12.0001 25.7463 13.2563 26.3176 15.1925C27.2071 18.2002 25.2835 20.8679 21.9785 21.1414C19.4474 21.3557 17.2851 19.8703 16.8512 17.609C16.2798 14.4757 18.3771 11.9853 21.6169 12.0001Z"
        fill="#003F2C"
      />
    </>
  ),
})
