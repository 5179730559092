'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Add',
  viewBox: '0 0 12 12',
  path: (
    <path
      d="M11.333 5.66634H6.33301V0.666341C6.33301 0.577936 6.29789 0.493151 6.23538 0.430639C6.17286 0.368127 6.08808 0.333008 5.99967 0.333008C5.91127 0.333008 5.82648 0.368127 5.76397 0.430639C5.70146 0.493151 5.66634 0.577936 5.66634 0.666341V5.66634H0.666341C0.577936 5.66634 0.493151 5.70146 0.430639 5.76397C0.368127 5.82648 0.333008 5.91127 0.333008 5.99967C0.333008 6.08808 0.368127 6.17286 0.430639 6.23538C0.493151 6.29789 0.577936 6.33301 0.666341 6.33301H5.66634V11.333C5.66634 11.4214 5.70146 11.5062 5.76397 11.5687C5.82648 11.6312 5.91127 11.6663 5.99967 11.6663C6.08808 11.6663 6.17286 11.6312 6.23538 11.5687C6.29789 11.5062 6.33301 11.4214 6.33301 11.333V6.33301H11.333C11.4214 6.33301 11.5062 6.29789 11.5687 6.23538C11.6312 6.17286 11.6663 6.08808 11.6663 5.99967C11.6663 5.91127 11.6312 5.82648 11.5687 5.76397C11.5062 5.70146 11.4214 5.66634 11.333 5.66634Z"
      fill="currentColor"
    />
  ),
})
