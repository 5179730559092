'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'CollaboratorsAmarenco',
  viewBox: '0 0 102 60',
  path: (
    <>
      <path
        opacity="0.5"
        d="M79.4806 40.5217C81.0737 42.1507 80.5745 43.9927 78.371 44.6155L34.6075 56.9831C32.4051 57.6054 31.088 56.3234 31.6813 54.1345L43.8657 9.17882C44.4589 6.98985 46.2477 6.53166 47.84 8.16042L79.4806 40.5217Z"
        fill="#F28C16"
      />
      <path
        d="M84.7778 37.9397V36.3823C92.8717 36.3823 96.1061 32.5647 96.994 31.2207C94.2805 28.3357 92.7771 24.5383 92.7883 20.5977V19.2183C92.822 17.5301 92.515 15.8524 91.8852 14.283C91.2554 12.7137 90.3157 11.2843 89.1207 10.0786C87.9258 8.87293 86.4997 7.91522 84.926 7.26138C83.3524 6.60753 81.6626 6.2707 79.9557 6.2707C78.2489 6.2707 76.5593 6.60753 74.9856 7.26138C73.412 7.91522 71.9859 8.87293 70.791 10.0786C69.596 11.2843 68.6561 12.7137 68.0263 14.283C67.3965 15.8524 67.0895 17.5301 67.1232 19.2183V20.5977C67.1339 24.5387 65.6296 28.3362 62.9151 31.2207C63.8013 32.5622 67.0357 36.3823 75.1321 36.3823V37.9397C64.4702 37.9397 61.3526 31.7036 61.226 31.4366L60.9876 30.9422L61.3791 30.5549C62.705 29.2507 63.7562 27.6992 64.472 25.9903C65.1877 24.2815 65.5539 22.4491 65.549 20.5993V19.22C65.5077 17.3226 65.8499 15.4362 66.5554 13.6713C67.2609 11.9065 68.3153 10.2987 69.6572 8.94237C70.9991 7.58606 72.6012 6.50848 74.3697 5.7728C76.1381 5.03712 78.0373 4.6582 79.9557 4.6582C81.8742 4.6582 83.7734 5.03712 85.5418 5.7728C87.3103 6.50848 88.9124 7.58606 90.2543 8.94237C91.5961 10.2987 92.6508 11.9065 93.3563 13.6713C94.0618 15.4362 94.404 17.3226 94.3627 19.22V20.5993C94.3575 22.4487 94.7228 24.2807 95.4377 25.9895C96.1525 27.6983 97.2028 29.2501 98.5275 30.5549L98.9214 30.9422L98.6833 31.4366C98.555 31.7036 95.4396 37.9397 84.7727 37.9397"
        fill="#354774"
      />
      <path
        d="M59.9674 59.1255H58.393V49.2374C58.3994 47.3605 59.0162 45.5354 60.1522 44.0321C61.2882 42.5287 62.883 41.4268 64.701 40.8895L72.3951 38.5822C73.0705 38.3834 73.6625 37.9732 74.0821 37.4132C74.5018 36.8532 74.7263 36.1737 74.7223 35.4766V33.3342H76.2965V35.4766C76.3014 36.5081 75.9685 37.5134 75.3474 38.342C74.7264 39.1706 73.8506 39.7779 72.8514 40.0729L65.1575 42.3801C63.6531 42.8251 62.3345 43.7398 61.399 44.9873C60.4635 46.2349 59.9614 47.7484 59.9674 49.3017V59.1255Z"
        fill="#354774"
      />
      <path
        d="M102 59.1256H100.426V49.3011C100.432 47.7478 99.9293 46.2344 98.9939 44.9868C98.0584 43.7393 96.7399 42.8246 95.2356 42.3794L87.539 40.0723C86.5404 39.7764 85.6654 39.1689 85.0444 38.3405C84.4235 37.5121 84.09 36.5073 84.0939 35.476V33.3335H85.6683V35.476C85.6647 36.173 85.8895 36.8523 86.3091 37.4122C86.7287 37.9721 87.3204 38.3824 87.9956 38.5816L95.6921 40.8888C97.521 41.4296 99.1239 42.5415 100.261 44.0583C101.398 45.5751 102.008 47.4152 102 49.3036V59.1256Z"
        fill="#354774"
      />
      <path
        d="M72.7973 18.6233V28.2641C72.7973 30.7254 75.9626 33.0499 77.8526 34.2159C78.5571 34.6457 79.3684 34.8734 80.1961 34.8734C81.0238 34.8734 81.8352 34.6457 82.5396 34.2159C84.4263 33.0499 87.5957 30.7254 87.5957 28.2641V17.7433C86.6079 17.4697 84.0872 17.0799 80.5288 18.7263C79.3242 19.3173 77.9946 19.6158 76.6501 19.5974C75.3056 19.5789 73.9849 19.2438 72.7973 18.62M80.1965 36.4292C79.0744 36.4318 77.974 36.1229 77.0205 35.5376C74.3758 33.9044 71.2231 31.317 71.2231 28.2641V17.1911C71.2234 17.0423 71.2667 16.8967 71.348 16.7715C71.4292 16.6463 71.545 16.5466 71.6817 16.4844C71.8183 16.4222 71.9701 16.3999 72.1191 16.4203C72.2681 16.4407 72.4081 16.5029 72.5226 16.5995C73.5544 17.3893 74.7901 17.8757 76.0885 18.0028C77.3868 18.1298 78.6951 17.8926 79.8634 17.3181C85.1286 14.8799 88.5711 16.4198 88.7152 16.4841C88.8511 16.547 88.966 16.647 89.0465 16.7722C89.1271 16.8974 89.1699 17.0427 89.1699 17.1911V28.2641C89.1699 31.3129 86.0164 33.9044 83.3718 35.5376C82.4186 36.123 81.3184 36.4319 80.1965 36.4292Z"
        fill="#354774"
      />
      <path
        d="M80.0432 47.6687C78.3348 47.6717 76.6556 47.2297 75.1746 46.3873L75.9618 45.0408C77.2764 45.7844 78.7729 46.1548 80.2866 46.1112C81.8003 46.0676 83.2726 45.6117 84.5413 44.7937L85.41 46.0932C83.8163 47.1264 81.9513 47.6746 80.0458 47.6703"
        fill="#354774"
      />
      <path
        d="M79.4085 59.5046H77.8343C77.8343 59.2327 77.8276 58.9484 77.8168 58.6501C77.5384 52.9364 75.9428 47.3615 73.1522 42.3514L71.6929 39.6923L73.0781 38.9507L74.5358 41.6098C77.4411 46.828 79.1025 52.6339 79.3935 58.5842C79.4043 58.9072 79.411 59.2121 79.411 59.5014"
        fill="#354774"
      />
      <path
        d="M74.5649 59.3703H72.9905C72.9905 54.7122 69.159 50.6762 69.1248 50.6359L68.6766 50.1711L70.4784 47.6752H68.2768L67.2448 40.8203L68.8033 40.5913L69.6363 46.1178H73.5453L70.7049 50.0508C71.802 51.3437 74.5708 55.0369 74.5708 59.3728"
        fill="#354774"
      />
      <path
        d="M82.5289 59.5663H80.9544C80.9544 59.2774 80.9613 58.9719 80.9746 58.65C81.2631 52.6995 82.9238 46.8933 85.8298 41.6755L87.2891 39.0173L88.6727 39.759L87.2134 42.418C84.4232 47.4273 82.8276 53.0014 82.5488 58.7142C82.538 59.0125 82.5313 59.2976 82.5313 59.5687"
        fill="#354774"
      />
      <path
        d="M87.3725 59.4313H85.7983C85.7983 55.0971 88.5669 51.4014 89.6639 50.1094L86.8236 46.1757H90.7326L91.5655 40.6548L93.1216 40.8814L92.0896 47.7363H89.8856L91.6888 50.233L91.2391 50.7002C91.2016 50.7423 87.3733 54.7757 87.3733 59.4346"
        fill="#354774"
      />
      <path
        d="M25.5037 59.4938H23.9293C23.9293 58.9648 23.9068 58.3814 23.8685 57.7634C23.5164 52.3706 22.0942 47.0991 19.683 42.2499C19.0366 40.9691 18.709 39.5539 18.7276 38.1225L18.581 38.2255C17.1121 39.0612 15.5397 39.7044 13.9031 40.1388L14.6319 44.6906H18.8915L15.7921 48.9821C16.9732 50.3639 20.1151 54.493 20.1151 59.3472H18.5409C18.5409 54.1279 14.2613 49.6165 14.2163 49.572L13.7681 49.1048L15.8305 46.248H13.2849L12.3578 40.33C12.2974 39.9362 12.3963 39.5346 12.6329 39.2123C12.8696 38.89 13.225 38.6728 13.6223 38.6078C15.0984 38.1917 16.5181 37.6002 17.8503 36.8461L18.1745 36.6813C18.5302 36.4971 18.9451 36.4596 19.3287 36.5768C19.7124 36.6939 20.0337 36.9562 20.2227 37.3067L20.3268 37.4938L20.3042 38.045C20.2705 39.2681 20.5439 40.4804 21.0998 41.5734C23.6009 46.6051 25.0757 52.0748 25.4403 57.6703C25.4811 58.3246 25.5037 58.9409 25.5037 59.4988"
        fill="#354774"
      />
      <path
        d="M28.9736 59.5665H27.3986C27.3986 59.0086 27.4235 58.3923 27.4618 57.738C27.8269 52.1421 29.3017 46.672 31.8023 41.6395C32.3649 40.5352 32.6641 39.318 32.677 38.0815V37.5969L32.7719 37.423C32.8774 37.2298 33.0222 37.0603 33.1974 36.9254C33.3725 36.7905 33.5741 36.693 33.7894 36.6393C34.0046 36.5855 34.2287 36.5766 34.4476 36.6131C34.6665 36.6496 34.8754 36.7306 35.061 36.8512C36.31 37.6651 37.6773 38.2856 39.1157 38.6912C39.3343 38.7163 39.5455 38.7847 39.7368 38.8921C39.9281 38.9995 40.0957 39.1438 40.2295 39.3166C40.3593 39.4845 40.453 39.677 40.5051 39.882C40.5572 40.087 40.5667 40.3002 40.5327 40.5089L39.6164 46.3182H37.0726L39.1375 49.175L38.6877 49.6397C38.6452 49.6867 34.363 54.1981 34.363 59.4173H32.7886C32.7886 54.5631 35.9307 50.4316 37.1135 49.0522L34.0122 44.7608H38.2696L38.9775 40.2683C37.5514 40.0771 35.0535 38.6978 34.2506 38.1886C34.2213 39.6243 33.8692 41.0356 33.2202 42.3201C30.81 47.1696 29.3876 52.4409 29.0344 57.8336C28.9961 58.4524 28.9746 59.0374 28.9746 59.564"
        fill="#354774"
      />
      <path
        d="M52.9029 59.4939H51.3287V51.9567C51.3368 50.0223 50.7119 48.1371 49.5472 46.5831C48.3826 45.0291 46.7408 43.8897 44.8673 43.3352L39.4356 41.7069L39.893 40.2163L45.3239 41.847C47.5216 42.4963 49.4476 43.832 50.814 45.6544C52.1803 47.4768 52.9134 49.6878 52.9037 51.9567L52.9029 59.4939Z"
        fill="#354774"
      />
      <path
        d="M33.6001 38.7652C32.1761 37.3313 31.3803 35.4003 31.3851 33.3903V32.46H32.9595V33.3903C32.9568 34.9923 33.5906 36.5312 34.7244 37.6751L33.6001 38.7652Z"
        fill="#354774"
      />
      <path
        d="M19.9144 38.2691L18.6649 37.3216C19.4923 36.2507 19.9419 34.9408 19.9445 33.593V32.46H21.5187V33.593C21.5159 35.2833 20.9518 36.926 19.9144 38.2691Z"
        fill="#354774"
      />
      <path
        d="M1.57433 59.4939H-7.94442e-05V51.9568C-0.0102126 49.6878 0.722815 47.4765 2.08923 45.654C3.45565 43.8316 5.38204 42.496 7.57993 41.8471L13.0249 40.2114L13.4814 41.702L8.03708 43.3352C6.16353 43.8897 4.52174 45.0291 3.35697 46.5831C2.19221 48.1371 1.56718 50.0222 1.57514 51.9568L1.57433 59.4939Z"
        fill="#354774"
      />
      <path
        d="M19.9111 12.6586C17.6963 12.6586 17.2414 14.8784 17.2414 18.2156V26.2744C17.2414 29.3669 21.1663 32.2501 23.5053 33.6904C24.3876 34.2399 25.409 34.5314 26.4516 34.5314C27.4941 34.5314 28.5153 34.2399 29.3976 33.6904C31.7366 32.2493 35.6607 29.3661 35.6607 26.2744V15.8549C35.6607 14.876 34.4946 12.9321 33.1061 11.6079C32.5998 11.0722 31.9779 10.6563 31.2868 10.3909C31.5367 11.2273 32.0424 12.9412 29.8708 13.9976C28.2424 14.7919 25.6227 14.0618 23.088 13.3548C22.0558 13.0121 20.9899 12.7787 19.9079 12.6586M26.4481 36.0923C25.1105 36.0897 23.8007 35.7158 22.6674 35.013C19.4739 33.0419 15.6638 29.9271 15.6638 26.2784V18.2198C15.6638 16.0906 15.6639 11.1053 19.9079 11.1053C21.1364 11.2141 22.3472 11.4672 23.5153 11.8593C25.5527 12.4287 28.0874 13.1365 29.1794 12.6067C30.124 12.1444 30.0383 11.7002 29.7734 10.8268C29.6538 10.584 29.5945 10.3163 29.6008 10.0462C29.607 9.77617 29.6785 9.51151 29.8092 9.27434C29.9349 9.11447 30.0973 8.98663 30.283 8.90136C30.4688 8.81609 30.6725 8.77587 30.8771 8.78408C32.1098 8.70909 33.3601 9.69052 34.2014 10.4906C35.7082 11.9326 37.2351 14.2398 37.2351 15.859V26.2784C37.2351 29.9312 33.4275 33.0461 30.2314 35.013C29.0984 35.7157 27.7888 36.0896 26.4516 36.0923"
        fill="#354774"
      />
      <path
        d="M14.6916 19.3914C14.4524 19.3914 14.2231 19.4853 14.0539 19.6527C13.8847 19.82 13.7895 20.0471 13.7895 20.2838V20.4749C13.7733 21.8396 14.0806 23.1892 14.6868 24.4152C15.0491 25.114 15.323 25.4478 15.6812 25.6011L15.6672 19.3914H14.6916ZM17.2597 27.2762H16.4668C14.7334 27.2762 13.9862 26.4868 13.2824 25.1231C12.5619 23.6782 12.1953 22.0859 12.2121 20.4749V20.2838C12.2125 19.6342 12.4736 19.0113 12.9379 18.552C13.4022 18.0926 14.0317 17.8344 14.6884 17.834H17.2365L17.2597 27.2762Z"
        fill="#354774"
      />
      <path
        d="M13.0051 18.953L12.5603 16.2585C12.1109 13.8671 12.2992 11.4011 13.1067 9.1037C14.1531 6.42595 15.994 4.12411 18.3876 2.50081C20.7812 0.877522 23.6152 0.00878906 26.5173 0.00878906C29.4193 0.00878906 32.2536 0.877522 34.6472 2.50081C37.0407 4.12411 38.8816 6.42595 39.928 9.1037C40.7362 11.401 40.9245 13.8671 40.4744 16.2585L40.0289 18.9554L38.4754 18.7042L38.9201 16.0096C39.3254 13.8837 39.1643 11.69 38.4528 9.64425C37.5176 7.26547 35.8777 5.22161 33.7481 3.78057C31.6186 2.33954 29.0986 1.56848 26.5185 1.56848C23.9384 1.56848 21.4186 2.33954 19.2891 3.78057C17.1595 5.22161 15.5196 7.26547 14.5844 9.64425C13.8796 11.6913 13.7184 13.8832 14.1162 16.0096L14.5593 18.7042L13.0051 18.953Z"
        fill="#354774"
      />
      <path
        d="M37.3058 19.3914L37.2924 25.6011C37.6498 25.4478 37.9247 25.1141 38.2862 24.4128C38.8941 23.1882 39.2016 21.8391 39.1841 20.4749V20.2838C39.1841 20.0471 39.0891 19.82 38.9199 19.6527C38.7507 19.4853 38.5212 19.3914 38.282 19.3914H37.3058ZM36.5028 27.2762H35.7131L35.7341 17.834H38.282C38.9385 17.8346 39.5679 18.093 40.0321 18.5523C40.4962 19.0116 40.7572 19.6343 40.7577 20.2838V20.4749C40.7805 22.0865 40.4134 23.6802 39.6872 25.1231C38.9833 26.4868 38.237 27.2762 36.5028 27.2762Z"
        fill="#354774"
      />
      <path
        d="M30.0907 46.8158L25.302 41.941L32.7728 35.6226L34.0889 37.3225L32.8395 38.2701L32.5338 37.8721L27.6018 42.0456L31.2201 45.7298L30.0907 46.8158Z"
        fill="#354774"
      />
      <path
        d="M22.8122 46.8157L21.6836 45.7297L25.302 42.0455L20.3701 37.872L20.1568 38.1505L18.9073 37.2029L20.1326 35.6233L27.6018 41.9417L22.8122 46.8157Z"
        fill="#354774"
      />
      <path
        d="M26.504 48.4118C26.1434 48.4131 25.7861 48.343 25.4533 48.2057C25.1204 48.0685 24.8185 47.8668 24.5656 47.6126L22.7256 45.7594L23.8502 44.6692L25.6902 46.5224C25.8996 46.732 26.1836 46.8523 26.4814 46.8577C26.7793 46.863 27.0675 46.753 27.2845 46.5512L29.3244 44.647L30.4073 45.78L28.3674 47.6859C27.8632 48.1545 27.1958 48.4143 26.504 48.4118Z"
        fill="#354774"
      />
      <path d="M45.0324 54.1255H43.4581V59.4988H45.0324V54.1255Z" fill="#354774" />
      <path d="M9.44574 54.1255H7.87144V59.4988H9.44574V54.1255Z" fill="#354774" />
    </>
  ),
})
