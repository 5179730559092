'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'UpBrush3',
  viewBox: '0 0 18 20',
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 3.49999C5.6 2.59999 6.1 1.79999 6.6 0.799988C6 1.49999 5.6 2.09999 5.2 2.79999C4 4.39999 2.4 5.79999 0.899996 7.29999C0.699996 7.59999 0.099996 7.59999 0.199996 8.09999C0.299996 8.59999 0.599996 8.59999 0.799996 8.59999C0.799996 8.79999 0.799996 9.09999 1.1 8.99999C1.5 8.89999 1.7 8.49999 2 8.19999C2.9 7.29999 3.7 6.19999 4.6 5.19999C5.8 3.89999 6.7 2.39999 7.6 0.799988C7 0.799988 7 1.09999 6.9 1.39999C6.8 1.59999 6.6 1.69999 6.5 1.89999C6.3 2.09999 6.2 2.39999 6 2.59999C6.2 2.19999 6.5 1.79999 6.7 1.39999C6.8 1.29999 6.8 0.899988 6.7 1.09999C6 1.79999 5.6 2.69999 5 3.49999Z"
        fill="#FF6C4A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9 17.4C14.9 17 15.8 16.6 16.7 16.1C15.9 16.3 15.1 16.6 14.4 16.9C12.5 17.6 10.4 17.9 8.40001 18.3C8.10001 18.4 7.60001 18.1 7.40001 18.6C7.20001 19 7.50001 19.2 7.60001 19.3C7.50001 19.4 7.40001 19.7 7.60001 19.8C8.00001 19.9 8.40001 19.7 8.70001 19.6C10 19.3 11.2 18.9 12.5 18.6C14.2 18.2 15.8 17.5 17.4 16.6C17.1 16.3 16.9 16.6 16.7 16.8C16.5 16.9 16.3 16.9 16.1 17C15.8 17.1 15.6 17.2 15.3 17.3C15.7 17.1 16.1 16.9 16.6 16.7C16.7 16.7 17 16.3 16.8 16.4C15.7 16.5 14.8 17 13.9 17.4Z"
        fill="#FF6C4A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3 8.49999C13.1 7.79999 13.8 7.09999 14.5 6.29999C13.8 6.79999 13.2 7.29999 12.6 7.79999C10.8 9.29999 9.7 9.59999 7.9 10.6C7.6 10.8 7 10.7 7 11.2C7 11.7 7.3 11.8 7.4 11.8C7.3 12 7.3 12.2 7.6 12.2C8 12.2 8.3 11.8 8.6 11.7C9.7 11 10.2 10.7 11.2 9.99999C12.8 8.99999 14 7.79999 15.2 6.49999C14.8 6.39999 14.8 6.69999 14.6 6.89999C14.4 7.09999 14.3 7.19999 14.1 7.29999C13.9 7.49999 13.7 7.69999 13.4 7.89999C13.7 7.59999 14.1 7.19999 14.4 6.89999C14.5 6.79999 14.6 6.39999 14.5 6.59999C13.8 7.09999 13.1 7.79999 12.3 8.49999Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
