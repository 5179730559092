'use client'

import { Box, BoxProps } from '@chakra-ui/react'
import { useId, forwardRef } from 'react'
import ReactSelect from 'react-select'

import { ClearIndicator, DropdownArrowIndicator, Option } from './components'

interface Props {
  name: string
  customStyles?: any
  data: any
  placeholder?: string
  defaultSelectValue?: any
  isMulti?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  getOptionLabel?: any
  getOptionValue?: any
  value?: any
  onChange?: (v: string) => void
  onInputChange?: (v: string) => void
  withCheckbox?: boolean
  blurInputOnSelect?: boolean
  closeMenuOnSelect?: boolean
  controlShouldRenderValue?: boolean
  hideSelectedOptions?: boolean
  maxMenuHeight?: number
}

const Select = forwardRef<any, Props & BoxProps>(
  (
    {
      data,
      customStyles,
      name,
      placeholder,
      defaultSelectValue,
      isMulti,
      isSearchable = false,
      isClearable,
      isDisabled = false,
      isLoading,
      onChange,
      blurInputOnSelect,
      onInputChange,
      getOptionLabel,
      getOptionValue,
      value,
      withCheckbox,
      closeMenuOnSelect,
      controlShouldRenderValue,
      hideSelectedOptions,
      maxMenuHeight,
      ...props
    },
    ref
  ) => {
    const id = useId() // We have to use a stable id for the select
    return (
      <Box {...props}>
        <ReactSelect
          name={name}
          ref={ref}
          maxMenuHeight={maxMenuHeight}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={data}
          value={value}
          onChange={onChange}
          onInputChange={onInputChange}
          isMulti={isMulti}
          defaultValue={defaultSelectValue}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable && !isDisabled}
          isSearchable={isSearchable}
          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          blurInputOnSelect={blurInputOnSelect}
          controlShouldRenderValue={controlShouldRenderValue}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator: DropdownArrowIndicator,
            Option: (props) => <Option withCheckbox={withCheckbox} {...props} />,
          }}
          styles={customStyles}
          placeholder={placeholder}
          instanceId={id}
        />
      </Box>
    )
  }
)

Select.displayName = 'Select'

export default Select
