'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Logo',
  viewBox: '0 0 120 63',
  path: (
    <>
      <path
        d="M0.0244255 55.997L2.04199 50.788H3.03612L5.06834 55.997H4.08887L3.69317 54.9778H1.39227L1.01123 55.997H0.0244255ZM2.54272 51.7549L1.66095 54.2031H3.41716L2.54272 51.7549Z"
        fill="white"
      />
      <path
        d="M7.14697 56.5334C7.44619 56.7936 7.92371 56.987 8.38047 56.987C9.0302 56.987 9.58344 56.6891 9.58344 55.8778V55.4388C9.32209 55.8328 8.82136 56.0785 8.2901 56.0785C7.16896 56.0785 6.4374 55.2004 6.4374 54.0985C6.4374 52.9966 7.13964 52.1489 8.29865 52.1489C8.8067 52.1489 9.27812 52.3569 9.58344 52.7886V52.2304H10.5031V55.5872C10.5031 57.1427 9.68847 57.7824 8.49283 57.7824C7.6709 57.7824 7.15552 57.5514 6.78181 57.2461C6.90882 57.0077 7.02851 56.7693 7.14819 56.5322L7.14697 56.5334ZM8.47695 55.3135C9.1047 55.3135 9.60542 54.8891 9.60542 54.1447C9.60542 53.4004 9.13523 52.9164 8.45497 52.9164C7.77471 52.9164 7.36436 53.46 7.36436 54.107C7.36436 54.7541 7.79059 55.3123 8.47818 55.3123L8.47695 55.3135Z"
        fill="white"
      />
      <path
        d="M13.1398 50.535C13.483 50.535 13.7529 50.788 13.7529 51.1151C13.7529 51.4423 13.4842 51.688 13.1398 51.688C12.7954 51.688 12.5414 51.4423 12.5414 51.1151C12.5414 50.788 12.7954 50.535 13.1398 50.535ZM12.677 55.997V52.2316H13.5966V55.997H12.677Z"
        fill="white"
      />
      <path
        d="M16.6816 53.0647C16.8086 52.425 17.3093 52.1489 17.7123 52.1489C17.8393 52.1489 17.9138 52.1708 18.0262 52.2012V53.1012C17.8845 53.0489 17.7123 53.027 17.5926 53.027C17.2568 53.027 16.6962 53.2131 16.6962 54.2554V55.997H15.7766V52.2316H16.6803V53.0647H16.6816Z"
        fill="white"
      />
      <path
        d="M26.4335 52.2316V55.997H25.5139V55.4315C25.2452 55.8413 24.7738 56.0785 24.2206 56.0785C23.0994 56.0785 22.3679 55.2004 22.3679 54.0985C22.3679 52.9966 23.0701 52.1489 24.2291 52.1489C24.7372 52.1489 25.2086 52.3569 25.5139 52.7886V52.2304H26.4335V52.2316ZM24.4086 55.3123C25.0364 55.3123 25.5371 54.8878 25.5371 54.1435C25.5371 53.3992 25.0669 52.9151 24.3867 52.9151C23.7064 52.9151 23.2961 53.4588 23.2961 54.1058C23.2961 54.7528 23.7223 55.3111 24.4099 55.3111L24.4086 55.3123Z"
        fill="white"
      />
      <path
        d="M30.6934 55.997H29.7298L28.2056 52.2316H29.1998L30.2085 54.9778L31.2246 52.2316H32.2188L30.6946 55.997H30.6934Z"
        fill="white"
      />
      <path
        d="M37.4483 55.3573C36.9403 55.9228 36.3199 56.0785 35.7373 56.0785C34.3768 56.0785 33.5622 55.2004 33.5622 54.1289C33.5622 53.0574 34.4366 52.1489 35.6323 52.1489C36.6557 52.1489 37.6205 52.7886 37.6205 54.2396H34.4818C34.4818 54.8355 34.9679 55.3038 35.7825 55.3038C36.2527 55.3038 36.6044 55.1323 36.9256 54.8428L37.4483 55.3561V55.3573ZM34.527 53.623H36.6789C36.5372 53.0428 36.0219 52.8786 35.6323 52.8786C35.2427 52.8786 34.7212 53.0428 34.5258 53.623H34.527Z"
        fill="white"
      />
      <path
        d="M41.3345 52.1501C41.7008 52.1501 42.2089 52.2316 42.7915 52.6561L42.3652 53.3104C41.9769 52.9905 41.5213 52.9686 41.3784 52.9686C40.6457 52.9686 40.1303 53.4369 40.1303 54.1143C40.1303 54.7918 40.6457 55.26 41.3784 55.26C41.5201 55.26 41.9769 55.2296 42.3652 54.9182L42.7915 55.5653C42.2089 55.997 41.7008 56.0785 41.3345 56.0785C40.0191 56.0785 39.2045 55.2296 39.2045 54.1362C39.2045 53.0428 39.9972 52.1489 41.3345 52.1489V52.1501Z"
        fill="white"
      />
      <path
        d="M47.6265 52.2316H48.5462V52.8641C48.7855 52.3727 49.2337 52.1501 49.7723 52.1501C50.7139 52.1501 51.1695 52.8276 51.1695 53.9137V55.997H50.2499V53.9209C50.2499 53.2666 49.9738 52.9237 49.5171 52.9237C48.9785 52.9237 48.5462 53.325 48.5462 54.1143V55.997H47.6265V52.2316Z"
        fill="white"
      />
      <path
        d="M55.0703 52.1501C56.1768 52.1501 57.1257 53.0355 57.1257 54.1143C57.1257 55.1931 56.1768 56.0785 55.0703 56.0785C53.9638 56.0785 53.0222 55.2077 53.0222 54.1143C53.0222 53.0209 53.9784 52.1501 55.0703 52.1501ZM55.0703 55.2977C55.7127 55.2977 56.1987 54.7772 56.1987 54.1143C56.1987 53.4515 55.7127 52.9309 55.0703 52.9309C54.4279 52.9309 53.9491 53.4515 53.9491 54.1143C53.9491 54.7772 54.4352 55.2977 55.0703 55.2977Z"
        fill="white"
      />
      <path
        d="M59.4266 54.7991C59.5243 55.1712 59.8895 55.3427 60.2339 55.3427C60.5026 55.3427 60.8018 55.2466 60.8018 54.9486C60.8018 54.7553 60.6222 54.6434 60.3902 54.5473L59.837 54.3393C59.0822 54.0559 58.888 53.6327 58.888 53.2897C58.888 52.5904 59.4864 52.1514 60.2705 52.1514C60.7493 52.1514 61.3465 52.3155 61.5932 52.9918L60.8531 53.2593C60.7554 52.9541 60.4794 52.8726 60.2778 52.8726C60.0385 52.8726 59.7844 53.0064 59.7844 53.2666C59.7844 53.4746 59.9713 53.5792 60.1801 53.6534L60.7407 53.8541C61.548 54.1216 61.7043 54.6057 61.7043 54.9328C61.7043 55.6772 61.0094 56.0785 60.2241 56.0785C59.611 56.0785 58.9161 55.8255 58.6999 55.1031L59.4254 54.7978L59.4266 54.7991Z"
        fill="white"
      />
      <path
        d="M66.6224 57.7009V52.2316H67.542V52.7972C67.8107 52.3873 68.2821 52.1501 68.8354 52.1501C69.9565 52.1501 70.6881 53.0282 70.6881 54.1301C70.6881 55.232 69.9858 56.0797 68.8268 56.0797C68.3188 56.0797 67.8474 55.8718 67.542 55.44V57.7022H66.6224V57.7009ZM68.6473 52.9164C68.0196 52.9164 67.5188 53.3408 67.5188 54.0851C67.5188 54.8295 67.989 55.3135 68.6693 55.3135C69.3496 55.3135 69.7599 54.7699 69.7599 54.1228C69.7599 53.4758 69.3337 52.9176 68.6461 52.9176L68.6473 52.9164Z"
        fill="white"
      />
      <path
        d="M76.4269 52.2316V55.997H75.5073V55.4315C75.2386 55.8413 74.7672 56.0785 74.2139 56.0785C73.0928 56.0785 72.3613 55.2004 72.3613 54.0985C72.3613 52.9966 73.0635 52.1489 74.2225 52.1489C74.7306 52.1489 75.202 52.3569 75.5073 52.7886V52.2304H76.4269V52.2316ZM74.402 55.3123C75.0298 55.3123 75.5305 54.8878 75.5305 54.1435C75.5305 53.3992 75.0603 52.9151 74.3801 52.9151C73.6998 52.9151 73.2894 53.4588 73.2894 54.1058C73.2894 54.7528 73.7157 55.3111 74.4032 55.3111L74.402 55.3123Z"
        fill="white"
      />
      <path
        d="M79.4985 53.0647C79.6255 52.425 80.1262 52.1489 80.5292 52.1489C80.6562 52.1489 80.7307 52.1708 80.8431 52.2012V53.1012C80.7014 53.0489 80.5292 53.027 80.4095 53.027C80.0737 53.027 79.5131 53.2131 79.5131 54.2554V55.997H78.5935V52.2316H79.4972V53.0647H79.4985Z"
        fill="white"
      />
      <path
        d="M83.7204 52.2316H84.8489V52.931H83.7204V54.9024C83.7204 55.1335 83.8181 55.3123 84.0868 55.3123C84.3115 55.3123 84.4605 55.1554 84.4605 54.9255C84.4605 54.8441 84.4459 54.6945 84.408 54.613H85.1628C85.23 54.7541 85.2446 54.9705 85.2446 55.0374C85.2446 55.5884 84.8636 56.0797 84.049 56.0797C83.4212 56.0797 82.8008 55.8122 82.8008 54.7772V52.9322H82.2109V52.2328H82.846L83.0109 51.2282H83.7204V52.2328V52.2316Z"
        fill="white"
      />
      <path
        d="M90.5951 55.3573C90.087 55.9228 89.4666 56.0785 88.884 56.0785C87.5235 56.0785 86.7089 55.2004 86.7089 54.1289C86.7089 53.0574 87.5834 52.1489 88.779 52.1489C89.8025 52.1489 90.7673 52.7886 90.7673 54.2396H87.6286C87.6286 54.8355 88.1146 55.3038 88.9292 55.3038C89.3994 55.3038 89.7512 55.1323 90.0724 54.8428L90.5951 55.3561V55.3573ZM87.6738 53.623H89.8257C89.684 53.0428 89.1686 52.8786 88.779 52.8786C88.3894 52.8786 87.8667 53.0428 87.6725 53.623H87.6738Z"
        fill="white"
      />
      <path
        d="M92.6126 52.2316H93.5323V52.8641C93.7716 52.3727 94.2199 52.1501 94.7584 52.1501C95.7001 52.1501 96.1556 52.8276 96.1556 53.9137V55.997H95.236V53.9209C95.236 53.2666 94.96 52.9237 94.5032 52.9237C93.9646 52.9237 93.5323 53.325 93.5323 54.1143V55.997H92.6126V52.2316Z"
        fill="white"
      />
      <path
        d="M102.089 52.2316V55.997H101.169V55.4315C100.9 55.8413 100.429 56.0785 99.8756 56.0785C98.7545 56.0785 98.0229 55.2004 98.0229 54.0985C98.0229 52.9966 98.7252 52.1489 99.8842 52.1489C100.392 52.1489 100.864 52.3569 101.169 52.7886V52.2304H102.089V52.2316ZM100.064 55.3123C100.691 55.3123 101.192 54.8878 101.192 54.1435C101.192 53.3992 100.722 52.9151 100.042 52.9151C99.3615 52.9151 98.9511 53.4588 98.9511 54.1058C98.9511 54.7528 99.3773 55.3111 100.065 55.3111L100.064 55.3123Z"
        fill="white"
      />
      <path
        d="M104.734 50.535C105.077 50.535 105.347 50.788 105.347 51.1151C105.347 51.4423 105.078 51.688 104.734 51.688C104.39 51.688 104.135 51.4423 104.135 51.1151C104.135 50.788 104.39 50.535 104.734 50.535ZM104.271 55.997V52.2316H105.191V55.997H104.271Z"
        fill="white"
      />
      <path
        d="M108.276 53.0647C108.403 52.425 108.903 52.1489 109.306 52.1489C109.433 52.1489 109.508 52.1708 109.62 52.2012V53.1012C109.479 53.0489 109.306 53.027 109.187 53.027C108.851 53.027 108.29 53.2131 108.29 54.2554V55.997H107.371V52.2316H108.274V53.0647H108.276Z"
        fill="white"
      />
      <path
        d="M114.912 55.3573C114.404 55.9228 113.784 56.0785 113.201 56.0785C111.841 56.0785 111.026 55.2004 111.026 54.1289C111.026 53.0574 111.9 52.1489 113.096 52.1489C114.119 52.1489 115.084 52.7886 115.084 54.2396H111.946C111.946 54.8355 112.432 55.3038 113.246 55.3038C113.716 55.3038 114.068 55.1323 114.389 54.8428L114.912 55.3561V55.3573ZM111.991 53.623H114.143C114.001 53.0428 113.486 52.8786 113.096 52.8786C112.706 52.8786 112.185 53.0428 111.99 53.623H111.991Z"
        fill="white"
      />
      <path
        d="M117.325 54.7991C117.423 55.1712 117.788 55.3427 118.133 55.3427C118.401 55.3427 118.701 55.2466 118.701 54.9486C118.701 54.7553 118.521 54.6434 118.289 54.5473L117.736 54.3393C116.981 54.0559 116.787 53.6327 116.787 53.2897C116.787 52.5904 117.385 52.1514 118.169 52.1514C118.648 52.1514 119.245 52.3155 119.492 52.9918L118.752 53.2593C118.654 52.9541 118.378 52.8726 118.177 52.8726C117.937 52.8726 117.683 53.0064 117.683 53.2666C117.683 53.4746 117.87 53.5792 118.079 53.6534L118.639 53.8541C119.447 54.1216 119.603 54.6057 119.603 54.9328C119.603 55.6772 118.908 56.0785 118.123 56.0785C117.51 56.0785 116.815 55.8255 116.599 55.1031L117.324 54.7978L117.325 54.7991Z"
        fill="white"
      />
      <path d="M39.196 61.4457H0.0744972V62.9854H39.196V61.4457Z" fill="#438ACA" />
      <path d="M119.628 61.4457H79.5876V62.9854H119.628V61.4457Z" fill="#E1251B" />
      <path
        d="M16.7561 34.0382L16.8293 31.2689C16.1442 32.3623 15.2868 33.1735 14.2536 33.7014C13.2204 34.2292 11.9649 34.4943 10.486 34.4943C7.50359 34.4943 5.01094 33.48 3.00681 31.4514C1.00146 29.4227 0 26.9149 0 23.9278C0 20.9408 0.990464 18.5145 2.97017 16.5041C4.94988 14.4936 7.4181 13.4891 10.3761 13.4891C11.6828 13.4891 12.8663 13.7469 13.9227 14.2638C14.9791 14.7807 15.9488 15.5785 16.8281 16.6597L16.7182 13.9999H21.3017V34.0395H16.7548L16.7561 34.0382ZM4.82164 23.8549C4.82164 25.5673 5.39931 26.9854 6.55343 28.1092C7.70876 29.233 9.15355 29.7949 10.889 29.7949C12.4779 29.7949 13.8408 29.2305 14.9766 28.1007C16.1137 26.9708 16.6816 25.6293 16.6816 24.075C16.6816 22.3869 16.1076 20.9724 14.9583 19.8304C13.8091 18.6884 12.379 18.118 10.6692 18.118C8.95936 18.118 7.66235 18.6714 6.52656 19.7757C5.38954 20.8812 4.82164 22.2422 4.82164 23.8573"
        fill="white"
      />
      <path
        d="M40.791 13.9986H45.0069V30.7228C45.0069 34.1964 44.0689 36.9231 42.193 38.9031C40.3171 40.8831 37.7597 41.8731 34.5209 41.8731C32.0893 41.8731 30.0473 41.3027 28.3986 40.1607C26.7486 39.0186 25.5505 37.3366 24.8056 35.1134H29.3524C29.8409 36.0365 30.5444 36.7468 31.4604 37.2442C32.3763 37.7428 33.4401 37.9909 34.6504 37.9909C36.4224 37.9909 37.9039 37.3537 39.0958 36.0778C40.2878 34.8032 40.8826 33.1626 40.8826 31.1595V30.9041C39.9544 31.9731 38.9114 32.7685 37.7573 33.2903C36.602 33.8132 35.316 34.0735 33.898 34.0735C30.9767 34.0735 28.5293 33.0774 26.5557 31.0853C24.5821 29.0931 23.5953 26.6461 23.5953 23.743C23.5953 20.8399 24.5698 18.45 26.519 16.4651C28.4682 14.4791 30.8729 13.4866 33.7319 13.4866C35.1743 13.4866 36.4786 13.7481 37.6462 14.2699C38.8137 14.7916 39.8921 15.5992 40.8814 16.6926L40.7898 13.9962L40.791 13.9986ZM28.252 23.6724C28.252 25.4214 28.8505 26.8845 30.0485 28.063C31.2466 29.2415 32.7134 29.8301 34.4488 29.8301C36.1843 29.8301 37.7512 29.25 38.9676 28.0897C40.184 26.9295 40.7922 25.5296 40.7922 23.8901C40.7922 22.1169 40.1962 20.6416 39.0055 19.4631C37.8135 18.2846 36.3199 17.6959 34.5233 17.6959C32.7268 17.6959 31.2637 18.2664 30.0595 19.4084C28.8553 20.5504 28.2532 21.9709 28.2532 23.6712"
        fill="white"
      />
      <path
        d="M57.5422 18.0608C55.7579 18.2068 54.5048 18.6714 53.7843 19.4546C53.0625 20.2378 52.7022 21.6924 52.7022 23.8184V34.0395H48.0088V13.9986H52.4079V16.3119C53.1284 15.4131 53.8893 14.7576 54.6905 14.3441C55.4904 13.9305 56.4406 13.7189 57.5409 13.7068V18.0608H57.5422Z"
        fill="white"
      />
      <path
        d="M88.4041 42.1869C88.5213 42.8741 88.7631 43.1818 89.5093 43.1818H91.6539V44.3724H89.5899C88.8047 44.3724 88.3735 44.2301 87.9204 43.8507C87.322 43.3459 87.013 42.5481 87.013 41.5301C87.013 40.5122 87.3904 39.6572 87.9913 39.2132C88.3601 38.9445 88.812 38.818 89.4971 38.818H91.6539V40.0062H89.5093C88.7619 40.0062 88.5213 40.2969 88.409 40.9962H91.5245V42.1869H88.4041Z"
        fill="white"
      />
      <path
        d="M110.926 42.1869C111.044 42.8741 111.291 43.1818 112.031 43.1818H114.181V44.3724H112.112C111.324 44.3724 110.894 44.2301 110.443 43.8507C109.844 43.3459 109.538 42.5481 109.538 41.5301C109.538 40.5122 109.913 39.6572 110.515 39.2132C110.884 38.9445 111.336 38.818 112.021 38.818H114.181V40.0062H112.031C111.291 40.0062 111.048 40.2969 110.929 40.9962H114.043V42.1869H110.926Z"
        fill="white"
      />
      <path
        d="M59.8431 38.7426C61.5101 38.7426 62.4957 39.8262 62.4957 41.6676C62.4957 43.38 61.4661 44.4551 59.8321 44.4551C58.198 44.4551 57.2161 43.397 57.2161 41.5447C57.2161 39.8554 58.259 38.7426 59.8431 38.7426ZM58.6181 41.6214C58.6083 42.7038 59.0456 43.2864 59.8724 43.2864C60.6992 43.2864 61.0924 42.7196 61.0924 41.5873C61.0924 40.455 60.6711 39.9065 59.8516 39.9065C59.0321 39.9065 58.6254 40.4696 58.6181 41.6214Z"
        fill="white"
      />
      <path
        d="M66.7482 38.818H68.1246V44.3736H65.0665C63.9393 44.3469 63.4324 43.6488 63.4324 42.2392V38.818H64.8003V42.39C64.8003 42.8874 65.0433 43.1818 65.4634 43.1818H66.747V38.8168L66.7482 38.818Z"
        fill="white"
      />
      <path
        d="M84.623 38.818H85.992V44.3736H82.9327C81.8091 44.3469 81.2974 43.6488 81.2974 42.2392V38.818H82.6714V42.39C82.6714 42.8874 82.9144 43.1818 83.3321 43.1818H84.6218V38.8168L84.623 38.818Z"
        fill="white"
      />
      <path
        d="M79.987 46.4558H78.6142V44.3724H77.3038C75.8321 44.3724 74.9052 43.3204 74.9052 41.6664C74.9052 40.6435 75.2227 39.7995 75.7992 39.2972C76.2254 38.9189 76.6016 38.8168 77.5322 38.8168H79.987V46.4546V46.4558ZM77.6213 40.0062C76.7738 40.0062 76.3146 40.5657 76.3146 41.5861C76.3146 42.6065 76.7848 43.1818 77.6702 43.1818H78.6142V40.0062H77.6213Z"
        fill="white"
      />
      <path
        d="M93.4053 37.0897H94.7768V38.818H96.0396V40.0062H94.7768V42.0215C94.7768 42.5919 94.7926 42.7634 94.8842 42.9142C95.0015 43.0978 95.2152 43.1818 95.5046 43.1818H96.0396V44.3724H95.0491C94.0562 44.3724 93.6996 43.9261 93.5286 43.4031C93.4333 43.1051 93.4065 42.8668 93.4065 42.283V40.005H92.5345V38.8168H93.4065V37.0885L93.4053 37.0897Z"
        fill="white"
      />
      <path
        d="M100.531 40.9038C100.531 40.2239 100.271 40.0062 99.4836 40.0062H97.3439V38.818H99.4836C99.7181 38.818 99.9013 38.8289 100.044 38.8289C101.46 38.8545 101.936 39.8554 101.897 41.2455V44.3749H99.1844C98.4174 44.3749 98.2122 44.3627 97.935 44.2849C97.3769 44.1207 96.9775 43.4834 96.9775 42.7257C96.9775 42.148 97.2035 41.6019 97.565 41.3076C97.8361 41.0862 98.2367 40.9962 99.0219 40.9962H100.531V40.9038ZM99.1148 42.1869H98.8876C98.5689 42.1869 98.3747 42.3766 98.3747 42.6953C98.3747 43.0601 98.5689 43.1818 99.1135 43.1818H100.53V42.1869H99.1135H99.1148Z"
        fill="white"
      />
      <path
        d="M107.329 44.3736H105.958V41.2455C105.958 40.6459 105.981 40.4477 106.074 40.1181C106.142 39.8554 106.269 39.6316 106.407 39.4358C106.734 39.0247 107.144 38.8472 107.807 38.8289C107.953 38.8289 108.139 38.8192 108.375 38.8192H109.272V40.0074H108.375C107.586 40.0074 107.329 40.2251 107.329 40.905V44.3736Z"
        fill="white"
      />
      <path
        d="M103.239 36.7638H104.612V38.2451H103.239V36.7638ZM104.612 44.3736H103.239V38.818H104.612V44.3736Z"
        fill="white"
      />
      <path
        d="M48.1712 36.7589V44.3736H49.544V38.087H50.8324C51.2538 38.087 51.4943 38.3777 51.4943 38.8824V44.3736H52.8805V38.087H54.1751C54.5915 38.087 54.8382 38.3777 54.8382 38.8824V44.3736H56.2073V38.8982C56.2366 37.2904 55.4953 36.7589 54.3986 36.7589H48.1737H48.1712Z"
        fill="white"
      />
      <path
        d="M73.7511 38.8131V40.0014C73.7511 40.0014 71.4209 40.0062 71.3 40.0062C71.1791 40.0062 70.6038 39.9685 70.6038 40.5122C70.6038 41.0558 71.1729 41.0315 71.3 41.0315H72.3319C73.2846 41.0315 74.1004 41.4912 74.1004 42.6174C74.1004 43.8774 73.3603 44.3737 72.3319 44.3737H69.4277V43.183H72.1023C72.4516 43.183 72.757 43.0614 72.757 42.6782C72.757 42.2951 72.4638 42.1735 72.1023 42.1735H71.0264C70.1532 42.1735 69.2702 41.7868 69.2702 40.4355C69.2702 39.4054 70.0542 38.818 71.0264 38.818L73.7499 38.8131H73.7511Z"
        fill="white"
      />
      <path
        d="M119.646 38.8131V40.0014C119.646 40.0014 117.317 40.0062 117.2 40.0062C117.082 40.0062 116.501 39.9685 116.501 40.5122C116.501 41.0558 117.07 41.0315 117.2 41.0315H118.233C119.185 41.0315 120 41.4912 120 42.6174C120 43.8774 119.257 44.3737 118.233 44.3737H115.332V43.183H118.002C118.351 43.183 118.657 43.0614 118.657 42.6782C118.657 42.2951 118.359 42.1735 118.002 42.1735H116.924C116.056 42.1735 115.17 41.7868 115.17 40.4355C115.17 39.4054 115.954 38.818 116.924 38.818L119.647 38.8131H119.646Z"
        fill="white"
      />
      <path d="M60.8201 33.9057V14.6603H65.3608V33.9045H60.8201V33.9057Z" fill="white" />
      <path d="M25.9914 44.6193H0.0744972V39.1549C11.4654 40.5949 19.9033 42.6345 25.9914 44.6193Z" fill="white" />
      <path
        d="M78.4995 33.8935H82.9303C79.0954 29.6149 74.5266 25.0942 69.1285 20.582V21.7277C72.2062 25.2389 75.3766 29.2877 78.4995 33.8935Z"
        fill="white"
      />
      <path
        d="M92.6078 33.8935C86.7028 28.8122 78.9464 23.2419 69.1285 18.2554V19.0654C73.9648 22.7226 79.6279 27.5996 85.528 33.8935H92.6078Z"
        fill="white"
      />
      <path
        d="M119.725 33.8935C110.116 27.467 91.9947 17.4941 69.1285 14.968V15.3657C78.4323 17.5062 93.1744 22.4708 108.984 33.8935H119.724H119.725Z"
        fill="white"
      />
      <path d="M75.572 33.8935C73.6693 30.9284 71.5345 27.8672 69.1285 24.7695V33.8935H75.572Z" fill="white" />
      <path
        d="M104.261 33.8935C96.7675 28.1432 85.1677 21.0685 69.1285 16.2973V17.0161C76.4514 20.2573 86.036 25.5418 96.1935 33.8935H104.261Z"
        fill="white"
      />
      <path d="M79.6389 61.4603H39.1484V63H79.6389V61.4603Z" fill="white" />
      <path
        d="M110.285 33.8412L107.781 31.6459C112.056 27.242 114.348 25.1355 119.617 20.503L119.62 21.6486C116.552 25.1696 113.585 29.3497 110.286 33.8412H110.285Z"
        fill="white"
      />
      <path
        d="M104.613 27.2639C108.947 24.1796 113.933 21.0819 119.61 18.1763L119.613 18.9864C114.788 22.6581 111.46 24.9409 105.578 31.2519"
        fill="white"
      />
      <path
        d="M92.9302 21.5295C100.728 18.5193 109.718 16.0091 119.599 14.8877V15.2854C113.723 16.6561 105.676 19.1615 96.5647 23.7174"
        fill="white"
      />
      <path
        d="M98.6898 24.7999C104.558 21.6669 111.515 18.6507 119.604 16.2182L119.607 16.937C112.292 20.2001 111.235 20.0457 100.553 27.7845"
        fill="white"
      />
      <path
        d="M64.5963 6.06649C66.184 3.59513 69.65 2.9773 69.65 2.9773C69.65 2.9773 70.544 6.37054 68.9575 8.84311C67.3711 11.3145 63.9026 11.9311 63.9026 11.9311C63.9026 11.9311 63.0086 8.53905 64.5951 6.06649H64.5963Z"
        fill="#CDD953"
      />
      <path
        d="M58.5082 9.15811C57.3113 7.07716 58.1748 4.34554 58.1748 4.34554C58.1748 4.34554 60.9789 4.98284 62.1769 7.06257C63.3738 9.1423 62.5104 11.8751 62.5104 11.8751C62.5104 11.8751 59.7051 11.2366 58.5082 9.15689V9.15811Z"
        fill="#CDD953"
      />
      <path
        d="M61.642 3.00527C61.6225 1.35486 63.0636 0 63.0636 0C63.0636 0 64.5365 1.32081 64.5548 2.97365C64.5743 4.62405 63.1344 5.98013 63.1344 5.98013C63.1344 5.98013 61.6616 4.65811 61.642 3.00649V3.00527Z"
        fill="#CDD953"
      />
    </>
  ),
})
