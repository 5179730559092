'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Creation',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M68.9 47.8C68.1 40.7 63.2 34.5 56.4 32.1C54.4 31.4 52.2 31 50 31C49.3 31 48.6 31 47.9 31.1C39 32.1 31.9 39.5 31.2 48.5C30.7 54.6 33.1 60.5 37.8 64.5C39.4 65.9 40.3 67.9 40.3 70.2V75.5C40.3 75.7 40.4 75.9 40.6 76C43.8 77.2 46.9 77.6 49.8 77.6C55.2 77.6 59.3 76.1 59.6 76C59.8 75.9 59.9 75.7 59.9 75.5V70.2C59.9 67.9 60.8 65.8 62.5 64.4C67.2 60.3 69.6 54.2 68.9 47.8ZM61.2 62.9C59.1 64.7 57.9 67.3 57.9 70.3V74.6C56.2 75.1 53.4 75.8 49.8 75.8C47.2 75.8 44.7 75.4 42.3 74.7V70.4C42.3 67.5 41.2 65 39.1 63.1C34.9 59.5 32.7 54.3 33.2 48.8C33.8 40.8 40.2 34.1 48.1 33.2C48.7 33.1 49.4 33.1 50 33.1C52 33.1 54 33.4 55.8 34.1C61.9 36.3 66.2 41.8 67 48.1C67.5 53.7 65.4 59.2 61.2 62.9Z"
        fill="#1D1D1B"
      />
      <path
        d="M59.1 77.8C58.5 77.8 58.1 78.2 58.1 78.8V79.1C56.7 79.6 53.7 80.5 49.9 80.5C47.2 80.5 44.6 80 42.2 79.1V78.7C42.2 78.1 41.8 77.7 41.2 77.7C40.6 77.7 40.2 78.1 40.2 78.7V79.4C40.2 80 40.5 80.5 41 80.8H41.1C43.8 81.9 46.8 82.5 49.9 82.5C54.4 82.5 57.9 81.3 59.2 80.8C59.2 80.8 59.3 80.8 59.3 80.7C59.8 80.4 60.1 79.9 60.1 79.3V78.7C60.1 78.3 59.6 77.8 59.1 77.8Z"
        fill="#1D1D1B"
      />
      <path
        d="M57.8 82.1C57.2 82.1 56.8 82.5 56.8 83.1V83.4C55.6 83.9 53 84.8 49.8 84.8C47.5 84.8 45.3 84.3 43.3 83.4V82.9C43.3 82.3 42.9 81.9 42.3 81.9C41.7 81.9 41.3 82.3 41.3 82.9V83.6C41.3 84.2 41.6 84.7 42 85C42 85 42.1 85 42.1 85.1C44.5 86.2 47.1 86.8 49.8 86.8C53.7 86.8 56.8 85.6 58 85.1C58.1 85.1 58.1 85.1 58.1 85C58.5 84.7 58.8 84.2 58.8 83.7V83.1C58.8 82.5 58.4 82.1 57.8 82.1Z"
        fill="#1D1D1B"
      />
      <path
        d="M56.8 86.3C56.2 86.3 55.8 86.7 55.8 87.3V87.6C54.8 88.1 52.6 88.9 49.9 88.9C47.9 88.9 46.1 88.5 44.4 87.6V87.1C44.4 86.5 44 86.1 43.4 86.1C42.8 86.1 42.4 86.5 42.4 87.1V87.8C42.4 88.3 42.6 88.8 43 89.1C43 89.1 43.1 89.1 43.1 89.2C45.2 90.3 47.5 90.9 49.9 90.9C53.3 90.9 56 89.7 57.1 89.2C57.2 89.2 57.2 89.1 57.3 89.1C57.7 88.8 57.9 88.3 57.9 87.8V87.2C57.8 86.8 57.4 86.3 56.8 86.3Z"
        fill="#1D1D1B"
      />
      <path
        d="M36.1 27.9C35.8 27.9 35.6 27.8 35.4 27.6L27.3 18.6C26.9 18.2 27 17.6 27.4 17.2C27.8 16.8 28.4 16.9 28.8 17.3L36.9 26.3C37.3 26.7 37.2 27.3 36.8 27.7C36.6 27.8 36.3 27.9 36.1 27.9Z"
        fill="#FF6C4A"
      />
      <path
        d="M65.1 27.1C64.9 27.1 64.7 27 64.5 26.9C64.1 26.6 64 25.9 64.3 25.5L71.3 16.1C71.6 15.7 72.3 15.6 72.7 15.9C73.1 16.2 73.2 16.9 72.9 17.3L65.9 26.7C65.7 27 65.4 27.1 65.1 27.1Z"
        fill="#FF6C4A"
      />
      <path
        d="M49.9 24.7C49.3 24.7 48.9 24.2 48.9 23.7L49 12C49 11.4 49.4 11 50 11C50.6 11 51 11.5 51 12L50.9 23.8C50.9 24.3 50.5 24.7 49.9 24.7Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
