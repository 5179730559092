import { TFunction } from 'next-i18next'

import { s3ImageURL } from '../utils'

export const BeRegionsMap: Record<string, string> = {
  Limburg: s3ImageURL('maps/belgique/Limbourg.svg'),
  Anvers: s3ImageURL('maps/belgique/Anvers.svg'),
  Bruxelles: s3ImageURL('maps/belgique/Bruxelles.svg'),
  'Flandre orientale': s3ImageURL('maps/belgique/Flandre_orientale.svg'),
  'Flandre occidentale': s3ImageURL('maps/belgique/Flandre_occidentale.svg'),
  'Brabant Flamand': s3ImageURL('maps/belgique/Brabant_Flamand.svg'),
  'Brabant Wallon': s3ImageURL('maps/belgique/Brabant_Wallon.svg'),
  Hainaut: s3ImageURL('maps/belgique/Hainaut.svg'),
  Namur: s3ImageURL('maps/belgique/Namur.svg'),
  Luxembourg: s3ImageURL('maps/belgique/Luxembourg.svg'),
  Liège: s3ImageURL('maps/belgique/Liege.svg'),
}

export const FranceRegionsMap: Record<string, string> = {
  'Auvergne-Rhône-Alpes': s3ImageURL('maps/france/Auvergne_Rhone_Alpes.svg', 'miimosa'),
  'Bourgogne Franche-Comté': s3ImageURL('maps/france/Bourgogne_Franche_Comte.svg', 'miimosa'),
  Bretagne: s3ImageURL('maps/france/Bretagne.svg', 'miimosa'),
  'Centre-Val de Loire': s3ImageURL('maps/france/Centre_Val_de_Loire.svg', 'miimosa'),
  Corse: s3ImageURL('maps/france/Corse.svg', 'miimosa'),
  'DROM-COM': s3ImageURL('maps/france/Outre_Mer.svg', 'miimosa'),
  Normandie: s3ImageURL('maps/france/Normandie.svg', 'miimosa'),
  'Nouvelle-Aquitaine': s3ImageURL('maps/france/Nouvelle_Aquitaine.svg', 'miimosa'),
  'Hauts-de-France': s3ImageURL('maps/france/Hauts_de_France.svg', 'miimosa'),
  'Grand Est': s3ImageURL('maps/france/Grand_Est.svg', 'miimosa'),
  'Île-de-France': s3ImageURL('maps/france/IDF.svg', 'miimosa'),
  Occitanie: s3ImageURL('maps/france/Occitanie.svg', 'miimosa'),
  "Provence-Alpes-Côte d'Azur": s3ImageURL('maps/france/PACA.svg', 'miimosa'),
  'Pays de la Loire': s3ImageURL('maps/france/Pays_de_la_Loire.svg', 'miimosa'),
}

export const getRegionsFR = (t: TFunction) => {
  return [
    {
      slug: '84',
      ...(t && { title: t('region.fr.84', { ns: 'common' }) }),
    },
    {
      slug: '27',
      ...(t && { title: t('region.fr.27', { ns: 'common' }) }),
    },
    {
      slug: '53',
      ...(t && { title: t('region.fr.53', { ns: 'common' }) }),
    },
    {
      slug: '24',
      ...(t && { title: t('region.fr.24', { ns: 'common' }) }),
    },
    {
      slug: '94',
      ...(t && { title: t('region.fr.94', { ns: 'common' }) }),
    },
    {
      slug: '44',
      ...(t && { title: t('region.fr.44', { ns: 'common' }) }),
    },
    {
      slug: '32',
      ...(t && { title: t('region.fr.32', { ns: 'common' }) }),
    },
    {
      slug: '11',
      ...(t && { title: t('region.fr.11', { ns: 'common' }) }),
    },
    {
      slug: '28',
      ...(t && { title: t('region.fr.28', { ns: 'common' }) }),
    },
    {
      slug: '75',
      ...(t && { title: t('region.fr.75', { ns: 'common' }) }),
    },
    {
      slug: '76',
      ...(t && { title: t('region.fr.76', { ns: 'common' }) }),
    },
    {
      slug: '52',
      ...(t && { title: t('region.fr.52', { ns: 'common' }) }),
    },
    {
      slug: '93',
      ...(t && { title: t('region.fr.93', { ns: 'common' }) }),
    },
    {
      slug: '00',
      title: 'Outre-mer',
    },
  ]
}

export const getRegionsBE = (t: TFunction) => {
  return [
    {
      slug: '14',
      ...(t && { title: t('region.be.14', { ns: 'common' }) }),
    },
    {
      slug: '15',
      ...(t && { title: t('region.be.15', { ns: 'common' }) }),
    },
    {
      slug: '16',
      ...(t && { title: t('region.be.16', { ns: 'common' }) }),
    },
    {
      slug: '17',
      ...(t && { title: t('region.be.17', { ns: 'common' }) }),
    },
    {
      slug: '18',
      ...(t && { title: t('region.be.18', { ns: 'common' }) }),
    },
    {
      slug: '19',
      ...(t && { title: t('region.be.19', { ns: 'common' }) }),
    },
    {
      slug: '20',
      ...(t && { title: t('region.be.20', { ns: 'common' }) }),
    },
    {
      slug: '21',
      ...(t && { title: t('region.be.21', { ns: 'common' }) }),
    },
    {
      slug: '22',
      ...(t && { title: t('region.be.22', { ns: 'common' }) }),
    },
    {
      slug: '23',
      ...(t && { title: t('region.be.23', { ns: 'common' }) }),
    },
    {
      slug: '24',
      ...(t && { title: t('region.be.24', { ns: 'common' }) }),
    },
  ]
}
