'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Communication',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M56.5 20.2C56.3 19.1 55.6 18.3 54.6 18.1C53.5 17.8 52.3 18.3 51.5 19.3C40.5 35.2 29.4 42.6 12.1 45.8C10 46.3 9.80001 47.9 10 49.2L14.8 67.1C15 67.7 15.4 68.2 16 68.6C17.7 69.5 20.4 68.6 23.6 67.5C24.4 67.2 25.3 67 26 66.7L30.3 82C30.6 83.4 31.6 84.2 32.9 84.2C33.1 84.2 33.3 84.2 33.5 84.1L41.5 84.2H41.6C42.4 84 43 83.5 43.4 82.8C43.8 82.2 43.8 81.4 43.6 80.8L39.4 65.6C51.1 66.9 61.6 70.7 65.7 72.3H65.8C67.4 72.8 68.5 72.6 69.2 72C69.8 71.4 70 70.5 69.7 69.2L56.5 20.2ZM48.7 26.4L60.2 68.1C56.3 66.8 51.3 65.4 45.8 64.4C42.8 63.7 40.1 63.4 37.6 63.3L36.8 63.2C32.8 63.1 29.6 63.5 27.4 64L22.3 45.2C32.8 41.8 41 35.9 48.7 26.4ZM16.9 66.8C16.8 66.8 16.7 66.7 16.7 66.6L12 48.7C11.9 48 12 47.9 12.5 47.7C15.3 47.2 17.9 46.5 20.4 45.8L25.5 64.6C24.4 65 23.7 65.3 23.5 65.3C23.3 65.4 23.1 65.4 23 65.5C20.7 66.3 17.9 67.2 16.9 66.8ZM41.8 81.3C41.8 81.4 41.8 81.6 41.7 81.7C41.6 81.8 41.5 82 41.2 82.1L33.2 82H33.1C32.7 82.1 32.4 82.1 32.2 81.4L27.9 66C30.1 65.5 33.3 65.1 37.4 65.2L41.8 81.3ZM68 70.5C68 70.5 67.7 70.7 66.6 70.4C65.6 70 64.2 69.5 62.6 68.9L50.2 24.4C51.2 23.1 52.1 21.9 53.1 20.5C53.5 20.1 53.9 20 54.1 20C54.3 20.1 54.5 20.2 54.5 20.6L67.8 69.7C68.1 70.3 68 70.5 68 70.5Z"
        fill="#2A2A2A"
      />
      <path
        d="M68.3002 29.2989C68.6002 29.2989 68.9001 29.1989 69.1001 28.8989L77.9002 17.5989C78.2002 17.1989 78.2001 16.4989 77.7001 16.1989C77.3001 15.8989 76.6002 15.8989 76.3002 16.3989L67.5001 27.6989C67.2001 28.0989 67.2001 28.7989 67.7001 29.0989C67.9001 29.1989 68.1002 29.2989 68.3002 29.2989Z"
        fill="#FF6C4A"
      />
      <path
        d="M89.4002 61.8989L77.0002 55.5989C76.5002 55.2989 75.9002 55.4989 75.7002 55.9989C75.5002 56.4989 75.6002 57.0989 76.1002 57.2989L88.5002 63.5989C88.6002 63.6989 88.8002 63.6989 89.0002 63.6989C89.4002 63.6989 89.7002 63.4989 89.9002 63.1989C90.1002 62.7989 89.9002 62.1989 89.4002 61.8989Z"
        fill="#FF6C4A"
      />
      <path
        d="M89.3002 39.3989C89.8002 39.2989 90.2001 38.6989 90.1001 38.1989C90.0001 37.6989 89.4002 37.2989 88.9002 37.3989L75.3002 40.4989C74.8002 40.5989 74.4002 41.1989 74.5002 41.6989C74.6002 42.1989 75.0002 42.4989 75.5002 42.4989C75.6002 42.4989 75.6001 42.4989 75.7001 42.4989L89.3002 39.3989Z"
        fill="#FF6C4A"
      />
    </>
  ),
})
