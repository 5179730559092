'use client'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Close',
  viewBox: '0 0 24 24',
  d: 'M12.67 12L19.29 5.34C19.3378 5.29511 19.3759 5.24089 19.402 5.18069C19.4281 5.12049 19.4415 5.05559 19.4415 4.99C19.4415 4.9244 19.4281 4.8595 19.402 4.7993C19.3759 4.7391 19.3378 4.68489 19.29 4.64C19.2435 4.59313 19.1882 4.55593 19.1273 4.53055C19.0663 4.50517 19.001 4.4921 18.935 4.4921C18.869 4.4921 18.8036 4.50517 18.7427 4.53055C18.6818 4.55593 18.6265 4.59313 18.58 4.64L12 11.29L5.34998 4.64C5.3035 4.59313 5.2482 4.55593 5.18727 4.53055C5.12634 4.50517 5.06099 4.4921 4.99498 4.4921C4.92898 4.4921 4.86362 4.50517 4.80269 4.53055C4.74176 4.55593 4.68646 4.59313 4.63998 4.64C4.59215 4.68489 4.55403 4.7391 4.52797 4.7993C4.50191 4.8595 4.48846 4.9244 4.48846 4.99C4.48846 5.05559 4.50191 5.12049 4.52797 5.18069C4.55403 5.24089 4.59215 5.29511 4.63998 5.34L11.26 12L4.63998 18.65C4.59312 18.6965 4.55592 18.7518 4.53054 18.8127C4.50515 18.8736 4.49208 18.939 4.49208 19.005C4.49208 19.071 4.50515 19.1364 4.53054 19.1973C4.55592 19.2582 4.59312 19.3135 4.63998 19.36C4.73862 19.4495 4.86683 19.4993 4.99998 19.5C5.13512 19.4997 5.26459 19.4457 5.35998 19.35L12 12.71L18.61 19.35C18.7054 19.4457 18.8348 19.4997 18.97 19.5C19.1001 19.4987 19.2249 19.4488 19.32 19.36C19.3668 19.3135 19.404 19.2582 19.4294 19.1973C19.4548 19.1364 19.4679 19.071 19.4679 19.005C19.4679 18.939 19.4548 18.8736 19.4294 18.8127C19.404 18.7518 19.3668 18.6965 19.32 18.65L12.67 12Z',
  defaultProps: {
    w: '100%',
    h: '100%',
  },
})
